export const last = <T>(array: Array<T>): T | undefined => [...array].pop();

export const throttle = <X extends Array<any>, Y>(wrapped: (...args: X) => Y, wait: number) => {
  let lastCallTime = 0;
  let timeout: any;
  return (...args: X) => {
    const now = Date.now();
    const timeSinceLastCall = now - lastCallTime;
    const callAndMarkTime = () => {
      lastCallTime = Date.now();
      return wrapped(...args);
    };
    const postponeCall = (f: () => Y) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }
      timeout = setTimeout(f, wait - timeSinceLastCall);
    };
    if (!lastCallTime || timeSinceLastCall > wait) {
      callAndMarkTime();
    } else {
      postponeCall(callAndMarkTime);
    }
  };
};

export const uniq = <T>(arg: Array<T>) => [...new Set(arg)];
