import { all, takeEvery } from 'redux-saga/effects';

import * as actions from '../../actions/account/constants';
import { deleteLicense, openCancelLicenceTypeform, postLicence, startTrial, updateLicence } from './licence';
import {
  fetchUserInfoRequest,
  fetchUserRequest,
  handleFetchInfoUser,
  login,
  logout,
  unvalidateLoginRequest,
  updateLang,
  updateUserRequest,
} from './session';
import { postOrganization, searchOrganizations } from './user';

function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, login),
    takeEvery(actions.USER_FETCH_REQUEST, fetchUserRequest),
    takeEvery(actions.UNVALIDATELOGIN, unvalidateLoginRequest),
    takeEvery(actions.USER_FETCH_INFO_REQUEST, fetchUserInfoRequest),
    takeEvery(actions.USER_FETCH_INFO_SUCCESS, handleFetchInfoUser),
    takeEvery(actions.LOGOUT, logout),
    takeEvery(actions.LICENCE_PROCESS_PAYMENT_REQUEST, postLicence),
    takeEvery(actions.NEW_ORGANIZATION_REQUEST, postOrganization),
    takeEvery(actions.NEW_TRIAL_REQUEST, startTrial),
    takeEvery(actions.LICENCE_UPDATE_REQUEST, updateLicence),
    takeEvery(actions.LICENCE_DELETE_REQUEST, deleteLicense),
    takeEvery(actions.OPEN_TRIAL_LICENCE_CANCEL_TYPEFORM, openCancelLicenceTypeform),
    takeEvery(actions.UPDATE_LANG, updateLang),
    takeEvery(actions.USER_UPDATE_REQUEST, updateUserRequest),
    takeEvery(actions.SEARCH_ORGANIZATIONS_REQUEST, searchOrganizations),
  ]);
}

export default rootSaga;
