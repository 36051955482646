import type { ConversationMode } from '../types';
import { ScribeCorrectionMode } from '../types';
import { startStopwatch } from './stopwatch';

export const sendWsMessage = (ws: WebSocket | null, message) => {
  if (ws) {
    ws.send(JSON.stringify(message));
  }
};

export const sendConnectionRequestMessage = (ws: WebSocket, fromSource, to, role, options = {}) => {
  const id = Date.now().toString();
  const message = {
    type: 'connection-request',
    fromSource,
    id,
    to,
    role,
    ...options,
  };
  startStopwatch('establishConnectTime');
  sendWsMessage(ws, message);
  return id;
};

export const sendWebRTCTrackMetadataMessage = (ws: WebSocket, metadata: any) => {
  return sendWsMessage(ws, {
    type: 'webrtc-track-metadata',
    ...metadata,
  });
};

export const sendAssignVoicingSpearkerMyself = (ws: WebSocket, speakerId) => {
  return sendWsMessage(ws, {
    type: 'assign-voicing-speaker-myself',
    speakerId,
  });
};

export const sendAssignVoicingSpearkerName = (ws: WebSocket, speakerId, speakerName) => {
  return sendWsMessage(ws, {
    type: 'assign-voicing-speaker-name',
    speakerId,
    speakerName,
  });
};

export const sendAssignBlocToDifferentSpeaker = (ws: WebSocket, speakerId: string, blocId: string) => {
  return sendWsMessage(ws, {
    type: 'assign-bloc-different-speaker',
    speakerId,
    blocId,
  });
};

export const sendResetRoomIdMessage = (ws: WebSocket, roomId) => {
  return sendWsMessage(ws, {
    type: 'connection-params-update',
    roomId,
  });
};

export const sendSaveTranscriptUpdateMessage = (ws: WebSocket, saveTranscript) => {
  return sendWsMessage(ws, {
    type: 'connection-params-update',
    saveTranscript,
  });
};

export const sendConversationModeUpdateMessage = (ws: WebSocket, conversationMode: ConversationMode) => {
  return sendWsMessage(ws, {
    type: 'connection-params-update',
    conversationMode,
  });
};

export const sendRoomStateUpdateMessage = (ws: WebSocket, state) => {
  return sendWsMessage(ws, {
    type: 'connection-params-update',
    state,
  });
};

export const sendUsePaidAsrUpdateMessage = (ws: WebSocket, usePaidASR) =>
  sendWsMessage(ws, {
    type: 'connection-params-update',
    usePaidASR,
  });

export const sendAccountUpdateMessage = (ws: WebSocket) =>
  sendWsMessage(ws, {
    type: 'account-update',
  });

export const getDefaultRoomId = (id) => `${id.slice(0, 8)}_00000000-0000-0000-0000-000000000000`;

export const isDefaultRoomId = (roomId: string | undefined) =>
  roomId?.includes('_00000000-0000-0000-0000-000000000000');

export const isConversationEnded = (status, avaId) => {
  const host = (status.id || '').split('_')[0];
  // eslint-disable-next-line
  for (let participant of status.participants || []) {
    // Host is present in the conversation.
    if (status.id && host === participant.avaId) {
      return false;
    }
  }
  // We are the host.
  if (status.id && host === avaId) {
    return false;
  }
  return true;
};

export const sendUserQueryMessage = (ws: WebSocket, avaNameStartsWith) =>
  sendWsMessage(ws, {
    type: 'user-query',
    avaNameStartsWith,
  });

export const sendEmailQueryMessage = (ws: WebSocket, emailEqualTo) =>
  sendWsMessage(ws, {
    type: 'user-query',
    emailEqualTo,
  });

export const updateDownAudio = (ws: WebSocket, speaker) => {
  if (speaker.audioLink) {
    return sendWsMessage(ws, {
      type: 'connection-params-update',
      downAudio: {
        forceSR: false,
        audioLink: true,
      },
    });
  } else {
    return sendWsMessage(ws, {
      type: 'connection-params-update',
      downAudio: {
        speakerId: speaker.avaId,
        trackId: speaker.trackId,
        forceSR: speaker.forceSR,
      },
    });
  }
};

export const sendMbackendMessage = (ws: WebSocket, data) => {
  return sendWsMessage(ws, {
    type: 'mbackend',
    content: JSON.stringify(data),
  });
};

export const sendBoostMessage = (ws: WebSocket, data) => {
  sendWsMessage(ws, {
    type: 'boost',
    ...data,
  });
};

export const sendAudioParams = (ws: WebSocket, audioParams) =>
  sendWsMessage(ws, {
    type: 'connection-params-update',
    audio: audioParams,
  });

export const sendUpdateTranscriptOwnerMessage = (ws, message) =>
  sendWsMessage(ws, {
    type: 'update-transcript-owner',
    ...message,
  });

export const sendUpdateTitleMessage = (ws: WebSocket, message) =>
  sendWsMessage(ws, {
    type: 'update-title',
    ...message,
  });

export const sendForceMute = (ws: WebSocket, avaId) =>
  sendWsMessage(ws, {
    type: 'force-mute',
    avaId,
  });

export const sendTypeOnly = (ws: WebSocket, type) =>
  sendWsMessage(ws, {
    type,
  });

export const sendRequestScribe = (ws: WebSocket) => {
  const correctionMode =
    (window.localStorage.getItem('scribe_correction_mode') as ScribeCorrectionMode) || ScribeCorrectionMode.Fast;

  sendWsMessage(ws, {
    type: 'request-scribe',
    correctionMode,
  });
};

export const sendUpdateScribeCorrectionMode = (ws: WebSocket, correctionMode: ScribeCorrectionMode) =>
  sendWsMessage(ws, {
    type: 'update-scribe-correction-mode',
    correctionMode,
  });

export const sendPing = (ws: WebSocket) =>
  sendWsMessage(ws, {
    type: 'ping',
    id: Date.now().toString(),
  });

export const respondPingMessage = (ws: WebSocket, pongMessage) =>
  sendWsMessage(ws, {
    type: 'PONG',
    serverTimeMs: pongMessage.serverTimeMs,
    clientTimeMs: Date.now(),
  });

export const sendAccessBoostWords = (ws: WebSocket, action, words?: string) => {
  sendWsMessage(ws, {
    type: 'access-boost-words',
    action,
    words,
  });
};
