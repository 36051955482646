import { combineReducers } from 'redux';
import type { ActionType } from 'typesafe-actions';

import type * as actions from '../../actions/account';
import type { ILicenceState } from './licence';
import licenceReducer, { initLicenceState } from './licence';
import type { ISessionState } from './session';
import sessionReducer, { initSessionState } from './session';
import type { IUserState } from './user';
import userReducer, { initUserState } from './user';

export type Actions = ActionType<typeof actions>;

interface IPersist {
  rehydrated: boolean;
  version: number;
}

export interface IState {
  session: ISessionState;
  user: IUserState;
  licence: ILicenceState;
  _persist?: IPersist;
}

export const initialState: IState = {
  licence: initLicenceState,
  session: initSessionState,
  user: initUserState,
};

const accountReducer = combineReducers({
  licence: licenceReducer,
  session: sessionReducer,
  user: userReducer,
});

export default accountReducer;
