import format from 'date-fns/format';
import uuidv4 from 'uuid/v4';

import { selectV1Websocket } from '../../selectors/v1Session';
import { addEditedWord } from '../../store/slices/conversation';
import { setIsWordBeingEdited } from '../../store/slices/uiState';
import type {
  ScribeTranscriptChangeSpeakerMutation,
  ScribeTranscriptMutation,
  Transcript,
  TranscriptEdit,
} from '../../types';
import { last } from '../../utils/lodash';
import {
  capitalizeWord,
  commonSubstring,
  endPunctuation,
  getLang,
  getText,
  getTextLang,
  getTranscriptWordCount,
  getWordCharacterIndex,
  removeEndPunctuation,
  shouldCapitalize,
  unCapitalizeWord,
} from '../../utils/scribeUtils';

export const scribeWordEditSetStarted =
  ({ indexTranscript }: { indexTranscript: number; index: number }) =>
  (dispatch: any, getState: any) => {
    const { transcripts } = getState().scribeConversation;
    if (!indexTranscript) return;

    const parentTranscript = transcripts[indexTranscript];
    if (!parentTranscript) {
      return;
    }

    dispatch({
      type: 'SCRIBE_WORD_EDIT_STARTED',
    });
  };

export const scribeWordEditSetStopped = () => ({
  type: 'SCRIBE_WORD_EDIT_STOPED',
});

export const scribeSetLastEditedWordIndex = (lastEditedWordIndex: number) => ({
  type: 'SCRIBE_SET_LAST_EDITED_WORD_INDEX',
  lastEditedWordIndex,
});

const getNewTranscriptId = (id, existingId, direction: number) => {
  if (existingId.includes(id)) {
    let intId;
    if (typeof id === 'string') {
      intId = parseInt(id, 10);
    } else {
      intId = id;
    }
    const sortedId = existingId.sort((a, b) => direction * (a - b));
    const nextId = sortedId[sortedId.indexOf(id) + 1] || (intId + direction * 1000).toString();
    const newId = Math.ceil((parseInt(nextId, 10) + intId) / 2).toString();
    return getNewTranscriptId(newId, existingId, direction);
  }
  return id.toString();
};

export const scribeJumpToNextBloc =
  ({
    transcriptId,
    skipCreateNextBloc = false,
    notEmpty = false,
  }: {
    transcriptId: number;
    skipCreateNextBloc: boolean;
    notEmpty: boolean;
  }) =>
  (dispatch: any, getState: any) => {
    if (!transcriptId) return;
    const { transcripts, transcriptsCurrent, lang } = getState().scribeConversation;

    const parentTranscript = transcripts[transcriptId];
    if (!parentTranscript) {
      return;
    }

    const nextTranscriptIndex = transcriptsCurrent.findIndex((tid) => tid === parentTranscript.id) + 1;
    const nextTranscriptId = transcriptsCurrent[nextTranscriptIndex];
    const nextTranscript = transcripts[nextTranscriptId];

    if (!nextTranscript) {
      if (skipCreateNextBloc) {
        return;
      }
      // eslint-disable-next-line
      scribeCreateTranscript(parentTranscript.id)(dispatch, getState);
      return;
    }

    const text = getText(nextTranscript, lang);
    if (!text && notEmpty) {
      scribeJumpToNextBloc({
        transcriptId: nextTranscriptId,
        skipCreateNextBloc,
        notEmpty,
      })(dispatch, getState);
      return;
    }
    dispatch({
      type: 'SCRIBE_TRANSCRIPT_SELECT_WORD',
      caret: 'all',
      index: 0,
      transcriptId: nextTranscriptId,
      wordText: getText(nextTranscript, lang).split(' ')[0],
    });
    dispatch(setIsWordBeingEdited(false));
    // eslint-disable-next-line
    handleChange(getState, dispatch);
  };

export const scribeJumpToNextHightlightedBloc =
  ({ transcriptId, direction }: { transcriptId: number; direction: -1 | 1 }) =>
  (dispatch: any, getState: any) => {
    if (!transcriptId) return;
    const { transcripts, transcriptsCurrent, lang } = getState().scribeConversation;

    const parentTranscript = transcripts[transcriptId];
    if (!parentTranscript) return;

    let currentIndex = transcriptsCurrent.findIndex((tid) => tid === parentTranscript.id) + direction;
    while (transcriptsCurrent[currentIndex] && !transcripts[transcriptsCurrent[currentIndex]].highlighted) {
      currentIndex += direction;
    }
    if (!transcriptsCurrent[currentIndex]) return;
    const nextTranscript = transcripts[transcriptsCurrent[currentIndex]];

    dispatch({
      type: 'SCRIBE_TRANSCRIPT_SELECT_WORD',
      caret: 'all',
      index: 0,
      transcriptId: nextTranscript.id,
      wordText: getText(nextTranscript, lang).split(' ')[0],
    });
    dispatch(setIsWordBeingEdited(false));
    // eslint-disable-next-line
    handleChange(getState, dispatch);
  };

export const scribeJumpToPreviousBloc =
  ({ indexTranscript, firstWord, notEmpty }: { indexTranscript: number; firstWord?: boolean; notEmpty?: boolean }) =>
  (dispatch: any, getState: any) => {
    if (!indexTranscript) return;
    const { transcripts, transcriptsCurrent, lang } = getState().scribeConversation;

    const parentTranscript = transcripts[indexTranscript];
    if (!parentTranscript) {
      return;
    }

    const previousTranscriptIndex = transcriptsCurrent.findIndex((tid) => tid === parentTranscript.id) - 1;
    const previousTranscriptId = transcriptsCurrent[previousTranscriptIndex];
    const previousTranscript = transcripts[previousTranscriptId];
    if (!previousTranscript) return;
    if (!getText(previousTranscript, lang).split(' ')) return;
    const text = getText(previousTranscript, lang);
    if (!text && notEmpty) {
      scribeJumpToPreviousBloc({
        indexTranscript: previousTranscriptId,
        firstWord,
        notEmpty,
      })(dispatch, getState);
      return;
    }

    const words = getText(previousTranscript, lang).split(' ');
    const newIndex = firstWord ? 0 : words.length - 1;
    dispatch({
      type: 'SCRIBE_TRANSCRIPT_SELECT_WORD',
      caret: 'all',
      index: newIndex,
      transcriptId: previousTranscriptId,
      wordText: words[newIndex],
    });
    dispatch(setIsWordBeingEdited(false));
    // eslint-disable-next-line
    handleChange(getState, dispatch);
  };

const dispatchMutation = (mutation, transcriptId: string, src: any, dispatch: any, getState: any) => {
  const transcript = getState().scribeConversation.transcripts[transcriptId];
  const socket = getState().v1Session.v1Socket;
  if (transcript && transcript.mutations && transcript.mutations.length) {
    const appliedMutation: ScribeTranscriptMutation = { ...mutation, parent: transcript.mutations[0].id };
    const wsMutationToSend = appliedMutation;
    /*
    NOTE: This code was here, when I realized that backendKnowUpdateField
    is always true. Which would mean that this never got triggered. I am keeping
    it here however, if it ever turns out that it was in the end needed.
    if (wsMutationToSend.type === 'updateField' && !backendKnowUpdateField) {
      wsMutationToSend = {
        ...wsMutationToSend,
        type: 'insert',
        data: {
          index: 0,
          text: '',
        },
      };
    }
     */
    const transcriptEditPayload: TranscriptEdit = {
      type: 'transcript-edit',
      data: {
        transcriptId: transcriptId,
        mutation: wsMutationToSend,
        origin: 'human',
      },
    } as const;

    socket.send(JSON.stringify(transcriptEditPayload));

    dispatch({
      type: 'RECEIVED_SCRIBE_CONVERSATION_TRANSCRIPT_MUTATION',
      mutation: appliedMutation,
      origin: 'human',
      transcriptId,
      trigger: {
        src,
      },
    });
  }
  // eslint-disable-next-line
  handleChange(getState, dispatch);
};

const dispatchCursor = (newState, pastState, dispatch, getState) => {
  const id = uuidv4();
  const mutatorHash = getState().scribeConversation.connectionHash;
  if (pastState.id !== '' && pastState.id !== newState.id) {
    const delMutation = {
      type: 'cursor',
      data: {
        mutatorHash,
        index: -1,
      },
      id,
    } as const;
    dispatchMutation(delMutation, pastState.id, undefined, dispatch, getState);
  }
  if (newState.id !== '') {
    const mutation = {
      type: 'cursor',
      data: {
        mutatorHash,
        index: newState.index,
      },
      id,
    } as const;
    dispatchMutation(mutation, newState.id, undefined, dispatch, getState);
  }
};

const dispatchSpeakerChangeMutationFromId =
  (speakerId: string, selectedTranscriptIndex: string) => (dispatch: any, getState: any) => {
    const id = uuidv4();
    const mutation = {
      type: 'changeSpeaker',
      data: {
        speakerId,
        index: 0,
      },
      id,
    } as const;
    dispatchMutation(mutation, selectedTranscriptIndex, undefined, dispatch, getState);
  };

export const dispatchSpeakerChangeMutation = (speakerIndex: number) => (dispatch: any, getState: any) => {
  const {
    scribeConversation: {
      status,
      transcripts,
      ui: { selectedTranscriptIndex },
    },
  } = getState();
  if (!transcripts[selectedTranscriptIndex]) {
    return;
  }
  let newSpeakerId;
  if (status.speakers) {
    newSpeakerId = status.speakers[speakerIndex].avaId;
  } else {
    newSpeakerId = (status.transcriptOwners || [])[speakerIndex];
  }
  dispatchSpeakerChangeMutationFromId(newSpeakerId, transcripts[selectedTranscriptIndex].id)(dispatch, getState);
};

const dispatchDeleteMutation = (dispatch: any, getState: any, { index, deleteLength, id, transcriptId, src, lang }) => {
  const { conversationEnded } = getState().conversation;

  if (conversationEnded) return;

  const mutation = {
    type: 'delete',
    data: {
      index,
      length: deleteLength,
      lang,
    },
    id,
  } as const;
  dispatchMutation(mutation, transcriptId, src, dispatch, getState);
};

const dispatchInsertMutation = (dispatch: any, getState: any, { index, text, id, transcriptId, src, lang }) => {
  const { conversationEnded } = getState().conversation;

  if (conversationEnded) return;

  const mutation = {
    type: 'insert',
    data: {
      index,
      text,
      lang,
    },
    id,
  } as const;
  dispatchMutation(mutation, transcriptId, src, dispatch, getState);
};

function select(state) {
  if (state.scribeConversation && state.scribeConversation.ui) {
    return {
      id: state.scribeConversation.ui.selectedTranscriptIndex,
      index: state.scribeConversation.ui.selectedWordIndex,
    };
  }
  return {
    id: '',
    index: 0,
  };
}
let pastState = { id: '', index: 0 };

function handleChange(getState, dispatch) {
  const currentState = select(getState());
  if (pastState.id !== currentState.id || pastState.index !== currentState.index) {
    const buf = pastState;
    pastState = currentState;
    dispatchCursor(currentState, buf, dispatch, getState);
  }
}

const processDeselect = (dispatch, getState, { src }): number => {
  const { selectedWordIndex, selectedWordInputValue, selectedTranscriptIndex } = getState().scribeConversation.ui;

  if (selectedWordIndex === undefined) return 0;
  if (selectedWordInputValue === undefined) return 0;

  const { transcripts, lang: globalLang } = getState().scribeConversation;

  const parentTranscript = transcripts[selectedTranscriptIndex];
  if (!parentTranscript || !getText(parentTranscript, globalLang)) return 0;

  const text = getText(parentTranscript, globalLang);
  const parentTranscriptWords = text.split(' ');

  const { startCharacterIndex, length, currentWordText, endCharacterIndex, withSpace } = getWordCharacterIndex(
    parentTranscriptWords,
    selectedWordIndex
  );

  const wordIsUnchanged = currentWordText === selectedWordInputValue;
  if (wordIsUnchanged && currentWordText !== '') return 0;

  dispatch(scribeSetLastEditedWordIndex(selectedWordIndex));

  const lang = getLang(parentTranscript.speechLang, globalLang);

  switch (selectedWordInputValue.trim() !== '') {
    case true:
      // eslint-disable-next-line
      dispatchUpdateWord(dispatch, getState, {
        deleteLength: length,
        endCharacterIndex,
        index: startCharacterIndex,
        newText: selectedWordInputValue,
        parentTranscript,
        src,
        lang,
        isDeselect: true,
      });
      return 0;

    case false:
      // eslint-disable-next-line
      dispatchDeleteWord(dispatch, getState, {
        index: withSpace.startCharacterIndexWithSpace,
        length: withSpace.lengthWithSpace,
        lang,
        parentTranscript,
        src,
      });

      return selectedWordIndex === getText(parentTranscript, lang).split(' ').length - 1 ? 0 : 1;

    default:
      return 0;
  }
};

const dispatchUpdateWord = (
  dispatch: any,
  getState: any,
  {
    deleteLength,
    endCharacterIndex,
    index,
    newText,
    parentTranscript,
    src,
    lang,
    isDeselect,
  }: {
    deleteLength: number;
    endCharacterIndex: number;
    lang: string;
    index: number;
    newText: string;
    parentTranscript: Transcript;
    src: any;
    isDeselect: boolean;
  }
) => {
  const deleteMutationId = uuidv4();

  dispatch(addEditedWord({ text: newText, transcriptionId: parentTranscript.id }));

  let text =
    getText(parentTranscript, getState().scribeConversation.lang)[endCharacterIndex] === ' ' && isDeselect
      ? `${newText.trim()}`
      : newText;

  const oldText = getText(parentTranscript, getState().scribeConversation.lang).slice(index, index + deleteLength);
  const substring = commonSubstring([oldText, text]);
  // eslint-disable-next-line no-param-reassign
  index += substring.length;
  // eslint-disable-next-line no-param-reassign
  deleteLength -= substring.length;
  text = text.slice(substring.length, text.length);

  if (deleteLength)
    dispatchDeleteMutation(dispatch, getState, {
      index,
      deleteLength,
      id: deleteMutationId,
      transcriptId: parentTranscript.id,
      lang,
      src,
    });

  dispatchInsertMutation(dispatch, getState, {
    index,
    text,
    id: uuidv4(),
    transcriptId: parentTranscript.id,
    lang,
    src,
  });
};

const dispatchDeleteWord = (dispatch: any, getState: any, { index, length, parentTranscript, src, lang }) => {
  const deleteMutationId = uuidv4();

  dispatchDeleteMutation(dispatch, getState, {
    lang,
    index,
    deleteLength: length,
    id: deleteMutationId,
    transcriptId: parentTranscript.id,
    src,
  });
};

export function scribeSelectTranscriptWord(
  {
    index,
    indexTranscript,

    // 1 if tab, -1 if maj tab
    direction = 0,

    caret = 'all',
    skipCommit = false,
    skipJumpNextBloc = false,
    skipCreateNextBloc = false,
  }: {
    index: number;
    caret: string;
    direction?: number;
    indexTranscript: string;
    skipCommit: boolean;
    skipJumpNextBloc: boolean;
    skipCreateNextBloc: boolean;
  },
  {
    src,
  }: {
    src?: string;
  } = {}
) {
  return (dispatch: any, getState: any) => {
    if (!indexTranscript) return;

    const newIndexTranscript = indexTranscript;

    if (index === undefined) return;
    let newIndex = index;

    let shift = 0;
    if (!skipCommit && typeof getState().scribeConversation.ui.selectedWordIndex === 'number') {
      shift = processDeselect(dispatch, getState, { src });
    }

    switch (direction) {
      case 1:
        newIndex += 1 - shift;
        break;
      case -1:
        newIndex -= 1;
        break;
      case 0:
        break;
      default:
        break;
    }

    const { transcripts, lang } = getState().scribeConversation;
    const parentTranscript = transcripts[newIndexTranscript];

    if (!parentTranscript) {
      return;
    }

    // Try to jump to the next transcript
    if (newIndex < 0) {
      scribeJumpToPreviousBloc({
        indexTranscript: parentTranscript.id,
        notEmpty: true,
      })(dispatch, getState);
      return;
    }
    const text = getText(parentTranscript, lang);
    if (newIndex >= text.split(' ').length || (newIndex === 0 && text.split(' ').length === 1 && text !== '_')) {
      if (!skipJumpNextBloc) {
        scribeJumpToNextBloc({
          transcriptId: parentTranscript.id,
          skipCreateNextBloc: skipCreateNextBloc || text === '_',
          notEmpty: true,
        })(dispatch, getState);
        return;
      }
      newIndex = Math.min(newIndex, text.split(' ').length - 1);
    }

    dispatch({
      type: 'SCRIBE_TRANSCRIPT_SELECT_WORD',
      caret,
      index: newIndex,
      transcriptId: indexTranscript,
      wordText: text.split(' ')[newIndex],
    });
    dispatch(setIsWordBeingEdited(false));
    const parentTranscriptText = getText(parentTranscript, lang);
    const parentWords = parentTranscriptText.split(' ');
    const { endCharacterIndex } = getWordCharacterIndex(parentWords, newIndex, 1);
    const lang2 = getLang(parentTranscript.speechLang, lang);
    if (parentTranscript.indexes[lang2] < endCharacterIndex) {
      dispatchInsertMutation(dispatch, getState, {
        index: endCharacterIndex,
        text: '',
        id: uuidv4(),
        transcriptId: indexTranscript,
        src: 'SCRIBE_SELECT_TRANSCRIPT_WORD',
        lang: lang2,
      });
    }
    handleChange(getState, dispatch);
  };
}

export const dispatchUpdateFieldMutation = (
  dispatch: any,
  getState: any,
  {
    field,
    value,
    transcriptId,
  }: {
    field: string;
    value: any;
    transcriptId: any;
  }
) => {
  const { conversationEnded } = getState().conversation;
  if (conversationEnded) return;

  const mutation = {
    type: 'updateField',
    data: {
      field,
      value,
    },
    id: uuidv4(),
  } as const;
  dispatchMutation(mutation, transcriptId, 'updateField', dispatch, getState);
};

export const highlightTranscript = (transcriptId: any) => (dispatch: any, getState: any) => {
  const {
    scribeConversation: { transcripts },
  } = getState();
  const { highlighted } = transcripts[transcriptId];
  return dispatchUpdateFieldMutation(dispatch, getState, {
    field: 'highlighted',
    value: !highlighted,
    transcriptId,
  });
};

export const updateSpeechLang = (transcriptId: any, speechLang: string) => (dispatch: any, getState: any) => {
  const {
    conversation: { conversationEnded },
  } = getState();
  if (conversationEnded) return;

  const mutation = {
    type: 'updateField',
    data: {
      field: 'speechLang',
      value: speechLang,
    },
    id: uuidv4(),
  } as const;
  dispatchMutation(mutation, transcriptId, 'updateField', dispatch, getState);
};

export const scribeSendMessageToMobile = (message: string) => (dispatch: any, getState: any) => {
  const state = getState();
  const ws = selectV1Websocket(state);
  if (ws) {
    ws.send(
      JSON.stringify({
        type: 'scribe-chat',
        message,
      })
    );
  }
};

export const scribeCreateTranscript =
  (transcriptIndex: number, text = '_', speakerId = '', direction = 1, highlighted = false, speechLang?: string) =>
  (dispatch: any, getState: any): { id: string; length: number } | undefined => {
    const { transcripts, transcriptsCurrent, langs, lang } = getState().scribeConversation;
    const { conversationEnded } = getState().conversation;
    const socket = getState().v1Session.v1Socket;

    if (transcripts[transcriptIndex] && getText(transcripts[transcriptIndex], lang) === '_') {
      return;
    }
    if (conversationEnded) return;
    const langNew = langs.length > 1 ? langs[1] : 'en';

    const parentTranscript = transcripts[transcriptIndex];
    const startingId = parentTranscript ? parentTranscript.id : Date.now();

    const newTranscriptId = getNewTranscriptId(startingId, transcriptsCurrent, direction);
    let author = speakerId;
    if (!author) {
      author = parentTranscript?.author ?? 'Callee';
    }

    const firstMutationChangeSpeaker: ScribeTranscriptChangeSpeakerMutation = {
      type: 'changeSpeaker',
      data: {
        speakerId: author,
      },
      parent: 'lucy',
      id: uuidv4(),
    };

    // We should take the index that it should be at, and calculate starting word index from that
    dispatch({
      type: 'SCRIBE_CREATE_TRANSCRIPT',
      id: newTranscriptId,
      mutation: firstMutationChangeSpeaker,
      transcriptIndex,
      author,
      lang: langNew,
    });

    const transcriptEditPayload: TranscriptEdit = {
      type: 'transcript-edit',
      data: {
        transcriptId: newTranscriptId,
        mutation: firstMutationChangeSpeaker,
        origin: 'human',
      },
    } as const;

    socket.send(JSON.stringify(transcriptEditPayload));

    // We need to select the empty word in the new transcript and even though the wordText is nothing allow that to be the selection

    if (highlighted) highlightTranscript(newTranscriptId)(dispatch, getState);
    if (speechLang && speechLang !== '~') updateSpeechLang(newTranscriptId, speechLang)(dispatch, getState);

    // eslint-disable-next-line
    dispatchUpdateFieldMutation(dispatch, getState, {
      field: 'speechLang',
      value: langNew,
      transcriptId: newTranscriptId,
    });
    dispatchUpdateFieldMutation(dispatch, getState, {
      field: 'timestampMs',
      value: parseInt(newTranscriptId.toString(), 10),
      transcriptId: newTranscriptId,
    });

    dispatchInsertMutation(dispatch, getState, {
      index: 0,
      text,
      id: uuidv4(),
      transcriptId: newTranscriptId,
      lang: langNew,
      src: 'human',
    });

    scribeSelectTranscriptWord({
      index: 0,
      caret: 'all',
      indexTranscript: newTranscriptId,
      skipCommit: true,
      skipJumpNextBloc: true,
      skipCreateNextBloc: true,
    })(dispatch, getState);

    return { id: newTranscriptId, length: text.split(' ').length };
  };

export const scribeJumpToBlocStart = () => (dispatch: any, getState: any) => {
  const { selectedTranscriptIndex, selectedWordIndex } = getState().scribeConversation.ui;

  scribeSelectTranscriptWord({
    index: 0,
    caret: 'all',
    direction: selectedWordIndex === 0 ? -1 : 0,
    indexTranscript: selectedTranscriptIndex,
    skipCommit: false,
    skipJumpNextBloc: false,
    skipCreateNextBloc: false,
  })(dispatch, getState);
};

export const scribeJumpToBlocEnd = () => (dispatch: any, getState: any) => {
  const { transcripts, lang } = getState().scribeConversation;
  const { selectedTranscriptIndex, selectedWordIndex } = getState().scribeConversation.ui;

  let direction = 0;

  const parentTranscript = transcripts[selectedTranscriptIndex];
  if (!parentTranscript) return;
  const lastWordIndex = getText(parentTranscript, lang).split(' ').length - 1;
  if (selectedWordIndex === lastWordIndex) {
    direction = 1;
  }

  scribeSelectTranscriptWord({
    index: lastWordIndex,
    caret: 'all',
    direction,
    indexTranscript: parentTranscript.id,
    skipCommit: false,
    skipJumpNextBloc: false,
    skipCreateNextBloc: false,
  })(dispatch, getState);
};

export const scribeUpdateInputValue = (inputValue: string, caret: string) => ({
  type: 'SCRIBE_TRANSCRIPT_UPDATE_WORD_INPUT_VALUE',
  inputValue,
  caret: caret || 'end',
});

export function insertUpdateMutation({
  selectedWordIndex,
  selectedTranscriptIndex,
  text,
  src,
  forceUncapitalize,
}: {
  selectedWordIndex: number;
  selectedTranscriptIndex: number;
  text: string;
  src: string;
  forceUncapitalize?: boolean;
}) {
  return (dispatch: any, getState: any) => {
    const { transcripts, lang } = getState().scribeConversation;

    const parentTranscript = transcripts[selectedTranscriptIndex];
    if (!parentTranscript) {
      return null;
    }

    const parentTranscriptText = getText(parentTranscript, lang);
    const parentWords = parentTranscriptText.split(' ');

    let {
      // eslint-disable-next-line
      startCharacterIndex,
      length,
      endCharacterIndex,
      // eslint-disable-next-line
      currentWordText,
    } = getWordCharacterIndex(parentWords, selectedWordIndex);
    const fText = text;

    // eslint-disable-next-line
    for (let i of endPunctuation) {
      if (
        (text === removeEndPunctuation(currentWordText) + i && endCharacterIndex < parentTranscriptText.length - 1) ||
        forceUncapitalize
      ) {
        const {
          length: afterLength,
          endCharacterIndex: afterEndCharacterIndex,
          currentWordText: afterCurrentWordText,
        } = getWordCharacterIndex(parentWords, selectedWordIndex + 1, 1);
        length = length + afterLength + 1;
        endCharacterIndex = afterEndCharacterIndex;
        // eslint-disable-next-line
        text = `${text} ${
          shouldCapitalize(i)
            ? capitalizeWord(afterCurrentWordText)
            : unCapitalizeWord(afterCurrentWordText, getTextLang(parentTranscript, lang))
        }`;
        break;
      }
    }

    dispatchUpdateWord(dispatch, getState, {
      deleteLength: length,
      endCharacterIndex,
      index: startCharacterIndex,
      newText: text,
      parentTranscript,
      src,
      lang: getLang(parentTranscript.speechLang, lang),
      isDeselect: false,
    });
    if (fText.includes(' ')) {
      scribeSelectTranscriptWord({
        index: selectedWordIndex + 1,
        caret: 'first',
        indexTranscript: parentTranscript.id,
        skipCommit: true,
        skipJumpNextBloc: true,
        skipCreateNextBloc: false,
      })(dispatch, getState);

      dispatch(scribeUpdateInputValue(last(text.split(' ')) ?? '', 'first'));
    }
    return true;
  };
}

export function splitTranscript({
  selectedWordIndex,
  selectedTranscriptIndex,
}: {
  selectedWordIndex: number;
  selectedTranscriptIndex: number;
}) {
  return (dispatch: any, getState: any) => {
    const { transcripts, lang } = getState().scribeConversation;

    const parentTranscript = transcripts[selectedTranscriptIndex];
    if (!parentTranscript) {
      return null;
    }

    const parentTranscriptText = getText(parentTranscript, lang);
    const parentWords = parentTranscriptText.split(' ');

    let {
      // eslint-disable-next-line
      startCharacterIndex,
    } = getWordCharacterIndex(parentWords, selectedWordIndex);

    dispatchUpdateWord(dispatch, getState, {
      deleteLength: startCharacterIndex,
      endCharacterIndex: startCharacterIndex,
      index: 0,
      newText: '',
      parentTranscript,
      src: 'split-transcript',
      lang: getLang(parentTranscript.speechLang, lang),
      isDeselect: false,
    });

    scribeCreateTranscript(
      parentTranscript.id,
      parentTranscriptText.slice(0, startCharacterIndex),
      parentTranscript.author,
      -1,
      parentTranscript.highlighted,
      lang
    )(dispatch, getState);

    scribeSelectTranscriptWord({
      index: 0,
      caret: 'all',
      indexTranscript: parentTranscript.id,
      skipCommit: true,
      skipJumpNextBloc: true,
      skipCreateNextBloc: false,
    })(dispatch, getState);

    return null;
  };
}

export function scribeDeselectTranscriptWord(
  {
    skipCommit,
  }: {
    skipCommit: boolean;
  } = { skipCommit: false },
  {
    src,
  }: {
    src?: string;
  } = {}
) {
  return (dispatch: any, getState: any) => {
    if (!skipCommit) processDeselect(dispatch, getState, { src });

    dispatch({
      type: 'SCRIBE_TRANSCRIPT_DESELECT_WORD',
    });
    dispatch(setIsWordBeingEdited(false));
  };
}

export function mergeTranscript({ selectedTranscriptIndex }: { selectedTranscriptIndex: number }) {
  return (dispatch: any, getState: any) => {
    const { transcripts, transcriptsCurrent, lang } = getState().scribeConversation;
    const parentTranscript = transcripts[selectedTranscriptIndex];
    if (!parentTranscript) {
      return null;
    }
    let toDeleteText;
    const text = getText(parentTranscript, lang);

    let toDeleteId;
    let transcriptToDelete;
    let i = 0;

    while (!toDeleteText) {
      i += 1;
      toDeleteId = transcriptsCurrent[transcriptsCurrent.indexOf(selectedTranscriptIndex) - i];
      transcriptToDelete = transcripts[toDeleteId];
      if (!transcriptToDelete) {
        return null;
      }
      toDeleteText = getText(transcriptToDelete, lang);
    }
    scribeDeselectTranscriptWord({ skipCommit: false })(dispatch, getState);

    dispatchDeleteMutation(dispatch, getState, {
      deleteLength: toDeleteText.length,
      index: 0,
      id: uuidv4(),
      transcriptId: toDeleteId,
      src: 'merge-transcript',
      lang: getLang(parentTranscript.speechLang, lang),
    });

    dispatchUpdateWord(dispatch, getState, {
      deleteLength: 1,
      endCharacterIndex: 0,
      index: 0,
      newText: `${removeEndPunctuation(toDeleteText)} ${text[0].toLowerCase()}`,
      parentTranscript,
      src: 'merge-transcript',
      lang: getLang(parentTranscript.speechLang, lang),
      isDeselect: false,
    });

    scribeSelectTranscriptWord({
      index: toDeleteText.split(' ').length,
      caret: 'all',
      indexTranscript: parentTranscript.id,
      skipCommit: true,
      skipJumpNextBloc: true,
      skipCreateNextBloc: false,
    })(dispatch, getState);

    if (transcriptToDelete && transcriptToDelete.highlighted && !parentTranscript.highlighted) {
      highlightTranscript(parentTranscript.id)(dispatch, getState);
    }

    return null;
  };
}

export const getTranscriptStats = () => {
  return (dispatch: any, getState: any) => {
    const {
      scribeConversation: { transcripts, transcriptsFinal, transcriptsCurrent },
      userProfile: { parse },
    } = getState();
    // eslint-disable-next-line
    const transcriptStats = {
      wordsTypedYou: 0,
      wordsTypedGroup: 0,
      wordsCapturedYou: 0,
      wordsCapturedGroup: 0,
    };
    transcriptsFinal
      .concat(transcriptsCurrent)
      .sort((a, b) => a - b)
      .forEach((tid) => {
        const transcript = transcripts[tid];
        const isTyped = !transcript['asr.timeReceivedMs'];
        const wordsCount = getTranscriptWordCount(transcript);
        const isYou = transcript.author === parse?.avaId;
        if (isTyped) {
          transcriptStats.wordsTypedGroup += wordsCount;
          if (isYou) {
            transcriptStats.wordsTypedYou += wordsCount;
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          transcriptStats.wordsCapturedGroup += wordsCount;
          if (isYou) {
            transcriptStats.wordsCapturedYou += wordsCount;
          }
        }
      });
    return transcriptStats;
  };
};

// NOTE unused
const getTranscriptText = () => {
  return (dispatch: any, getState: any) => {
    const {
      scribeConversation: { transcripts, transcriptsFinal, transcriptsCurrent, speakers, langs, status },
    } = getState();
    // eslint-disable-next-line
    for (let lang of langs) {
      if (lang === '~') {
        // eslint-disable-next-line
        continue;
      }
      const now = format(new Date(Date.now()), 'MM/dd/yy');
      const time = new Date().toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });

      let highlightedText = '';
      let text = transcriptsFinal
        .concat(transcriptsCurrent)
        .sort((a, b) => a - b)
        .filter((tid) => getText(transcripts[tid], lang))
        .reduce((acc, tid) => {
          const ttext = getText(transcripts[tid], lang);
          return `${acc}\n${
            transcripts[tid].authorShow
              ? `\n#${(speakers[transcripts[tid].author] || {}).userName}#${ttext ? '\n' : ''}`
              : ''
          }${ttext}`;
        }, '');
      transcriptsFinal
        .concat(transcriptsCurrent)
        .filter((tid) => getText(transcripts[tid], lang))
        .forEach((tid) => {
          if (transcripts[tid].highlighted) {
            highlightedText += `#${(speakers[transcripts[tid].author] || {}).userName}#: ${getText(
              transcripts[tid],
              lang
            )}\n`;
          }
        });

      const hostId = status && status.id ? status.id.split('_')[0] : undefined;
      const host = speakers[hostId] || {};
      const withT = (status.transcriptOwners || []).map((id) => (speakers[id] || {}).userName).join(', ');
      text = `Conversation on ${now}, ${time} hosted by ${host.userName} (&${host.avaName}) with ${withT}\n\n*Highlighted Transcripts*\n${highlightedText}\n\n*Full Transcript*${text}`;
      if (window.navigator.share) {
        window.navigator.share({
          text,
        });
      } else {
        const textField = document.createElement('textarea');
        textField.innerText = text;
        const element = document.createElement('a');
        const file = new Blob([text], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `transcript-ava-${now}-${lang}-.txt`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    }
  };
};

export const insertSpeakerName = (speakerIndex: number) => (dispatch: any, getState: any) => {
  const {
    scribeConversation: {
      status,
      transcripts,
      speakers,
      ui: { selectedTranscriptIndex, selectedWordIndex },
    },
  } = getState();
  if (!transcripts[selectedTranscriptIndex]) {
    return;
  }
  const newSpeakerId = (status.transcriptOwners || [])[speakerIndex];
  const newSpeaker = speakers[newSpeakerId] || {};
  const userName = newSpeaker.userName || '';

  insertUpdateMutation({
    selectedWordIndex,
    selectedTranscriptIndex,
    text: newSpeaker.userName,
    src: 'insertSpeakerName',
    forceUncapitalize: false,
  })(dispatch, getState);

  scribeDeselectTranscriptWord({
    skipCommit: true,
  })(dispatch, getState);

  scribeSelectTranscriptWord(
    {
      index: selectedWordIndex + (userName.split(' ').length >= 2 ? userName.split(' ').length - 1 : 0),
      indexTranscript: selectedTranscriptIndex,
      direction: 0, // 1 if tab, -1 if maj tab
      caret: 'all',
      skipCommit: true,
      skipJumpNextBloc: true,
      skipCreateNextBloc: true,
    },
    { src: 'insertSpeaker' }
  )(dispatch, getState);
};
