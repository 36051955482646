import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type State = {
  text?: string;
  progress?: number;
};

const initialState: State = {};

export const scribeNotificationSlice = createSlice({
  name: 'scribeNotification',
  initialState,
  reducers: {
    scribeClearNotification(state) {
      state.text = undefined;
      state.progress = undefined;
    },
    scribeSetNotificationText(state, { payload }: PayloadAction<string>) {
      state.text = payload;
    },
    scribeSetNotificationProgress(state, { payload }: PayloadAction<number>) {
      state.progress = payload;
    },
  },
});

export const scribeNotificationReducer = scribeNotificationSlice.reducer;
export const { scribeClearNotification, scribeSetNotificationText, scribeSetNotificationProgress } =
  scribeNotificationSlice.actions;
