import { createSelector } from '@reduxjs/toolkit';
const selectV1Session = (state) => state.v1Session;
export const selectConferenceCallRequested = createSelector([selectV1Session], (v1Session) => v1Session.conferenceCallRequested);
export const selectV1WebsocketStatus = createSelector([selectV1Session], (v1Session) => v1Session.v1WebsocketStatus);
export const selectV1Websocket = createSelector([selectV1Session], (v1Session) => v1Session.v1WebsocketStatus === 'online' || v1Session.v1WebsocketStatus === 'pending' ? v1Session.v1Socket : undefined);
export const selectBackends = createSelector([selectV1Session], (v1Session) => v1Session.backends);
export const selectDisplayDev = createSelector([selectV1Session], (v1Session) => {
    return v1Session.displayDev;
});
export const selectV1Token = createSelector([selectV1Session], (v1Session) => {
    return v1Session.v1Token;
});
