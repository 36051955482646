var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Sentry from '@sentry/react';
const branch = require('branch-sdk');
class Branch {
    constructor(branchId) {
        this.branchId = branchId;
        this.data = null;
    }
    initializeBranch() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.data = yield new Promise((resolve, reject) => {
                    branch.init('key_live_penYi35ThCyWIhmocnHM4piotqcldd1Y', { branch_match_id: this.branchId, no_journeys: false }, (err, data) => {
                        if (err) {
                            reject(err);
                        }
                        else {
                            resolve(data.data_parsed);
                        }
                    });
                });
            }
            catch (err) {
                // Branch is not a must have and is blocked on Firefox incognito, so we
                // just log the error.
                Sentry.captureException(err);
            }
        });
    }
    hasData() {
        return !!this.data;
    }
    hasAudio() {
        return !!this.data.audio;
    }
    savePropertiesToLocalStorageIfExists() {
        const { backendEndpoint, hostAvaId } = this.data;
        if (backendEndpoint) {
            localStorage.setItem('avaBackendUrl', backendEndpoint);
        }
        if (hostAvaId) {
            localStorage.setItem('ava_request', hostAvaId);
            localStorage.setItem('via_branch', hostAvaId);
        }
    }
}
export default Branch;
