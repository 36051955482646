import produce from 'immer';
import type { ActionType } from 'typesafe-actions';
import { getType } from 'typesafe-actions';

import * as actions from '../../actions/account';
import type { ILicence } from '../../modules/account/models/licence';
import type { IOffer } from '../../modules/account/models/offer';

export interface ILicenceState {
  licences: ILicence[];
  selfLicence: ILicence | typeof undefined;
  offers: IOffer[];
  currentOfferSelected: string;
  loadingPayment: boolean;
  loadingLicences: boolean;
  loadingOffers: boolean;
  loadingLicenceAssign: string[];
  loadingLicenceDelete: string[];
  errorMessagePayment: string;
  errorNewTrialFailed: boolean;
  errorEmailAssign: Array<{
    id: any;
    msg: any;
  }>;
  trialNewLoading: boolean;
}

export const initLicenceState: ILicenceState = {
  currentOfferSelected: '',
  errorEmailAssign: [],
  errorMessagePayment: '',
  errorNewTrialFailed: false,
  licences: [],
  loadingLicenceAssign: [],
  loadingLicenceDelete: [],
  loadingLicences: false,
  loadingOffers: false,
  loadingPayment: false,
  offers: [],
  selfLicence: undefined,
  trialNewLoading: false,
};

export type LicenceAction = ActionType<typeof actions>;

export default (state = initLicenceState, action: LicenceAction) =>
  // $FlowFixMe
  produce(state, (draftArg) => {
    const draft = draftArg;
    switch (action.type) {
      case getType(actions.session.rehydrate): {
        draft.loadingPayment = true;
        draft.errorMessagePayment = '';

        return draft;
      }
      case getType(actions.licence.newLicenceRequest): {
        draft.loadingPayment = true;
        draft.errorMessagePayment = '';

        return draft;
      }
      case getType(actions.licence.resetCheckout): {
        draft.errorMessagePayment = '';
        draft.loadingPayment = false;
        return draft;
      }
      case getType(actions.session.logout): {
        draft.licences = initLicenceState.licences;
        return draft;
      }
      case getType(actions.user.fetchUserInfos.request): {
        draft.loadingLicences = true;
        return draft;
      }
      case getType(actions.licence.newLicenceSuccess): {
        if (action.payload.licences.length) {
          action.payload.licences.forEach((licence) => {
            draft.licences.push(licence);
          });
        }
        draft.loadingPayment = false;
        return draft;
      }
      case getType(actions.user.fetchUserInfos.success): {
        draft.licences = action.payload.licences;
        //@ts-ignore
        draft.offers = action.payload.offers;
        if (action.payload.selfLicence && action.payload.selfLicence.licence) {
          draft.selfLicence = action.payload.selfLicence.licence;
          if (action.payload.selfLicence.organization) {
            draft.selfLicence.organization = { type: '', name: '' };
            draft.selfLicence.organization.name = action.payload.selfLicence.organization;
          }
        }
        return draft;
      }
      case getType(actions.user.dashboardReady): {
        draft.loadingLicences = false;
        return draft;
      }
      case getType(actions.licence.newLicenceFailure): {
        draft.loadingPayment = true;

        if (action.payload.error && action.payload.error.error && action.payload.error.error.error.content) {
          draft.loadingPayment = false;
          draft.errorMessagePayment = action.payload.error.error.error.content.msg;
        }
        return draft;
      }
      case getType(actions.licence.updateLicence.success): {
        if (draft.licences) {
          draft.licences = draft.licences.map((el) => {
            if (el.id === action.payload.id) {
              const licence = action.payload;
              return {
                ...el,
                ...licence,
              };
            }
            return el;
          });
        }

        draft.loadingLicenceAssign.splice(
          draft.loadingLicenceAssign.findIndex((el) => el === action.payload.id),
          1
        );
        return draft;
      }
      case getType(actions.licence.updateLicence.request): {
        draft.loadingLicenceAssign.push(action.payload.id);
        draft.errorEmailAssign.splice(
          draft.errorEmailAssign.findIndex((el) => el.id === action.payload.id),
          1
        );
        return draft;
      }
      case getType(actions.licence.updateLicence.failure): {
        const { response, licenceId } = action.payload;
        draft.loadingLicenceAssign.splice(
          draft.loadingLicenceAssign.findIndex((el) => el === licenceId),
          1
        );

        draft.errorEmailAssign.push({
          id: licenceId,
          msg: `licence-update-email-${response?.data?.error?.error?.content?.code}`,
        });
        return draft;
      }
      case getType(actions.licence.endUpdateRecipient): {
        if (draft.licences) {
          draft.licences = draft.licences.map((el) => {
            if (el.id === action.payload.id) {
              return {
                ...el,
                hasUpdated: false,
              };
            }
            return el;
          });
        }
        return draft;
      }
      case getType(actions.licence.endErrorRecipient): {
        draft.errorEmailAssign.splice(
          draft.errorEmailAssign.findIndex((el) => el.id === action.payload.id),
          1
        );
        return draft;
      }
      case getType(actions.licence.updateRecipient): {
        if (draft.licences) {
          draft.licences = draft.licences.map((el) => {
            if (el.id === action.payload.id) {
              return {
                ...el,
                hasUpdated: true,
                recipient: {
                  email: action.payload.email,
                },
              };
            }
            return el;
          });
        }
        return draft;
      }
      case getType(actions.licence.deleteLicence.success): {
        if (draft.licences) {
          draft.licences = draft.licences.map((el) => {
            if (el.id === action.payload.id) {
              return { ...el, autoRenewal: false };
            }
            return el;
          });
        }
        draft.loadingLicenceDelete.splice(
          draft.loadingLicenceDelete.findIndex((el) => el === action.payload.id),
          1
        );
        return draft;
      }
      case getType(actions.licence.deleteLicence.request): {
        draft.loadingLicenceDelete.push(action.payload.id);
        return draft;
      }

      case getType(actions.licence.newTrial.request): {
        draft.trialNewLoading = true;
        return draft;
      }
      case getType(actions.licence.newTrial.success): {
        draft.trialNewLoading = false;
        return draft;
      }
      case getType(actions.licence.newTrial.failure): {
        // New Trial fails often, and we don't want to block the UI when it does
        // not succeed
        //draft.errorNewTrialFailed = true;
        draft.trialNewLoading = false;
        return draft;
      }
      default:
        return draft;
    }
  });
