import randomMC from 'random-material-color';

import availableLangs from '../../assets/langs.json';
import { updateUserName } from '../store/slices/userProfile';
import type { ScribeTranscriptMutation, Speaker, Transcript, TranscriptMap } from '../types';
import { assertScribeConversationStateConsistency } from '../utils/consistence';
import { navLang } from '../utils/languageHelpers';
import { last, uniq } from '../utils/lodash';
import { computeTextFormLastMutation, getLang, getText, mutate as mutateTranscript } from '../utils/scribeUtils';

const isProd = window.location.href.indexOf('web.ava.me') > -1;

export type State = {
  // WARNING WARNING WARNING
  // This file handles receiving transcripts from the backend and editing them.
  // It is old, very difficult to comprehend, but luckily hasn't been a source
  // of many bugs.
  // Please do not add anything here. Add new fields to store/slices instead.
  // If you can refactor something out of here - please do so safely.
  // WARNING WARNING WARNING

  // This is currently only used to distinguish our cursors
  connectionHash?: string;

  transcripts: TranscriptMap;
  transcriptsFinal: Array<string>;
  transcriptsCurrent: Array<string>;
  speakers: Record<string, Speaker>;
  lang: string;
  langs: Array<string>;
  speechLang: string;
  status: any;

  // This is used to keep track of the speakers who have spoken in the conversation.
  // it's an array of avaId's, so we can tell solo-dia speakers by speaker labels being speaker_1, speaker_2, etc.
  speakersWhoSpoke: Array<string>;

  // How far into the transcripts we have seen each participant click. This is useful to see how far the scribe
  // has touched the transcript, with the assumption that if a scribe tab-ed or clicked onto a word, then everything
  // before then is *checked*.
  furthestObservedCursors: {
    [scribeHash: string]: {
      tid: string;
      index: number;
    };
  };

  // Related to editing
  conversationInputIsEdited?: boolean;
  ui: {
    prevSelectedWordIndex?: number;
    selectedWordIndex?: number;
    selectedTranscriptIndex?: string;
    selectedTranscriptCaretPosition?: string;
    selectedWordInputValue?: string; // The text for this should probably be changed,
    lastEditedWordIndex: number;
  };
};

const updateAuthorShow = (state) => {
  const transcriptsToUpdate: Record<string, any> = {};
  let lt;
  // eslint-disable-next-line
  for (let i = 0; i < state.transcriptsCurrent.length; i++) {
    const transcript = state.transcripts[state.transcriptsCurrent[i]];
    const ltCandidate = state.transcripts[state.transcriptsCurrent[i - 1]];
    if (ltCandidate && getText(ltCandidate, state.lang)) {
      lt = ltCandidate;
    }
    if (i === 0) {
      lt = state.transcripts[state.transcriptsFinal[state.transcriptsFinal.length - 1]];
    }
    const shouldShow =
      !lt ||
      transcript.author !== lt.author ||
      transcript.trackName !== lt.trackName ||
      transcript.speechLang !== lt.speechLang;
    if (transcript.authorShow !== shouldShow) {
      transcriptsToUpdate[transcript.id] = {
        ...transcript,
        authorShow: shouldShow,
      };
    }
  }
  return {
    ...state,
    transcripts: {
      ...state.transcripts,
      ...transcriptsToUpdate,
    },
  };
};

const mutate = (
  state: State,
  action: {
    mutation: ScribeTranscriptMutation;
    asr: boolean;
    transcriptId: any;
  }
) => {
  const transcript = state.transcripts[action.transcriptId];
  const { transcript: newTranscript, indexWord: newIndexWord } = mutateTranscript(
    transcript,
    state.ui.selectedWordIndex || 0,
    action.mutation,
    action.asr
  );
  const words = getText(newTranscript, state.lang).split(' ');
  const cursors = transcript.cursors || [];
  const currentMutation: ScribeTranscriptMutation = action.mutation;
  const furthestCursorForCurrentMutator =
    currentMutation.data.mutatorHash && state.furthestObservedCursors[currentMutation.data.mutatorHash];
  let currentTouchedIndex;
  let cursorsUpdated = false;
  if (currentMutation.type === 'cursor') {
    currentTouchedIndex = { tid: action.transcriptId, index: currentMutation.data.index };
    // This is used to distinguish cursors of the current user from the cursors of
    // other users.
    if (state.connectionHash !== currentMutation.data.mutatorHash) {
      cursorsUpdated = true;
      const indexToChange = (cursors || [])
        .map((cursor) => cursor.mutatorHash)
        //@ts-ignore
        .indexOf(currentMutation.data.mutatorHash);
      if (indexToChange !== -1) {
        //@ts-ignore
        cursors[indexToChange] = currentMutation.data;
      } else {
        //@ts-ignore
        cursors.push(currentMutation.data);
      }
    }
  }
  if (
    (action.mutation.type === 'insert' || action.mutation.type === 'delete') &&
    action.mutation.data.lang === state.lang
  ) {
    //@ts-ignore
    currentTouchedIndex = { tid: action.transcriptId, index: currentMutation.data.index };
    for (let i = 0; i < cursors.length; i += 1) {
      const movedWord = computeTextFormLastMutation(
        getText(transcript, action.mutation.data.lang),
        action.mutation,
        cursors[i].index
      );
      cursorsUpdated = true;
      if (cursors[i].index !== -1) cursors[i].index = movedWord.indexWord;
    }
  }
  newTranscript.cursors = cursors;
  if (cursorsUpdated) {
    newTranscript.cursors = [...newTranscript.cursors];
  }
  const updatedFurthestObservedCursors = { ...state.furthestObservedCursors };
  if (currentTouchedIndex && currentMutation.data.mutatorHash) {
    if (
      !furthestCursorForCurrentMutator ||
      currentTouchedIndex.tid > furthestCursorForCurrentMutator.tid ||
      (currentTouchedIndex.tid === furthestCursorForCurrentMutator.tid &&
        currentTouchedIndex.index > furthestCursorForCurrentMutator.index)
    ) {
      updatedFurthestObservedCursors[currentMutation.data.mutatorHash] = currentTouchedIndex;
    }
  }

  let newPrevSelectedWordIndex = state.ui.prevSelectedWordIndex;
  let newSelectedWordIndex = state.ui.selectedWordIndex;
  let newSelectedWordInputValue = state.ui.selectedWordInputValue;
  let newSelectedTranscriptIndex = state.ui.selectedTranscriptIndex;

  if (
    currentMutation.parent === 'lucy' &&
    currentMutation.type === 'insert' &&
    action.asr &&
    newPrevSelectedWordIndex != null &&
    newPrevSelectedWordIndex < words.length
  ) {
    newSelectedWordIndex = newPrevSelectedWordIndex;
    newSelectedWordInputValue = words[newSelectedWordIndex];
    newSelectedTranscriptIndex = newTranscript.id;
  } else if (
    action.asr &&
    state.ui.selectedTranscriptIndex === transcript.id &&
    //@ts-ignore
    getLang(newTranscript.speechLang, state.lang) === action.mutation.data.lang
  ) {
    if (typeof state.ui.selectedWordIndex === 'number') {
      newSelectedWordIndex = newIndexWord;
      newSelectedWordInputValue = words[newSelectedWordIndex];

      newSelectedWordIndex = Math.min(newSelectedWordIndex || 0, words.length - 1);
      newSelectedWordInputValue = words[newSelectedWordIndex];
    }
  }

  if (newSelectedWordIndex !== state.ui.selectedWordIndex) {
    newPrevSelectedWordIndex = state.ui.selectedWordIndex;
  }

  let langs: Array<string> = state.langs || [];
  let { lang } = state;
  if (action.mutation.type === 'insert' || action.mutation.type === 'delete') {
    const dlang = action.mutation.data.lang;
    if (!langs.includes(dlang)) langs = uniq([...langs, dlang]);
  }
  if (langs.length === 1) {
    [lang] = langs;
  }
  return {
    ...state,
    lang,
    langs,
    ui: {
      ...state.ui,
      prevSelectedWordIndex: newPrevSelectedWordIndex,
      selectedWordIndex: newSelectedWordIndex,
      selectedWordInputValue: newSelectedWordInputValue,
      selectedTranscriptIndex: newSelectedTranscriptIndex,
    },
    furthestObservedCursors: updatedFurthestObservedCursors,
    transcripts: {
      ...state.transcripts,
      [action.transcriptId]: {
        ...newTranscript,
        mutations: [{ ...action.mutation, timestamp: Date.now() }],
        mutationsQueuedByHumanMutation: [],
      },
    },
    speakersWhoSpoke: uniq([...state.speakersWhoSpoke, newTranscript.author]),
  };
};

type Action =
  | {
      type: 'SET_SPEECH_LANG';
      lang: string;
    }
  | {
      type: 'SWITCH_LANG';
      lang: string;
    }
  | {
      type: 'RECEIVED_SCRIBE_CONVERSATION_TRANSCRIPT_MUTATION';
      transcriptId: string;
      origin: 'asr';
      mutation: ScribeTranscriptMutation;
      speakerId: string | null | undefined;
    }
  | {
      type: 'CLEAR_SCRIBE_CONVERSATION';
    }
  | {
      type: 'CONNECTION_SUCCEEDED';
      status: string;
    }
  | {
      type: 'SCRIBE_WORD_EDIT_STARTED';
    }
  | {
      type: 'SCRIBE_SET_LAST_EDITED_WORD_INDEX';
      lastEditedWordIndex: number;
    }
  | {
      type: 'SCRIBE_TRANSCRIPT_UPDATE_WORD_INPUT_VALUE';
      inputValue: string;
      caret: string;
    }
  | {
      type: 'SCRIBE_TRANSCRIPT_DESELECT_WORD';
    }
  | {
      type: 'ROOM_STATUS_UPDATE';
      status: any;
      force?: boolean;
    }
  | {
      type: 'SCRIBE_TRANSCRIPT_SELECT_WORD';
      transcriptId: string;
      index: number;
      caret: string;
      wordText: string;
    }
  | {
      type: 'SCRIBE_CREATE_TRANSCRIPT';
      id: string;
      mutation: ScribeTranscriptMutation;
      author: string;
      lang: string;
    };

export type RoomStatusUpdateAction = Extract<Action, { type: 'ROOM_STATUS_UPDATE' }>;

const availableCodeLanguage: Array<string> = Object.values(availableLangs).map((x) => {
  if (x && x.languageCode && typeof x.languageCode === 'string') {
    return x.languageCode;
  }
  return 'en-US';
});
const getInitialState = (): State => ({
  lang: '~',
  langs: ['~'],
  transcripts: {},
  speakers: {},
  transcriptsFinal: [],
  transcriptsCurrent: [],
  furthestObservedCursors: {},
  conversationInputIsEdited: false,
  speechLang:
    localStorage.getItem('speechLang') ||
    (availableCodeLanguage.includes(navLang)
      ? navLang
      : availableCodeLanguage.find((lang: string) => lang.substring(0, navLang.length) === navLang) || 'en-US'),
  status: {},
  speakersWhoSpoke: [],
  ui: {
    lastEditedWordIndex: -1,
    prevSelectedWordIndex: undefined,
  },
});
/* eslint-enable global-require */

const initialState = getInitialState();

const applyQueuedMutationsIfPossible = (state: State, transcriptId?: string): State => {
  if (!transcriptId) {
    return state;
  }
  const parentSpeechBloc: Transcript | null | undefined = state.transcripts[transcriptId];

  if (!parentSpeechBloc) return { ...state };
  if (state.conversationInputIsEdited) {
    return { ...state };
  }

  (parentSpeechBloc.mutationsQueuedByHumanMutation || []).forEach((mutation) => {
    // eslint-disable-next-line no-param-reassign
    //@ts-ignore
    state = mutate(state, { mutation, transcriptId, asr: true });
  });

  return {
    ...state,
  };
};

function legacyConversationReducer(state = initialState, action: Action): State {
  switch (action.type) {
    case 'SET_SPEECH_LANG': {
      return {
        ...state,
        speechLang: action.lang,
      };
    }

    case 'CLEAR_SCRIBE_CONVERSATION': {
      return {
        ...getInitialState(),
      };
    }

    case 'SCRIBE_WORD_EDIT_STARTED': {
      return {
        ...state,
        conversationInputIsEdited: true,
      };
    }

    case 'SCRIBE_TRANSCRIPT_SELECT_WORD': {
      return applyQueuedMutationsIfPossible(
        {
          ...state,
          conversationInputIsEdited: false,
          ui: {
            ...state.ui,
            selectedTranscriptCaretPosition: action.caret || 'all',
            prevSelectedWordIndex: state.ui.selectedWordIndex,
            selectedWordIndex: action.index,
            selectedTranscriptIndex: action.transcriptId,
            selectedWordInputValue: action.wordText,
          },
        },
        state.ui.selectedTranscriptIndex
      );
    }

    case 'SCRIBE_TRANSCRIPT_DESELECT_WORD': {
      return applyQueuedMutationsIfPossible(
        {
          ...state,
          conversationInputIsEdited: false,
          ui: {
            ...state.ui,
            prevSelectedWordIndex: undefined,
            selectedWordIndex: undefined,
            selectedTranscriptCaretPosition: 'none',
            selectedTranscriptIndex: undefined,
          },
        },
        state.ui.selectedTranscriptIndex
      );
    }

    case 'CONNECTION_SUCCEEDED': {
      return {
        ...state,
        connectionHash: action.status,
      };
    }

    case 'RECEIVED_SCRIBE_CONVERSATION_TRANSCRIPT_MUTATION': {
      const { transcriptId, mutation, origin } = action;
      // eslint-disable-next-line
      let { speakerId } = action;
      if (mutation.type === 'changeSpeaker') {
        ({ speakerId } = mutation.data);
      }
      let { transcriptsCurrent } = state;
      let { transcriptsFinal } = state;
      const parentSpeechBloc = state.transcripts[transcriptId];

      if (state.transcriptsFinal.includes(transcriptId) && mutation.parent === 'lucy') {
        transcriptsCurrent = [...transcriptsCurrent, transcriptId].sort(
          //@ts-ignore
          (a, b) => a - b
        );
        transcriptsFinal = transcriptsFinal.filter((tid) => tid !== transcriptId);
        parentSpeechBloc.mutationsQueuedByHumanMutation = [];
        parentSpeechBloc.mutations = [];
        parentSpeechBloc.texts = {};
      }
      if (
        parentSpeechBloc &&
        mutation.parent !== 'lucy' &&
        last(parentSpeechBloc.mutations || []) &&
        last(parentSpeechBloc.mutations || [])?.id !== mutation.parent &&
        (!last(parentSpeechBloc.mutationsQueuedByHumanMutation || []) ||
          last(parentSpeechBloc.mutationsQueuedByHumanMutation || [])?.id !== mutation.parent)
      ) {
        return state;
      }

      const mutationWithTimestamp = {
        ...mutation,
        origin,
        timestamp: Date.now(),
      } as const;

      if (action.mutation.type === 'insert' || action.mutation.type === 'delete') {
        // eslint-disable-next-line no-param-reassign
        action.mutation.data.lang = action.mutation.data.lang || state.lang;
      }

      // Don't apply ASR mutation when user edit input in this transcript
      //@ts-ignore
      if (state.conversationInputIsEdited && state.ui.selectedTranscriptIndex === transcriptId && origin !== 'human') {
        return {
          ...state,
          transcripts: {
            ...state.transcripts,
            [transcriptId]: {
              ...parentSpeechBloc,
              mutationsQueuedByHumanMutation: [...(parentSpeechBloc.mutationsQueuedByHumanMutation || []), mutation],
            },
          },
          transcriptsCurrent,
          transcriptsFinal,
        };
      }
      if (!parentSpeechBloc) {
        //@ts-ignore
        const lastTranscript = state.transcripts[last(state.transcriptsCurrent)];

        const authorShow = !lastTranscript || (speakerId && lastTranscript.author !== speakerId);

        //@ts-ignore
        return mutate(
          {
            ...state,
            transcriptsCurrent: [...state.transcriptsCurrent, transcriptId].sort(
              //@ts-ignore
              (a, b) => a - b
            ),
            //@ts-ignore
            transcripts: {
              ...state.transcripts,
              [transcriptId]: {
                id: transcriptId,
                isFinal: false,
                //@ts-ignore
                index: 0,
                //@ts-ignore
                author: speakerId || mutation.data.speakerId || 'Callee',
                authorShow,
                origin,
                mutationsQueuedByHumanMutation: [],
                mutations: [mutationWithTimestamp],
                speechLang: state.lang,
                texts: {
                  [state.lang]: '',
                },
              },
            },
          },
          {
            mutation: action.mutation,
            transcriptId,
            asr: action.origin === 'asr',
          }
        );
      }

      if (!parentSpeechBloc.mutations) {
        return state;
      }

      return updateAuthorShow(
        mutate(
          {
            ...state,
            transcriptsCurrent,
            transcriptsFinal,
          },
          {
            mutation: action.mutation,
            transcriptId: action.transcriptId,
            asr: action.origin === 'asr',
          }
        )
      );
    }

    case 'SCRIBE_CREATE_TRANSCRIPT': {
      const newState = {
        ...state,
        transcripts: {
          ...state.transcripts,
          [action.id]: {
            id: action.id,
            isFinal: false,
            origin: 'human',
            author: action.author || 'Callee',
            authorShow: false,
            mutationsQueuedByHumanMutation: [],
            mutations: [action.mutation],
            speechLang: action.lang,
            texts: {
              [action.lang]: '',
            },
          },
        },
        transcriptsCurrent: [...state.transcriptsCurrent, action.id].sort(
          //@ts-ignore
          (a, b) => a - b
        ),
      } as const;

      return updateAuthorShow(
        mutate(
          //@ts-ignore
          {
            ...newState,
          },
          {
            mutation: action.mutation,
            transcriptId: action.id,
            asr: false,
          }
        )
      );
    }

    case 'SCRIBE_SET_LAST_EDITED_WORD_INDEX':
      return {
        ...state,
        ui: {
          ...state.ui,
          lastEditedWordIndex: Math.max(action.lastEditedWordIndex, state.ui.lastEditedWordIndex),
        },
      };

    case 'SCRIBE_TRANSCRIPT_UPDATE_WORD_INPUT_VALUE':
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedWordInputValue: action.inputValue,
          selectedTranscriptCaretPosition: action.caret,
        },
      };

    case 'SWITCH_LANG':
      return {
        ...state,
        lang: action.lang,
      };

    case 'ROOM_STATUS_UPDATE': {
      const { speakers: oldSpeakers } = state;
      const speakers = {};
      let possiblyMyselfIsSet = false;
      // if we had more speakers before and now we have less, the comparison algorithm will not work
      (action.status.speakers || []).forEach((speaker) => {
        const oldSpeaker = oldSpeakers[speaker.avaId];
        // If avaId starts with speaker_ then the speaker is coming from Solo-Dia
        const isSoloDiaSpeaker =
          speaker?.avaId?.startsWith('speaker_') && action?.status?.host?.flags?.['mono-segmentation'];
        // If avaId !== avaName then the speaker hasn't been named yet.
        const isSoloDiaSpeakerIdentified = isSoloDiaSpeaker && speaker.avaId !== speaker.avaName;
        // If is Solo-Dia speaker and is marked as myself = true. Then speaker is current user.
        const isSoloDiaSpeakerCurrentUser = isSoloDiaSpeaker && speaker.myself;
        let possiblyMyself = isSoloDiaSpeakerCurrentUser;

        /*
          We want to identify the first unidentified speaker as being possibly the current user. Once we've
          identified the first unidentified speaker as possibly the current user then ignore all following unidentified
          speakers.
          Unidentified speakers meaning speakers identified by Solo-Dia but aren't named, i.e. Spearker 1, Speaker 2.
        */
        if (!isSoloDiaSpeakerIdentified && !possiblyMyself && !possiblyMyselfIsSet) {
          possiblyMyself = true;
          possiblyMyselfIsSet = true;
        }

        const newSpeaker = {
          ...oldSpeaker,
          ...speaker,
          userAvatar: speaker.photoUrl ?? speaker.userPhoto?.url ?? oldSpeaker?.userAvatar,
          isSoloDiaSpeaker,
          isSoloDiaSpeakerCurrentUser,
          possiblyMyself,
        };

        if (!newSpeaker.theme) {
          newSpeaker.theme = {
            dark_theme: randomMC.getColor({ text: speaker.avaId }),
            light_theme: randomMC.getColor({ text: speaker.avaId }),
          };
        }
        let isSpeakerUnchanged = true;
        const speakerAttributes = [
          'avaId',
          'id',
          'userName',
          'avaName',
          'userAvatar',
          'isSoloDiaSpeaker',
          'isSoloDiaSpeakerCurrentUser',
          'possiblyMyself',
        ];
        for (const attribute of speakerAttributes) {
          if (!oldSpeaker || newSpeaker[attribute] !== oldSpeaker[attribute]) {
            isSpeakerUnchanged = false;
          }
        }
        if (
          newSpeaker?.theme?.dark_theme?.text_color !== oldSpeaker?.theme?.dark_theme?.text_color ||
          newSpeaker?.theme?.light_theme?.text_color !== oldSpeaker?.theme?.light_theme?.text_color
        ) {
          isSpeakerUnchanged = false;
        }

        speakers[speaker.avaId] = isSpeakerUnchanged ? oldSpeaker : newSpeaker;
      });

      // Default room id has the format avaId_00000000-0000-0000-0000-000000000000
      // if we dont take it into account it will:
      // reset the transcript and wont redirect to transcript page + show rating
      const newRoom =
        action.status.id &&
        action.status.id !== state.status.id &&
        !action.status.id.includes('00000000-0000-0000-0000-000000000000');
      const status = {
        ...action.status,
      } as const;
      let newLangs = uniq(state.langs.concat(action.status.langs || []));
      if (newLangs.length === state.langs.length) {
        // newLangs must contain all languages of state.langs, becuase it is
        // concatenated. So if the length is the same - it means all the elements
        // are the same. So we don't change the field, so as to not trigger a rerender.
        newLangs = state.langs;
      }
      return {
        ...state,
        speakers,
        status,
        langs: newLangs,
        lang: state.langs.length === 0 ? action.status.langs[0] : state.lang,
        ui: {
          ...state.ui,
        },
        transcripts: newRoom ? {} : state.transcripts,
        transcriptsFinal: newRoom ? [] : state.transcriptsFinal,
        transcriptsCurrent: newRoom ? [] : state.transcriptsCurrent,
      };
    }

    case updateUserName.fulfilled.type:
      // @ts-ignore
      const { userName, avaId }: { userName: string; avaId: string } = action.payload;
      if (!userName || userName === state.speakers[avaId]?.userName) {
        return state;
      }
      return {
        ...state,
        speakers: {
          ...state.speakers,
          [avaId]: {
            ...state.speakers[avaId],
            userName,
          },
        },
      };

    default:
      // eslint-disable-next-line no-unused-expressions
      action as never;
      return state;
  }
}

export default function reducerWrapper(state: State | null | undefined = initialState, action: Action): State {
  //@ts-ignore
  const newState = legacyConversationReducer(state, action);
  if (!isProd) assertScribeConversationStateConsistency(newState);
  return newState;
}
