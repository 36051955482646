import React from 'react';
import { Navigate } from 'react-router-dom';

import Loading from '../components/Loading';
import { selectLoggedIn, selectLoggedInAnonymously, selectLoggedOut } from '../selectors/combined';
import { useAppSelector } from '../store/store';

export default function PrivateRouteWrapper({
  children,
  authenticationMethod,
  redirectPath,
}: {
  children: JSX.Element;
  authenticationMethod: () => boolean;
  redirectPath: string;
}) {
  const loggedIn = useAppSelector(selectLoggedIn);
  const loggedOut = useAppSelector(selectLoggedOut);
  const loggedInAnonymously = useAppSelector(selectLoggedInAnonymously);

  const isAuthenticated = authenticationMethod();

  if (!loggedOut && !loggedIn && !loggedInAnonymously) {
    return <Loading />;
  }

  if ((loggedIn || loggedInAnonymously) && isAuthenticated) {
    return children;
  }

  return <Navigate to={redirectPath} replace />;
}
