import type { Selector } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';

import type { RecallAI, RecallAIStatus, Services } from '../store/slices/recallAI';
import type { RootState } from '../store/store';
import type { RecallAIManager } from '../utils/recallAI';

const selectRecallAI = (state: RootState): RecallAI => state.recallAI;

export const selectRecallAIStatus: Selector<RootState, RecallAIStatus> = createSelector(
  [selectRecallAI],
  (recallAI) => recallAI.recallAIStatus
);

export const selectRecallAIError: Selector<RootState, string | undefined> = createSelector(
  [selectRecallAI],
  (recallAI) => recallAI.errorMessage
);

export const selectRecallAIService: Selector<RootState, Services> = createSelector(
  [selectRecallAI],
  (recallAI) => recallAI.service
);

export const selectRecallAIManager: Selector<RootState, RecallAIManager | undefined> = createSelector(
  [selectRecallAI],
  (recallAI) => recallAI.recallAIManager
);
