import { getText } from './scribeUtils';
const stop = (scribeConversation, message) => {
    if (process.env.NODE_ENV === 'test') {
        // eslint-disable-next-line
        console.log(scribeConversation);
        throw new Error(`consistency issue: ${message}`);
    }
    if (process.env.NODE_ENV === 'dev') {
        // eslint-disable-next-line
        console.log(message);
        // eslint-disable-next-line
        debugger;
    }
    if (process.env.NODE_ENV === 'playground') {
        // eslint-disable-next-line
        console.log(message);
    }
};
export const assertScribeConversationStateConsistency = (scribeConversation) => {
    // assert that no ASR mutations are queued in a non selected transcript
    scribeConversation.transcriptsCurrent.forEach((tid) => {
        var _a;
        const transcript = scribeConversation.transcripts[tid];
        if ((_a = transcript.mutationsQueuedByHumanMutation) === null || _a === void 0 ? void 0 : _a.length) {
            if (transcript.id !== scribeConversation.ui.selectedTranscriptIndex) {
                stop(scribeConversation, 'error!');
            }
        }
    });
    // assert that there is not two transcripts with the same id.
    const idCount = {};
    scribeConversation.transcriptsCurrent.forEach((tid) => {
        const transcript = scribeConversation.transcripts[tid];
        if (idCount[transcript.id]) {
            stop(scribeConversation, 'duplicate transcript id !');
        }
        idCount[transcript.id] = 1;
    });
    // ui check
    const { ui, transcripts, transcriptsCurrent } = scribeConversation;
    if (ui.selectedWordIndex < 0) {
        stop(scribeConversation, 'invalid selectedWordIndex');
    }
    const currentEditedTranscriptId = transcriptsCurrent.find((tid) => tid === ui.selectedTranscriptIndex);
    const { lang } = scribeConversation;
    const currentEditedTranscript = transcripts[currentEditedTranscriptId];
    if (currentEditedTranscript) {
        if (ui.selectedWordIndex >= getText(currentEditedTranscript, lang).split(' ').length) {
            stop(scribeConversation, 'invalid selectedWordIndex');
        }
        if (ui.selectedWordIndex > getText(currentEditedTranscript, lang).split(' ') || ui.selectedWordIndex < 0) {
            stop(scribeConversation, 'invalid selectedWordIndex');
        }
    }
};
