import { createAction, createAsyncAction } from 'typesafe-actions';
import { LICENCE_DELETE_FAILURE, LICENCE_DELETE_REQUEST, LICENCE_DELETE_SUCCESS, LICENCE_PROCESS_PAYMENT_FAILURE, LICENCE_PROCESS_PAYMENT_REQUEST, LICENCE_PROCESS_PAYMENT_RESET, LICENCE_PROCESS_PAYMENT_SUCCESS, LICENCE_SET_RECIPIENT, LICENCE_UNSET_ERROR_RECIPIENT, LICENCE_UNSET_RECIPIENT, LICENCE_UPDATE_FAILURE, LICENCE_UPDATE_REQUEST, LICENCE_UPDATE_SUCCESS, NEW_TRIAL_FAILURE, NEW_TRIAL_REQUEST, NEW_TRIAL_SUCCESS, OPEN_TRIAL_LICENCE_CANCEL_TYPEFORM, } from './constants';
export const newLicenceRequest = createAction(LICENCE_PROCESS_PAYMENT_REQUEST, (action) => {
    return (request) => action(request);
});
export const newLicenceSuccess = createAction(LICENCE_PROCESS_PAYMENT_SUCCESS, (action) => {
    return (licences) => action({ licences });
});
export const newLicenceFailure = createAction(LICENCE_PROCESS_PAYMENT_FAILURE, (action) => {
    return (error) => action({ error });
});
export const updateRecipient = createAction(LICENCE_SET_RECIPIENT, (action) => {
    return (id, email) => action({ id, email });
});
export const endUpdateRecipient = createAction(LICENCE_UNSET_RECIPIENT, (action) => {
    return (id) => action({ id });
});
export const endErrorRecipient = createAction(LICENCE_UNSET_ERROR_RECIPIENT, (action) => {
    return (id) => action({ id });
});
export const updateLicence = createAsyncAction(LICENCE_UPDATE_REQUEST, LICENCE_UPDATE_SUCCESS, LICENCE_UPDATE_FAILURE)();
export const deleteLicence = createAsyncAction(LICENCE_DELETE_REQUEST, LICENCE_DELETE_SUCCESS, LICENCE_DELETE_FAILURE)();
export const openCancelTypeform = createAction(OPEN_TRIAL_LICENCE_CANCEL_TYPEFORM);
export const newTrial = createAsyncAction(NEW_TRIAL_REQUEST, NEW_TRIAL_SUCCESS, NEW_TRIAL_FAILURE)();
export const resetCheckout = createAction(LICENCE_PROCESS_PAYMENT_RESET);
