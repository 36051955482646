export const last = (array) => [...array].pop();
export const throttle = (wrapped, wait) => {
    let lastCallTime = 0;
    let timeout;
    return (...args) => {
        const now = Date.now();
        const timeSinceLastCall = now - lastCallTime;
        const callAndMarkTime = () => {
            lastCallTime = Date.now();
            return wrapped(...args);
        };
        const postponeCall = (f) => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = undefined;
            }
            timeout = setTimeout(f, wait - timeSinceLastCall);
        };
        if (!lastCallTime || timeSinceLastCall > wait) {
            callAndMarkTime();
        }
        else {
            postponeCall(callAndMarkTime);
        }
    };
};
export const uniq = (arg) => [...new Set(arg)];
