import bowser from 'bowser';

export const SMALL = window.innerWidth <= 800;

export const isMobile = () => {
  // Electron replaces user agent so we handle this case manually
  if (window.isElectron || /Windows|Macintosh/i.test(navigator.userAgent)) {
    return false;
  }
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const getOS = () => {
  let OS = '';
  if (window.isElectron && window.isElectron.electronOS) {
    OS = window.isElectron.electronOS;
  } else {
    const parser = bowser.getParser(window.navigator.userAgent);
    OS = parser.getOSName();
  }
  // We prefer "Mac" to macOS
  return OS === 'macOS' ? 'Mac' : OS;
};

export const isMac = getOS() === 'Mac';
export const isWindows = getOS() === 'Windows';
export const isLinux = getOS() === 'Linux';

export const getOSVersion = () => {
  if (window.isElectron && window.isElectron.electronOS) {
    return undefined;
  }
  const parser = bowser.getParser(window.navigator.userAgent);
  return parser.getOSVersion();
};

export const getBrowserName = () => {
  const parser = bowser.getParser(window.navigator.userAgent);
  return parser.getBrowserName();
};

export const isFirefox = getBrowserName() === 'Firefox';

export const getBrowserVersion = () => {
  const parser = bowser.getParser(window.navigator.userAgent);
  return parser.getBrowserVersion();
};

type QueryParams = {
  auth?: string;
  tauri?: string;
  [key: string]: string | undefined;
};

export const getUrlParams = (): QueryParams => {
  let parametersString = window.location.search;
  if (parametersString && parametersString.startsWith('?')) {
    parametersString = parametersString.slice(1);
  } else if (!parametersString && window.location.hash && window.location.hash.includes('?')) {
    parametersString = window.location.hash.slice(window.location.hash.indexOf('?') + 1);
  }

  if (parametersString) {
    const parameters = {};
    for (const pairString of parametersString.split('&')) {
      const [key, value] = pairString.split('=', 2);
      parameters[key] = decodeURIComponent(value);
    }
    return parameters;
  }
  return {};
};

export const isProd = () =>
  window.location.href.indexOf('app.avascribe.com') > -1 ||
  window.location.href.indexOf('app.ava.me') > -1 ||
  window.location.href.indexOf('web.ava.me') > -1;

// Change this to `true` to test SNCF codepaths.
export const SNCF = window.location.href.indexOf('sncf.ava.me') > -1;

export const validateEmail = (email: string): boolean => {
  // Lint complained about 'unnecessary escape characters', but the original regex is kept below. Just in case.
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isMobileiOS = () => {
  if (window.electron) return false;
  return /iPhone|iPad/.test(window.navigator.userAgent);
};

export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const sleep = (timeMs: number) => {
  return new Promise<undefined>((resolve) => setTimeout(resolve, timeMs));
};

export const generateRandomId = () => {
  return Math.random().toString(36).substring(2, 15);
};

export const closeDesktopApp = () => {
  window.electronIPC?.sendCCMode('close');
};
