import { createSlice } from '@reduxjs/toolkit';
const initialState = {};
export const scribeNotificationSlice = createSlice({
    name: 'scribeNotification',
    initialState,
    reducers: {
        scribeClearNotification(state) {
            state.text = undefined;
            state.progress = undefined;
        },
        scribeSetNotificationText(state, { payload }) {
            state.text = payload;
        },
        scribeSetNotificationProgress(state, { payload }) {
            state.progress = payload;
        },
    },
});
export const scribeNotificationReducer = scribeNotificationSlice.reducer;
export const { scribeClearNotification, scribeSetNotificationText, scribeSetNotificationProgress } = scribeNotificationSlice.actions;
