import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { selectElectronCaptionMode, selectWasMutedBeforeKaraoke } from '../../selectors/ui';
import { ScribeCorrectionMode } from '../../types';
import { sleep } from '../../utils';
import { getSearchValue } from '../../utils/http';
import { checkEndsWithPunctuation, KARAOKE_DELAY_MAPPING, KARAOKE_RESET_DELAY } from '../../utils/karaoke';
import * as segment from '../../utils/segment';
import type { RootState } from '../store';
import { startRecording } from './audioV2';

export type DesktopTheme = {
  [key: string]: {
    // Background for floating captions
    floatingBackground: string;
    // Background for container mode captions, also control bar
    containerBackground: string;
    // background for old menu, keeping just in case we change design again
    solidBackground: string;
    // background for old transparent background + blur, if the tauri bug gets fixed
    transparentBackground: string;
  };
};

export type DesktopThemeKey = keyof typeof DESKTOP_THEMES;

export const DESKTOP_THEMES: DesktopTheme = {
  oceanBlue: {
    floatingBackground: 'rgba(18, 36, 82, 0.96)',
    containerBackground: 'linear-gradient(118deg, rgba(23, 95, 161, 0.96) -2.39%, rgba(17, 28, 54, 0.96) 52.25%)',
    solidBackground: 'linear-gradient(118deg, rgb(23, 95, 161) -2.39%, rgb(17, 28, 54) 52.25%)',
    transparentBackground: 'linear-gradient(118deg, rgba(54, 141, 221, 0.60) -2.39%, rgba(18, 36, 82, 0.60) 52.25%)',
  },
  starryNight: {
    containerBackground: 'linear-gradient(118deg, rgba(36, 57, 73, 0.96) -2.39%, rgba(17, 28, 50, 0.96) 52.25%)',
    floatingBackground: 'rgba(42, 50, 69, 0.96)',
    solidBackground: 'linear-gradient(118deg, rgb(36, 57, 73) -2.39%, rgb(17, 28, 50) 52.25%)',
    transparentBackground: 'linear-gradient(118deg, rgba(36, 57, 73, 0.70) -2.39%, rgba(17, 28, 50, 0.70) 52.25%)',
  },
  fog: {
    containerBackground: 'linear-gradient(125deg, rgba(60, 64, 66, 0.96) 17.28%, rgba(46, 48, 49, 0.96) 55.43%)',
    solidBackground: 'linear-gradient(125deg, rgb(60, 64, 66) 17.28%, rgb(46, 48, 49) 55.43%)',
    transparentBackground: 'linear-gradient(125deg, rgba(60, 64, 66, 0.80) 17.28%, rgba(46, 48, 49, 0.80) 55.43%)',
    floatingBackground: 'rgba(50, 55, 58, 0.96)',
  },
  charcoal: {
    containerBackground: 'linear-gradient(125deg, rgba(41, 44, 46, 0.85) 17.28%, rgba(30, 32, 32, 0.85) 55.43%)',
    solidBackground: 'linear-gradient(125deg, rgb(41, 44, 46) 17.28%, rgb(30, 32, 32) 55.43%)',
    transparentBackground: 'linear-gradient(125deg, rgba(41, 44, 46, 0.96) 17.28%, rgba(30, 32, 32, 0.96) 55.43%)',
    floatingBackground: 'rgba(28, 30, 31, 0.96)',
  },
} as const;

export enum DesktopThemes {
  oceanBlue = 'oceanBlue',
  starryNight = 'starryNight',
  fog = 'fog',
  charcoal = 'charcoal',
}

export const ScribeCorrectionModeDefault = ScribeCorrectionMode.Fast;

export type KaraokeState = {
  wordIndex: number;
  transcriptId: string;
  message?: string;
};

type LoadingPayload = {
  isLoading: boolean;
  loadingReason?: string | undefined;
};

export type UiState = {
  readonly loading?: boolean;
  loadingReason?: string | undefined;
  readonly electronAppLoaded?: boolean;
  sidebar: boolean;
  intercomOpen: boolean;
  isInviteCardOpen: boolean;
  readonly: boolean;
  fontSize: number;
  correctionMode: ScribeCorrectionMode;
  wordBeingEdited: boolean;
  fullScreen: boolean;
  displayShortcuts: boolean;
  insertSpeakerNameMode: boolean;
  electronCaptionMode: boolean;
  keyboardInputShown: boolean;
  isConnectToMeetingsOpen: boolean;
  karaokeState: KaraokeState;
  wasMutedBeforeKaraoke?: boolean;
  shouldHighlightConnectToMeetings: boolean;
  // Whether the user has attempted to open the Tauri app, and is still here.
  // This means that there is some chance they are still on electron.
  openTauriAttempted: boolean;
  isOneClickScribeModalOpen: boolean;
  desktopTheme: DesktopThemes;
  showControlBar: boolean;
};

const INITIAL_STATE: UiState = {
  loading: false,
  loadingReason: undefined,
  electronAppLoaded: false,
  sidebar: false,
  intercomOpen: false,
  isInviteCardOpen: false,
  readonly: getSearchValue(window, 'readonly', 'false') !== 'false',
  fontSize: 20,
  correctionMode:
    (window.localStorage.getItem('scribe_correction_mode') as ScribeCorrectionMode) || ScribeCorrectionMode.Unset,
  wordBeingEdited: false,
  fullScreen: false,
  displayShortcuts: false,
  insertSpeakerNameMode: false,
  electronCaptionMode: false,
  keyboardInputShown: false,
  isConnectToMeetingsOpen: false,
  karaokeState: {
    wordIndex: 0,
    transcriptId: '',
  },
  shouldHighlightConnectToMeetings: false,
  openTauriAttempted: false,
  isOneClickScribeModalOpen: false,
  desktopTheme: (window.localStorage.getItem('desktop_theme') as DesktopThemes) || DesktopThemes.oceanBlue,
  showControlBar: false,
};

const uiState = createSlice({
  name: 'uiState',
  initialState: INITIAL_STATE,
  reducers: {
    scribeSetFontSize: (state, { payload }: PayloadAction<number>) => {
      state.fontSize = payload;
    },
    setDisplayShortcuts: (state, { payload }: PayloadAction<boolean>) => {
      state.displayShortcuts = payload;
    },
    setScribeCorrectionMode: (state, { payload }: PayloadAction<ScribeCorrectionMode>) => {
      window.localStorage.setItem('scribe_correction_mode', payload);
      segment.track('Web - Correction Mode - Mode Changed', {
        mode: payload,
      });
      state.correctionMode = payload;
    },
    setIsWordBeingEdited: (state, { payload }: PayloadAction<boolean>) => {
      state.wordBeingEdited = payload;
    },
    setFullScreen: (state, { payload }: PayloadAction<boolean>) => {
      state.fullScreen = payload;
    },
    internalSetElectronCaptionMode: (state, { payload }: PayloadAction<boolean>) => {
      state.electronCaptionMode = payload;
    },
    setInsertSpeakerNameMode: (state, { payload }: PayloadAction<boolean>) => {
      state.insertSpeakerNameMode = payload;
      state.displayShortcuts = payload;
    },
    setElectronAppLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.electronAppLoaded = payload;
    },
    setLoading: (state, { payload }: PayloadAction<LoadingPayload>) => {
      state.loading = payload.isLoading;
      if (payload.loadingReason) {
        state.loadingReason = payload.loadingReason;
      }
    },
    finishLoadingReason: (state, { payload }: PayloadAction<string>) => {
      if (state.loadingReason === payload) {
        state.loadingReason = undefined;
      }
    },
    setIsInviteCardOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isInviteCardOpen = payload;
    },
    toggleSideBar: (state, { payload }: PayloadAction<boolean | undefined>) => {
      if (state.sidebar === payload) return state;
      state.sidebar = !state.sidebar;
    },
    cleanUpUi: () => {
      return INITIAL_STATE;
    },
    setIntercomOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.intercomOpen = payload;
    },
    setKeyboardInputShown: (state, { payload }: PayloadAction<boolean>) => {
      state.keyboardInputShown = payload;
    },
    setConnectToMeetingsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isConnectToMeetingsOpen = payload;
    },
    setKaraokeState: (state, { payload }: PayloadAction<KaraokeState>) => {
      state.karaokeState = payload;
    },
    resetKaraokeState: (state) => {
      state.karaokeState = { wordIndex: 0, transcriptId: '' };
    },
    setWasMutedBeforeKaraoke(state, { payload }: PayloadAction<boolean>) {
      state.wasMutedBeforeKaraoke = payload;
    },
    setShouldHighlightConnectToMeetings: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldHighlightConnectToMeetings = payload;
    },
    setOpenTauriAttempted: (state, { payload }: PayloadAction<boolean>) => {
      state.openTauriAttempted = payload;
    },
    setIsOneClickScribeModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOneClickScribeModalOpen = payload;
    },
    setDesktopTheme: (state, { payload }: PayloadAction<DesktopThemes>) => {
      window.localStorage.setItem('desktop_theme', payload);
      state.desktopTheme = payload;
    },
    setShowControlBar: (state, { payload }: PayloadAction<boolean>) => {
      state.showControlBar = payload;
    },
  },
});

export const uiStateReducer = uiState.reducer;
export const {
  setDisplayShortcuts,
  scribeSetFontSize,
  setScribeCorrectionMode,
  setFullScreen,
  setInsertSpeakerNameMode,
  setElectronAppLoaded,
  setLoading,
  finishLoadingReason,
  setIsInviteCardOpen,
  toggleSideBar,
  cleanUpUi,
  setIntercomOpen,
  setIsWordBeingEdited,
  setConnectToMeetingsOpen,
  setKaraokeState,
  resetKaraokeState,
  setShouldHighlightConnectToMeetings,
  setOpenTauriAttempted,
  setWasMutedBeforeKaraoke,
  setIsOneClickScribeModalOpen,
  setDesktopTheme,
  setShowControlBar,
} = uiState.actions;
const { setKeyboardInputShown, internalSetElectronCaptionMode } = uiState.actions;

export const updateKeyboardInputShown = createAsyncThunk(
  'ui/updateKeyboardInputShown',
  async (keyboardInputShown: boolean, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const dispatch = thunkAPI.dispatch;
    // TODO: if muted -> keyboardInputShown false
    // TODO: On Room status update:
    dispatch(setKeyboardInputShown(keyboardInputShown));
    if (selectElectronCaptionMode(state)) {
      window.electronIPC?.sendCCMode('keyboard', keyboardInputShown);
    }
  }
);

export const setElectronCaptionMode = createAsyncThunk(
  'ui/setElectronCaptionMode',
  async (electronCaptionMode: boolean, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    window.electronIPC?.sendSetInCaptionMode(electronCaptionMode);
    dispatch(internalSetElectronCaptionMode(electronCaptionMode));
  }
);

if (window.electronIPC) {
  window.electronIPC.onSetElectronCaptionMode((on) => {
    if (on) {
      //@ts-ignore
      window.navigate('/cc/conversation');
    } else {
      //@ts-ignore
      window.navigate('/web/transcript');
    }
  });
}

/**
 * due to https://linear.app/weareava/issue/WEB-2004/remove-karaoke-until-the-api-gets-better we are removing the karaoke effect
 * but keeping its ability to "time" the length of audio to be played for muting and unmuting purposes
 * it is important to note that this is a temporary solution and will be changed/removed once the API is updated
 */
export const performKaraoke = createAsyncThunk(
  'uiState/karaoke',
  async (
    {
      voice,
      language,
      transcriptId,
      wordCount,
      content,
    }: { voice: string; language: string; transcriptId: string; wordCount: number; content: string[] },
    { dispatch, getState }
  ) => {
    for (let currentWordIndex = 0; currentWordIndex < wordCount; currentWordIndex++) {
      const currentWord = content[currentWordIndex];

      const { isPunctuation, punctuationDelay } = checkEndsWithPunctuation(currentWord, voice, language);
      const karaokeDelay: number = KARAOKE_DELAY_MAPPING[voice];

      await sleep(karaokeDelay);
      dispatch(setKaraokeState({ transcriptId, wordIndex: currentWordIndex }));

      if (isPunctuation) {
        await sleep(punctuationDelay);
      }
    }
    await sleep(KARAOKE_RESET_DELAY);
    dispatch(resetKaraokeState());

    const state = getState() as RootState;
    if (!selectWasMutedBeforeKaraoke(state)) {
      dispatch(startRecording());
    }
  }
);
