import 'firebase/analytics';
import 'firebase/remote-config';

// In testing environments, this import will be undefined because we're going to mock it
import firebase from 'firebase/app';

export { getAuthInstance, setAuthInstance } from './base';

let analyticsInstance = firebase?.analytics();
export const getAnalyticsInstance = () => analyticsInstance;
export const setAnalyticsInstance = (value: any) => {
  analyticsInstance = value;
};

let performanceInstance = firebase?.performance();
export const getPerformanceInstance = () => performanceInstance;
export const setPerformanceInstance = (value: any) => {
  performanceInstance = value;
};

let remoteConfigInstance = firebase?.remoteConfig();
export const getRemoteConfigInstance = () => remoteConfigInstance;
export const setRemoteConfigInstance = (value: any) => {
  remoteConfigInstance = value;
};
if (remoteConfigInstance) {
  remoteConfigInstance.settings.minimumFetchIntervalMillis = 60 * 60 * 1000;
}

export const googleProvider = firebase && new firebase.auth.GoogleAuthProvider();
export const facebookProvider = firebase && new firebase.auth.FacebookAuthProvider();
export const appleProvider = firebase && new firebase.auth.OAuthProvider('apple.com');

let firestore: firebase.firestore.Firestore | undefined = undefined;
export const getFirestore = async () => {
  await import(/* webpackChunkName: "firebase" */ 'firebase/firestore');
  if (!firestore) firestore = firebase.firestore();
  return firestore;
};
export const setFirestore = (instance: any) => {
  firestore = instance;
};
export default firebase;

export const restName = 'firebase-mobile';
