import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { updateIsInConversation } from './conversation';

export type State = {
  conversationStatus: any;
  isOpen: boolean;
  rate?: number;
  rateHover?: number;
  hostName: string;
};

const initialState: State = {
  conversationStatus: {},
  isOpen: false,
  rate: undefined,
  rateHover: undefined,
  hostName: '',
};

export const rateConversationSlice = createSlice({
  name: 'rateConversation',
  initialState,
  reducers: {
    clearConversationStatus(state) {
      state.conversationStatus = {};
    },
    setConversationStatus(state, { payload }: PayloadAction<any>) {
      state.conversationStatus = payload;
    },
    setRateConversationOpen(state, { payload }: PayloadAction<boolean>) {
      state.isOpen = payload;
    },
    setRate(state, { payload }: PayloadAction<number | undefined>) {
      state.rate = payload;
    },
    setRateHover(state, { payload }: PayloadAction<number | undefined>) {
      state.rateHover = payload;
    },
    setHostName(state, { payload }: PayloadAction<string>) {
      state.hostName = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(updateIsInConversation.fulfilled, (state, payload) => {
      if (payload.meta.arg.isInConversation) {
        state.isOpen = false;
      }
    });
  },
});

export const rateConversationReducer = rateConversationSlice.reducer;
export const {
  clearConversationStatus,
  setConversationStatus,
  setRateConversationOpen,
  setRate,
  setRateHover,
  setHostName,
} = rateConversationSlice.actions;
