// Please KEEP THIS SORTED by value for ease of use!!!
export const Z_INDICES = {
  fontSizeInputExampleInactive: -1,
  fontSizeInputItem: 0,
  appDownloadScreenBlueWave: 0,
  fontSizeInputExampleActive: 1,
  appDownloadScreenLogoLink: 1,
  appDownloadScreenMainMessage: 1,
  appDownloadScreenBottomMessage: 1,
  scribeLoginProfilingFormLabel: 1,
  scribeScrollWithTranscriptFollowBtn: 1,
  audioControlsRoot: 1,
  joinConversationMenu: 1,
  organizationSearchResults1: 1,
  scribeJoining: 1,
  organizationSearchResults2: 2,
  toggleRecordingButton: 2,
  setupAudioTitleHome: 2,
  setupAudioTitle: 2,
  setupAudioSetup: 2,
  setupAudioSubtitle: 2,
  setupAudioImage: 0,
  maxParticipantBadgeAvatar: 10, // starts here, but gets decreased by one for each participant
  exportButtonDropdown: 10,
  darkenScreen: 11,
  leftSidebarMobile: 12,
  accountSettingsWrapper: 20,
  accountSettingsNavigationTopLayer: 30,
  accountSettingsNavigationRaisedContainer: 40,
  scribeScrollWithTranscriptWrapper: 100,
  scribeVolumeControllerControls: 100,
  scribeVolumeControllerVolumeDisplay: 100,
  audioRecorderPopper: 100,
  conversationButton: 101,
  fullScreenHamburgerFab: 101,
  ccConversationViewConversationContainer: 101,
  ccConversationViewInviteCardContainer: 101,
  websocketReconnectingBannerElectron: 102,
  ccCaptionAccuracy: 103,
  conversationViewBanner: 102,
  ccNotificationContainer: 103,
  licensesRowProgress: 300,
  inviteCard: 500,
  connectToMeetingsCardPanel: 500,
  websocketReconnectingBannerWeb: 800,
  desktopExpandedAppBar: 1300,
  headerAppBar: 1300,
  loading: 1400,
  connectToMeetingsCardInput: 9999,
  autoTranslateGuestLanguageSelector: 10000,
  connectToMeetingsTooltip: 9999999,
  ttsVoiceButtonPlayButton: 19999999,
  rateConversion: 2147483002,
  oneClickStatusBar: 999999999,
};
