import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import type { OptionsObject } from 'notistack';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback } from 'react';

import type { ConvoV2NotificationProps } from '../modules/customNotifications/ConvoV2Notification';
import { selectElectronCaptionMode } from '../selectors/ui';
import { addNotification } from '../store/slices/ccMode';
import { useAppDispatch, useAppSelector } from '../store/store';

export type NotificationOptions = OptionsObject & {
  convoV2Icon?: React.ReactNode;
  isDesktop?: boolean;
};
export interface ButtonOptions {
  url?: string;
  action?: () => void;
  text: string;
}

export const useNotifications = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
  const dispatch = useAppDispatch();

  const notify = useCallback(
    (text: string, options?: NotificationOptions, button?: ButtonOptions) => {
      if (options?.variant?.includes('soloDia')) {
        return enqueueSnackbar(text, {
          ...options,
          closeSnackbar,
        });
      }
      if (options?.variant === 'convoV2') {
        const variantOptions: OptionsObject & ConvoV2NotificationProps = {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          icon: options.convoV2Icon,
          ...options,
          closeSnackbar,
          isDesktop: options.isDesktop,
        };
        return enqueueSnackbar(text, variantOptions);
      }
      if (electronCaptionMode) {
        dispatch(
          addNotification({
            text,
            duration: options?.autoHideDuration || undefined,
            timestamp: Date.now(),
            //@ts-ignore
            type: options?.variant || 'default',
            link: button?.url,
            linkText: button?.text,
            linkAction: button?.action,
          })
        );
      } else {
        return enqueueSnackbar(text, {
          ...(options || {}),
          action: (key) => (
            <Fragment>
              {button && (
                <Button
                  href={button.url || ''}
                  onClick={button.action}
                  target="_blank"
                  rel="noreferrer noopener"
                  variant="outlined"
                  style={{
                    borderColor: options?.color || 'white',
                    borderRadius: 20,
                    color: options?.color || 'white',
                    fontSize: '0.9em',
                    textTransform: 'none',
                  }}
                >
                  {button.text}
                </Button>
              )}
              <Button
                onClick={() => {
                  closeSnackbar(key);
                }}
                style={{
                  color: 'inherit',
                }}
              >
                <Close />
              </Button>
            </Fragment>
          ),
        });
      }
    },
    [closeSnackbar, enqueueSnackbar, electronCaptionMode]
  );

  return {
    notify,
  };
};
