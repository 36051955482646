import { getFirestore } from './index';

export const deleteTranscriptFromUser = async ({ userId, roomId }: { userId: string; roomId: string }) => {
  const firestore = await getFirestore();
  const userRef = firestore.doc(`users/${userId}`);
  const userSnapshot = await userRef.get();
  if (!userSnapshot.exists) return;

  const roomRef = firestore.doc(`users/${userId}/rooms/${roomId}`);
  const roomSnapshot = await roomRef.get();
  if (!roomSnapshot.exists) return;

  await firestore.collection('users').doc(userId).collection('rooms').doc(roomId).update({ deleted: true });
};

export const deleteManyTranscriptsFromUser = async ({ userId, roomIds }: { userId: string; roomIds: string[] }) => {
  const firestore = await getFirestore();
  const userRef = firestore.doc(`users/${userId}`);
  const userSnapshot = await userRef.get();
  if (!userSnapshot.exists) return;

  const deleteTranscript = async (roomId: string) => {
    const roomRef = await firestore.doc(`users/${userId}/rooms/${roomId}`);
    const roomSnapshot = await roomRef.get();
    if (!roomSnapshot.exists) return;

    await firestore.collection('users').doc(userId).collection('rooms').doc(roomId).update({ deleted: true });

    return roomId;
  };

  const deletedIds = await Promise.all(roomIds.map(deleteTranscript));

  return deletedIds;
};

export const updateTranscriptTitleForUser = async ({
  userId,
  roomId,
  title,
}: {
  userId: string;
  roomId: string;
  title: string;
}) => {
  if (!title) return;

  const firestore = await getFirestore();
  const userRef = firestore.doc(`users/${userId}`);
  const userSnapshot = await userRef.get();
  if (!userSnapshot.exists) return;

  const roomRef = firestore.doc(`users/${userId}/rooms/${roomId}`);
  const roomSnapshot = await roomRef.get();
  if (!roomSnapshot.exists) return;

  await firestore.collection('users').doc(userId).collection('rooms').doc(roomId).update({ title });
};
