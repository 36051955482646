import { createSelector } from 'reselect';

import type { RootState } from '../store/store';

const selectScribeDashboard = (state: RootState) => state.scribeDashboard;

export const selectScribeTrainingRequested = createSelector(
  [selectScribeDashboard],
  (scribeDashboard) => scribeDashboard.scribeTrainingRequested
);

export const selectScribeTrainingFileUrl = createSelector(
  [selectScribeDashboard],
  (scribeDashboard) => scribeDashboard.scribeTrainingSelected?.url
);

export const selectScribeTrainingLang = createSelector(
  [selectScribeDashboard],
  (scribeDashboard) => scribeDashboard.scribeTrainingSelected?.language
);

export const selectScribeTrainingAudioStream = createSelector(
  [selectScribeDashboard],
  (scribeDashboard) => scribeDashboard.scribeTrainingAudioStream
);

export const selectRooms = createSelector([selectScribeDashboard], (scribeDashboard) => scribeDashboard.rooms);

export const selectScribeUrl = createSelector([selectScribeDashboard], (scribeDashboard) => scribeDashboard.scribeUrl);
