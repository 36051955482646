import { createSelector } from 'reselect';

import type { RootState } from '../store/store';

const selectAccount = (state: RootState) => state.account;

export const selectErrorMessagePayment = createSelector(
  [selectAccount],
  (account) => account.licence.errorMessagePayment
);

export const selectLoadingPayment = createSelector([selectAccount], (account) => account.licence.loadingPayment);
export const selectLoadingLicenses = createSelector([selectAccount], (account) => account.licence.loadingLicences);
export const selectLoadingLicencesAssign = createSelector(
  [selectAccount],
  (account) => account.licence.loadingLicenceAssign
);
export const selectLoadingLicencesDelete = createSelector(
  [selectAccount],
  (account) => account.licence.loadingLicenceDelete
);
export const selectErrorNewTrialFailed = createSelector(
  [selectAccount],
  (account) => account.licence.errorNewTrialFailed
);
export const selectTrialNewLoading = createSelector([selectAccount], (account) => account.licence.trialNewLoading);
export const selectOffers = createSelector([selectAccount], (account) => account.licence.offers);
export const selectLoadingOffers = createSelector([selectAccount], (account) => account.licence.loadingOffers);
export const selectErrorEmailAssign = createSelector([selectAccount], (account) => account.licence.errorEmailAssign);
export const selectFeatures = createSelector([selectAccount], (account) => account.user.user.features);
export const selectLicences = createSelector([selectAccount], (account) => account.licence.licences);
export const selectSelfLicence = createSelector([selectAccount], (account) => account.licence.selfLicence);

export const selectAccountUser = createSelector([selectAccount], (account) => account.user.user);
export const selectErrorOrganizationAdminAlreadyExists = createSelector(
  [selectAccount],
  (account) => account.user.errorOrganizationAdminAlreadyExists
);
export const selectErrorOrganizationNameAlreadyUse = createSelector(
  [selectAccount],
  (account) => account.user.errorOrganizationNameAlreadyUse
);
export const selectOrganization = createSelector([selectAccount], (account) => account.user.user.organization);
export const selectOrganizationNewLoading = createSelector(
  [selectAccount],
  (account) => account.user.organizationNewLoading
);
export const selectUserLoading = createSelector([selectAccount], (account) => account.user.loading);
