import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AvaTranslateManager } from '../../utils/AvaTranslate';
const initialState = {
    toggleInProgress: false,
    guestLanguageSelectorOpen: false,
    autoTranslateActive: false,
    // Consent is implied to start with.
    sncfConsentGiven: true,
    sncfConsentDialogShown: false,
};
export const AvaTranslateSlice = createSlice({
    name: 'avaTranslate',
    initialState,
    reducers: {
        setToggleInProgress(state, { payload }) {
            state.toggleInProgress = payload;
        },
        setAutoTranslateActive(state, { payload }) {
            state.autoTranslateActive = payload;
        },
        setHostLanguage(state, { payload }) {
            state.hostLanguage = payload;
        },
        setGuestLanguage(state, { payload }) {
            state.guestLanguage = payload;
        },
        setActive(state, { payload }) {
            state.active = payload;
        },
        setGuestLanguageSelectorOpen(state, { payload }) {
            state.guestLanguageSelectorOpen = payload;
        },
        setSncfConsentGiven(state, { payload }) {
            state.sncfConsentGiven = payload;
        },
        setSncfConsentDialogShown(state, { payload }) {
            state.sncfConsentDialogShown = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createAvaTranslateManager.fulfilled, (state, { payload }) => {
            if (payload)
                state.avaTranslateManager = payload;
        });
    },
});
export const AvaTranslateReducer = AvaTranslateSlice.reducer;
export const { setToggleInProgress, setAutoTranslateActive, setActive, setHostLanguage, setGuestLanguage, setGuestLanguageSelectorOpen, setSncfConsentGiven, setSncfConsentDialogShown, } = AvaTranslateSlice.actions;
export const createAvaTranslateManager = createAsyncThunk('avaTranslate/createAvaTranslateManager', (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const { v1Socket } = state.v1Session;
    if (!v1Socket)
        return;
    return new AvaTranslateManager(v1Socket, dispatch);
});
