import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RecallAIManager } from '../../utils/recallAI';
import type { AppDispatch } from '../store';

export type RecallAIStatus =
  | 'LOADING'
  | 'WAITING_FOR_HOST'
  | 'CONNECTING_TO_AUDIO'
  | 'CONNECTED'
  | 'CAPTIONING'
  | 'DISCONNECT'
  | 'NOT_CONNECTED'
  | 'ERROR';

export type Services = 'Zoom' | 'Google Meet' | 'MS Teams' | '';
export type ServiceProvider = Exclude<Services, ''>;

export type RecallAI = {
  recallAIManager?: RecallAIManager;
  recallAIStatus: RecallAIStatus;
  errorMessage: string | undefined;
  service: Services;
  host: boolean | undefined;
};

const INITIAL_STATE: RecallAI = {
  recallAIManager: undefined,
  recallAIStatus: 'NOT_CONNECTED',
  errorMessage: undefined,
  service: '',
  host: undefined,
};

const recallAI = createSlice({
  name: 'recallAI',
  initialState: INITIAL_STATE,
  reducers: {
    setRecallAIStatus: (state, action: PayloadAction<{ status: RecallAIStatus; errorMessage?: string }>) => {
      const { status, errorMessage } = action.payload;
      state.recallAIStatus = status;
      state.errorMessage = errorMessage;
    },
    setRecallAIService: (state, { payload }: PayloadAction<Services>) => {
      state.service = payload;
    },
    setIsRecallAIHost: (state, { payload }: PayloadAction<boolean>) => {
      state.host = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(createRecallAIManager.fulfilled, (state, { payload }: PayloadAction<RecallAIManager>) => {
      state.recallAIManager = payload;
    });
  },
});

export const recallAIReducer = recallAI.reducer;
export const { setRecallAIStatus, setRecallAIService, setIsRecallAIHost } = recallAI.actions;

export const createRecallAIManager = createAsyncThunk(
  'recallAI/createRecallAIManager',
  ({ ws, dispatch }: { ws: WebSocket; dispatch: AppDispatch }) => {
    return new RecallAIManager(ws, dispatch);
  }
);
