import { createSelector } from 'reselect';

import { AudioErrorType } from '../modules/AudioV2/errors';
import type { RootState } from '../store/store';

const selectAudioV2 = (state: RootState) => state.audioV2;

export const selectAudioV2Status = createSelector([selectAudioV2], (audioV2) => audioV2.status);
export const selectAudioContext = createSelector([selectAudioV2], (audioV2) => audioV2.audioContext);

export const selectSelectedMicrophone = createSelector([selectAudioV2], (audioV2) => {
  return audioV2.selectedMicrophone;
});

export const selectAvailableMicrophones = createSelector([selectAudioV2], (audioV2) => {
  return audioV2.availableMicrophones;
});

export const selectIncludeMicrophoneWithInternalAudio = createSelector([selectAudioV2], (audioV2) => {
  return audioV2.includeMicrophoneWithInternalAudio;
});

export const selectWebRTCTracks = createSelector([selectAudioV2], (audioV2) => audioV2.webRTCTracks);
export const selectWebRTCSenders = createSelector([selectAudioV2], (audioV2) => audioV2.webRTCSenders);

export const selectAudioV2Error = createSelector([selectAudioV2], (audioV2) => audioV2.error);
export const selectAudioV2MicrophoneDenied = createSelector([selectAudioV2Error], (audioV2Error) => {
  if (!audioV2Error) return false;
  return (
    audioV2Error.audioErrorType === AudioErrorType.NO_AUDIO_TRACKS ||
    audioV2Error.audioErrorType === AudioErrorType.PERMISSION_DENIED ||
    audioV2Error.audioErrorType === AudioErrorType.PERMISSION_DISMISSED
  );
});

export const selectMicrophoneAccess = createSelector([selectAudioV2], (audioV2) => {
  return audioV2.microphoneAccess;
});

export const selectNeedInternalAudioAccess = createSelector([selectAudioV2], (audioV2) => {
  return audioV2.needInternalAudioAccess;
});

export const selectVolume = createSelector([selectAudioV2], (audioV2) => {
  return audioV2.volume;
});

export const selectAllMicsSelected = createSelector([selectAudioV2], (audioV2) => {
  return audioV2.allMicsSelected;
});

export const selectWebRTCConnectionStatus = createSelector([selectAudioV2], (audioV2) => {
  return audioV2.webRTCConnectionStatus;
});

export const selectExternalMicForAvaMic = createSelector([selectAudioV2], (audioV2) => audioV2.externalMicForAvaMic);

export const selectOverrideAudioRestart = createSelector([selectAudioV2], (audioV2) => audioV2.overrideAudioRestart);

export const selectIsOneAudioStream = createSelector(
  [selectSelectedMicrophone, selectIncludeMicrophoneWithInternalAudio],
  (selectedMic, includeMicWithInternal) => {
    if (!selectedMic) return false;
    if (!selectedMic.isInternal) return true;
    return !includeMicWithInternal;
  }
);
