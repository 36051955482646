import { setIntercomOpen } from '../store/slices/uiState';
import type { AppDispatch } from '../store/store';

export const closeIntercomTour = () => {
  try {
    // Intercom provides an Intercom("startTour", tourId)
    // method but no method to programmatically close
    // a tour.

    const { frames } = window;
    let intercomTourFrame;
    for (let i = 0; i < frames.length; i += 1) {
      if (frames[i].name === 'intercom-tour-frame') {
        intercomTourFrame = frames[i];
      }
    }
    if (!intercomTourFrame) return;
    const intercomTourHeader = intercomTourFrame.document.getElementsByClassName('intercom-tour-step-header');
    if (intercomTourHeader.length === 0) return;
    const intercomTourCloseButton = intercomTourHeader[0].getElementsByTagName('span');
    if (intercomTourCloseButton.length === 0) return;
    intercomTourCloseButton[0].click();
  } catch (e) {
    //
  }
};

const IntercomAPI = (method, args) => {
  //@ts-ignore
  if (window.Intercom) {
    //@ts-ignore
    return window.Intercom.apply(null, [method, args]);
  }
  return null;
};

export const shutdown = () => {
  if (IntercomAPI) {
    //@ts-ignore
    IntercomAPI('shutdown');
  }
};
export const boot = (metaData) => {
  //@ts-ignore
  window.intercomSettings = metaData;
  if (IntercomAPI) {
    IntercomAPI('boot', metaData);
  }
};
export const hide = () => {
  if (IntercomAPI) {
    //@ts-ignore
    IntercomAPI('hide');
  }
};
export const show = () => {
  // This will only work if intercom-display is set to true, so outside CCMode
  document.documentElement.style.setProperty('--intercom-messenger-display', `block`);
  if (IntercomAPI) {
    //@ts-ignore
    IntercomAPI('show');
  }
};
export const showNewMessage = (message) => {
  show();
  if (IntercomAPI) {
    IntercomAPI('showNewMessage', message);
  }
};
export const startTour = (tourId) => {
  if (IntercomAPI) {
    IntercomAPI('startTour', tourId);
  }
};
export const hideDefaultLauncher = (value) => {
  if (IntercomAPI) {
    IntercomAPI('update', {
      hide_default_launcher: value,
    });
  }
};
export const openOrClosed = (dispatch: AppDispatch) => {
  IntercomAPI('onShow', () => dispatch(setIntercomOpen(true)));
  IntercomAPI('onHide', () => dispatch(setIntercomOpen(false)));
};

export const unreadCountChange = (callback) => {
  IntercomAPI('onUnreadCountChange', callback);
};
