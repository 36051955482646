export const LOGIN = 'session/LOGIN';
export const LOGOUT = 'session/LOGOUT';
export const LOGOUT_IF_NOT_GOOGLE = 'session/LOGOUT_IF_NOT_GOOGLE';
export const UNVALIDATELOGIN = 'session/UNVALIDATELOGIN';
export const USER_FETCH_REQUEST = 'session/USER_FETCH_REQUEST';
export const USER_FETCH_SUCCESS = 'session/USER_FETCH_SUCCESS';
export const USER_FETCH_FAILURE = 'session/USER_FETCH_FAILURE';
export const USER_UPDATE_REQUEST = 'session/USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'session/USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'session/USER_UPDATE_FAILURE';
export const USER_FETCH_INFO_REQUEST = 'session/USER_FETCH_INFO_REQUEST';
export const USER_FETCH_INFO_SUCCESS = 'session/USER_FETCH_INFO_SUCCESS';
export const USER_FETCH_INFO_FAILURE = 'session/USER_FETCH_INFO_FAILURE';
export const UPDATE_LANG = 'session/lang/UPDATE';
export const NEW_ORGANIZATION_REQUEST = 'user/Organization/new/REQUEST';
export const NEW_ORGANIZATION_SUCCESS = 'user/Organization/new/SUCCESS';
export const NEW_ORGANIZATION_FAILURE = 'user/Organization/new/FAILURE';
export const SEARCH_ORGANIZATIONS_REQUEST = 'user/Organization/search/REQUEST';
export const SEARCH_ORGANIZATIONS_SUCCESS = 'user/Organization/search/SUCCESS';
export const SEARCH_ORGANIZATIONS_FAILURE = 'user/Organization/search/FAILURE';
export const CLEAR_ORGANIZATION_ERRORS = 'user/Organization/new/CLEAR';
export const NEW_TRIAL_REQUEST = 'licence/trial/new/REQUEST';
export const NEW_TRIAL_SUCCESS = 'licence/trial/new/SUCCESS';
export const NEW_TRIAL_FAILURE = 'licence/trial/new/FAILURE';
export const LICENCE_PROCESS_PAYMENT_REQUEST = 'licence/create/REQUEST';
export const LICENCE_PROCESS_PAYMENT_SUCCESS = 'licence/create/SUCCESS';
export const LICENCE_PROCESS_PAYMENT_FAILURE = 'licence/create/FAILURE';
export const LICENCE_PROCESS_PAYMENT_RESET = 'licence/create/RESET';
export const LICENCE_SET_RECIPIENT = 'licence/update/recipient';
export const LICENCE_UNSET_RECIPIENT = 'licence/unset/recipient';
export const LICENCE_UNSET_ERROR_RECIPIENT = 'licence/unset/error/recipient';
export const LICENCE_UPDATE_REQUEST = 'licence/update/REQUEST';
export const LICENCE_UPDATE_SUCCESS = 'licence/update/SUCCESS';
export const LICENCE_UPDATE_FAILURE = 'licence/update/FAILURE';
export const OPEN_TRIAL_LICENCE_CANCEL_TYPEFORM = 'licence/OPEN_TRIAL_CANCEL_TYPEFORM';
export const LICENCE_DELETE_REQUEST = 'licence/delete/REQUEST';
export const LICENCE_DELETE_SUCCESS = 'licence/delete/SUCCESS';
export const LICENCE_DELETE_FAILURE = 'licence/delete/FAILURE';
export const DASHBOARD_READY = 'dashboard/READY';
export const DASHBOARD_LOADING = 'dashboard/LOADING';
