import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { BoostManager } from '../../utils/boost';
import type { AppDispatch, RootState } from '../store';

export interface State {
  boostWords: string[];
  addBoostWordsLoading: boolean;
  addBoostWordsSuccess: boolean;
  deleteBoostWordLoading: boolean;
  boostModifyError: boolean;
  isBoosting: boolean;
  boostManager?: BoostManager;
}

const INITIAL_STATE: State = {
  boostWords: [],
  isBoosting: false,
  addBoostWordsLoading: false,
  addBoostWordsSuccess: false,
  deleteBoostWordLoading: false,
  boostModifyError: false,
};

const boostSlice = createSlice({
  name: 'boostSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setIsBoosting(state, { payload }: PayloadAction<boolean>) {
      state.isBoosting = payload;
    },
    setBoostWords: (state, { payload }: PayloadAction<string | null>) => {
      const words = payload ? payload.split(',').map((w) => w.trim()) : [];
      state.boostWords = words;
    },
    resetBoostWordsStatus: (state) => {
      state.addBoostWordsLoading = false;
      state.addBoostWordsLoading = false;
      state.addBoostWordsSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addBoostWordsRequest.pending, (state) => {
      state.addBoostWordsLoading = true;
    });
    builder.addCase(addBoostWordsRequest.fulfilled, (state) => {
      state.addBoostWordsLoading = false;
      state.addBoostWordsSuccess = true;
    });
    builder.addCase(addBoostWordsRequest.rejected, (state) => {
      state.addBoostWordsLoading = false;
      state.boostModifyError = true;
    });
    builder.addCase(deleteBoostWordRequest.pending, (state) => {
      state.deleteBoostWordLoading = true;
    });
    builder.addCase(deleteBoostWordRequest.fulfilled, (state) => {
      state.deleteBoostWordLoading = false;
    });
    builder.addCase(deleteBoostWordRequest.rejected, (state) => {
      state.deleteBoostWordLoading = false;
      state.boostModifyError = true;
    });
    builder.addCase(createBoostManager.fulfilled, (state, { payload }: PayloadAction<BoostManager | undefined>) => {
      if (payload) state.boostManager = payload;
    });
  },
});

export const boostReducer = boostSlice.reducer;
export const { setIsBoosting, setBoostWords, resetBoostWordsStatus } = boostSlice.actions;

export const addBoostWordsRequest = createAsyncThunk(
  'boostSlice/addBoostWords',
  async (words: string[], { getState }) => {
    const state = getState() as RootState;
    const { boostManager } = state.boost;

    if (!boostManager) throw new Error('BoostManager does not exist');
    boostManager.handleUserAddOrRemoveBoostWords('add', words.join(','));
    return { words }; // we need to return something to ensure thunk is fulfilled
  }
);
export const deleteBoostWordRequest = createAsyncThunk(
  'boostSlice/deleteBoostWord',
  async (word: string, { getState }) => {
    const state = getState() as RootState;
    const { boostManager } = state.boost;

    if (!boostManager) throw new Error('BoostManager does not exist');
    boostManager.handleUserAddOrRemoveBoostWords('remove', word);
    return { word }; // we need to return something to ensure thunk is fulfilled
  }
);

export const createBoostManager = createAsyncThunk('boostSlice/createBoostManager', (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppDispatch;
  const state = thunkAPI.getState() as RootState;
  const { v1Socket } = state.v1Session;
  const { subscription } = state.userProfile;

  if (!v1Socket) return;
  return new BoostManager(v1Socket, dispatch, subscription);
});
