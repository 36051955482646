import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme } from '@mui/material/styles';
export function makeTheme(themeMode, bold) {
    const colors = {
        primary: { main: '#000000', dark: '#0F1316', light: '#FFFFFF' },
        secondary: { main: '#C5C8C6', dark: '#3F4044', light: '#FFFFFF' },
        contrast: {
            dark: '#FFFFFF',
            light: '#0F1316',
        },
        red: {
            dark: '#b71c1c',
            light: '#ffcdd2',
        },
        background: {
            dark: '#0F1316',
            default: themeMode === 'dark' ? '#0F1316' : '#FFFFFF',
            light: '#FFFFFF',
        },
        border: {
            dark: '#555555',
            light: '#DDDDDD',
        },
        text: {
            dark: {
                primary: '#EEEEEE',
                secondary: '#DDDDDD',
            },
            light: {
                primary: '#4B4B4B',
                secondary: '#9B9B9B',
            },
        },
    };
    const ava = {
        accent: '#FC9C21',
        blue: '#50ACEE',
        blue1: '#0679B4',
        blue2: '#50ACED',
        blue4: '#B0D9F8',
        blue5: '#D0E9FB',
        dark1: '#000000',
        dark2: '#101010',
        dark4: '#232324',
        dark5: '#2D2F34',
        darkBlue: '#214a88',
        darkBg1: 'linear-gradient(131.91deg, #112967 19.06%, #272728 81.95%)',
        darkBg2: 'linear-gradient(229.3deg, #000000 5.57%, #000E34 80.9%)',
        deepBlue: '#1B367C',
        gradientBlue: 'linear-gradient(135deg, #214a88 25%, #438fd0 85%)',
        asrGrey: 'rgba(100, 100, 100, 0.2)',
        asrGrey2: '#3F3F3F',
        grey: '#9B9B9B',
        grey2: '#7b7c80',
        grey3: '#676666',
        grey4: '#474B43',
        grey5: '#778290',
        grey6: '#DADCE0',
        grey7: '#E3E5E9',
        grey8: '#000E34',
        green: '#34C54F',
        green2: '#009778',
        navy: '#102867',
        navy2: '#035A98',
        navy5: '#F4F9FF',
        offBlue1: '#F6F8FF',
        red2: '#DE423E',
        red3: '#E66E6B',
        red4: '#ED9996',
        red5: '#F5C3C2',
        scribe1: '#238736',
        scribe2: '#02C874',
        scribe6: '#F0FCF7',
        selfColorBlue: '#035A98',
        selfColorBlueDark: '#155D7C',
        white: '#FFFFFF',
        whiteGradient: 'linear-gradient(131.94deg, #F7FBFF 15.67%, #D5D7D9 85.55%)',
        quality: {
            basic: 'radial-gradient(120.92% 106.78% at 0.00% 0%, rgba(134, 190, 255, 0.98) 0%, rgba(134, 205, 255, 0.98) 0.01%, #50ACED 100%)',
            free: 'radial-gradient(120.92% 106.78% at 0.00% 0%, #50ACED 0%, rgba(134, 205, 255, 0.98) 99.99%, rgba(134, 190, 255, 0.98) 100%)',
            premium: 'linear-gradient(118.26deg, #1B367C -2.39%, #102867 19.74%, #000E34 52.25%)',
            scribe: '#02C874',
        },
        transparent: {
            green: {
                light: '#e7fffc',
                dark: '#5b575e',
            },
            black: {
                light: 'rgba(0,0,0,0.5)',
            },
            blue: {
                light: '#F5F8FB',
                dark: '#12202f',
            },
            blueHover: {
                light: '#d1dfec',
                dark: '#1c3247',
            },
        },
        icon: {
            dark1: '#B0D9F8',
            darkBg: '#5B575E',
        },
        text: {
            dark1: '#F2F2F2',
            dark2: '#DADCE0',
            grey: '#7B7C80',
        },
        text1: '#778290',
        states: {
            online: '#34C54F',
        },
        navyGroup: {
            convoText: '#035A98',
            convoTextDark: '#81C3F3',
        },
    };
    const t = {
        palette: {
            mode: themeMode,
            primary: colors.primary,
            secondary: colors.secondary,
            background: colors.background,
            contrast: colors.contrast,
            border: colors.border[themeMode],
            divider: colors.border[themeMode],
            text: colors.text[themeMode],
            red: colors.red[themeMode],
            bold,
            ava,
            ccMode: {
                conversationButtonsBg: 'rgba(38, 50, 82, 0.9)',
                backgroundGradient: 'linear-gradient(180deg, rgba(38, 50, 82, 0.9) 0%, rgba(39, 39, 40, 0.9) 100%)',
            },
        },
        typography: {
            useNextVariants: true,
            fontFamily: [
                'Source Sans Pro',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: (themeParam) => {
                    return {
                        body: {
                            margin: 0,
                            fontSize: '0.875rem !important',
                            lineHeight: '1.43 !important',
                            color: themeParam.palette.text[themeParam.palette.mode],
                        },
                        small: {
                            fontSize: '75%',
                        },
                        textarea: {
                            fontFamily: 'inherit',
                            color: 'inherit',
                        },
                        h3: {
                            marginTop: 20,
                            marginBottom: 10,
                        },
                        h5: {
                            marginTop: 10,
                            marginBottom: 10,
                        },
                        h6: {
                            small: {
                                fontWeight: 400,
                                lineHeight: 1,
                                color: '#777',
                            },
                        },
                        button: {
                            color: 'inherit',
                            cursor: 'pointer',
                            '&[disabled]': {
                                cursor: 'default',
                            },
                        },
                        a: {
                            textDecoration: 'none',
                        },
                    };
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    colorPrimary: {
                        '&.Mui-checked': {
                            color: '#50acef',
                            '& + .MuiSwitch-track': {
                                backgroundColor: ava.blue,
                            },
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-notchedOutline legend': {
                            borderBottom: 'none',
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: ava.blue,
                            fontWeight: 400,
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: '#9B9B9B',
                        },
                        borderBottom: 'none',
                        fontWeight: 400,
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: ava.blue,
                        },
                        '&.Mui-error': {
                            color: '#f44336',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                    containedPrimary: {
                        '&:hover': {
                            backgroundColor: ava.blue,
                        },
                        backgroundColor: ava.blue,
                    },
                    contained: {
                        '&.Mui-disabled': {
                            color: 'white',
                            opacity: 0.3,
                            backgroundColor: ava.blue,
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: '4px 12px',
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: 44,
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        color: ava.blue,
                    },
                    colorSecondary: {
                        '&.Mui-checked': {
                            color: ava.blue,
                        },
                    },
                },
            },
        },
    };
    return createTheme(t);
}
