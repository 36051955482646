import takeRight from 'lodash/takeRight';

import { users } from '../services/api/ava';
import LocalStorage, { STORAGE_KEYS } from '../services/localStorage';
import { setJoinConversationError, setJoiningStatus } from '../store/slices/conversation';
import { setIsInviteCardOpen, setLoading } from '../store/slices/uiState';
import type { AppDispatch } from '../store/store';
import * as segment from '../utils/segment';
import { isAvaRoomValid } from './search-ava-room';
import { stopAndTrack } from './stopwatch';
import { isDefaultRoomId, sendConnectionRequestMessage, sendUserQueryMessage, sendWsMessage } from './ws-v1';

export type FullValue = {
  value: string;
  label: string;
  user: { avaName: string; userName: string };
};

export type ConnectionResponse = {
  type: 'connection-response';
  id: string;
  value: boolean;
  // reason only exists when rejected, ie value is false
  reason?: 'userNotFound' | 'rejectedByUser' | 'timeout';
  // exists when joining a conversation, ie value is true
  conversationMode?: 'private' | 'group' | 'public' | 'public-muted';
};

export type RoomStatus = {
  type: 'room-status';
  id: string;
  host?: {
    avaId: string;
    avaName?: string;
    userName?: string;
    photoUrl?: string;
    planType?: 'free' | 'premium' | 'starter' | 'welcome' | 'pro' | 'welcome_pro' | 'event' | 'enterprise';
    /**
     * What flags this host has
     */
    flags?: {
      [name: string]: boolean;
    };
  };
};

export type QueryResponseUser = {
  avaName?: string;
  userName?: string;
  email?: string;
  userPhoto?: {
    url: string;
  };
};

export type UserQueryResponse = {
  type: 'user-query-response';
  avaNameStartsWith?: string;
  users: QueryResponseUser[];
};

export type cacheUser = {
  [key in string]: {
    userName: string;
    avaName: key;
    userPhoto?: {
      url: string;
    };
  };
};
type Host = RoomStatus['host'];
export class JoinConversationManager {
  ws: WebSocket;
  dispatch: AppDispatch;
  self: string;
  cache: { [key: string]: Set<string> };
  users: cacheUser;
  isScribe: boolean;
  gotConnectionResponse: boolean;
  id: string;
  joined: boolean;
  meta: any;
  host: Host;
  firebaseUser: string;
  avaId: string;
  isJoinedByQRCode: boolean;
  qrCodeAvaName: string | undefined;

  private error: boolean;

  constructor(ws: WebSocket, dispatch: AppDispatch, avaName: string, firebaseUser: string, avaId: string) {
    this.ws = ws;
    this.dispatch = dispatch;
    this.ws.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      this.handleMessage(message);
    });
    this.self = avaName;
    this.avaId = avaId;
    this.firebaseUser = firebaseUser;
    this.cache = {};
    this.users = {};
    this.error = false;
    this.gotConnectionResponse = false;
    this.isScribe = false;
    this.joined = false;
    this.id = '';
    this.isJoinedByQRCode = false;
    this.qrCodeAvaName = undefined;
    this.handleMessage = this.handleMessage.bind(this);
  }

  shouldJoinOnInit() {
    return !!localStorage.getItem('ava_request');
  }

  joinConversationOnInit() {
    const avaName = localStorage.getItem('ava_request');
    if (!avaName) return;
    const joinByQRCodeToken = sessionStorage.getItem('join_by_qrcode_token') || undefined;
    sessionStorage.removeItem('join_by_qrcode_token');
    if (joinByQRCodeToken) {
      this.isJoinedByQRCode = true;
      this.qrCodeAvaName = avaName;
    }
    const viaBranch = localStorage.getItem('via_branch') === avaName;
    localStorage.removeItem('ava_request');
    localStorage.removeItem('via_branch');

    const fromSource = viaBranch ? 'qrcode' : 'default';
    this.handleJoinAvaRoom(avaName, false, fromSource, { viaBranch }, joinByQRCodeToken);
  }

  // TODO: This used to be called, but most likely is not needed. Kept
  // here in case it turns out to be needed.
  // TODO: branch seemed to never be set, do we need this?
  /*
  if (branch && (branch.convoChannel || branch.roomId)) {
    this.onAvaName(`${branch.hostAvaId}`, {
      roomId: branch.convoChannel || branch.roomId,
      viaBranch: true,
    });
  // TODO: joining by v1Token is handled in v1Session directly
  } else if (v1Token) {
    this.onAvaName(``, { v1Token, scribe: !!scribing });
  }
   */

  handleMessage(message: ConnectionResponse | UserQueryResponse | RoomStatus) {
    if (this.error) return;
    switch (message.type) {
      case 'user-query-response': {
        this.updateCache(message.users);
        break;
      }
      case 'connection-response': {
        stopAndTrack('establishConnectTime');
        if (message.reason && this.id) {
          if (this.isJoinedByQRCode) {
            segment.track('AppLess Handled', {
              isSuccessful: false,
              qrCodeAvaName: this.qrCodeAvaName,
              failedReason: message.reason,
            });
          }
          this.dispatch(setJoiningStatus('none'));
          this.dispatch(setJoinConversationError(message.reason));
          this.dispatch(
            setLoading({
              isLoading: false,
            })
          );
          this.error = true;
          this.reset();
          break;
        }
        if (message.id === this.id) {
          this.gotConnectionResponse = true;
        }
        break;
      }
      case 'room-status': {
        if (this.isScribe)
          sendWsMessage(this.ws, {
            type: 'connection-params-update',
            downAudio: {
              host: true,
            },
            role: 'scribe',
          });
        // only if we are joining a conversation that's not ours
        if (this.gotConnectionResponse) {
          this.dispatch(setJoiningStatus('accepted'));
          stopAndTrack('joinConversation');
          segment.track('Joined Conversation', {
            HostAvaName: this.host?.avaName,
            HostUserId: this.host?.avaId,
            'Join Method': 'Connect Request',
          });

          const metricsToUpdate = {
            'Total Joins': 1,
            'Total Invites Connected Count': 0,
          } as const;
          users.updateJoinMetrics({
            avaId: this.avaId,
            firebaseAuthUID: this.firebaseUser,
            joinMetrics: metricsToUpdate,
          });
        }
        if (!isDefaultRoomId(message.id)) {
          this.host = message.host;
          this.joined = true;
          this.id = '';
        }
        break;
      }
    }
  }
  updateCache(users: [{ userName: string; avaName: string }] | Record<any, any>) {
    this.users = {
      ...this.users,
      ...users.reduce((total, user) => {
        total[user.avaName] = user;
        return total;
      }, {}),
    };
    users.forEach((user) => {
      for (let i = 0; i <= user.avaName.length; i += 1) {
        const key = user.avaName.slice(0, i);
        if (!this.cache[key]) {
          this.cache[key] = new Set();
        }
        this.cache[key].add(`&${user.avaName}`);
      }
    });
  }

  updateLocalStorageCache = (value) => {
    let localStorageCache = LocalStorage.get(STORAGE_KEYS.CACHE, {});

    // Store 4 users max in the cache
    if (Object.keys(localStorageCache).length > 3) {
      const lastThreeValues: { username: string; avaName: string }[] = takeRight(Object.values(localStorageCache), 3);
      localStorageCache = Object.fromEntries(lastThreeValues.map((v) => [v.avaName, v]));
    }

    localStorageCache[value.user.avaName] = value.user;
    LocalStorage.set(STORAGE_KEYS.CACHE, localStorageCache);
  };

  getFormattedSuggestions(inputValue: string, fullValue?: FullValue, hideOwnInput?: boolean) {
    const suggestionsAvaName: string[] = Array.from(this.cache[inputValue] || new Set());

    const suggestions = (suggestionsAvaName || []).map((s) => this.users[s.slice(1)]);
    const formattedSuggestions = inputValue
      ? suggestions
          .filter((user) => user)
          .sort()
          .map((user) => ({
            label: user?.userName || '',
            value: `&${user?.avaName}`,
            user: user || { avaName: '', userName: '' },
          }))
      : [];

    if (!hideOwnInput && inputValue !== '') {
      const customS = {
        value: `&${inputValue}`,
        label: `&${inputValue}`,
        user: { avaName: inputValue, userName: '' },
      };
      if (!formattedSuggestions.find((f) => f.value === customS.value)) {
        formattedSuggestions.push(customS);
      }
    }

    if (formattedSuggestions.length === 0 && fullValue) {
      formattedSuggestions.push(fullValue);
    }

    return formattedSuggestions;
  }

  joinRoomByAvaName(name: string, location: any, navigate: any, joinAsScribe?: boolean) {
    if (!name || name.length < 3) return;

    this.dispatch(
      setLoading({
        isLoading: true,
        loadingReason: 'Handling Join Room',
      })
    );

    this.handleJoinAvaRoom(name, joinAsScribe);
  }

  handleJoinAvaRoom(
    avaName: string,
    joinAsScribe?: boolean,
    fromSource?: string,
    meta?: any,
    joinByQRCodeToken?: string
  ) {
    if (meta) this.meta = meta;
    if (!isAvaRoomValid(avaName)) return;
    if (this.self && this.self === avaName) {
      this.dispatch(setJoinConversationError('self'));
      this.dispatch(
        setLoading({
          isLoading: false,
        })
      );
      setTimeout(() => {
        this.dispatch(setJoinConversationError(undefined));
      }, 1000);
      return;
    }
    if (joinAsScribe) {
      this.isScribe = true;
    }
    const role = joinAsScribe ? 'scribe' : 'participant';
    this.dispatch(setJoiningStatus('requested'));
    this.dispatch(setIsInviteCardOpen(false));
    this.dispatch(
      setLoading({
        isLoading: true,
        loadingReason: 'Proceeding Connect',
      })
    );

    this.id = sendConnectionRequestMessage(
      this.ws,
      fromSource || 'default',
      { avaName, token: joinByQRCodeToken },
      role,
      meta || {}
    );
    this.gotConnectionResponse = false;
  }
  handleUserQuery(query: string) {
    sendUserQueryMessage(this.ws, query);
  }

  reset() {
    this.error = false;
    this.gotConnectionResponse = false;
    this.id = '';
    this.isScribe = false;
    this.joined = false;
    setTimeout(() => {
      this.dispatch(setJoinConversationError(undefined));
    }, 1000);
  }
}
