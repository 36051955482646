import { AnalyticsBrowser } from '@segment/analytics-next';
import { getBackendConfig } from './backends';
import { getAppVersion } from './electron';
import { getOS } from './index';
const analytics = new AnalyticsBrowser();
let isInitialized = false;
let electronAppVersion;
const prepareElectronAppVersion = () => {
    if (!electronAppVersion && window.isElectron) {
        getAppVersion().then((version) => {
            electronAppVersion = version;
            if (version) {
                identify({
                    'Desktop App Version': electronAppVersion,
                });
            }
        });
    }
};
export const initAnalytics = () => {
    if (!isInitialized) {
        const writeKey = getBackendConfig('segmentKey');
        analytics.load({ writeKey });
        prepareElectronAppVersion();
        isInitialized = true;
        // Track LifeCycle
        track('Application Opened');
    }
};
const injectContext = (options = {}) => {
    const newOptions = options || {};
    newOptions.context = newOptions.context || {};
    if (window.utm_parameters) {
        newOptions.context.campaign = window.utm_parameters;
    }
    const OS = getOS();
    if (OS) {
        newOptions.context.os = { name: OS };
    }
    return newOptions;
};
export const track = (eventName, properties = {}, options = {}) => {
    const Platform = `Ava ${window.isElectron ? 'Desktop' : 'Web'}`;
    const OS = getOS();
    const propertiesToTrack = Object.assign(Object.assign({}, properties), { Platform, OS });
    prepareElectronAppVersion();
    if (electronAppVersion) {
        propertiesToTrack['Desktop App Version'] = electronAppVersion;
    }
    analytics.track(eventName, propertiesToTrack, injectContext(options));
};
export const identifyWithId = (userId, traits = {}, options = {}) => {
    analytics.identify(userId, traits, injectContext(options));
};
export const identify = (traits = {}, options = {}) => {
    analytics.identify(traits, injectContext(options));
};
export const reset = () => {
    analytics.reset();
};
window.onbeforeunload = () => {
    track('Application Closed');
    return null;
};
// Tracking from the electron app
if (window.electronIPC) {
    window.electronIPC.onTrack((_, eventName) => {
        track(eventName);
    });
}
export const trackMicPermission = (permission) => {
    track('Microphone Permission Prompted', {
        result: permission,
    });
    identify({ microphone_permission: permission });
};
