import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';

export type ScribeTraining = {
  name: string;
  displayName?: string;
  url: string;
  language: 'en-US' | 'fr-FR';
};

export const ScribeTrainings: Record<string, ScribeTraining> = {
  FR_1_chirac: {
    name: 'FR_1_chirac',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_1_chirac.mp3',
    language: 'fr-FR',
  },
  FR_1_degaulle: {
    name: 'FR_1_degaulle',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_1_degaulle.mp3',
    language: 'fr-FR',
  },
  FR_1_pomme_20min: {
    name: 'FR_1_pomme_20min',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_1_pomme_20min.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_fr_1: {
    name: 'FR_2_braqueur_fr_1',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-fr-1.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_fr_2: {
    name: 'FR_2_braqueur_fr_2',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-fr-2.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_fr_3: {
    name: 'FR_2_braqueur_fr_3',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-fr-3.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_mi_1: {
    name: 'FR_2_braqueur_mi_1',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-mi-1.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_mi_2: {
    name: 'FR_2_braqueur_mi_2',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-mi-2.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_mi_3: {
    name: 'FR_2_braqueur_mi_3',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-mi-3.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_mi_4: {
    name: 'FR_2_braqueur_mi_4',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-mi-4.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_mi_5: {
    name: 'FR_2_braqueur_mi_5',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-mi-5.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_ti_1: {
    name: 'FR_2_braqueur_ti_1',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-ti-1.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_ti_2: {
    name: 'FR_2_braqueur_ti_2',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-ti-2.mp3',
    language: 'fr-FR',
  },
  FR_2_braqueur_ti_3: {
    name: 'FR_2_braqueur_ti_3',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_braqueur-ti-3.mp3',
    language: 'fr-FR',
  },
  FR_2_thibava: {
    name: 'FR_2_thibava',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_2_thibava.mp3',
    language: 'fr-FR',
  },
  FR_4_darwin0: {
    name: 'FR_4_darwin0',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_4_darwin0.mp3',
    language: 'fr-FR',
  },
  FR_4_vampire1: {
    name: 'FR_4_vampire1',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_4_vampire1.mp3',
    language: 'fr-FR',
  },
  FR_4_vampire2: {
    name: 'FR_4_vampire2',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_4_vampire2.mp3',
    language: 'fr-FR',
  },
  FR_4_vampire3: {
    name: 'FR_4_vampire3',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_4_vampire3.mp3',
    language: 'fr-FR',
  },
  FR_4_vampire4: {
    name: 'FR_4_vampire4',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_4_vampire4.mp3',
    language: 'fr-FR',
  },
  FR_4_vampire5: {
    name: 'FR_4_vampire5',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_4_vampire5.mp3',
    language: 'fr-FR',
  },
  FR_4_vampire6: {
    name: 'FR_4_vampire6',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_4_vampire6.mp3',
    language: 'fr-FR',
  },
  FR_5_quidam2: {
    name: 'FR_5_quidam2',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_5_quidam2.mp3',
    language: 'fr-FR',
  },
  FR_6_quidam1: {
    name: 'FR_6_quidam1',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/FR_6_quidam1.mp3',
    language: 'fr-FR',
  },
  US_1Speaker: {
    name: 'US_1Speaker',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_1Speaker.mp3',
    language: 'en-US',
  },
  US_1_dopamine: {
    name: 'US_1_dopamine',
    displayName: 'US 1 - Dopamine Detox - How to Take Back Control of Your Life',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_1_dopamine.mp3',
    language: 'en-US',
  },
  US_1_narcissism: {
    name: 'US_1_narcissism',
    displayName: 'US_1 - Surviving Narcissism',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_1_narcissism.mp3',
    language: 'en-US',
  },
  US_2_plastic: {
    name: 'US_2_plastic',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_2_plastic.mp3',
    language: 'en-US',
  },
  US_2speakers: {
    name: 'US_2speakers',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_2speakers.mp3',
    language: 'en-US',
  },
  US_2_change_your_brain: {
    name: 'US_2_change_your_brain',
    displayName: 'US_2 Change Your Brain - Podcast',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_2_change_your_brain.mp3',
    language: 'en-US',
  },
  US_3_computers: {
    name: 'US_3_computers',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_3_computers.mp3',
    language: 'en-US',
  },
  US_3_kitkat: {
    name: 'US_3_kitkat',
    displayName: 'US_3 Can Rie Make This Matcha Kitkat Fancy',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_3_kitkat.mp3',
    language: 'en-US',
  },
  US_3_reward_circuit: {
    name: 'US_3_reward_circuit',
    displayName: "US_3 how Your Brain's Reward Circuits Drive Your Choices",
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_3_rewards.mp3',
    language: 'en-US',
  },
  US_4Speakers: {
    name: 'US_4Speakers',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_4Speakers.mp3',
    language: 'en-US',
  },
  US_4_jobinterview: {
    name: 'US_4_jobinterview',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_4_jobinterview.mp3',
    language: 'en-US',
  },
  US_4_colors: {
    name: 'US_4_colors',
    displayName: 'US_4 What is Color, Really - Radiolab Podcast',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_4_colors.mp3',
    language: 'en-US',
  },
  US_4_laws_of_human_nature: {
    name: 'US_4_laws_of_human_nature',
    displayName: 'US_4 Laws of Human Nature Dissected',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_4_laws_of_human_nature.mp3',
    language: 'en-US',
  },
  US_4_urban_delivery_vans: {
    name: 'US_4_urban_delivery_vans',
    displayName: "US_4 It's time to replace urban delivery vans",
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_4_vans.webm',
    language: 'en-US',
  },
  US_4_Kafka: {
    name: 'US_4_Kafka',
    displayName: 'US_4 Kafka',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_4_Kafka.webm',
    language: 'en-US',
  },
  US_4_starbucks: {
    name: 'US_4_starbucks',
    displayName: 'US_4 Why Starbucks Failed in Australia',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_4_starbucks.webm',
    language: 'en-US',
  },
  US_5_flat_earthers: {
    name: 'US_5_flat_earthers',
    displayName: 'US_5 Flat Earthers vs Scientists - Middle Ground',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_5_flat_earthers.mp3',
    language: 'en-US',
  },
  US_5_H3_podcast: {
    name: 'US_5_H3_podcast',
    displayName: 'US_5 H3 Podcast # 25',
    url: 'https://ava-scribe-trainings.s3.us-west-2.amazonaws.com/US_5_H3_podcast.mp3',
    language: 'en-US',
  },
};

export const fetchScribeTrainings = createAsyncThunk('scribeDashboard/fetchScribeTrainings', async () => {
  return Object.values(ScribeTrainings);
});

export const scribeDashboardRoomsUpdate = createAsyncThunk(
  'scribeDashboard/scribeDashboardRoomsUpdate',
  async (
    { subtype, room }: { subtype: 'add-room' | 'room-status-update' | 'remove-room' | string; room: any },
    { dispatch }
  ) => {
    if (subtype === 'add-room') {
      dispatch(scribeDashboardAddRoom(room));
    } else if (subtype === 'room-status-update') {
      dispatch(scribeDashboardRoomsUpdate(room));
    } else if (subtype === 'remove-room') {
      dispatch(scribeDashboardRemoveRoom(room));
    }
  }
);

export type State = {
  rooms: Array<any>;
  scribeUrl?: string;
  trainings: ScribeTraining[];
  scribeTrainingRequested: boolean;
  scribeTrainingSourceNode?: MediaElementAudioSourceNode;
  scribeTrainingSelected?: ScribeTraining;
  scribeTrainingAudioStream?: MediaStream;

  scribeSocket?: WebSocket;
};

const initialState: State = {
  trainings: [],
  rooms: [],
  scribeTrainingRequested: false,
  scribeSocket: undefined,
};

export const scribeDashboardSlice = createSlice({
  name: 'scribeDashboard',
  initialState,
  reducers: {
    scribeDashboardWSUrl(state, { payload }: PayloadAction<string>) {
      state.scribeUrl = payload;
    },
    scribeDashboardRequestTraining(state, { payload }: PayloadAction<ScribeTraining>) {
      state.scribeTrainingRequested = true;
      state.scribeTrainingSelected = payload;
    },
    scribeDashboardStreamReady(state, { payload }: PayloadAction<{ audioStream: MediaStream }>) {
      state.scribeTrainingAudioStream = payload.audioStream;
    },
    scribeDashboardRoomStatusUpdate(state, { payload }: PayloadAction<any>) {
      const roomsById = Object.fromEntries(state.rooms.map((v) => [v.id, v]));
      const rooms = Object.values({ ...roomsById, [payload.id]: payload });
      state.rooms = rooms;
    },
    scribeDashboardAddRoom(state, { payload }: PayloadAction<any>) {
      state.rooms = state.rooms.filter((v) => v.id !== payload.id);
      state.rooms.push(payload);
    },
    scribeDashboardRemoveRoom(state, { payload }: PayloadAction<any>) {
      state.rooms = state.rooms.filter((v) => v.id !== payload.id);
    },
    setScribeSocket(state, { payload }) {
      state.scribeSocket = payload;
    },
    scribeDashboardClear(state) {
      state.rooms = [];
      state.scribeTrainingRequested = false;
      state.scribeTrainingSelected = undefined;
      state.scribeTrainingAudioStream = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScribeTrainings.fulfilled, (state, { payload }) => {
      state.trainings = payload;
    });
    builder.addCase(fetchScribeTrainings.rejected, (state, { payload }) => {
      console.log('fetchScribeTrainings.rejected', payload);
    });
    builder.addCase(fetchScribeTrainings.pending, (state, { payload }) => {
      console.log('fetchScribeTrainings.pending', payload);
    });
  },
});

export const scribeDashboardReducer = scribeDashboardSlice.reducer;
export const {
  scribeDashboardAddRoom,
  scribeDashboardRemoveRoom,
  scribeDashboardRoomStatusUpdate,
  scribeDashboardRequestTraining,
  scribeDashboardStreamReady,
  scribeDashboardWSUrl,
  scribeDashboardClear,
} = scribeDashboardSlice.actions;

const { setScribeSocket } = scribeDashboardSlice.actions;

export const createScribeSocket = createAsyncThunk(
  'v1Session/createScribeSocket',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const scribeUrl = state.scribeDashboard.scribeUrl;
    if (!scribeUrl) throw new Error('no scribe url');
    const ws = new WebSocket(scribeUrl);
    dispatch(setScribeSocket(ws));
    ws.addEventListener('message', (data) => {
      const message = JSON.parse(data.data);
      dispatch(scribeDashboardRoomsUpdate({ room: message.status || { id: message.roomId }, subtype: message.type }));
    });
    return ws;
  }
);
