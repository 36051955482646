import sagaMonitor from '@redux-saga/simple-saga-monitor';
import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import type { Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import accountSaga from '../sagas/account';
import reducer from './reducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();
const logRocketMiddleware = LogRocket.reduxMiddleware() as Middleware;
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const isProduction = process.env.NODE_ENV === 'production';

export const store = configureStore({
  reducer,
  devTools: !isProduction,
  middleware: (gdm) =>
    gdm({ thunk: true, immutableCheck: false, serializableCheck: false }).concat([sagaMiddleware, logRocketMiddleware]),
  enhancers: [sentryReduxEnhancer],
});

window.store = store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector = <TSelected>(selector: (state: RootState) => TSelected) => {
  return useSelector(selector);
};

sagaMiddleware.run(accountSaga);
