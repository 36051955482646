var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getFirestore } from './index';
export const deleteTranscriptFromUser = ({ userId, roomId }) => __awaiter(void 0, void 0, void 0, function* () {
    const firestore = yield getFirestore();
    const userRef = firestore.doc(`users/${userId}`);
    const userSnapshot = yield userRef.get();
    if (!userSnapshot.exists)
        return;
    const roomRef = firestore.doc(`users/${userId}/rooms/${roomId}`);
    const roomSnapshot = yield roomRef.get();
    if (!roomSnapshot.exists)
        return;
    yield firestore.collection('users').doc(userId).collection('rooms').doc(roomId).update({ deleted: true });
});
export const deleteManyTranscriptsFromUser = ({ userId, roomIds }) => __awaiter(void 0, void 0, void 0, function* () {
    const firestore = yield getFirestore();
    const userRef = firestore.doc(`users/${userId}`);
    const userSnapshot = yield userRef.get();
    if (!userSnapshot.exists)
        return;
    const deleteTranscript = (roomId) => __awaiter(void 0, void 0, void 0, function* () {
        const roomRef = yield firestore.doc(`users/${userId}/rooms/${roomId}`);
        const roomSnapshot = yield roomRef.get();
        if (!roomSnapshot.exists)
            return;
        yield firestore.collection('users').doc(userId).collection('rooms').doc(roomId).update({ deleted: true });
        return roomId;
    });
    const deletedIds = yield Promise.all(roomIds.map(deleteTranscript));
    return deletedIds;
});
export const updateTranscriptTitleForUser = ({ userId, roomId, title, }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!title)
        return;
    const firestore = yield getFirestore();
    const userRef = firestore.doc(`users/${userId}`);
    const userSnapshot = yield userRef.get();
    if (!userSnapshot.exists)
        return;
    const roomRef = firestore.doc(`users/${userId}/rooms/${roomId}`);
    const roomSnapshot = yield roomRef.get();
    if (!roomSnapshot.exists)
        return;
    yield firestore.collection('users').doc(userId).collection('rooms').doc(roomId).update({ title });
});
