import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotifications } from '../../hooks/useNotifications';
import usePrevious from '../../hooks/usePrevious';
import { selectAudioV2Status } from '../../selectors/audioV2';
import {
  selectConversationMuted,
  selectIsAvaMicAvailable,
  selectUserRoleInConversation,
  UserRoleInConversation,
} from '../../selectors/combined';
import { selectInTwilioCalls, selectIsInConversation } from '../../selectors/conversation';
import {
  selectStatusAudioStreams,
  selectTranscripts,
  selectUniqueParticipants,
} from '../../selectors/legacy-conversation';
import { selectScribeTrainingRequested } from '../../selectors/scribe-dashboard';
import { selectAvaId, selectSubscription } from '../../selectors/userProfile';
import { selectConferenceCallRequested } from '../../selectors/v1Session';
import { getCurrentAvailableMics, RecordingStatus, startRecording, stopRecording } from '../../store/slices/audioV2';
import { useAppDispatch, useAppSelector } from '../../store/store';

const Muter = () => {
  // This component is responsible for muting and restarting the audio under certain conditions
  // which I do not understand. It contains a set of hooks that have been moved
  // from ConversationPage in order to make it render less often. For product
  // explanation please consult JB.

  const dispatch = useAppDispatch();

  const silenceTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const recording = useAppSelector(selectAudioV2Status) === RecordingStatus.RECORDING;
  const participants = useAppSelector(selectUniqueParticipants);
  const subscription = useAppSelector(selectSubscription);
  const transcripts = useAppSelector(selectTranscripts);
  const inTwilioCalls = useAppSelector(selectInTwilioCalls);
  const conferenceCallRequested = useAppSelector(selectConferenceCallRequested);
  const userRoleIsParticipant = useAppSelector(selectUserRoleInConversation) === UserRoleInConversation.PARTICIPANT;
  const isInConversation = useAppSelector(selectIsInConversation);
  const muted = useAppSelector(selectConversationMuted);
  const scribeTrainingRequested = useAppSelector(selectScribeTrainingRequested);
  const audioStreams = useAppSelector(selectStatusAudioStreams);
  const recordingStatus = useAppSelector(selectAudioV2Status);
  const avaMicAvailable = useAppSelector(selectIsAvaMicAvailable);
  const { t } = useTranslation();
  const { notify } = useNotifications();
  const avaId = useAppSelector(selectAvaId);

  const participantCount = participants.length;

  // Returns true is the timer was previously started.
  const stopMutingTimer = () => {
    const timeoutSet = silenceTimeout.current !== undefined;
    if (timeoutSet) {
      clearTimeout(silenceTimeout.current);
      silenceTimeout.current = undefined;
    }
    return timeoutSet;
  };

  // If onlyRestart is set to true, will only start the timer if it was already
  // running. If onlyRestart is unset it will always start the timer anew.
  const startMutingTimer = (onlyRestart = false) => {
    const timeoutSet = stopMutingTimer();
    if (onlyRestart && !timeoutSet) return;
    silenceTimeout.current = setTimeout(() => {
      dispatch(stopRecording());
    }, 100 * 1000);
  };

  useEffect(() => {
    // Restart the muting timer if there is a transcript mutation.
    if (
      !subscription ||
      subscription.ongoingSubscription === 'Free' ||
      subscription.ongoingSubscriptionSubtype === 'Lite'
    ) {
      startMutingTimer(true);
    }
  }, [transcripts]);

  useEffect(() => {
    // WARNING: Muting timer logic is tricky and error-prone, so be careful.
    // If we are not recording anymore - stop the muting timer, so that it does
    // not spill over into the next potential recording session.
    if (!recording) {
      stopMutingTimer();
    }
    if (
      recording &&
      !inTwilioCalls &&
      !conferenceCallRequested &&
      // Sometimes the status.participants list will be empty when we start
      // recording. But if there is more than one participant - the timer
      // will be reset after the list updates.
      participantCount <= 1 &&
      (subscription?.ongoingSubscription === 'Free' || subscription?.ongoingSubscriptionSubtype === 'Lite')
    ) {
      // If we are starting to record, but it's not a twilio call and there is
      // only one user -> silence the mic after 100 seconds.
      startMutingTimer();
    }
  }, [recording]);

  useEffect(() => {
    // For some reason backend's participants list contains duplicates.
    // If there is more than one user - never mute the mic.
    if (participantCount > 1) {
      stopMutingTimer();
    }
  }, [participantCount]);

  useEffect(() => {
    if (inTwilioCalls) {
      dispatch(stopRecording());
    }
  }, [inTwilioCalls]);

  useEffect(() => {
    // Conversation's audio streams can be updated remotely and backend sends this info
    // via 'room-status-update' message. Here we are interested if our audio has been
    // muted remotely.
    //
    // There could be more than one stream for each participant. We check the last (latest)
    // for this user.
    const myStreams = (audioStreams || []).filter((stream) => stream.id === avaId);
    if (myStreams.length > 0) {
      const [last] = myStreams.slice(-1);
      if (last.forceMuted && recordingStatus === RecordingStatus.RECORDING) {
        dispatch(stopRecording());
        notify(t('conversation.notifications.youHaveBeenMuted'));
      }
    }
  }, [audioStreams]);

  const prevMuted = usePrevious(muted);
  useEffect(() => {
    // If the conversation is ongoing, and we flipped from muted to unmuted
    // we potentially need to start recording. `muted` depends on
    // conversationMode.
    if (
      isInConversation &&
      !conferenceCallRequested &&
      prevMuted &&
      !muted &&
      (userRoleIsParticipant || scribeTrainingRequested) &&
      !inTwilioCalls
    ) {
      dispatch(startRecording());
    }
  }, [muted, isInConversation]);

  useEffect(() => {
    dispatch(getCurrentAvailableMics());
  }, [avaMicAvailable]);

  return null;
};

export default Muter;
