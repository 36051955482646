import { getSaasBackendEndpoint, httpRequestWithToken } from '../../utils/http';

export const triggerOrgTrial = async (avaId: string, firebaseAuthUID: string) => {
  const channel = window.isElectron ? 'desktop' : 'web';
  return httpRequestWithToken({
    url: `${getSaasBackendEndpoint()}/api/v1/subscription/activate-organization-trial?avaId=${avaId}&firebaseAuthUID=${firebaseAuthUID}&channel=${channel}`,
    method: 'POST',
    payload: { planType: 'Pro' },
  });
};
