import React from 'react';
import { Navigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { selectLoggedIn, selectLoggedInAnonymously, selectLoggedOut } from '../selectors/combined';
import { useAppSelector } from '../store/store';
export default function PrivateRouteWrapper({ children, authenticationMethod, redirectPath, }) {
    const loggedIn = useAppSelector(selectLoggedIn);
    const loggedOut = useAppSelector(selectLoggedOut);
    const loggedInAnonymously = useAppSelector(selectLoggedInAnonymously);
    const isAuthenticated = authenticationMethod();
    if (!loggedOut && !loggedIn && !loggedInAnonymously) {
        return React.createElement(Loading, null);
    }
    if ((loggedIn || loggedInAnonymously) && isAuthenticated) {
        return children;
    }
    return React.createElement(Navigate, { to: redirectPath, replace: true });
}
