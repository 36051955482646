import * as Sentry from '@sentry/react';

const branch = require('branch-sdk');

class Branch {
  branchId: any;
  data: any;

  constructor(branchId) {
    this.branchId = branchId;
    this.data = null;
  }

  async initializeBranch() {
    try {
      this.data = await new Promise((resolve, reject) => {
        branch.init(
          'key_live_penYi35ThCyWIhmocnHM4piotqcldd1Y',
          { branch_match_id: this.branchId, no_journeys: false },
          (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data.data_parsed);
            }
          }
        );
      });
    } catch (err) {
      // Branch is not a must have and is blocked on Firefox incognito, so we
      // just log the error.
      Sentry.captureException(err);
    }
  }

  hasData() {
    return !!this.data;
  }

  hasAudio() {
    return !!this.data.audio;
  }

  savePropertiesToLocalStorageIfExists() {
    const { backendEndpoint, hostAvaId } = this.data;

    if (backendEndpoint) {
      localStorage.setItem('avaBackendUrl', backendEndpoint);
    }

    if (hostAvaId) {
      localStorage.setItem('ava_request', hostAvaId);
      localStorage.setItem('via_branch', hostAvaId);
    }
  }
}

export default Branch;
