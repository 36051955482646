var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Sentry from '@sentry/react';
import { getAuthInstance } from '../../firebase';
import { userCredits } from '../../services/api/ava';
import { setElectronAppLoaded } from '../../store/slices/uiState';
import { setPaidASRCreditTime, setScribeRemainingTime } from '../../store/slices/userProfile';
export const fetchRemainingCredits = (avaId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const user = getAuthInstance().currentUser;
        if (!user) {
            throw new Error(`Tried to fetch scribe credits but user is ${user}`);
        }
        const res = yield userCredits.getUserCredits({ avaId, firebaseAuthUID: user.uid });
        let { scribeCredit, extraPremiumASRCredit, recurringPremiumASRCredit } = res.data;
        if (!scribeCredit || scribeCredit < 0) {
            scribeCredit = 0;
        }
        if (!extraPremiumASRCredit || extraPremiumASRCredit < 0) {
            extraPremiumASRCredit = 0;
        }
        if (!recurringPremiumASRCredit || recurringPremiumASRCredit < 0) {
            recurringPremiumASRCredit = 0;
        }
        dispatch(setScribeRemainingTime(scribeCredit));
        dispatch(setPaidASRCreditTime(extraPremiumASRCredit + recurringPremiumASRCredit));
    }
    catch (err) {
        console.error(err);
        Sentry.captureException(err);
    }
});
if (window.isElectron) {
    window.electronIPC.onDesktopAppLoaded(() => __awaiter(void 0, void 0, void 0, function* () {
        window.store.dispatch(setElectronAppLoaded(true));
    }));
}
