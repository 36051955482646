import produce from 'immer';
import { getType } from 'typesafe-actions';
import * as actions from '../../actions/account';
export const initSessionState = {
    features: [],
    isLoggedin: false,
    loading: false,
    token: '',
};
export default (state = initSessionState, action) => 
// $FlowFixMe
produce(state, (draftArg) => {
    const draft = draftArg;
    switch (action.type) {
        case getType(actions.session.login): {
            draft.token = action.payload.token;
            draft.loading = true;
            draft.isLoggedin = true;
            return draft;
        }
        case getType(actions.session.logout): {
            draft.token = initSessionState.token;
            draft.isLoggedin = false;
            return draft;
        }
        case getType(actions.user.fetchUser.failure): {
            draft.loading = false;
            return draft;
        }
        case getType(actions.user.fetchUser.success): {
            draft.loading = false;
            return draft;
        }
        case getType(actions.session.unvalidateLogin): {
            draft.isLoggedin = false;
            draft.loading = false;
            return draft;
        }
        default:
            return draft;
    }
});
