import type { Selector } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';

import type { TextToSpeechState, TtsErrors, TtsGender, TtsVoice, TtSVoiceEntry } from '../store/slices/textToSpeech';
import type { RootState } from '../store/store';
import { type Participant, type TextToSpeechManager, sortVoiceByGender } from '../utils/textToSpeech';

const selectTextToSpeech = (state: RootState): TextToSpeechState => state.textToSpeech;

export const selectTtsError: Selector<RootState, TtsErrors> = createSelector(
  [selectTextToSpeech],
  (textToSpeech) => textToSpeech.ttsError
);

export const selectIsUsingTts: Selector<RootState, boolean> = createSelector(
  [selectTextToSpeech],
  (textToSpeech) => textToSpeech.isUsingTts
);

export const selectTextToSpeechManager: Selector<RootState, TextToSpeechManager | undefined> = createSelector(
  [selectTextToSpeech],
  (textToSpeech) => textToSpeech.textToSpeechManager
);

export const selectUserTyping: Selector<RootState, Participant | undefined> = createSelector(
  [selectTextToSpeech],
  (texToSpeech) => texToSpeech.userTyping
);

export const selectDisplayedItemsCount: Selector<RootState, number> = createSelector(
  [selectTextToSpeech],
  (textToSpeech) => textToSpeech.displayedItemsCount
);

export const selectCurrentSelectedVoice: Selector<RootState, TtsVoice | undefined> = createSelector(
  [selectTextToSpeech],
  (textToSpeech) => textToSpeech.currentSelectedVoice
);

export const selectTtsGender: Selector<RootState, TtsGender | undefined> = createSelector(
  [selectTextToSpeech],
  (textToSpeech) => textToSpeech.ttsGender
);

export const selectV1TtsVoices: Selector<RootState, Array<SpeechSynthesisVoice>> = createSelector(
  [selectTextToSpeech],
  (textToSpeech) => textToSpeech.v1Voices
);

export const selectV1TtsBestVoice: Selector<RootState, SpeechSynthesisVoice | undefined> = createSelector(
  [selectTextToSpeech],
  (textToSpeech) => textToSpeech.v1TtsBestVoice
);

export const selectTtsVoices: Selector<RootState, TtSVoiceEntry> = createSelector(
  [selectTextToSpeech, selectTtsGender],
  (textToSpeech, gender) => {
    if (!gender) {
      return textToSpeech.ttsVoices;
    } else {
      const sortedVoices = sortVoiceByGender(textToSpeech.ttsVoices, gender);
      return sortedVoices;
    }
  }
);

export const selectVoicesToShow: Selector<RootState, [string, TtsVoice][]> = createSelector(
  [selectTextToSpeech, selectTtsVoices, selectDisplayedItemsCount, selectCurrentSelectedVoice],
  (texToSpeech, ttsVoices, displayedItemsCount, currentSelectedVoice) => {
    const voicesToShow = Array.from(ttsVoices).filter(([_, voice]) => {
      // we basically want to show all voices besides the selected voice in the list
      // additionally we want to only show voices of the same gender as the selected voice
      // unless all voices are already shown ie we clicked showMoreButton
      const isNotSelectedVoice = voice.voiceProviderId !== currentSelectedVoice?.voiceProviderId;

      const shouldMatchGender = displayedItemsCount !== ttsVoices.size && currentSelectedVoice;

      const isGenderMatch = shouldMatchGender ? voice.gender === currentSelectedVoice?.gender : true;

      return isNotSelectedVoice && isGenderMatch;
    });

    return voicesToShow.slice(0, displayedItemsCount);
  }
);

export const selectIsTtsLoading: Selector<RootState, boolean> = createSelector(
  [selectTextToSpeech],
  (textToSpeech) => textToSpeech.isTtsLoading
);
