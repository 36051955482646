import { getPerformanceInstance } from '../firebase';
import * as segment from './segment';

type Stopwatch = {
  startTimestamp: number;
  trace?: firebase.performance.Trace;
};

// We're keeping this as a global, because when splitting the code into different bundles,
// this module might get copied multiple times, each having their own module-local vars.
const globals: { stopWatchData: { [name: string]: Stopwatch } } = globalThis as any;
if (!globals.stopWatchData) {
  globals.stopWatchData = {};
}

const STOPWATCH_DATA: { [name: string]: Stopwatch } = globals.stopWatchData;

if (localStorage.getItem('stopwatch_index_init')) {
  STOPWATCH_DATA['init'] = { startTimestamp: parseInt(localStorage.getItem('stopwatch_index_init') ?? '0') };
}

export const startStopwatch = (name: string) => {
  const trace = getPerformanceInstance().trace(name);
  trace.start();
  STOPWATCH_DATA[name] = { startTimestamp: Date.now(), trace: trace };
};

export const stopAndTrack = (name: string, properties: { [key: string]: string | boolean } = {}) => {
  const stopwatch = STOPWATCH_DATA[name];
  const elapsed = stopwatch && Date.now() - stopwatch.startTimestamp;
  if (stopwatch) {
    for (const [key, value] of Object.entries(properties)) {
      stopwatch.trace?.putAttribute(key, value.toString());
    }
    stopwatch.trace?.stop();
    if (!stopwatch.trace) {
      segment.track(`Web - Stopwatch ${name}`, { ...properties, elapsed }, {});
    }
  }
  delete STOPWATCH_DATA[name];
  if (process.env.LOG_TIMINGS) {
    if (stopwatch) {
      console.log(`Stopwatch '${name}': ${elapsed}ms elapsed`);
    } else {
      console.log(`Stopwatch '${name}' stopped, but not started`);
    }
  }
};
