import * as Sentry from '@sentry/react';

import { getAuthInstance } from '../../firebase';
import { userCredits } from '../../services/api/ava';
import { setElectronAppLoaded } from '../../store/slices/uiState';
import { setPaidASRCreditTime, setScribeRemainingTime } from '../../store/slices/userProfile';
import type { AppDispatch } from '../../store/store';

export const fetchRemainingCredits = (avaId: string) => async (dispatch: AppDispatch) => {
  try {
    const user = getAuthInstance().currentUser;
    if (!user) {
      throw new Error(`Tried to fetch scribe credits but user is ${user}`);
    }
    const res = await userCredits.getUserCredits({ avaId, firebaseAuthUID: user.uid });
    let { scribeCredit, extraPremiumASRCredit, recurringPremiumASRCredit } = res.data;
    if (!scribeCredit || scribeCredit < 0) {
      scribeCredit = 0;
    }
    if (!extraPremiumASRCredit || extraPremiumASRCredit < 0) {
      extraPremiumASRCredit = 0;
    }
    if (!recurringPremiumASRCredit || recurringPremiumASRCredit < 0) {
      recurringPremiumASRCredit = 0;
    }
    dispatch(setScribeRemainingTime(scribeCredit));
    dispatch(setPaidASRCreditTime(extraPremiumASRCredit + recurringPremiumASRCredit));
  } catch (err: any) {
    console.error(err);
    Sentry.captureException(err);
  }
};

if (window.isElectron) {
  window.electronIPC.onDesktopAppLoaded(async () => {
    window.store.dispatch(setElectronAppLoaded(true));
  });
}
