export const KARAOKE_COLOR = '#50ACED';
export const KARAOKE_RESET_DELAY = 200;
export const KARAOKE_PRE_DELAY_MAPPING = {
    nova: 2000,
    shimmer: 1500,
    alloy: 1000,
    fable: 1500,
    echo: 1000,
    onyx: 1200,
};
export const KARAOKE_DELAY_MAPPING = {
    nova: 230,
    shimmer: 230,
    alloy: 230,
    fable: 270,
    echo: 250,
    onyx: 230,
};
// These are all fine tuned to match the voices
// because unfortunately we don't know exactly when a word is being uttered
// thanks API!
export const PUNCTUATION_DELAYS = {
    nova: {
        en: {
            ',': 250,
            '.': 1000,
            '?': 600,
            '!': 600,
            ';': 500,
            ':': 250,
            '...': 600,
        },
        fr: {
            ',': 150,
            '.': 400,
            '?': 300,
            '!': 300,
            ';': 250,
            ':': 250,
            '...': 300,
        },
    },
    shimmer: {
        en: {
            ',': 250,
            '.': 1000,
            '?': 600,
            '!': 800,
            ';': 500,
            ':': 250,
            '...': 900,
        },
        fr: {
            ',': 150,
            '.': 400,
            '?': 300,
            '!': 300,
            ';': 250,
            ':': 250,
            '...': 300,
        },
    },
    alloy: {
        en: {
            ',': 350,
            '.': 1000,
            '?': 700,
            '!': 900,
            ';': 500,
            ':': 250,
            '...': 1500,
        },
        fr: {
            ',': 150,
            '.': 400,
            '?': 300,
            '!': 300,
            ';': 250,
            ':': 250,
            '...': 300,
        },
    },
    fable: {
        en: {
            ',': 350,
            '.': 1000,
            '?': 700,
            '!': 900,
            ';': 500,
            ':': 250,
            '...': 1500,
        },
        fr: {
            ',': 150,
            '.': 400,
            '?': 300,
            '!': 300,
            ';': 250,
            ':': 250,
            '...': 300,
        },
    },
    echo: {
        en: {
            ',': 350,
            '.': 1000,
            '?': 700,
            '!': 800,
            ';': 500,
            ':': 250,
            '...': 600,
        },
        fr: {
            ',': 150,
            '.': 400,
            '?': 300,
            '!': 300,
            ';': 250,
            ':': 250,
            '...': 300,
        },
    },
    onyx: {
        en: {
            ',': 350,
            '.': 1000,
            '?': 600,
            '!': 700,
            ';': 500,
            ':': 250,
            '...': 700,
        },
        fr: {
            ',': 150,
            '.': 400,
            '?': 300,
            '!': 300,
            ';': 250,
            ':': 250,
            '...': 300,
        },
    },
};
export function checkEndsWithPunctuation(word, voice, language) {
    const ignoredChars = ['"', "'", '`'];
    let strippedWord = word;
    while (ignoredChars.includes(strippedWord[strippedWord.length - 1])) {
        strippedWord = strippedWord.slice(0, -1);
    }
    if (strippedWord.endsWith('...')) {
        return { isPunctuation: true, lastChar: '...', punctuationDelay: PUNCTUATION_DELAYS[voice][language]['...'] };
    }
    const lastChar = strippedWord[strippedWord.length - 1];
    const punctuationDelay = PUNCTUATION_DELAYS[voice][language][lastChar];
    return {
        isPunctuation: !!punctuationDelay,
        lastChar,
        punctuationDelay: punctuationDelay !== null && punctuationDelay !== void 0 ? punctuationDelay : 0,
    };
}
