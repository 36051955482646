import platform from 'platform';
import { useCallback, useEffect } from 'react';

import {
  dispatchSpeakerChangeMutation,
  insertSpeakerName,
  mergeTranscript,
  scribeCreateTranscript,
  scribeDeselectTranscriptWord,
  scribeSelectTranscriptWord,
  splitTranscript,
} from '../../actions';
import { selectUserRoleInConversation, UserRoleInConversation } from '../../selectors/combined';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectTranscriptsCurrent } from '../../selectors/legacy-conversation';
import { selectInsertSpeakerNameMode } from '../../selectors/ui';
import { tauriInvoke } from '../../services/desktopIntegration';
import { setDisplayShortcuts, setInsertSpeakerNameMode } from '../../store/slices/uiState';
import type { AppDispatch, RootState } from '../../store/store';
import { singletonTrackPlayer } from '../../utils/audio';
import { last } from '../../utils/lodash';

const isMac = platform.os.family === 'OS X';

interface Modifiers {
  // true - only trigger with modifier
  // false - only trigger without modifier
  // undefined - trigger with or without modifier
  ctrl?: boolean;
  shift?: boolean;
  alt?: boolean;
}

interface HotKeyHandler {
  active: (state: RootState) => boolean;
  modifiers: Modifiers;
  // If key set - it will only trigger with this key
  key?: string;
  // If codePredicate set - it will only trigger if codePredicate returns true
  codePredicate?: (key: string) => boolean;
  handler: (ev: KeyboardEvent, dispatch: AppDispatch, state: RootState) => void;
}

const IN_CONVERSATION: (state: RootState) => boolean = (state) => selectIsInConversation(state);
const IN_CONVERSATION_AND_SCRIBING: (state: RootState) => boolean = (state) =>
  IN_CONVERSATION(state) && selectUserRoleInConversation(state) === UserRoleInConversation.SCRIBE;

const GLOBAL_HOTKEYS: HotKeyHandler[] = [
  {
    active: () => {
      return !!window.__TAURI__;
    },
    modifiers: { ctrl: true, shift: true },
    key: 'i',
    handler: () => {
      tauriInvoke('open_devtools');
    },
  },
  {
    active: IN_CONVERSATION,
    modifiers: {},
    key: 'Escape',
    handler: (ev, dispatch, state) => {
      // deselect, dont commit whatever was changed
      const insertSpeakerNameMode = selectInsertSpeakerNameMode(state);
      dispatch(scribeDeselectTranscriptWord({ skipCommit: true }));
      if (insertSpeakerNameMode) dispatch(setInsertSpeakerNameMode(false));
    },
  },
  {
    active: IN_CONVERSATION,
    modifiers: {},
    key: 'Tab',
    handler: (ev, dispatch, state) => {
      const insertSpeakerNameMode = selectInsertSpeakerNameMode(state);
      const selectedWordIndex = state.scribeConversation.ui.selectedWordIndex;
      const selectedTranscriptIndex = state.scribeConversation.ui.selectedTranscriptIndex;
      if (insertSpeakerNameMode) dispatch(setInsertSpeakerNameMode(false));
      if (selectedWordIndex === undefined) {
        return;
      }
      dispatch(
        scribeSelectTranscriptWord(
          {
            index: selectedWordIndex,
            //@ts-ignore
            indexTranscript: selectedTranscriptIndex,
            direction: ev.shiftKey ? -1 : 1,
            skipCreateNextBloc: true,
          },
          { src: `${ev.shiftKey ? 'SHIFT_' : ''}TAB_KEYPRESS` }
        )
      );
    },
  },
  {
    active: IN_CONVERSATION,
    modifiers: { ctrl: true },
    key: 'Backspace',
    handler: (ev, dispatch, state) => {
      const selectedTranscriptIndex = state.scribeConversation.ui.selectedTranscriptIndex;
      dispatch(
        mergeTranscript({
          //@ts-ignore
          selectedTranscriptIndex,
        })
      );
    },
  },
  {
    active: IN_CONVERSATION,
    modifiers: { ctrl: true, shift: true },
    key: 'Enter',
    handler: (ev, dispatch, state) => {
      const transcriptsCurrent = selectTranscriptsCurrent(state);
      const lastIdTranscript = last(transcriptsCurrent) ?? 0;
      const insertSpeakerNameMode = selectInsertSpeakerNameMode(state);
      //@ts-ignore
      scribeCreateTranscript(lastIdTranscript)(dispatch, window.store.getState);
      if (insertSpeakerNameMode) dispatch(setInsertSpeakerNameMode(false));
    },
  },
  {
    active: IN_CONVERSATION,
    modifiers: { ctrl: true, shift: false },
    key: 'Enter',
    handler: (ev, dispatch, state) => {
      const selectedTranscriptIndex = state.scribeConversation.ui.selectedTranscriptIndex;
      //@ts-ignore
      scribeCreateTranscript(selectedTranscriptIndex);
    },
  },
  {
    active: IN_CONVERSATION_AND_SCRIBING,
    modifiers: { ctrl: true, shift: false },
    codePredicate: (key) => !!key.split('Digit')[1]?.match(/[0-9]/),
    handler: (ev, dispatch, state) => {
      const insertSpeakerNameMode = selectInsertSpeakerNameMode(state);
      let index = parseInt(ev.code.split('Digit')[1], 10);
      if (ev.altKey) {
        index += 9;
      }
      if (insertSpeakerNameMode) {
        dispatch(insertSpeakerName(index - 1));
        dispatch(setInsertSpeakerNameMode(false));
      } else {
        dispatch(dispatchSpeakerChangeMutation(index - 1));
      }
    },
  },
  {
    active: IN_CONVERSATION_AND_SCRIBING,
    modifiers: { ctrl: true },
    key: 'x',
    handler: (ev, dispatch, state) => {
      dispatch(setInsertSpeakerNameMode(true));
    },
  },
  {
    active: IN_CONVERSATION_AND_SCRIBING,
    modifiers: { ctrl: true },
    key: 's',
    handler: (ev, dispatch, state) => {
      const selectedWordIndex = state.scribeConversation.ui.selectedWordIndex;
      const selectedTranscriptIndex = state.scribeConversation.ui.selectedTranscriptIndex;
      dispatch(
        splitTranscript({
          //@ts-ignore
          selectedWordIndex,
          //@ts-ignore
          selectedTranscriptIndex,
        })
      );
    },
  },
  {
    active: IN_CONVERSATION_AND_SCRIBING,
    modifiers: { ctrl: true },
    key: 'j',
    handler: (ev, dispatch, state) => {
      singletonTrackPlayer.incrementDelay();
    },
  },
  {
    active: IN_CONVERSATION_AND_SCRIBING,
    modifiers: { ctrl: true },
    key: 'k',
    handler: (ev, dispatch, state) => {
      singletonTrackPlayer.decrementDelay();
    },
  },
  {
    active: IN_CONVERSATION_AND_SCRIBING,
    modifiers: { ctrl: true },
    key: 'u',
    handler: (ev, dispatch, state) => {
      singletonTrackPlayer.incrementTinyDelay();
    },
  },
  {
    active: IN_CONVERSATION_AND_SCRIBING,
    modifiers: { ctrl: true },
    key: 'i',
    handler: (ev, dispatch, state) => {
      singletonTrackPlayer.decrementTinyDelay();
    },
  },
  {
    active: IN_CONVERSATION_AND_SCRIBING,
    modifiers: { ctrl: true },
    key: 'p',
    handler: (ev, dispatch, state) => {
      singletonTrackPlayer.playPause();
    },
  },
  {
    active: IN_CONVERSATION_AND_SCRIBING,
    modifiers: { ctrl: true },
    key: 'g',
    handler: (ev, dispatch, state) => {
      singletonTrackPlayer.decreaseGain();
    },
  },
  {
    active: IN_CONVERSATION_AND_SCRIBING,
    modifiers: { ctrl: true },
    key: 'h',
    handler: (ev, dispatch, state) => {
      singletonTrackPlayer.increaseGain();
    },
  },
  {
    active: () => {
      // On Tauri on some OSes, we need to handle CTRL+R ourselves.
      return !!window.__TAURI__;
    },
    modifiers: { ctrl: true },
    key: 'r',
    handler: () => {
      window.location.reload();
    },
  },
];

const isMetaOrCtrl = (ev) => {
  return (!isMac && ev.ctrlKey) || (isMac && ev.metaKey);
};

export default function GlobalHotkeyHandler() {
  const handleKeydown = useCallback((ev: KeyboardEvent) => {
    const state = window.store.getState();
    const dispatch = window.store.dispatch;
    if (isMetaOrCtrl(ev)) {
      dispatch(setDisplayShortcuts(true));
    }
    for (const hotkey of GLOBAL_HOTKEYS) {
      if (hotkey.active(state)) {
        if (
          (hotkey.modifiers.ctrl === undefined || hotkey.modifiers.ctrl === (isMac ? ev.metaKey : ev.ctrlKey)) &&
          (hotkey.modifiers.shift === undefined || hotkey.modifiers.shift === ev.shiftKey) &&
          (hotkey.modifiers.alt === undefined || hotkey.modifiers.alt === ev.altKey) &&
          (hotkey.key === undefined || hotkey.key === ev.key) &&
          (hotkey.codePredicate === undefined || hotkey.codePredicate(ev.code))
        ) {
          ev.preventDefault();
          ev.stopPropagation();
          hotkey.handler(ev, dispatch, state);
          return;
        }
      }
    }
  }, []);

  const handleKeyup = useCallback((ev: KeyboardEvent) => {
    const dispatch = window.store.dispatch;
    if ((!isMac && ev.key === 'Control') || (isMac && ev.key === 'Meta')) {
      dispatch(setDisplayShortcuts(false));
    }
  }, []);

  const handleBlur = useCallback(() => {
    const dispatch = window.store.dispatch;
    dispatch(setDisplayShortcuts(false));
  }, []);

  function unsubscribeFromKeydownEvents() {
    document.removeEventListener('keydown', handleKeydown, false);
    document.removeEventListener('keyup', handleKeyup, false);
    window.removeEventListener('blur', handleBlur, false);
  }

  function subscribeToKeydownEvents() {
    document.addEventListener('keydown', handleKeydown, false);
    document.addEventListener('keyup', handleKeyup, false);
    window.addEventListener('blur', handleBlur, false);
  }

  useEffect(() => {
    subscribeToKeydownEvents();
    return unsubscribeFromKeydownEvents;
  }, []);

  return null;
}
