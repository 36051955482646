import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAuthInstance, restName } from '../../firebase';
import {
  deleteManyTranscriptsFromUser,
  deleteTranscriptFromUser,
  updateTranscriptTitleForUser,
} from '../../firebase/saved-transcript';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectLastReadTimestamp, selectTranscripts } from '../../selectors/saved-transcript';
import { selectAvaId } from '../../selectors/userProfile';
import type { RootState } from '../../store/store';
import type { SavedTranscript } from '../../types/saved-transcript';
import { cloudTranscriptsEndpoint } from '../../utils/http';
import { loadIDToken } from './auth';

export interface State {
  loading: boolean;
  deleteLoading: boolean;
  updateTitleLoading: boolean;
  hasMoreTranscripts: boolean;
  lastReadTimestamp: number | null;
  transcripts: SavedTranscript[];
  selectedId: string;
  error: string;
}

const INITIAL_STATE: State = {
  loading: false,
  deleteLoading: false,
  updateTitleLoading: false,
  hasMoreTranscripts: true,
  lastReadTimestamp: null,
  transcripts: [],
  selectedId: '',
  error: '',
};

const savedTranscriptSlice = createSlice({
  name: 'saved-transcript',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedTranscriptId: (state, { payload }: PayloadAction<string>) => {
      state.selectedId = payload;
    },
    resetTranscripts: (state) => {
      state.hasMoreTranscripts = true;
      state.lastReadTimestamp = null;
      state.loading = false;
      state.transcripts = [];
    },
    addNewTranscript: (state, { payload }: PayloadAction<SavedTranscript>) => {
      state.lastReadTimestamp = state.transcripts.length
        ? retrieveLastReadTranscript(state.transcripts).startTimestampMs
        : payload.startTimestampMs;
      state.transcripts = filterAccessibleTranscripts([payload], state.transcripts);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTranscriptsRequestAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchTranscriptsRequestAsync.fulfilled,
      (
        state,
        { payload }: PayloadAction<{ transcripts: SavedTranscript[]; hasMoreTranscripts: boolean } | undefined>
      ) => {
        state.loading = false;
        if (payload) {
          state.hasMoreTranscripts = payload.hasMoreTranscripts;
          if (payload.transcripts.length) {
            state.lastReadTimestamp = retrieveLastReadTranscript(payload.transcripts).startTimestampMs;
            state.transcripts = filterAccessibleTranscripts(state.transcripts, payload.transcripts);
          }
        }
      }
    );
    builder.addCase(fetchTranscriptsRequestAsync.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });
    builder.addCase(deleteTranscriptRequestAsync.pending, (state) => {
      state.deleteLoading = true;
    });
    builder.addCase(deleteTranscriptRequestAsync.fulfilled, (state, { payload }: PayloadAction<SavedTranscript>) => {
      state.deleteLoading = false;
      state.transcripts = filterDeletedTranscript(state.transcripts, payload);
    });
    builder.addCase(deleteTranscriptRequestAsync.rejected, (state, { payload }) => {
      state.deleteLoading = false;
      state.error = payload as string;
    });
    builder.addCase(deleteManyTranscriptsRequestAsync.pending, (state) => {
      state.deleteLoading = true;
    });
    builder.addCase(deleteManyTranscriptsRequestAsync.fulfilled, (state, { payload }: PayloadAction<string[]>) => {
      state.deleteLoading = false;
      state.transcripts = filterDeletedManyTranscripts(state.transcripts, payload);
    });
    builder.addCase(deleteManyTranscriptsRequestAsync.rejected, (state, { payload }) => {
      state.deleteLoading = false;
      state.error = payload as string;
    });
    builder.addCase(updateTranscriptTitleRequestAsync.pending, (state) => {
      state.updateTitleLoading = true;
    });
    builder.addCase(
      updateTranscriptTitleRequestAsync.fulfilled,
      (state, { payload }: PayloadAction<SavedTranscript>) => {
        state.updateTitleLoading = false;
        state.transcripts = updateTranscripts(state.transcripts, payload);
      }
    );
    builder.addCase(updateTranscriptTitleRequestAsync.rejected, (state, { payload }) => {
      state.updateTitleLoading = false;
      state.error = payload as string;
    });
  },
});

export const savedTranscriptReducer = savedTranscriptSlice.reducer;
export const { setSelectedTranscriptId, resetTranscripts, addNewTranscript } = savedTranscriptSlice.actions;

export const fetchTranscriptsRequestAsync = createAsyncThunk(
  'savedTranscript/fetchTranscripts',
  async (beforeTimestamp: number | undefined, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState() as RootState;
      const avaId = selectAvaId(state);
      const firebaseUser = selectFirebaseUser(state);
      if (!firebaseUser) return;

      let endpoint = `${cloudTranscriptsEndpoint}?avaId=${avaId}&firebaseAuthID=${firebaseUser.uid}&limit=10`;

      const transcripts: SavedTranscript[] = selectTranscripts(state);
      if (beforeTimestamp) {
        endpoint += `&before=${beforeTimestamp}`;
      } else if (transcripts.length > 0) {
        const lastReadTimestamp = selectLastReadTimestamp(state);
        endpoint += `&before=${lastReadTimestamp}`;
      }
      const firebaseIdToken = await dispatch(loadIDToken()).unwrap();

      const token = firebaseIdToken ? firebaseIdToken : getAuthInstance().currentUser?.getIdToken();

      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Basic ${window.btoa(`${restName}:${token}`)}`,
        },
      });

      const data = await response.json();

      if (data.length && filterAccessibleTranscripts(data, []).length === 0) {
        // Note: This means that all of the fetched transcripts are not accessible
        // so the infinite scroll that loads this will think there is more data, but
        // it will keep waiting for the current fetch to finish rather than refetching
        // (because the transcripts visible by it will not be updated). This means
        // we need to manually refetch with the next timestamp. This could potentially
        // keep refetching if we keep receiving non-empty response that contains
        // only not accessible transcripts.
        // This action needs to be dispatched after the lastReadTimestamp has updated
        // hence the need for setTimeout.
        // Note: If there are transcripts in the list, we have no timestamp, so we would
        // loop forever. That's why we call this action with the last timestamp in the list.
        setTimeout(() => {
          dispatch(fetchTranscriptsRequestAsync(data[data.length - 1].startTimestampMs));
        }, 0);
      }

      if (data.length) {
        return { transcripts: data, hasMoreTranscripts: true };
      } else {
        return { transcripts: [], hasMoreTranscripts: false };
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTranscriptRequestAsync = createAsyncThunk(
  'savedTranscript/deleteTranscript',
  async ({ avaId, transcript }: { avaId: string; transcript: SavedTranscript }) => {
    try {
      await deleteTranscriptFromUser({
        userId: avaId,
        roomId: transcript.roomId,
      });

      return transcript;
    } catch (err: any) {
      return err.message;
    }
  }
);

export const deleteManyTranscriptsRequestAsync = createAsyncThunk(
  'savedTranscript/deleteManyTranscripts',
  async ({ avaId, transcripts }: { avaId: string; transcripts: SavedTranscript[] }) => {
    try {
      const roomIds = transcripts.map((t) => t.roomId);
      const deletedTranscriptRoomIds: any = await deleteManyTranscriptsFromUser({
        userId: avaId,
        roomIds,
      });

      return deletedTranscriptRoomIds;
    } catch (err: any) {
      return err.message;
    }
  }
);

export const updateTranscriptTitleRequestAsync = createAsyncThunk(
  'savedTranscript/updateTranscriptTitle',
  async ({ avaId, transcript, title }: { avaId: string; transcript: SavedTranscript; title: string }) => {
    try {
      await updateTranscriptTitleForUser({
        userId: avaId,
        roomId: transcript.roomId,
        title,
      });

      const updatedTranscript = { ...transcript, title } as const;
      return updatedTranscript;
    } catch (err: any) {
      return err.message;
    }
  }
);

export const filterAccessibleTranscripts = (
  currentTranscripts: SavedTranscript[],
  newTranscripts: SavedTranscript[]
) => {
  const updatedTranscripts: SavedTranscript[] = [];

  const transcriptMapping: Record<string, any> = {};
  currentTranscripts.forEach((transcript: SavedTranscript) => {
    if (!(transcript.roomId in transcriptMapping)) {
      transcriptMapping[transcript.roomId] = true;
      updatedTranscripts.push(transcript);
    }
  });
  newTranscripts.forEach((transcript: SavedTranscript) => {
    if (!(transcript.roomId in transcriptMapping)) {
      transcriptMapping[transcript.roomId] = true;
      updatedTranscripts.push(transcript);
    }
  });

  const filteredTranscripts: SavedTranscript[] = updatedTranscripts.filter((transcript) => {
    return transcript.hasTranscripts && transcript.isAllowedAccess && !transcript.deleted;
  });

  return filteredTranscripts;
};
export const retrieveLastReadTranscript = (newTranscripts: SavedTranscript[]) =>
  newTranscripts[newTranscripts.length - 1];
export const filterDeletedTranscript = (currentTranscripts: SavedTranscript[], deletedTranscript: SavedTranscript) => {
  const filteredTranscripts: SavedTranscript[] = currentTranscripts.filter(
    (transcript: SavedTranscript) => transcript.roomId !== deletedTranscript.roomId
  );

  return filteredTranscripts;
};
export const filterDeletedManyTranscripts = (
  currentTranscripts: SavedTranscript[],
  deletedTranscriptIds: string[]
): SavedTranscript[] =>
  currentTranscripts.filter((transcript: SavedTranscript) => !deletedTranscriptIds.includes(transcript.roomId));
export const updateTranscripts = (currentTranscripts: SavedTranscript[], updatedTranscript: SavedTranscript) => {
  const updatedTranscripts = [...currentTranscripts];
  for (let i = 0; i < updatedTranscripts.length; i += 1) {
    if (updatedTranscripts[i].roomId === updatedTranscript.roomId) {
      updatedTranscripts[i] = { ...updatedTranscript };
      break;
    }
  }

  return updatedTranscripts;
};
