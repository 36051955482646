import io from 'socket.io-client';

import { getBackendConfig } from './backends';

const loggers = [
  (meta, data) => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line
      console.log(data);
    }
  },
];

const meta = {
  platform: {
    osType: 'WebApp',
    channel: 'web',
    tag: process.env.TAG_COMMIT,
    osVersion: process.env.TAG_COMMIT,
    hash: process.env.HASH_COMMIT,
    userAgent: navigator.userAgent,
  },
  user: {},
};

const room = {
  id: undefined,
};

export const updateUserInfo = (user) => {
  meta.user = { ...meta.user, ...user };
};

export const updateRoomId = (id) => {
  room.id = id;
};

export const init = () =>
  new Promise((resolve) => {
    const socket = io(getBackendConfig('chabarakUrl'), {
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      loggers.push((m, data) => socket.emit('log', JSON.stringify({ meta: m, data })));
      resolve('Chabarak logger initialized');
    });
  });
