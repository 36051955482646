import { getAuthInstance } from '../../firebase';
import { getSaasBackendEndpoint, httpRequest, httpRequestWithToken } from '../../utils/http';

export const fetchCustomToken = async () => {
  const currentUser = getAuthInstance().currentUser;
  if (!currentUser) return '';

  const url = `${getSaasBackendEndpoint()}/api/v1/users/me/customToken`;
  const idToken = await getAuthInstance().currentUser?.getIdToken();

  const res = await httpRequestWithToken({
    url,
    method: 'POST',
    payload: { idToken },
  });

  const { data } = res;
  return data.customToken || '';
};

const getCustomTokenURL = async (newUser = false) => {
  const firebaseCustomToken = await fetchCustomToken();
  let link = `ava.me://open/web?firebaseCustomToken=${firebaseCustomToken}`;
  if (newUser) {
    link += '&newUser=true';
  }
  return link;
};

const createOrganization = async ({
  type,
  name,
  role,
  placeId,
}: {
  type: string;
  name: string;
  role: string;
  placeId?: string;
}) =>
  httpRequestWithToken({
    url: `${getSaasBackendEndpoint()}/api/v1/organization`,
    method: 'POST',
    payload: {
      type,
      name,
      role,
      placeId,
    },
  });

const getOrCreateUser = async (data: any) =>
  // NOTE: if email is not verified this will throw 403
  httpRequestWithToken({
    url: `${getSaasBackendEndpoint()}/api/v1/users/me`,
    method: 'PATCH',
    payload: data,
  });

const getUser = async (language: string) =>
  httpRequestWithToken({
    url: `${getSaasBackendEndpoint()}/api/v1/users/me?language=${language}`,
    method: 'GET',
  });

const createLicence = async ({ licence, language, trial }: { licence: any; language: string; trial: any }) => {
  const payload = {
    ...licence,
    language,
    trial,
  } as const;

  if (licence.type === 'magicLink') {
    return httpRequest({
      url: `${getSaasBackendEndpoint()}/api/v1/licences/magicLink`,
      method: 'POST',
      payload,
    });
  } else {
    return httpRequestWithToken({
      url: `${getSaasBackendEndpoint()}/api/v1/licences`,
      method: 'POST',
      payload,
    });
  }
};

const unassignLicence = ({ id, licence, language }: { id: string; licence: any; language: string }) =>
  httpRequestWithToken({
    url: `${getSaasBackendEndpoint()}/api/v1/licences/${id}`,
    method: 'DELETE',
    payload: {
      ...licence,
      language,
    },
  });

const updateLicence = async ({
  id,
  licence,
  recipient,
  language,
}: {
  id: string;
  licence: any;
  recipient: any;
  language: string;
}) =>
  httpRequestWithToken({
    url: `${getSaasBackendEndpoint()}/api/v1/licences/${id}`,
    method: 'PATCH',
    payload: {
      ...licence,
      recipient,
      language,
    },
  });

export const users = {
  fetchCustomToken,
  getCustomTokenURL,
  getOrCreateUser,
  getUser,
} as const;

export const organizations = {
  createOrganization,
} as const;

export const licences = {
  createLicence,
  unassignLicence,
  updateLicence,
} as const;
