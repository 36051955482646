export const compareIntegerStrings = (left, right) => {
    const leftInt = parseInt(left);
    const rightInt = parseInt(right);
    return leftInt - rightInt;
};
// Returns target index if found or -1 if not found
export function binarySearchArray(arr, target, compare) {
    let leftIndex = 0;
    let rightIndex = arr.length - 1;
    while (leftIndex <= rightIndex) {
        const midIndex = Math.floor((leftIndex + rightIndex) / 2);
        const midValue = arr[midIndex];
        const compared = compare(midValue, target);
        if (compared === 0) {
            return midIndex;
        }
        else if (compared < 0) {
            leftIndex = midIndex + 1; // Search right half
        }
        else {
            rightIndex = midIndex - 1; // Search left half
        }
    }
    return -1;
}
