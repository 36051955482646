var _a;
import { getPerformanceInstance } from '../firebase';
import * as segment from './segment';
// We're keeping this as a global, because when splitting the code into different bundles,
// this module might get copied multiple times, each having their own module-local vars.
const globals = globalThis;
if (!globals.stopWatchData) {
    globals.stopWatchData = {};
}
const STOPWATCH_DATA = globals.stopWatchData;
if (localStorage.getItem('stopwatch_index_init')) {
    STOPWATCH_DATA['init'] = { startTimestamp: parseInt((_a = localStorage.getItem('stopwatch_index_init')) !== null && _a !== void 0 ? _a : '0') };
}
export const startStopwatch = (name) => {
    const trace = getPerformanceInstance().trace(name);
    trace.start();
    STOPWATCH_DATA[name] = { startTimestamp: Date.now(), trace: trace };
};
export const stopAndTrack = (name, properties = {}) => {
    var _a, _b;
    const stopwatch = STOPWATCH_DATA[name];
    const elapsed = stopwatch && Date.now() - stopwatch.startTimestamp;
    if (stopwatch) {
        for (const [key, value] of Object.entries(properties)) {
            (_a = stopwatch.trace) === null || _a === void 0 ? void 0 : _a.putAttribute(key, value.toString());
        }
        (_b = stopwatch.trace) === null || _b === void 0 ? void 0 : _b.stop();
        if (!stopwatch.trace) {
            segment.track(`Web - Stopwatch ${name}`, Object.assign(Object.assign({}, properties), { elapsed }), {});
        }
    }
    delete STOPWATCH_DATA[name];
    if (process.env.LOG_TIMINGS) {
        if (stopwatch) {
            console.log(`Stopwatch '${name}': ${elapsed}ms elapsed`);
        }
        else {
            console.log(`Stopwatch '${name}' stopped, but not started`);
        }
    }
};
