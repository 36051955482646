import type { Selector } from 'reselect';
import { createSelector } from 'reselect';

import type { RootState } from '../store/store';
import type { FeatureMap } from '../types';

const selectUserProfile = (state: RootState) => state.userProfile;

export const selectSubscription = createSelector([selectUserProfile], (userProfile) => userProfile.subscription);

export const selectFeatures = createSelector([selectUserProfile], (userProfile) => userProfile.features);
export const selectFeaturesScribe: Selector<RootState, boolean> = createSelector([selectFeatures], (features) =>
  Boolean(features?.scribe)
);

export const selectFeature = (feature: keyof FeatureMap) =>
  createSelector([selectFeatures], (features) => features?.[feature]);
export const selectUpdateFeaturesLoading = createSelector(
  [selectUserProfile],
  (userProfile) => userProfile.updateFeaturesLoading
);

export const selectCountry = createSelector([selectUserProfile], (userProfile) => userProfile.avaUser?.country);

export const selectCity = createSelector([selectUserProfile], (userProfile) => userProfile.avaUser?.city);

export const selectFeedbackMetrics = createSelector(
  [selectUserProfile],
  (userProfile) => userProfile.avaUser?.convoMetrics
);
export const selectOrgProperties = createSelector(
  [selectUserProfile],
  (userProfile) => userProfile.profile?.orgProperties
);
export const selectRole = createSelector([selectUserProfile], (userProfile) => userProfile.profile?.role);

export const selectHearingProfile = createSelector(
  [selectUserProfile],
  (userProfile) => userProfile.profile?.hearingProfile
);
export const selectUpdateHearingProfileLoading = createSelector(
  [selectUserProfile],
  (userProfile) => userProfile.updateHearingProfileLoading
);

export const selectParse = createSelector([selectUserProfile], (userProfile) => userProfile.parse);
export const selectAvaId = createSelector([selectParse], (parse) => parse?.avaId);

export const selectIsUserLoggedIn = createSelector([selectParse], (parse) => Boolean(parse));
export const selectIsUserTemporary = createSelector([selectParse], (parse) => parse?.accountType === 'Temporary');
export const selectOrganization: Selector<RootState, { id?: string; name?: string; owner?: string; type?: string }> =
  createSelector([selectParse], (parse) => parse?.organization);
export const selectUserName = createSelector([selectParse], (parse) => parse?.userName);
export const selectAvaName = createSelector([selectParse], (parse) => parse?.avaName);
export const selectAccountType = createSelector([selectParse], (parse) => parse?.accountType);
export const selectUserPhotoURL = createSelector([selectParse], (parse) => parse?.userPhoto?.url);
export const selectDisplayName = createSelector([selectParse], (parse) => parse?.userName || parse?.avaName);
export const selectIsAdmin = createSelector(
  [selectUserProfile],
  (userProfile) => userProfile.profile?.orgProperties?.role === 'admin' || userProfile.profile?.role === 'admin'
);

export const selectDisplayId = createSelector([selectParse], (parse) => {
  if (!parse) return 'Logged Out';
  if (parse.emails) {
    return Array.from(Object.values(parse.emails))[0] as string;
  }
  if (parse.phoneNumber) {
    return parse.phoneNumber;
  }
  if (parse.accountType === 'Temporary') {
    return parse.accountType;
  }
  return parse.firebaseAuthUID || '';
});

export const selectUpdateUserNameLoading = createSelector(
  [selectUserProfile],
  (userProfile) => userProfile.updateUserNameLoading
);
export const selectUpdateUserNameSuccess = createSelector(
  [selectUserProfile],
  (userProfile) => userProfile.updateUserNameSuccess
);

export const getPaidASRCreditTime = createSelector([selectUserProfile], (userProfile) => {
  return userProfile.paidASRCreditTime;
});
export const getScribeRemainingTime = createSelector([selectUserProfile], (userProfile) => {
  return userProfile.scribeRemainingTime;
});

export const selectUserProfileFetchFinished = createSelector(
  [selectUserProfile],
  (userProfile) => userProfile.userProfileFetchFinished
);
export const selectUserProfileFetchInitiated = createSelector(
  [selectUserProfile],
  (userProfile) => userProfile.userProfileFetchInitiated
);

export const selectIsProfileComplete = createSelector(
  [selectUserProfile, selectHearingProfile],
  (userProfile, hearingProfile) => {
    const { avaUser } = userProfile;
    if (!avaUser) return false;
    const {
      parse: { organizationId, userName },
    } = avaUser;
    let bestHearingProfile = hearingProfile;
    if ((hearingProfile === -1 || hearingProfile === undefined) && avaUser.profile?.hearingProfile !== undefined) {
      bestHearingProfile = avaUser.profile?.hearingProfile;
    }
    return (
      bestHearingProfile !== -1 ||
      // The user has had their first conversation
      avaUser.convoMetrics.total_conversations > 0 ||
      // or we already have all the necessary info
      (userName && organizationId)
    );
  }
);

export const selectIsWebGuest = createSelector([selectUserName], (userName) => userName === 'web guest');
