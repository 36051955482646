import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles(() => ({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
    },
}));
function OnboardingV2({ children }) {
    const classes = useStyles();
    return React.createElement("main", { className: classes.root }, children);
}
export default OnboardingV2;
