import { combineReducers } from 'redux';
import licenceReducer, { initLicenceState } from './licence';
import sessionReducer, { initSessionState } from './session';
import userReducer, { initUserState } from './user';
export const initialState = {
    licence: initLicenceState,
    session: initSessionState,
    user: initUserState,
};
const accountReducer = combineReducers({
    licence: licenceReducer,
    session: sessionReducer,
    user: userReducer,
});
export default accountReducer;
