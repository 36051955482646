import { stopRecording } from '../store/slices/audioV2';
import { setRecallAIService, setRecallAIStatus } from '../store/slices/recallAI';
import { setConnectToMeetingsOpen } from '../store/slices/uiState';
import * as segment from './segment';
import { sendConversationModeUpdateMessage, sendWsMessage } from './ws-v1';
const HELP_ARTICLE = 'https://help.ava.me/en/articles/8584613-troubleshooting-ava-connect-in-video-calls';
export class RecallAIManager {
    constructor(ws, dispatch) {
        this.v1RecallAIStatus = 'ready';
        this.ws = ws;
        this.ws.addEventListener('message', (event) => {
            const message = JSON.parse(event.data);
            this.handleMessage(message);
        });
        this.dispatch = dispatch;
        this.errorOccured = false;
        this.connected = false;
        this.handleMessage = this.handleMessage.bind(this);
        this.serviceProvider = '';
        this.link = undefined;
        this.currentUserRequested = false;
        this.message = '';
        this.sentConnectingToAudio = false;
    }
    createBot(speechLang, meetingUrl, currentUserRequested) {
        segment.track('Web - RecallAI - Bot Requested');
        this.dispatch(setRecallAIStatus({ status: 'LOADING' }));
        this.errorOccured = false;
        this.currentUserRequested = currentUserRequested;
        this.meetingUrl = meetingUrl;
        sendWsMessage(this.ws, { type: 'request-ava-bot', speechLang, meetingUrl });
    }
    handleMessage(response) {
        if (response.avaBot && !this.errorOccured) {
            const { statusCode, statusSubCode, message, meetingPlatform } = response.avaBot;
            this.v1RecallAIStatus = statusCode;
            switch (statusCode) {
                case 'joining_call':
                    // The bot has acknowledged the request to join the call, and is in the process of connecting.
                    // this will always be false for anyone besides the requestor and dispatching these actions will sync
                    // the flow for all other users
                    if (!this.currentUserRequested) {
                        this.dispatch(setConnectToMeetingsOpen(true));
                        this.dispatch(setRecallAIStatus({ status: 'LOADING' }));
                    }
                    this.setServiceProvider(meetingPlatform);
                    break;
                case 'in_waiting_room':
                    // The bot is in the "waiting room" of the meeting.
                    if (!this.currentUserRequested) {
                        this.dispatch(setConnectToMeetingsOpen(true));
                    }
                    this.dispatch(setRecallAIStatus({ status: 'WAITING_FOR_HOST' }));
                    if (!this.sentConnectingToAudio) {
                        this.sentConnectingToAudio = true;
                        setTimeout(() => {
                            this.dispatch(setRecallAIStatus({ status: 'CONNECTING_TO_AUDIO' }));
                        }, 5000);
                    }
                    break;
                case 'in_call_not_recording':
                    // The bot has joined the meeting, however is not recording yet.
                    // This could be because the bot is still setting up, does not have recording permissions, or the recording was paused.
                    break;
                case 'in_call_recording':
                    // The bot is in the meeting, and is currently recording the audio and video.
                    // this status keeps getting sent while we're connected, so we have to "ignore" it when we're captioning
                    if (!this.connected) {
                        segment.track('Web - RecallAI - Bot Joined');
                        if (!this.currentUserRequested) {
                            this.dispatch(setConnectToMeetingsOpen(true));
                        }
                        if (!this.serviceProvider)
                            this.setServiceProvider(meetingPlatform);
                        this.dispatch(setRecallAIStatus({ status: 'CONNECTED' }));
                        this.dispatch(stopRecording());
                        sendConversationModeUpdateMessage(this.ws, 'public-muted');
                        this.connected = true;
                        setTimeout(() => {
                            this.dispatch(setRecallAIStatus({ status: 'CAPTIONING' }));
                        }, 1500);
                    }
                    break;
                case 'recording_permission_denied':
                    let subCode = statusSubCode;
                    this.errorOccured = true;
                    this.connected = false;
                    sendConversationModeUpdateMessage(this.ws, 'public');
                    // we have to have a hacky solution for now since recording_permission_denied doesnt return a subcode currently
                    if (!statusSubCode && statusCode === 'recording_permission_denied') {
                        subCode = 'zoom_local_recording_request_denied_by_host';
                    }
                    this.link = HELP_ARTICLE;
                    this.dispatch(setRecallAIStatus({
                        status: 'ERROR',
                        errorMessage: `connectToOnlineMeetings.errors.${subCode}`,
                    }));
                    this.destroyBot();
                    setTimeout(() => {
                        this.dispatch(setConnectToMeetingsOpen(false));
                        this.dispatch(setRecallAIStatus({ status: 'NOT_CONNECTED' }));
                    }, 1500);
                    break;
                case 'call_ended':
                    // The bot has left the call, and the real-time transcription is complete.
                    // we only care about this status when there's a subcode
                    delete this.meetingUrl;
                    if (statusSubCode && statusSubCode !== 'bot_received_leave_call') {
                        this.errorOccured = true;
                        this.connected = false;
                        sendConversationModeUpdateMessage(this.ws, 'public');
                        this.dispatch(setRecallAIStatus({
                            status: 'ERROR',
                            errorMessage: `connectToOnlineMeetings.errors.${statusSubCode}`,
                        }));
                        setTimeout(() => {
                            this.dispatch(setConnectToMeetingsOpen(false));
                            this.dispatch(setRecallAIStatus({ status: 'NOT_CONNECTED' }));
                        }, 1500);
                    }
                    else {
                        this.message = statusSubCode || '';
                    }
                    break;
                case 'done':
                    // The video is uploaded and available for download.
                    // if we invoked destroy bot then:
                    if (this.message.length) {
                        this.dispatch(setRecallAIStatus({
                            status: 'ERROR',
                            errorMessage: `connectToOnlineMeetings.errors.${statusSubCode}`,
                        }));
                        sendConversationModeUpdateMessage(this.ws, 'public');
                        this.reset();
                    }
                    break;
                case 'fatal':
                    // The bot has encountered an error that prevented it from joining the call. The data.status.message field may contain a description of the error.
                    this.errorOccured = true;
                    this.connected = false;
                    this.link = message ? message.split(':').slice(1).join(':').trim() : undefined;
                    let errorMessage = message
                        ? 'connectToOnlineMeetings.errors.ava_bot_requested_already'
                        : 'connectToOnlineMeetings.failed';
                    if (statusSubCode) {
                        errorMessage = `connectToOnlineMeetings.errors.${statusSubCode}`;
                    }
                    this.dispatch(setRecallAIStatus({
                        status: 'ERROR',
                        errorMessage,
                    }));
                    sendConversationModeUpdateMessage(this.ws, 'public');
                    if (errorMessage === 'connectToOnlineMeetings.failed') {
                        setTimeout(() => {
                            this.dispatch(setRecallAIStatus({ status: 'NOT_CONNECTED' }));
                        }, 2000);
                    }
                    else {
                        setTimeout(() => {
                            this.dispatch(setConnectToMeetingsOpen(false));
                            this.dispatch(setRecallAIStatus({ status: 'NOT_CONNECTED' }));
                        }, 2000);
                    }
                    break;
            }
        }
    }
    prepareDisconnect() {
        this.dispatch(setRecallAIStatus({ status: 'DISCONNECT' }));
    }
    destroyBot() {
        sendWsMessage(this.ws, {
            type: 'end-ava-bot',
        });
        this.reset();
    }
    reset(isRecallAIHost) {
        if (this.connected && isRecallAIHost) {
            sendWsMessage(this.ws, {
                type: 'end-ava-bot',
            });
        }
        this.dispatch(setRecallAIStatus({ status: 'NOT_CONNECTED' }));
        this.dispatch(setConnectToMeetingsOpen(false));
        this.errorOccured = false;
        this.connected = false;
        this.sentConnectingToAudio = false;
        delete this.meetingUrl;
        this.v1RecallAIStatus = 'ready';
    }
    mobileClick() {
        this.dispatch(setRecallAIStatus({ status: 'DISCONNECT' }));
    }
    handleChangeLang(speechLang) {
        sendWsMessage(this.ws, {
            type: 'update-ava-bot-speech-lang',
            speechLang,
        });
    }
    setServiceProvider(provider) {
        this.serviceProvider = provider;
        this.dispatch(setRecallAIService(this.serviceProvider));
    }
    handleTextToSpeech(text) {
        sendWsMessage(this.ws, { type: 'ava-bot-text-to-speech', text });
    }
}
