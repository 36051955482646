import './index.css';
import * as SentryBrowser from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import * as SentryTracing from '@sentry/tracing';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { getRemoteConfigInstance } from './firebase';
import { setupDesktopIntegration } from './services/desktopIntegration';
import { setFirebaseRemoteConfig } from './store/slices/auth';
import { logLatestSocketMessagesToSentry } from './store/slices/v1Session';
import { store } from './store/store';
import { initAnalytics } from './utils/segment';
import { startStopwatch } from './utils/stopwatch';
if (sessionStorage.getItem('join_by_qrcode_token')) {
    startStopwatch('appLessFinished');
}
if (window.location.origin.includes('https://app-stage.ava.me')) {
    LogRocket.init('xyqwjx/ava-scribe');
    setupLogRocketReact(LogRocket);
}
const isProd = window.location.href.indexOf('app.avascribe.com') > -1 ||
    window.location.href.indexOf('app.ava.me') > -1 ||
    window.location.href.indexOf('web.ava.me') > -1;
const isLocal = window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('127.0.0.1') > -1;
// See https://docs.sentry.io/clients/javascript/config/ for details.
SentryReact.init({
    dsn: 'https://598382256bd44dc7b175357bac4bc464@sentry.io/1303073',
    integrations: [
        new SentryBrowser.Integrations.Breadcrumbs({
            console: true,
            dom: true,
            fetch: true,
            history: true,
            xhr: true,
        }),
        new SentryTracing.BrowserTracing(),
    ],
    maxBreadcrumbs: 200,
    environment: isProd ? 'production' : 'playground',
    release: process.env.HASH_COMMIT,
    enabled: !isLocal,
    ignoreErrors: ['connection appears to be offline'],
});
setupDesktopIntegration();
initAnalytics();
getRemoteConfigInstance()
    .fetchAndActivate()
    .then(() => {
    store.dispatch(setFirebaseRemoteConfig(getRemoteConfigInstance().getAll()));
});
const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(React.createElement(Provider, { store: store },
        React.createElement(App, null)));
}
globalThis.reportError = (title) => {
    logLatestSocketMessagesToSentry();
    SentryReact.captureMessage(title);
};
