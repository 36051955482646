import { createAction, createAsyncAction } from 'typesafe-actions';

import type { IOrganization } from '../../modules/account/models/organization';
import type { IError } from '../../modules/account/models/request';
import type {
  IOrganizationNewResponse,
  IOrganizationsSearchNewResponse,
  IUserFetchResponse,
} from '../../modules/account/models/user';
import * as actions from './constants';

export const fetchUser = createAsyncAction(
  actions.USER_FETCH_REQUEST,
  actions.USER_FETCH_SUCCESS,
  actions.USER_FETCH_FAILURE
)<any, IUserFetchResponse, IError>();

export const updateUser = createAsyncAction(
  actions.USER_UPDATE_REQUEST,
  actions.USER_UPDATE_SUCCESS,
  actions.USER_UPDATE_FAILURE
)<any, IUserFetchResponse, IError>();

export const fetchUserInfos = createAsyncAction(
  actions.USER_FETCH_INFO_REQUEST,
  actions.USER_FETCH_INFO_SUCCESS,
  actions.USER_FETCH_INFO_FAILURE
)<typeof undefined, IUserFetchResponse, IError>();

export const newOrganization = createAsyncAction(
  actions.NEW_ORGANIZATION_REQUEST,
  actions.NEW_ORGANIZATION_SUCCESS,
  actions.NEW_ORGANIZATION_FAILURE
)<IOrganization, IOrganizationNewResponse, IError>();

export const fetchOrganizations = createAsyncAction(
  actions.SEARCH_ORGANIZATIONS_REQUEST,
  actions.SEARCH_ORGANIZATIONS_SUCCESS,
  actions.SEARCH_ORGANIZATIONS_FAILURE
)<IOrganization, IOrganizationsSearchNewResponse, IError>();

export const clearOrganizationErrors = createAction(actions.CLEAR_ORGANIZATION_ERRORS);

export const dashboardReady = createAction(actions.DASHBOARD_READY);
export const dashboardLoading = createAction(actions.DASHBOARD_LOADING);
