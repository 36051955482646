import { createSelector } from 'reselect';

const selectSavedTranscript = (state) => state.savedTranscript;

export const selectTranscripts = createSelector(
  [selectSavedTranscript],
  (savedTranscript) => savedTranscript.transcripts
);

export const selectHasMoreTranscripts = createSelector(
  [selectSavedTranscript],
  (savedTranscript) => savedTranscript.hasMoreTranscripts
);

export const selectLastReadTimestamp = createSelector(
  [selectSavedTranscript],
  (savedTranscript) => savedTranscript.lastReadTimestamp
);

export const selectLoading = createSelector([selectSavedTranscript], (savedTranscript) => savedTranscript.loading);

export const selectDeleteLoading = createSelector(
  [selectSavedTranscript],
  (savedTranscript) => savedTranscript.deleteLoading
);

export const selectSelectedId = createSelector(
  [selectSavedTranscript],
  (savedTranscript) => savedTranscript.selectedId
);
