import type { i18n, TFunction } from 'i18next';
import i18next from 'i18next';

import { AVA_URL } from '../constants';
import { fetchTtsVoices } from '../store/slices/textToSpeech';
import type { AppDispatch } from '../store/store';

export interface PhraseData {
  text: string;
  isBold: boolean;
}

export const langs = ['fr', 'en'];
export const langsDisplay = {
  fr: 'Français',
  en: 'English',
} as const;

export const getLanguageCode = () => {
  let langCode = i18next.language.split('-')[0];
  // we only support fr and en for now
  // so we default to en if the language is not supported
  if (!langs.includes(langCode)) {
    langCode = 'en';
  }
  return langCode;
};
export const getAvaLanguageUrl = () => {
  const languageCode = getLanguageCode();
  if (languageCode !== 'en') {
    return `${AVA_URL}/${languageCode}`;
  }
  return AVA_URL;
};

export const getCurrentLanguage = () => {
  return langsDisplay[getLanguageCode()];
};

export const getPhraseOrEmptyString = (key: string) => {
  return i18next.exists(key) ? i18next.t(key) : '';
};

export const getPhraseBoldData = (phrase: string, separator: string): PhraseData[] => {
  const phrases: string[] = phrase.split(separator);
  return phrases.map((phrase, index) => ({ text: phrase, isBold: index % 2 === 1 })).filter((p) => p.text);
};

export const changeLanguage = (lang: string, i18n: i18n, t: TFunction, dispatch: AppDispatch) => {
  if (lang === i18n.language) return;

  i18n.changeLanguage(lang, () => {
    window.document.title = t<string>('title');
  });
  dispatch(fetchTtsVoices(lang));
};
