// In order to be backwards and forwards compatible we sometimes
// need to use different versions of tauri functions. As clients migrate
// to newer versions of the app, we can remove the old versions of the
// functions. This is why we have a single file for all tauri functions.
// This is until we are fully migrated to the beta or 2.0 version of tauri.
import { invoke as invokeAlpha13 } from 'tauri-api-alpha-13/core';
import { emit as emitAlpha13, listen as listenAlpha13 } from 'tauri-api-alpha-13/event';
import { getCurrent as getCurrentAlpha13 } from 'tauri-api-alpha-13/window';
import { invoke as invokeBeta9 } from 'tauri-api-beta-9/core';
import { emit as emitBeta9, listen as listenBeta9 } from 'tauri-api-beta-9/event';
import { PhysicalSize } from 'tauri-api-beta-9/window';
import { getCurrent as getCurrentBeta9 } from 'tauri-api-beta-9/window';
import { writeText as writeTextAlpha5 } from 'tauri-plugin-clipboard-manager-alpha-5';
import { writeText as writeTextBeta0 } from 'tauri-plugin-clipboard-manager-beta-0';
import { save as saveAlpha5 } from 'tauri-plugin-dialog-alpha-5';
import { save as saveBeta2 } from 'tauri-plugin-dialog-beta-2';
import { writeFile as writeFileAlpha5 } from 'tauri-plugin-fs-alpha-5';
import { writeFile as writeFileBeta2 } from 'tauri-plugin-fs-beta-2';
import { open as openAlpha5 } from 'tauri-plugin-shell-alpha-5';
import { open as openBeta2 } from 'tauri-plugin-shell-beta-2';

import { isMac } from '../utils';

// Used in order to initiate dragging of the window.
let mouseDown = false;
let requestedDragging = false;

export const toggleDragging = (value: boolean) => {
  if (value) {
    document.addEventListener('mousedown', mouseDownListenerForDrag);
    document.addEventListener('mouseup', mouseUpListenerForDrag);
    document.addEventListener('mousemove', mouseMoveListenerForDrag);
  } else {
    document.removeEventListener('mousedown', mouseDownListenerForDrag);
    document.removeEventListener('mouseup', mouseUpListenerForDrag);
    document.removeEventListener('mousemove', mouseMoveListenerForDrag);
  }
};

export const setWindowSize = async (height: number, width: number) => {
  // the api requires physical size but we're using logical size numbers
  const window = await tauriWindowGetCurrent();
  const scaleFactor = await window.scaleFactor();
  const physicalWidth = width * scaleFactor;
  const physicalHeight = height * scaleFactor;
  const newSize = new PhysicalSize(physicalWidth, physicalHeight);
  await window.setSize(newSize);
};

export const getWindowSize = async () => {
  // we want to work with logical size for consistency across DPIs/Monitors
  const currentWindow = await tauriWindowGetCurrent();
  const scaleFactor = await currentWindow.scaleFactor();
  const physicalSize = await currentWindow.innerSize();
  const logicalWidth = physicalSize.width / scaleFactor;
  const logicalHeight = physicalSize.height / scaleFactor;
  return { width: logicalWidth, height: logicalHeight };
};

const tauriVersionPromise = window.__TAURI__
  ? window.__TAURI__.app.getTauriVersion().catch(() => 'alpha')
  : Promise.resolve('none');

export const setupDesktopIntegration = () => {
  if (window.isElectron) return;
  if (!window.__TAURI__) return;
  // We need to know which version of tauri is running us. On alpha this call
  // fails, but on all the versions of beta it succeeds, so it's a trick to
  // always have it.
  window.desktopIntegration = {
    openExternalURL: async (url) => {
      const tauriVersion = await tauriVersionPromise;
      tauriVersion === 'alpha' ? openAlpha5(url) : openBeta2(url);
    },
    resizeWindowToDefault: () => {},
    writeToClipboard: async (text: string) => {
      const tauriVersion = await tauriVersionPromise;
      tauriVersion === 'alpha' ? await writeTextAlpha5(text) : await writeTextBeta0(text);
    },
  };

  window.isElectron = {};

  tauriInvoke('plugin:os_specific|get_os_name').then((os_name) => {
    window.isElectron.electronOS = os_name; // Windows, macOS, Linux
  });

  tauriInvoke('get_version').then((version) => {
    window.isElectron.version = version;
  });

  window.electronCurrentWindow = {
    minimize: () => {
      tauriWindowGetCurrent().then((window) => window.minimize());
    },
    maximize: () => {
      tauriWindowGetCurrent().then((window) => window.maximize());
    },
    unmaximize: () => {
      tauriWindowGetCurrent().then((window) => window.unmaximize());
    },
    close: () => {
      //getCurrent().close();
      // On Mac we do not close the main window - we just hide it instead.
      tauriInvoke('plugin:positioner|close_main_window');
    },
    // This is used only on Mac, in order to make the transparent window click-through
    setIgnoreMouseEvents: (...args) => {},
  };
  window.electronApp = {
    // Might sometimes return undefined before the promise finishes,
    // but we are not making it async to stay consistent with electron.
    getVersion: () => window.isElectron.version,
    getVersionAsync: () => tauriInvoke('get_version'),
  };
  window.electronIPC = {
    sendCCMode: (ccMode, value = true) => {
      tauriInvoke('plugin:positioner|position_cc', { ccMode, value });
    },

    sendSetInCaptionMode: (value: boolean) => {
      mouseDown = false;
      toggleDragging(value);
      tauriInvoke('plugin:positioner|position_cc', { ccMode: value ? 'begin_cc' : 'expand', value: false });
    },

    sendChangeVolume: (newVolume) => {
      console.log('sendChangeVolume', newVolume);
      return tauriInvoke('plugin:os_specific|set_volume', { volume: newVolume / 100.0 });
    },

    invokeGetVolume: async () => {
      const result = ((await tauriInvoke('plugin:os_specific|get_volume')) as number) * 100;
      console.log('invokeGetVolume', result);
      return result;
    },

    // This is sent by menu and accepted in one place to end the conversation.
    onEndConversation: listenToTauriEvent('request_end_conversation'),
    onStartConversation: listenToTauriEvent('request_start_conversation'),
    onSignOut: listenToTauriEvent('request_sign_out'),
    onAudioAllStopped: listenToTauriEvent('audio-all-stopped'),
    onAudioOutputChanged: listenToTauriEvent('audio-output-changed'),
    onTauriWebRTCConnectionStateChange: listenToTauriEvent('webrtc-connection-state-change'),
    onLogEventReceived: listenToTauriEvent('log_event'),
    onVolumeReceived: listenToTauriEvent('volume'),
    onScreenCaptureError: listenToTauriEvent('screen-capture-error'),

    emitDebug: (eventName, payload) => {
      console.log('emit', eventName, payload);
      tauriEventEmit(eventName, payload);
    },

    // TO OBVIATE BEFORE FULL MIGRATION

    // 1. In CCPage, this is triggered on window-activated, before initiating
    // new conversation.
    // 2. In SetupAudio it's called on last step.
    // On electron side this is equivalent to setInCaptionMode(true).
    // TASK: In electron: obviate.
    sendGoInFloatingMode: () => {},

    // This dispatches a redux action and is issued in electron on did-finish-load
    // This is read only by ScribeWelcome, and used to decide whether we should
    // start a new conversation.
    // TASK: To Obviate
    onDesktopAppLoaded: (callback) => {},

    // This is currently not used
    onLog: (callback) => {},

    // This is supposed to pass events to segment, but is currently unused
    onTrack: (callback) => {},

    // This is sent by the menu and used to dispatch an action.
    onSetElectronCaptionMode: (callback) => {},

    // This is sent by electrona as described, and then it issues some calls
    // to start a new conversation and go into cc-mode.
    // TASK: To obviate, move the logic to inside electron, and re-implement in Tauri
    onWindowActivated: (callback) => {},

    // This is currently only listened to as directly after the relevant
    // call, so it could be obviated.
    // TASK: obviate
    onceMacInternalAudioInstallation: (callback) => {},

    // Only listened to once, and directly after the relevant call. As such
    // can be obviated.
    // TASK: obviate
    onceMacMicrophoneAccessResult: (callback) => {},

    // NEEDED FOR MENU

    // This is called after the v1 socket is initiated. It is only used
    // by the menu bar in order to initiate the conversation in the right momement.
    // It could be replaced by a better URL selection (with startCnonvo).
    // TASK: In electron, figure out getting rid of this call.
    sendDoneLoading: () => {},

    // This is sent by an action updateIsInConversation
    // It's used to change the menu setting in Electron
    // TASK: In Tauri: implement as is
    sendSetIsInConversation: (arg) => {},

    // This is sent after ava_session is fetched, or when suer is logged out
    // Used by MenuBar to show appropriate menu settings
    // TASK: In Tauri: implement as is
    sendSetIsLoggedIn: (arg) => {},

    // This is sent by Menu in Electron, and transformed into a dispatched action
    onCreateNewConversation: (callback) => {},

    // This is called by the menu to sign off and accessed in one place
    onSignOutAndShowSignIn: (callback) => {},

    // NEEDED FOR VOLUME MANIPULATION

    // NEEDED FOR OFFLINE WINDOW

    // Related to internal offline mode window
    // TASK: Implement the whole behavior in Tauri differently.
    sendAvaWebAvailable: () => {},

    // This is used by the Offline window to know where to redirect to.
    // TASK: In Tauri relate on a different mechanism for the offline window,
    // maybe employ local vite.
    getAppUrl: () => {},

    // NEEDED FOR USING AVA_MIC

    // Related to AvaMic on Mac
    // This is sent when user selects Ava Computer Audio on Mac, or when the conversation
    // begins with it as selected mic. In Electron it creates and selects the
    // multi-output MIDI device.
    // TASK: In Tauri this will automatically be obviated, as Tauri can handle
    // the special Ava Computer Audio case on start_recording.
    sendActivateMacInternalAudio: () => {},
    sendDeactivateMacInternalAudio: () => {},

    // THis is called (only in certain scenarios) when the multi output device
    // gets recreated. It is then read in the webapp to refetch available devices.
    // TASK: In Tauri this will be obviated by re-doing the flow
    onMultiOutputChanged: (callback) => {},

    // NEEDED FOR SETTING UP AUDIO (ON MAC?)

    // This is sent in SetupAudio
    // In Electron it resizes the window.
    // TASK: In Tauri, re-imagine the setup-audio flow, as potentially built-in
    // into the app.
    sendSetupAudioLoaded: () => {},

    // This is sent in SetupAudio.
    // In Electron, it asks for microphone permission.
    // TASK: In Tauri: Potentially re-implement once we tackle SetupAudio.
    sendAskMacMicrophoneAccess: () => {},

    // This is called in SetupAudio.
    // In Electron it attempts to install the Black Hole driver
    // TASK: Tackle once we approach Ava Computer Audio
    sendSetupMacInternalAudio: () => {},

    // This is bad, but it's really hard to get rid of it, and it's only called once,
    // while the app is being loaded.
    sendSyncCheckAudioSetup_UNSAFE: () => {},

    // It's invoked in SetupAudio, but the logic is a little complex
    // In Electron it checks 2 things: whether Black Hole driver exists, and whether
    // mic permission has been given
    invokeCheckAudioSetup: async () => {},

    // THIS IS NEEDED FOR POST-MVP FEATURES

    // This is called when the app is loading, and if it returns true, then
    // a new conversation is NOT loaded.
    // In Electron it does what it says
    // TASK: Re-implement in Tauri
    invokeCheckLaunchedOnLogin: async () => {},

    // This is invoked when the user begins a conversation on mac
    invokeHasSeenInternalAudioMicTour: async () => {},
  };
};

export const mouseDownListenerForDrag = async (e) => {
  const noDragSelector = '.tauri-no-drag'; // CSS selector
  if (e.target?.closest(noDragSelector)) return; // a non-draggable element either in target or its ancestors
  const buttonSelector = 'input, a, button';
  // On Mac we initiate dragging also when clicking on a button. Maybe we can also
  // initiate dragging like this on Windows, but it's untested.
  //if (!isMac && e.target?.closest(buttonSelector)) return;
  const currentHeight = document.documentElement.clientHeight;
  const currentWidth = document.documentElement.clientWidth;
  if (e.clientX < 5 || e.clientX > currentWidth - 5 || e.clientY < 5 || e.clientY > currentHeight - 5) return;
  mouseDown = true;
  requestedDragging = false;
};

export const mouseUpListenerForDrag = async () => {
  // This does not get triggered when dragging finishes
  requestedDragging = false;
  mouseDown = false;
  document.removeEventListener('click', stubClickListener);
};

export const mouseMoveListenerForDrag = async () => {
  if (!mouseDown || requestedDragging) return;
  mouseDown = false;
  requestedDragging = true;
  if (isMac) {
    // On Mac we keep receiving mouseup and click events while dragging, which
    // means we need to stub it out until the user stops dragging (mouseup
    // event).
    // On Linux and Windows we cannot stub it, because we do not receive the event when the
    // user finishes dragging, so we wouldn't know when to unregister the stub.
    // However that's completely okay, because we also do not receive the click
    // events at the end of dragging.
    document.addEventListener('click', stubClickListener, { capture: true, once: true });
  }
  await (await tauriWindowGetCurrent()).startDragging();
};

// While dragging we want the click events to not happen at all in the app, until
// the user stops dragging.
const stubClickListener = async (e) => {
  e.stopPropagation();
  e.preventDefault();
};

const listenToTauriEvent = (eventName: string) => (callback) => {
  return tauriEventListen(eventName, (event) => {
    if (eventName === 'log_event') {
      console.log(`Tauri_Log:${event.payload}`);
      return;
    }
    if (eventName !== 'volume') {
      console.log(`tauri event: ${eventName} with payload ${event.payload}`);
    }
    callback(event.payload);
  });
};

// We are re-exporting tauri functions here for easier library updates.
// During library migration we might have to do some non-trivial work
// to be back-compatible with old binary versions. This is why we want
// to keep all that difficulty here and not in the rest of the codebase.
export const tauriWindowGetCurrent = async () => {
  if (!window.__TAURI__) throw new Error('getCurrent invoked in non-tauri context');
  const tauriVersion = await tauriVersionPromise;
  const getCurrent = tauriVersion === 'alpha' ? getCurrentAlpha13 : getCurrentBeta9;
  return getCurrent();
};

export const tauriEventListen = async (eventName, callback) => {
  if (!window.__TAURI__) return () => {};
  const tauriVersion = await tauriVersionPromise;
  const listen = tauriVersion === 'alpha' ? listenAlpha13 : listenBeta9;
  return listen(eventName, callback);
};

export const tauriEventEmit = async (eventName, payload) => {
  if (!window.__TAURI__) return;
  console.log('tauri emit: ', eventName, payload);
  const tauriVersion = await tauriVersionPromise;
  const emit = tauriVersion === 'alpha' ? emitAlpha13 : emitBeta9;
  return emit(eventName, payload);
};

export const tauriInvoke = async (cmd, args?, options?) => {
  if (!window.__TAURI__) throw new Error('invoke invoked in non-tauri context');
  console.log('tauri invoke: ', cmd, args, options);
  const tauriVersion = await tauriVersionPromise;
  const invoke = tauriVersion === 'alpha' ? invokeAlpha13 : invokeBeta9;
  console.log('tauri version is ', tauriVersion);
  try {
    return await invoke(cmd, args, options);
  } catch (e) {
    // On Rust side we refactored plugins into non-plugin modules. We do not
    // know which version of Ava-CC runs here.
    if (cmd === 'plugin:redux_state|update') {
      return invoke('redux_state_update', args, options);
    } else if (cmd.startsWith('plugin:')) {
      cmd = cmd.replace(/plugin:.*\|/, '');
      console.log('tauri invoke plugin:redux_state|update: ', cmd, args);
      return invoke(cmd, args, options);
    } else {
      throw e;
    }
  }
};

export const tauriSaveAs = async (blob: Blob, filename: string) => {
  const tauriVersion = await tauriVersionPromise;
  const save = tauriVersion === 'alpha' ? saveAlpha5 : saveBeta2;
  const writeFile = tauriVersion === 'alpha' ? writeFileAlpha5 : writeFileBeta2;
  const filePath = await save({
    defaultPath: filename,
  });
  if (!filePath) return;
  try {
    await writeFile(filePath, new Uint8Array(await blob.arrayBuffer()));
  } catch (e) {
    console.error('failed to save using writeFile: ', e);
  }
};

export const checkIfEligibleForCCV2 = (): boolean => {
  // min version is '2.1.9'
  // remove -rc from version
  if (!window.__TAURI__) return false;
  const version = window.electronApp.getVersion();
  const parts = version.split('.');
  if (parts[2].includes('-')) {
    parts[2] = parts[2].split('-')[0];
  }
  const numberParts = parts.map((part) => parseInt(part));
  if (numberParts[0] > 2) return true;
  else if (numberParts[0] === 2 && numberParts[1] > 1) return true;
  else if (numberParts[0] === 2 && numberParts[1] === 1 && numberParts[2] >= 9) return true;
  else return false;
};
