export const STORAGE_KEYS = {
  AVA_ID: 'avaId',
  AVA_REQUEST: 'ava_request',
  CACHE: 'avaCachedUserRequested',
  SELECTED_MIC_ID: 'selectedMicId',
  INCLUDE_EXTERNAL_AUDIO: 'includeExternalAudio',
} as const;

const LocalStorage = {
  get: (key: string, defaultValue?: any) => {
    const value = localStorage.getItem(key);
    if (typeof value !== 'string') {
      return defaultValue;
    }
    try {
      return JSON.parse(value);
    } catch (err: any) {
      return value;
    }
  },
  set: (key: string, value: any) =>
    localStorage.setItem(key, typeof value !== 'string' ? JSON.stringify(value) : value),
} as const;

export default LocalStorage;
