import { call, put } from 'redux-saga/effects';

import { newTrial } from '../../actions/account/licence';
import { fetchOrganizations, fetchUserInfos, newOrganization } from '../../actions/account/user';
import { getAuthInstance } from '../../firebase';
import { organizations as avaOrganizations } from '../../services/api/ava';
import { organizations as saasOrganizations } from '../../services/api/saas';

export function* postOrganization({ payload }: any) {
  try {
    const res = yield call(saasOrganizations.createOrganization, { ...payload });

    yield put(fetchUserInfos.request());
    yield put(newTrial.request(res.data.organization));
    yield put(newOrganization.success(res.data));
  } catch (err: any) {
    yield put(newOrganization.failure(err.response));
  }
}

export function* searchOrganizations({ orgName, orgType }: any) {
  try {
    const firebaseUser = getAuthInstance().currentUser;
    if (!firebaseUser) {
      return;
    }

    const data = {
      firebaseAuthUID: firebaseUser.uid,
      orgName,
      orgType,
    } as const;
    const res = yield call(avaOrganizations.findOrganizations, data);
    yield put(fetchOrganizations.success(res.data));
  } catch (err: any) {
    yield put(fetchOrganizations.failure(err.response));
  }
}
