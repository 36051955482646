import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

import type { AudioError } from '../../modules/AudioV2/errors';
import { AudioErrorType, errorToAudioError } from '../../modules/AudioV2/errors';
import {
  selectAudioContext,
  selectAudioV2Status,
  selectAvailableMicrophones,
  selectExternalMicForAvaMic,
  selectIncludeMicrophoneWithInternalAudio,
  selectSelectedMicrophone,
  selectWebRTCSenders,
  selectWebRTCTracks,
} from '../../selectors/audioV2';
import { selectSncfConsentGiven } from '../../selectors/avaTranslate';
import { selectCanBeginRecording, selectCanRecord, selectIsAvaMicAvailable } from '../../selectors/combined';
import { selectCurseFilter } from '../../selectors/conversation';
import { selectLang, selectSpeechLang } from '../../selectors/legacy-conversation';
import { selectScribeTrainingAudioStream, selectScribeTrainingRequested } from '../../selectors/scribe-dashboard';
import {
  selectAvaId,
  selectHearingProfile,
  selectUserProfileFetchFinished,
  selectUserProfileFetchInitiated,
} from '../../selectors/userProfile';
import { selectV1Websocket } from '../../selectors/v1Session';
import { tauriInvoke } from '../../services/desktopIntegration';
import LocalStorage, { STORAGE_KEYS } from '../../services/localStorage';
import { isMac, isWindows } from '../../utils';
import { getSingletonWebRTCManager } from '../../utils/webrtc';
import { sendAudioParams, sendMbackendMessage, sendWebRTCTrackMetadataMessage } from '../../utils/ws-v1';
import type { RootState } from '../store';
import { setSncfConsentDialogShown } from './avaTranslate';

export const AUDIO_CONSTRAINTS = {
  echoCancellation: false,
  noiseSuppression: false,
  autoGainControl: false,
};

export const INTERNAL_AUDIO_MIC_ID = 'internal';
export const INTERNAL_AUDIO_MIC_NAME = 'Ava Computer Audio';

export interface Microphone {
  default?: boolean;
  id: string;
  label: string;
  name: string;
  isInternal: boolean;
  isBluetooth: boolean;
}

export enum RecordingStatus {
  // Status when the recording is stopped.
  NOT_RECORDING = 'NOT_RECORDING',

  // The recording has been requested, but it is not stable.
  // This state should soon resolve into either NOT_RECORDING or RECORDING
  PENDING = 'PENDING',

  // Recording is in progress.
  RECORDING = 'RECORDING',
}

export interface StreamToRecord {
  stream: MediaStream;
  name: string;
  isInternal: boolean;
}

export interface AudioV2Slice {
  audioContext?: AudioContext;
  status: RecordingStatus;
  webRTCConnectionStatus?: RTCPeerConnectionState;
  error?: AudioError;

  availableMicrophones: Array<Microphone>;
  selectedMicrophone?: Microphone;
  externalMicForAvaMic?: Microphone;
  allMicsSelected: string[];
  includeMicrophoneWithInternalAudio: boolean;
  overrideAudioRestart?: boolean;

  webRTCTracks: Array<MediaStreamTrack>;
  webRTCSenders: Array<RTCRtpSender>;

  needInternalAudioAccess: boolean;
  microphoneAccess: string;

  volume: number;
  cancelVolumeMetering?: () => void;

  // Whether or not we have subscribed to the state to listen to canRecord
  // and issue stopRecording then. We unsubscribe once it gets triggered for
  // the first time so we need to resubscribe on every recording.
  recordingCancellerSubscribed: boolean;
}

const getInitialState: () => AudioV2Slice = () => {
  const isWindowsWeb = isWindows && (!window.isElectron || !window.__TAURI__);
  console.log('Default status set to NOT_RECORDING');
  const state = {
    status: RecordingStatus.NOT_RECORDING,
    availableMicrophones: [],
    includeMicrophoneWithInternalAudio:
      (isWindowsWeb && true) || LocalStorage.get(STORAGE_KEYS.INCLUDE_EXTERNAL_AUDIO) || false,
    webRTCSenders: [],
    webRTCTracks: [],
    needInternalAudioAccess: false,
    microphoneAccess: 'granted',
    volume: 0,
    allMicsSelected: [],
    recordingCancellerSubscribed: false,
  } as AudioV2Slice;
  if (window.isElectron) {
    // We ask electron to check the mac internal audio config
    const audioSetup = window.electronIPC.sendSyncCheckAudioSetup_UNSAFE();
    if (audioSetup) {
      state.needInternalAudioAccess = !audioSetup.internalAudio;
      state.microphoneAccess = audioSetup.micAccess;
    }
  }
  if (window.__TAURI__) {
    // Just initializing the AudioContext in some versions of WebKit makes the
    // app use additional 20% CPU.
    return state;
  }
  try {
    state.audioContext = new AudioContext();
  } catch (e) {
    state.error = {
      audioErrorType: AudioErrorType.FAILED_TO_INIT_AUDIO_CONTEXT,
      message: '' + e,
    };
  }
  return state;
};

export const audioV2Slice = createSlice({
  name: 'audioV2',
  initialState: getInitialState,
  reducers: {
    setError(state, { payload }: PayloadAction<AudioError | undefined>) {
      state.error = payload;
    },
    setWebRTCTracks(state, { payload }: PayloadAction<Array<MediaStreamTrack>>) {
      state.webRTCTracks = payload;
    },
    setWebRTCSenders(state, { payload }: PayloadAction<Array<RTCRtpSender>>) {
      state.webRTCSenders = payload;
    },
    setAvailableMicrophones(state, { payload }: PayloadAction<Array<Microphone>>) {
      state.availableMicrophones = payload;
    },
    setSelectedMicrophone(state, { payload }: PayloadAction<Microphone | undefined>) {
      state.selectedMicrophone = payload;
      state.allMicsSelected = [...new Set([...state.allMicsSelected, payload?.label || 'no microphone'])];
    },
    setIncludeMicrophoneWithInternalAudio(state, { payload }: PayloadAction<boolean>) {
      state.includeMicrophoneWithInternalAudio = payload;
    },
    setStatus(state, { payload }: PayloadAction<RecordingStatus>) {
      state.status = payload;
    },
    setupMacAudioComplete(state) {
      state.microphoneAccess = 'granted';
      state.needInternalAudioAccess = false;
    },
    setVolume(state, { payload }: PayloadAction<number>) {
      state.volume = payload;
    },
    setCancelVolumeMetering(state, { payload }: PayloadAction<(() => void) | undefined>) {
      state.cancelVolumeMetering = payload;
    },
    setWebRTCConnectionStatus(state, { payload }: PayloadAction<undefined | RTCPeerConnectionState>) {
      state.webRTCConnectionStatus = payload;
    },
    resetAllMicsSelected(state) {
      state.allMicsSelected = [];
    },
    setRecordingCancellerSubscribed(state, { payload }: PayloadAction<boolean>) {
      state.recordingCancellerSubscribed = payload;
    },
    setExternalMicForAvaMic(state, { payload }: PayloadAction<Microphone | undefined>) {
      state.externalMicForAvaMic = payload;
    },
    setOverrideAudioRestart(state, { payload }: PayloadAction<boolean>) {
      state.overrideAudioRestart = payload;
    },
  },
});

export const audioV2Reducer = audioV2Slice.reducer;
export const {
  setIncludeMicrophoneWithInternalAudio,
  setVolume,
  setStatus,
  setupMacAudioComplete,
  resetAllMicsSelected,
  setWebRTCConnectionStatus,
  setExternalMicForAvaMic,
  setOverrideAudioRestart,
} = audioV2Slice.actions;
const {
  setError,
  setWebRTCSenders,
  setWebRTCTracks,
  setAvailableMicrophones,
  setSelectedMicrophone,
  setCancelVolumeMetering,
  setRecordingCancellerSubscribed,
} = audioV2Slice.actions;

// This variable is used to cache the internal audio stream on Windows.
let cachedInternalAudioStream: StreamToRecord | null = null;

/**
 * Returns the cached internal audio stream if it exists, otherwise creates a new one.
 * The stream is cached to avoid creating a new stream every time the user starts recording.
 */
export const getCachedWindowsInternalAudioStream = async (): Promise<StreamToRecord> => {
  if (cachedInternalAudioStream) {
    return cachedInternalAudioStream;
  }
  cachedInternalAudioStream = await getWindowsInternalAudioStream();
  return cachedInternalAudioStream;
};

/**
 * Clears the cached internal audio stream if it exists.
 */
export const clearCachedInternalAudioStream = (): void => {
  if (cachedInternalAudioStream) {
    cachedInternalAudioStream.stream.getTracks().forEach((track) => track.stop());
    cachedInternalAudioStream = null;
  }
};

export const startRecording = createAsyncThunk('audioV2/startRecording', async (_, { dispatch, getState }) => {
  // TODO: Restart recording when curseFilter, lang or speechLang change
  const state = getState() as RootState;
  const audioContext = selectAudioContext(state);
  const canBeginRecording = selectCanBeginRecording(state);
  const selectedMicrophone = selectSelectedMicrophone(state);
  const ws = selectV1Websocket(state);
  const webRTCManager = getSingletonWebRTCManager();
  const isScribeTraining = selectScribeTrainingRequested(state);
  const includeMicrophoneWithInternalAudio = selectIncludeMicrophoneWithInternalAudio(state);
  const webRTCTracks = selectWebRTCTracks(state);

  if (!selectSncfConsentGiven(state)) {
    // Can't begin recording if SNCF consent was not given
    dispatch(setSncfConsentDialogShown(true));
    return;
  }

  if (!canBeginRecording || !ws) return;

  if (!selectedMicrophone) {
    dispatch(
      setError({
        audioErrorType: AudioErrorType.NO_MICROPHONE_SELECTED,
      })
    );
    console.error('No microphone selected');
    await dispatch(setStatus(RecordingStatus.NOT_RECORDING));

    return;
  }

  dispatch(setError(undefined));

  if (window.__TAURI__) {
    try {
      console.log('startRecording');
      console.log('Tauri stop all recording');
      await tauriInvoke('stop_all_recording');
      await dispatch(setStatus(RecordingStatus.PENDING));
      sendAudioParams(
        ws,
        prepareAudioParams(selectCurseFilter(state), selectLang(state), selectSpeechLang(state), selectedMicrophone)
      );

      console.log('Tauri start recording');
      await tauriInvoke('start_recording', { deviceName: selectedMicrophone.name });
      if (selectedMicrophone.isInternal) {
        const nonInternalMicrophones = selectAvailableMicrophones(state).filter((m) => !m.isInternal);
        const externalMicForAvaMic = selectExternalMicForAvaMic(state);
        const isDesktop = window.isElectron || window.__TAURI__; // we want to make sure that Windows Web can use Ava Mic since they don't have an option to mute/unmute external mic
        const includeExternalFromLocalStorage = isDesktop
          ? LocalStorage.get(STORAGE_KEYS.INCLUDE_EXTERNAL_AUDIO)
          : true;

        if (
          nonInternalMicrophones.length > 0 &&
          (includeMicrophoneWithInternalAudio || includeExternalFromLocalStorage)
        ) {
          const defaultMic =
            externalMicForAvaMic || nonInternalMicrophones.find((m) => m.default) || nonInternalMicrophones[0];
          if (!externalMicForAvaMic) {
            dispatch(setExternalMicForAvaMic(defaultMic));
          }
          console.log('Tauri start recording with external mic');
          await tauriInvoke('start_recording', { deviceName: defaultMic.name });
        }
      }
    } catch (e) {
      console.error('Catch startRecording', e);
      await dispatch(setStatus(RecordingStatus.NOT_RECORDING)); // if any issue we should not stay in pending mode that is stuck
    }

    return;
  }

  if (!webRTCManager) {
    dispatch(
      setError({
        audioErrorType: AudioErrorType.NO_WEBRTC_MANAGER,
      })
    );
    console.error('No WebRTC manager');
    await dispatch(setStatus(RecordingStatus.NOT_RECORDING));
    return;
  }

  if (!audioContext) {
    dispatch(
      setError({
        audioErrorType: AudioErrorType.NO_AUDIO_CONTEXT,
      })
    );
    console.error('No audio context');
    await dispatch(setStatus(RecordingStatus.NOT_RECORDING));
    return;
  }

  await dispatch(setStatus(RecordingStatus.PENDING));

  try {
    const streams: Array<StreamToRecord> = [];
    if (isScribeTraining) {
      const scribeTrainingAudioStream = selectScribeTrainingAudioStream(getState() as RootState);
      if (!scribeTrainingAudioStream) {
        console.error('No scribe training audio stream');
        dispatch(setStatus(RecordingStatus.NOT_RECORDING));
        return;
      }
      streams.push({
        stream: scribeTrainingAudioStream,
        name: 'Scribe Training Audio',
        isInternal: false,
      });
    } else if (selectedMicrophone.isInternal) {
      if (isWindows) {
        const streamData = await getCachedWindowsInternalAudioStream();
        // Re-enable audio tracks in case they were disabled during stopRecording
        streamData.stream.getAudioTracks().forEach((track) => {
          track.enabled = true;
        });
        streams.push(streamData);
      }
      if (window.isElectron && isMac) {
        window.electronIPC.sendActivateMacInternalAudio();
        streams.push(await getMicrophoneStream(selectedMicrophone));
      }
      if (includeMicrophoneWithInternalAudio) {
        try {
          const defaultMicStream = await getDefaultMicrophoneStream();
          if (defaultMicStream) streams.push(defaultMicStream);
        } catch (e) {
          console.error('No default microphone stream');
          dispatch(setStatus(RecordingStatus.NOT_RECORDING));
          return;
        }
      }
    } else {
      let microphoneStream: StreamToRecord | undefined;
      if (selectedMicrophone && selectedMicrophone.id) {
        microphoneStream = await getMicrophoneStream(selectedMicrophone);
      } else {
        microphoneStream = await getDefaultMicrophoneStream();
      }
      if (microphoneStream) streams.push(microphoneStream);
    }

    if (streams.length === 0) {
      console.error('No streams');
      dispatch(setStatus(RecordingStatus.NOT_RECORDING));
      return;
    }

    dispatch(
      setCancelVolumeMetering(
        await setupVolumeMetering(
          audioContext,
          (volume) => {
            dispatch(setVolume(volume));
          },
          streams
        )
      )
    );

    sendAudioParams(
      ws,
      prepareAudioParams(selectCurseFilter(state), selectLang(state), selectSpeechLang(state), selectedMicrophone)
    );
    streams.forEach((stream) =>
      sendWebRTCTrackMetadataMessage(ws, {
        streamId: stream.stream.id,
        name: stream.name,
        isInternal: stream.isInternal,
      })
    );

    const tracks: Array<MediaStreamTrack> = [];
    const senders: Array<RTCRtpSender> = [];

    streams.forEach((stream) =>
      stream.stream.getAudioTracks().forEach((track) => {
        try {
          senders.push(webRTCManager.addTrack(track, stream.stream));
          tracks.push(track);
          track.addEventListener('ended', () => {
            dispatch(stopRecording());
          });
        } catch (e) {
          console.error('Error adding track', e);
          dispatch(setStatus(RecordingStatus.NOT_RECORDING));
          return;
        }
      })
    );

    if (tracks.length === 0) {
      console.error('No tracks');
      dispatch(setStatus(RecordingStatus.NOT_RECORDING));
      return;
    }

    dispatch(setWebRTCTracks(tracks));
    dispatch(setWebRTCSenders(senders));
    console.log('startRecording: recording status to recording!');
    dispatch(setStatus(RecordingStatus.RECORDING));
    if (ws) {
      webRTCTracks.forEach((track) => {
        sendMbackendMessage(ws, {
          type: 'webrtc-track-mute-state',
          streamId: track.id, // Replace with actual MediaStream's ID
          muted: false, // Unmute action
        });
      });
    }

    if (tracks.length > 0) {
      // If we have any tracks, that means that user has granted permission, which
      // means we should get full data about available mics.
      dispatch(getCurrentAvailableMics());
    }

    if (!state.audioV2.recordingCancellerSubscribed) {
      dispatch(setRecordingCancellerSubscribed(true));
      const unsubscribe = window.store.subscribe(() => {
        const state = window.store.getState() as RootState;
        const canRecord = selectCanRecord(state);
        const audioStatus = selectAudioV2Status(state);
        if (!canRecord && audioStatus === RecordingStatus.RECORDING) {
          console.log('stopRecording because canRecord is false');
          unsubscribe();
          dispatch(setRecordingCancellerSubscribed(false));
          dispatch(stopRecording());
        }
      });
    }
  } catch (err: any) {
    console.error('Error starting recording', err);
    dispatch(setStatus(RecordingStatus.NOT_RECORDING));
    if (err.audioErrorType) {
      dispatch(setError(err));
    } else {
      dispatch(setError(errorToAudioError(err)));
    }
  }
});

export const stopRecording = createAsyncThunk('audioV2/stopRecording', async (_, { dispatch, getState }) => {
  const state = getState() as RootState;
  const selectedMicrophone = selectSelectedMicrophone(state);
  const audioStatus = selectAudioV2Status(state);
  const webRTCTracks = selectWebRTCTracks(state);

  if (audioStatus !== RecordingStatus.RECORDING && audioStatus !== RecordingStatus.PENDING) return;

  if (window.__TAURI__) {
    console.log('Tauri stop all recording');
    await tauriInvoke('stop_all_recording');
    const ws = selectV1Websocket(state);
    if (ws) {
      sendMbackendMessage(ws, {
        type: 'mute',
      });
    }
    console.log('stopRecording');
    dispatch(setStatus(RecordingStatus.NOT_RECORDING));
    return;
  }

  if (window.isElectron && isMac && selectedMicrophone?.isInternal) {
    window.electronIPC.sendDeactivateMacInternalAudio();
  }

  // Stop only the tracks that do NOT belong to the cached internal audio stream.
  webRTCTracks.forEach((track) => {
    // If we're on Windows and using the internal mic, check if the track belongs to the cached stream.
    if (
      selectedMicrophone?.isInternal &&
      isWindows &&
      cachedInternalAudioStream &&
      cachedInternalAudioStream.stream.getAudioTracks().includes(track)
    ) {
      // Instead of stopping the track, you might simply disable it (or do nothing)
      // so that it can be reused later.
      track.enabled = false;
    } else {
      track.stop();
    }
  });

  const webRTCManager = getSingletonWebRTCManager();
  const webRTCSenders = selectWebRTCSenders(state);
  if (webRTCManager) {
    webRTCSenders.forEach((sender) => webRTCManager.removeSender(sender));
  }

  const cancelVolumeMetering = state.audioV2.cancelVolumeMetering;
  if (cancelVolumeMetering) {
    cancelVolumeMetering();
    dispatch(setCancelVolumeMetering(undefined));
  }

  const ws = selectV1Websocket(state);
  if (ws) {
    const ourAudioStream = state.scribeConversation?.status?.audioStreams?.find(
      (audioStream) => audioStream.avaId === selectAvaId(state)
    );
    if (ourAudioStream) {
      sendMbackendMessage(ws, {
        type: 'mute',
      });
    }
    webRTCTracks.forEach((track) => {
      sendMbackendMessage(ws, {
        type: 'webrtc-track-mute-state',
        streamId: track.id,
        muted: true,
      });
    });
  }
  console.log('stopRecording');
  dispatch(setStatus(RecordingStatus.NOT_RECORDING));
});
// If RecordingStatus === RECORDING - restarts recording. Otherwise does not do anything.
export const maybeRestartRecording = createAsyncThunk(
  'audioV2/maybeRestartRecording',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const audioStatus = selectAudioV2Status(state);
    if (audioStatus === RecordingStatus.RECORDING) {
      console.log('maybeRestartRecording: restarting recording');
      await dispatch(stopRecording());
      await dispatch(startRecording());
    }
  }
);

export const prepareAudioParams = (curseFilter, translationLang, speechLang, selectedMic) => {
  const mic = selectedMic.isBluetooth ? 'BT' : 'BUILTIN';
  const translation = translationLang && translationLang !== '~' ? { target: translationLang } : undefined;
  return {
    lang: speechLang,
    pFilter: curseFilter,
    mic,
    format: 'webrtc',
    sampleRateHz: 16000,
    recordingMode: 'web',
    chunkLengthMs: 60,
    recordingStart: Date.now(),
    translation,
  };
};

const getDefaultMicrophoneStream = async () => {
  const stream = await window.navigator.mediaDevices.getUserMedia({
    audio: { ...AUDIO_CONSTRAINTS, deviceId: { ideal: 'default' } },
  });
  return {
    stream,
    name: getTrackNameOrThrow(stream),
    isInternal: false,
  };
};

const getMicrophoneStream = async (mic: Microphone) => {
  // TODO: For some reason this was not working on Windows.
  const stream = await window.navigator.mediaDevices.getUserMedia({
    audio: { ...AUDIO_CONSTRAINTS, deviceId: { exact: mic.id } },
  });
  return {
    stream,
    name: getTrackNameOrThrow(stream),
    isInternal: false,
  };
};

const getWindowsInternalAudioStream = async () => {
  let audioStream: MediaStream;
  const mediaDevices = window.navigator.mediaDevices;

  if (window.isElectron) {
    audioStream = await mediaDevices.getUserMedia({
      audio: {
        // @ts-ignore
        mandatory: { chromeMediaSource: 'desktop' },
      },
      video: {
        // @ts-ignore
        mandatory: { chromeMediaSource: 'desktop' },
      },
    });
  } else {
    audioStream = await mediaDevices.getDisplayMedia({
      audio: AUDIO_CONSTRAINTS,
      // Even though we do not need video, 'Audio only requests are not
      // supported' is the error given by Windows.
      video: true,
    });
    // We do not need the video tracks, so immediately stop them.
    audioStream.getVideoTracks().forEach((track) => {
      track.stop();
    });
  }

  return {
    stream: audioStream,
    name: getTrackNameOrThrow(audioStream),
    isInternal: true,
  };
};

const getTrackNameOrThrow = (stream: MediaStream) => {
  const tracks = stream.getAudioTracks();
  if (!tracks.length) {
    throw {
      audioErrorType: AudioErrorType.NO_AUDIO_TRACKS,
    };
  }
  return tracks[0].label;
};

export const getCurrentAvailableMics = createAsyncThunk(
  'audioV2/getCurrentAvailableMics',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    let availableMics: Microphone[];
    if (window.__TAURI__) {
      console.log('Tauri get all input devices names');
      availableMics = (await tauriInvoke('get_input_device_names')) as Microphone[];
    } else {
      // Note that the following doesn't get full device info (enumerates only default devices and
      // without labels or ids) until the user grants permission - a manual process which
      // is triggered as part of recording with `navigator.mediaDevices.getUserMedia()` and
      // `navigator.mediaDevices.getDisplayMedia()` (or by the user changing browser settings,
      // which needs to happen if they denied access at one point. We nudge them in the right
      // direction with <MicrophoneDenied /> modal).
      const devices = await navigator.mediaDevices.enumerateDevices();

      const isDeviceAudioInput = (device) => {
        return device.kind === 'audioinput';
      };

      // The list of devices may contain duplicates for 'default' device. Google Chrome will
      // contain duplicates, but other browsers might not.
      //
      // For example, we might have:
      //
      // * Default - MacBook Pro Microphone (Built-in)
      // * MacBook Pro Microphone (Built-in)
      //
      // We want to only show one to the user, so the 'default' will be filtered out.
      const defaultMic = devices.find((device) => isDeviceAudioInput(device) && device.deviceId === 'default');

      availableMics = devices
        .filter(
          (device) =>
            isDeviceAudioInput(device) &&
            device.deviceId !== 'default' &&
            // We filter out internal mic on non-electron Mac
            (!isMac || window.isElectron || !device.label.startsWith(INTERNAL_AUDIO_MIC_NAME))
        )
        .map((device) => ({
          id: device.deviceId,
          label: device.label,
          name: device.label,
          default: defaultMic ? device.groupId === defaultMic.groupId : false,
          isInternal: device.label.startsWith(INTERNAL_AUDIO_MIC_NAME),
          isBluetooth: device.label.toLowerCase().includes('bluetooth'),
        }));

      if (isWindows && selectIsAvaMicAvailable(state)) {
        availableMics.unshift({
          id: INTERNAL_AUDIO_MIC_ID,
          label: INTERNAL_AUDIO_MIC_NAME,
          name: INTERNAL_AUDIO_MIC_NAME,
          default: false,
          isInternal: true,
          isBluetooth: false,
        });
      }
    }
    // filter Ava Mic out if it's not available
    if (!selectIsAvaMicAvailable(state)) {
      availableMics = availableMics.filter((mic) => !mic.isInternal);
    }
    dispatch(setAvailableMicrophones(availableMics));
    if (!availableMics.length) {
      dispatch(
        setError({
          audioErrorType: AudioErrorType.NO_AUDIO_INPUTS,
        })
      );
    }
    dispatch(refreshSelectedMic());
  }
);

// Microphone is selected in the following priority:
//  1. Currently selected mic from Redux state (by ID, if exists in availableMics)
//  2. Saved mic from local storage if user manually selects a mic
//  3. Ava mic if available (Desktop app or Windows browser (except Firefox))
//  4. Default mic, Google Chrome will label this while other browsers might not
//  5. First available mic
const refreshSelectedMic = createAsyncThunk('audioV2/refreshSelectedMic', async (_, { dispatch, getState }) => {
  const state = getState() as RootState;
  const availableMics = selectAvailableMicrophones(state);
  const hearingProfile = selectHearingProfile(state);
  const userProfileFetchInitiated = selectUserProfileFetchInitiated(state);
  const userProfileFetchFinished = selectUserProfileFetchFinished(state);
  const userProfileFetchErrored = !userProfileFetchInitiated && userProfileFetchFinished;
  // initially we have userProfile as undefined and we want to make sure it's defined to select mic, but if it errors oh well
  if (!userProfileFetchFinished && !userProfileFetchErrored) {
    return refreshSelectedMic();
  }

  if (!availableMics.length) {
    dispatch(selectMicrophone(undefined));
    return;
  }

  const selectedMicrophone = selectSelectedMicrophone(state);
  if (selectedMicrophone && availableMics.find((mic) => mic.id === selectedMicrophone.id)) {
    // Currently selected microphone is still available, so we don't need to change it
    return;
  }

  const getMicrophoneByIdIfExist = (availableMics, microphoneId) => {
    return microphoneId && availableMics.find((mic: Microphone) => mic.id === microphoneId);
  };
  const savedMic = getMicrophoneByIdIfExist(availableMics, LocalStorage.get(STORAGE_KEYS.SELECTED_MIC_ID));

  const avaMic = availableMics.find((mic) => mic.isInternal);
  const bluetoothMic = availableMics.find((mic) => mic.isBluetooth);
  const defaultMic = availableMics.find((mic) => mic.default);
  const firstAvailableMic = availableMics[0];

  if (hearingProfile == 2) {
    return await dispatch(selectMicrophone(savedMic || defaultMic || firstAvailableMic || avaMic));
  }
  if (!savedMic) {
    dispatch(setIncludeMicrophoneWithInternalAudio(false));
    LocalStorage.set(STORAGE_KEYS.INCLUDE_EXTERNAL_AUDIO, false);
  }

  await dispatch(selectMicrophone(savedMic || avaMic || bluetoothMic || defaultMic || firstAvailableMic));
});

export const selectMicrophone = createAsyncThunk(
  'audioV2/selectMicrophone',
  async (microphone: Microphone | undefined, { dispatch, getState }) => {
    const state = getState() as RootState;
    const selectedMicrophone = selectSelectedMicrophone(state);
    if (selectedMicrophone?.id === microphone?.id) {
      return;
    }
    if (!microphone) {
      dispatch(setOverrideAudioRestart(true));
      await dispatch(setSelectedMicrophone(microphone));
      dispatch(stopRecording());
      return;
    }
    LocalStorage.set(STORAGE_KEYS.SELECTED_MIC_ID, microphone.id);
    if (selectedMicrophone && !selectedMicrophone.id) {
      // If the previously selected microphone was empty, and the new one is
      // the default one - it means we should not restart recording, and only
      // switch the underlying selected microphone.
      await dispatch(setSelectedMicrophone(microphone));
      return;
    }

    await dispatch(setSelectedMicrophone(microphone));
    await dispatch(maybeRestartRecording());
  }
);
const setupVolumeMetering = async (
  audioContext: AudioContext,
  setVolume: (volume: number) => void,
  streams: Array<StreamToRecord>
) => {
  const moduleUrl = new URL('/vumeter-processor.js', window.location.href);
  await audioContext.audioWorklet.addModule(moduleUrl.href);

  // Volume metering is needed to display volume levels
  // to the user (shadow on the mic button).
  //
  // Because it is loaded as a separate script/file, it can happen
  // that audioContext fails to fetch it. For example, if recording
  // is requested in offline mode, the vumeter-processor.js will
  // fail to load in case it hasn't been cached yet.
  //
  // To avoid showing the user red error banner in such cases,
  // we wrap this setup in a try .. catch block.
  let volumeMeterWorkletNode;
  let volumeInterval;

  try {
    volumeMeterWorkletNode = new AudioWorkletNode(audioContext, 'vumeter');

    const volumeHandler = () => {
      let volumeAverage = 0;
      let volumeCount = 0;
      let prevVolumeAverage = 0;

      volumeInterval = setInterval(() => {
        if (Math.abs(prevVolumeAverage - volumeAverage) > 1) {
          setVolume(volumeAverage);
          prevVolumeAverage = volumeAverage;
        }

        volumeCount = 0;
        volumeAverage = 0;
      }, 250);

      return (event) => {
        const eventVolume = event.data.volume || 0;
        const newVolumeSum = volumeAverage * volumeCount + eventVolume * 100;
        volumeCount += 1;
        volumeAverage = newVolumeSum / volumeCount;
      };
    };

    volumeMeterWorkletNode.port.onmessage = volumeHandler();
  } catch (err) {
    Sentry.captureException(err);
  }
  const sources = streams.map((stream) => {
    const streamSource = audioContext.createMediaStreamSource(stream.stream);
    streamSource.connect(volumeMeterWorkletNode);
    return streamSource;
  });
  if (audioContext.state === 'suspended') {
    await audioContext.resume();
  }
  return () => {
    sources.forEach((source) => {
      source.disconnect();
    });
    volumeMeterWorkletNode.disconnect();
    clearInterval(volumeInterval);
  };
};
