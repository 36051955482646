import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        gap: 16,
        padding: '12px 24px',
        alignItems: 'center',
        background: theme.palette.primary.main,
        color: theme.palette.ava.white,
        fontWeight: 400,
        fontSize: 16,
        borderRadius: 100,
    },
    desktop: {
        padding: '4px 4px 4px 8px',
        gap: 8,
        borderRadius: 6,
        color: 'rgba(255, 255, 255, 0.8)',
        fontWeight: 600,
        fontSize: 14,
    },
}));
export const ConvoV2NotificationContent = React.memo(({ children, desktop }) => {
    const classes = useStyles();
    return React.createElement(Box, { className: classNames(classes.root, { [classes.desktop]: desktop }) }, children);
});
