import type { Selector } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';

import type { AvaTranslateState } from '../store/slices/avaTranslate';
import type { RootState } from '../store/store';
import type { AvaTranslateManager } from '../utils/AvaTranslate';

const selectAvaTranslate = (state: RootState): AvaTranslateState => state.avaTranslate;

export const selectAvaTranslateManager: Selector<RootState, AvaTranslateManager | undefined> = createSelector(
  [selectAvaTranslate],
  (avaTranslate) => avaTranslate.avaTranslateManager
);

export const selectToggleInProgress: Selector<RootState, boolean> = createSelector(
  [selectAvaTranslate],
  (avaTranslate) => avaTranslate.toggleInProgress
);

export const selectHostLanguage: Selector<RootState, string | undefined> = createSelector(
  [selectAvaTranslate],
  (avaTranslate) => avaTranslate.hostLanguage
);

export const selectGuestLanguage: Selector<RootState, string | undefined> = createSelector(
  [selectAvaTranslate],
  (avaTranslate) => avaTranslate.guestLanguage
);

export const selectAutoTranslateActiveOption = createSelector(
  [selectAvaTranslate],
  (avaTranslate) => avaTranslate.active
);

export const selectAutoTranslateActive: Selector<RootState, boolean> = createSelector(
  [selectAvaTranslate],
  (avaTranslate) => avaTranslate.autoTranslateActive
);

export const selectCanSwitchTranslation = createSelector(
  [selectAutoTranslateActive],
  (autoTranslateActive) => !autoTranslateActive
);

// This is the langyage to show the flag and the transcripts in. Surprisingly
// this is REVERSE from the concept of `active` on the backend.
export const selectAutoTranslateLanguage = createSelector(
  [selectAutoTranslateActiveOption, selectHostLanguage, selectGuestLanguage],
  (active, hostLanguage, guestLanguage) => {
    if (active === 'host') {
      return guestLanguage;
    }
    if (active === 'guest') {
      return hostLanguage;
    }
    return undefined;
  }
);

export const selectGuestLanguageSelectorOpen: Selector<RootState, boolean> = createSelector(
  [selectAvaTranslate],
  (avaTranslate) => avaTranslate.guestLanguageSelectorOpen
);

export const selectSncfConsentGiven: Selector<RootState, boolean> = createSelector(
  [selectAvaTranslate],
  (avaTranslate) => avaTranslate.sncfConsentGiven
);

export const selectSncfConsentDialogShown: Selector<RootState, boolean> = createSelector(
  [selectAvaTranslate],
  (avaTranslate) => avaTranslate.sncfConsentDialogShown
);
