import { createSelector } from '@reduxjs/toolkit';
const selectAvaTranslate = (state) => state.avaTranslate;
export const selectAvaTranslateManager = createSelector([selectAvaTranslate], (avaTranslate) => avaTranslate.avaTranslateManager);
export const selectToggleInProgress = createSelector([selectAvaTranslate], (avaTranslate) => avaTranslate.toggleInProgress);
export const selectHostLanguage = createSelector([selectAvaTranslate], (avaTranslate) => avaTranslate.hostLanguage);
export const selectGuestLanguage = createSelector([selectAvaTranslate], (avaTranslate) => avaTranslate.guestLanguage);
export const selectAutoTranslateActiveOption = createSelector([selectAvaTranslate], (avaTranslate) => avaTranslate.active);
export const selectAutoTranslateActive = createSelector([selectAvaTranslate], (avaTranslate) => avaTranslate.autoTranslateActive);
export const selectCanSwitchTranslation = createSelector([selectAutoTranslateActive], (autoTranslateActive) => !autoTranslateActive);
// This is the langyage to show the flag and the transcripts in. Surprisingly
// this is REVERSE from the concept of `active` on the backend.
export const selectAutoTranslateLanguage = createSelector([selectAutoTranslateActiveOption, selectHostLanguage, selectGuestLanguage], (active, hostLanguage, guestLanguage) => {
    if (active === 'host') {
        return guestLanguage;
    }
    if (active === 'guest') {
        return hostLanguage;
    }
    return undefined;
});
export const selectGuestLanguageSelectorOpen = createSelector([selectAvaTranslate], (avaTranslate) => avaTranslate.guestLanguageSelectorOpen);
export const selectSncfConsentGiven = createSelector([selectAvaTranslate], (avaTranslate) => avaTranslate.sncfConsentGiven);
export const selectSncfConsentDialogShown = createSelector([selectAvaTranslate], (avaTranslate) => avaTranslate.sncfConsentDialogShown);
