import { batch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { selectIsInConversation } from '../../selectors/conversation';
import { selectAvaId } from '../../selectors/userProfile';
import { selectV1Websocket } from '../../selectors/v1Session';
import { maybeRestartRecording } from '../../store/slices/audioV2';
import { setCCMode } from '../../store/slices/ccMode';
import { updateConversationEnded, updateIsInConversation } from '../../store/slices/conversation';
import { initiateConversationEndSequence } from '../../store/slices/conversationEnd';
import { setLoading } from '../../store/slices/uiState';
import { setConferenceCallRequested, setV1Token } from '../../store/slices/v1Session';
import type { AppDispatch, RootState } from '../../store/store';
import { navigateToTranscriptURLIfNeeded } from '../../utils/conversation';
import { getSearchValueFromString, setSearchValue } from '../../utils/http';
import { updateRoomId } from '../../utils/log';
import {
  isConversationEnded,
  isDefaultRoomId,
  sendResetRoomIdMessage,
  sendRoomStateUpdateMessage,
  sendUsePaidAsrUpdateMessage,
} from '../../utils/ws-v1';
import { fetchRemainingCredits } from '../session';

export const setLangSelected = (lang: string) => {
  return {
    type: 'SET_SPEECH_LANG',
    lang,
  };
};

export const clearScribeConversationState = () => ({
  type: 'CLEAR_SCRIBE_CONVERSATION',
});

// Returns [batchDispatchFn, cancelFn]
const makeBatchDispatcher = (dispatch) => {
  const actionsToDispatch = [];
  const batchedDispatch = (action) => {
    //@ts-ignore
    actionsToDispatch.push(action);
  };
  const dispatchAllActions = () => {
    batch(() => {
      actionsToDispatch.forEach(dispatch);
      actionsToDispatch.splice(0, actionsToDispatch.length);
    });
  };
  const interval = setInterval(dispatchAllActions, 250);
  return [
    batchedDispatch,
    () => {
      dispatchAllActions();
      clearInterval(interval);
    },
  ];
};

export const handleNewRoomStatus = (status: any) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const avaId = selectAvaId(state);
    const isInConversation = selectIsInConversation(state);
    const ws = selectV1Websocket(state);

    updateRoomId(status.id);
    const defaultRoomId = !status?.id || isDefaultRoomId(status.id);
    const isEnded = isConversationEnded(status, avaId);

    if (!defaultRoomId && !isInConversation) {
      // The conversation just started
      if (ws) sendRoomStateUpdateMessage(ws, { value: 'ongoing' });
      if (ws) sendUsePaidAsrUpdateMessage(ws, true);
    }

    // The order of things happening here is important. updateIsInConversation is responsible
    // for saving the current transcript into the saved transcript list, so if we call it AFTER
    // the room status has been changed - it won't have access to much of the data.
    const shouldBeIsInConversation = !!status.id && !defaultRoomId && !status.past;
    dispatch(updateIsInConversation({ isInConversation: shouldBeIsInConversation, roomId: status.id }));
    dispatch(updateConversationEnded(isEnded && isInConversation));

    dispatch({
      type: 'ROOM_STATUS_UPDATE',
      status,
    });

    if (status?.state?.value === 'ended' && status?.reason === 'room-ended-due-to-convo-restricted-session') {
      dispatch(initiateConversationEndSequence());
    }

    if (defaultRoomId) {
      dispatch(setV1Token(undefined));
    }

    if (!defaultRoomId) {
      const rwLink = (status.links || {}).rw;
      const token = getSearchValueFromString(rwLink.split('?')[1], 'token', undefined);
      if (token) {
        dispatch(setV1Token(token));
      }
      const endpoint = getSearchValueFromString(rwLink.split('?')[1], 'endpoint', undefined);
      if (endpoint) {
        setSearchValue(window, 'endpoint', endpoint);
      }
    }
  };
};

export const startConversationReadClient = (ws: WebSocket, dispatch: AppDispatch, getState: () => RootState) => {
  let loadedAudio;

  const [batchedDispatch, cancelBatchDispatch] = makeBatchDispatcher(dispatch);
  const currentConversationListener = (event) => {
    const message = JSON.parse(event.data);
    if (message.type === 'transcript-edit') {
      const { mutation, origin, transcriptId, speakerId } = message.data;

      batchedDispatch({
        type: 'RECEIVED_SCRIBE_CONVERSATION_TRANSCRIPT_MUTATION',
        mutation,
        origin,
        transcriptId,
        speakerId,
      });
    } else if (message.type === 'audio' && !loadedAudio) {
      loadedAudio = true;
    } else if (message.type === 'room-status-update' || message.type === 'room-status') {
      batchedDispatch(handleNewRoomStatus(message));
    } else if (message.type === 'reset-recorder') {
      dispatch(maybeRestartRecording());
    }
  };
  ws.addEventListener('message', currentConversationListener);
  ws.addEventListener('close', () => {
    //@ts-ignore
    cancelBatchDispatch();
  });
};

export const switchLang = (lang: string) => ({ type: 'SWITCH_LANG', lang });

export const createNewConversation =
  (options?: { roomId: string; inConferenceCall: boolean }) => async (dispatch: any, getState: any) => {
    const state = getState() as RootState;
    const ws = state.v1Session.v1Socket;

    dispatch(
      setLoading({
        isLoading: true,
        loadingReason: 'Creating new conversation',
      })
    );
    if (!ws) {
      dispatch(
        setLoading({
          isLoading: false,
        })
      );
      return;
    }
    navigateToTranscriptURLIfNeeded();
    dispatch(setCCMode('conversation'));

    const cache = { listener: undefined, closeListener: undefined } as const;
    //@ts-ignore
    cache.closeListener = () => {
      if (cache.listener) ws.removeEventListener('message', cache.listener);
      if (cache.closeListener) ws.removeEventListener('close', cache.closeListener);
      dispatch(
        setLoading({
          isLoading: false,
        })
      );
    };
    //@ts-ignore
    ws.addEventListener('close', cache.closeListener);

    const { parse } = getState().userProfile;
    // Purposefully ignoring promise, as we only care about side effects.
    fetchRemainingCredits(parse?.avaId)(dispatch);
    const roomId = options?.roomId || `${parse?.avaId}_${uuid()}`;
    const inConferenceCall = options?.inConferenceCall;
    dispatch(setConferenceCallRequested(!!inConferenceCall));
    //@ts-ignore
    cache.listener = (message) => {
      const data = JSON.parse(message.data);
      if (data.type === 'room-status' && data.id === roomId) {
        //@ts-ignore
        ws.removeEventListener('message', cache.listener);
        //@ts-ignore
        ws.removeEventListener('close', cache.closeListener);
      }
    };
    //@ts-ignore
    ws.addEventListener('message', cache.listener);
    sendResetRoomIdMessage(ws, roomId);
  };

if (window.isElectron) {
  window.electronIPC.onCreateNewConversation(async () => {
    window.store.dispatch(createNewConversation());
  });
  window.electronIPC.onEndConversation(() => {
    window.store.dispatch(initiateConversationEndSequence());
  });
}
