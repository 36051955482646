export const deleteLocalAndSessionStorageAndCookies = () => {
  localStorage.clear();
  sessionStorage.clear();
  const cookies = document.cookie;
  for (let i = 0; i < cookies.split(';').length; i += 1) {
    const myCookie = cookies[i];
    const pos = myCookie.indexOf('=');
    const name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};
