import uniqBy from 'lodash/uniqBy';
import { selectBoostWords } from '../selectors/boost';
import { selectEditedWords } from '../selectors/conversation';
import { selectLang, selectTranscripts } from '../selectors/legacy-conversation';
import { setBoostWords } from '../store/slices/boost';
import { getText } from './scribeUtils';
import { isBoostEnabled } from './status';
import { sendAccessBoostWords, sendBoostMessage } from './ws-v1';
export class BoostManager {
    constructor(ws, dispatch, subscription) {
        this.ws = ws;
        this.dispatch = dispatch;
        this.ws.addEventListener('message', (event) => {
            const message = JSON.parse(event.data);
            this.handleMessage(message);
        });
        this.boostEnabled = isBoostEnabled(subscription);
        this.handleMessage = this.handleMessage.bind(this);
        this.loading = false;
    }
    handleMessage(message) {
        if (!this.boostEnabled)
            return;
        switch (message.type) {
            case 'user-boost-words': {
                if (this.loading)
                    this.loading = false;
                this.dispatch(setBoostWords(message.words));
                break;
            }
        }
    }
    handleLoadBoostWords() {
        if (!this.boostEnabled)
            return;
        sendAccessBoostWords(this.ws, 'list');
        this.loading = true;
    }
    handleUserAddOrRemoveBoostWords(action, words) {
        if (!this.boostEnabled)
            return;
        switch (action) {
            case 'add': {
                if (words.length) {
                    sendAccessBoostWords(this.ws, action, words);
                }
                break;
            }
            case 'remove': {
                sendAccessBoostWords(this.ws, action, words);
                break;
            }
        }
    }
    handleScribeAddOrRemoveBoostWords(action, word) {
        switch (action) {
            case 'add': {
                if (word.length) {
                    sendBoostMessage(this.ws, { action, word });
                }
                break;
            }
            case 'remove': {
                sendBoostMessage(this.ws, { action, word });
                break;
            }
        }
    }
}
export const checkEditedWords = (state) => {
    const boostWords = selectBoostWords(state);
    const transcripts = selectTranscripts(state);
    const editedWords = selectEditedWords(state);
    const lang = selectLang(state);
    const boostWordsLowercase = boostWords.map((w) => w.toLowerCase());
    const stripPunctuation = (value) => {
        return value ? value.replace(/[_.,!?]/g, '').trim() : '';
    };
    const words = editedWords
        .map((word) => (Object.assign(Object.assign({}, word), { text: stripPunctuation(word.text) })))
        .filter((w) => !!w.text &&
        transcripts[w.transcriptionId] &&
        getText(transcripts[w.transcriptionId], lang).includes(w.text) &&
        !boostWordsLowercase.includes(w.text.toLowerCase()))
        .map((w) => w.text);
    return words.length ? uniqBy(words, (w) => w.toLowerCase()) : null;
};
