import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAuthInstance } from '../../firebase';
import type { RoomStatusUpdateAction } from '../../reducers/legacyConversationReducer';
import { selectConfigEnableCCV2, selectConfigEnableConvoV2, selectConfigEnableSolodia } from '../../selectors/auth';
import { selectBoostManager } from '../../selectors/boost';
import { selectUserRoleInConversation, UserRoleInConversation } from '../../selectors/combined';
import { selectJoinConversationManager } from '../../selectors/conversation';
import { selectPageToShowAfterEndingConversation } from '../../selectors/conversationEnd';
import {
  selectHaveTranscripts,
  selectIsHost,
  selectStatus,
  selectStatusRoomId,
} from '../../selectors/legacy-conversation';
import { selectRecallAIManager } from '../../selectors/recallAI';
import { selectAvaId, selectFeatures, selectSubscription } from '../../selectors/userProfile';
import { isMobile } from '../../utils';
import * as Conversation from '../../utils/conversation';
import { navigateToTranscriptURLIfNeeded } from '../../utils/conversation';
import { clearSearchValue } from '../../utils/http';
import { JoinConversationManager } from '../../utils/joinConversation';
import * as segment from '../../utils/segment';
import { isUnlimitedPaidASR } from '../../utils/status';
import { stopAndTrack } from '../../utils/stopwatch';
import type { AppDispatch, RootState } from '../store';
import { getCurrentAvailableMics, resetAllMicsSelected, startRecording, stopRecording } from './audioV2';
import { setCCMode } from './ccMode';
import { initiateConversationEndSequence, resetConversationEndingState } from './conversationEnd';
import { setUrlToOpenAfterStartingConvo } from './quickSetup';
import { setConversationStatus, setRateConversationOpen } from './rateConversation';
import { addNewTranscript, setSelectedTranscriptId } from './savedTranscript';
import { scribeDashboardClear } from './scribeDashboard';
import { setConnectToMeetingsOpen, setLoading, toggleSideBar } from './uiState';
import { setV1Token } from './v1Session';

const branchSdk = require('branch-sdk');

export type JoiningStatus = 'none' | 'requested' | 'accepted';
export type JoinConversationError = 'timeout' | 'userNotFound' | 'rejectedByUser' | 'unknown' | 'self' | undefined;
export type SelectedCaptions = undefined | 'free' | 'premium' | 'scribe';

export type EditedWord = {
  text: string;
  transcriptionId: string;
};

export type State = {
  editedWords: EditedWord[];
  curseFilter: number;
  joiningStatus: JoiningStatus;
  selectedCaptions: SelectedCaptions;
  backendSelectedCaptions?: SelectedCaptions;

  isInConversation: boolean;
  conversationEnded: boolean;

  inTwilioCalls: boolean;

  createConversationWhenReady: boolean;
  joinConversationManager?: JoinConversationManager;
  joinConversationError?: JoinConversationError;

  //@ts-ignore
  conversationWakeLock?: WakeLockSentinel;
};

const initialState: State = {
  editedWords: [],
  curseFilter: parseInt(localStorage.getItem('ava-curse-filter') || '2', 10),
  joiningStatus: 'none',
  selectedCaptions: 'free',
  isInConversation: false,
  conversationEnded: false,
  inTwilioCalls: false,
  createConversationWhenReady: false,
};

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    addEditedWord(state, { payload }: PayloadAction<EditedWord>) {
      state.editedWords.push(payload);
    },
    setCurseFilter(state, { payload }: PayloadAction<number>) {
      window.localStorage.setItem('ava-curse-filter', payload.toString());
      state.curseFilter = payload;
    },
    setJoiningStatus(state, { payload }: PayloadAction<JoiningStatus>) {
      state.joiningStatus = payload;
    },
    setIsInConversation(state, { payload }: PayloadAction<boolean>) {
      state.isInConversation = payload;
    },
    setConversationEnded(state, { payload }: PayloadAction<boolean>) {
      state.conversationEnded = payload;
    },
    setSelectedCaptions(state, { payload }: PayloadAction<SelectedCaptions>) {
      state.selectedCaptions = payload;
    },
    setCreateConversationWhenReady(state, { payload }: PayloadAction<boolean>) {
      state.createConversationWhenReady = payload;
    },
    setJoinConversationError(state, { payload }: PayloadAction<JoinConversationError>) {
      state.joinConversationError = payload;
    },
    //@ts-ignore
    setWakeLockSentinel(state, { payload }: PayloadAction<WakeLockSentinel | undefined>) {
      state.conversationWakeLock = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('ROOM_STATUS_UPDATE', (state, action: RoomStatusUpdateAction) => {
      state.inTwilioCalls = action.status.twilioCalls && Object.keys(action.status.twilioCalls).length > 0;
      // backend will send usePaidASR in audioStreams if using premium captions
      const userAudioStream = action.status.audioStreams?.[0];
      if (userAudioStream) {
        if (userAudioStream.usePaidASR) {
          state.backendSelectedCaptions = 'premium';
        } else {
          state.backendSelectedCaptions = 'free';
        }
      }

      // if user has google-cloud-asr flag on it will show on backend status as such:
      if (action.status?.asr?.googleCloud) {
        state.backendSelectedCaptions = 'premium';
      }
    });
    builder.addCase(
      createJoinConversationManager.fulfilled,
      (state, { payload }: PayloadAction<JoinConversationManager | undefined>) => {
        if (payload) state.joinConversationManager = payload;
      }
    );
  },
});

export const conversationReducer = conversationSlice.reducer;
export const { setSelectedCaptions, addEditedWord, setCurseFilter, setJoiningStatus, setCreateConversationWhenReady } =
  conversationSlice.actions;
export const { setWakeLockSentinel, setIsInConversation, setConversationEnded, setJoinConversationError } =
  conversationSlice.actions;

export const updateConversationEnded = createAsyncThunk(
  'conversation/updateConversationEnded',
  async (conversationEnded: boolean, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const dispatch = thunkAPI.dispatch;

    // scribes that are scribing should never be impacted by a conversation ending
    const userIsScribe = selectUserRoleInConversation(state) === UserRoleInConversation.SCRIBE;
    if (userIsScribe) {
      return;
    }
    dispatch(setConversationEnded(conversationEnded));
    if (conversationEnded) {
      clearSearchValue(window, 'endpoint');
      dispatch(setV1Token(undefined));
      dispatch(initiateConversationEndSequence());
    }
  }
);

export const resetSelectedCaptions = createAsyncThunk('conversation/resetSelectedCaptions', async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const dispatch = thunkAPI.dispatch;

  const asrTime = Math.floor(state.userProfile?.paidASRCreditTime / 1000);
  const subscription = selectSubscription(state);
  const features = selectFeatures(state);

  const unlimitedPaidASR = isUnlimitedPaidASR(subscription, features);
  const canSelectPremium = unlimitedPaidASR || asrTime > 0;

  dispatch(setSelectedCaptions(canSelectPremium ? 'premium' : 'free'));
});

export const updateIsInConversation = createAsyncThunk(
  'conversation/updateIsInConversation',
  async ({ isInConversation, roomId }: { isInConversation: boolean; roomId: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const dispatch = thunkAPI.dispatch;
    const joinConversationManager = selectJoinConversationManager(state);
    // got connection response only becomes true when you try to join another conversation
    // and got the connection response from the server, we want to be able to join a conversation WHILE in a conversation
    const gotConnectionResponse = Boolean(joinConversationManager?.gotConnectionResponse);
    if (isInConversation === state.conversation.isInConversation && !gotConnectionResponse) {
      return thunkAPI.rejectWithValue(false);
    }
    // if it's the same room as state we really don't need to do anything
    // this will not prevent this function from running when you close your room or change rooms
    if (selectStatusRoomId(state) === roomId) {
      return;
    }

    if (window.electronIPC) {
      window.electronIPC.sendSetIsInConversation(isInConversation);
    }
    dispatch(setIsInConversation(isInConversation));
    if (isInConversation) {
      if (joinConversationManager?.isJoinedByQRCode) {
        segment.track('AppLess Handled', {
          isSuccessful: true,
          qrCodeAvaName: joinConversationManager.qrCodeAvaName,
        });
        stopAndTrack('appLessFinished');
      }
      const avaId = selectAvaId(state);
      const isConvoV2 = selectConfigEnableConvoV2(state);
      const isDesktopV2 = selectConfigEnableCCV2(state);
      if (avaId) {
        if (window.__TAURI__) {
          segment.identifyWithId(avaId, { desktop_v2: isDesktopV2 });
        } else {
          segment.identifyWithId(avaId, { web_convo_v2: isConvoV2 });
        }
      }
      stopAndTrack('startConversation');
      navigateToTranscriptURLIfNeeded();
      dispatch(setSelectedTranscriptId(''));
      const boostManager = selectBoostManager(state);
      branchSdk.closeJourney();
      dispatch(resetAllMicsSelected());
      await dispatch(resetSelectedCaptions());
      await dispatch(getCurrentAvailableMics());
      await dispatch(startRecording());
      dispatch(resetConversationEndingState());
      if (window.navigator.mediaDevices) {
        window.navigator.mediaDevices.ondevicechange = () => dispatch(getCurrentAvailableMics());
      }
      //@ts-ignore
      if (window.navigator.wakeLock) {
        if (isMobile()) {
          //@ts-ignore
          window.navigator.wakeLock.request('screen').then((wakeLockSentinel) => {
            dispatch(setWakeLockSentinel(wakeLockSentinel));
          });
        }
      }
      const screenWidth = Math.max(
        (document.documentElement && document.documentElement.clientWidth) || 0,
        window.innerWidth || 0
      );
      if (screenWidth > 768 && !window.isElectron) {
        dispatch(toggleSideBar(true));
      }
      boostManager?.handleLoadBoostWords();
      if (window.isElectron) {
        dispatch(setCCMode('conversation'));
      }

      dispatch(
        setLoading({
          isLoading: false,
        })
      );
    }

    if (!isInConversation) {
      const isHost = selectIsHost(state);
      const recallAIManager = selectRecallAIManager(state);
      const joinConversationManager = selectJoinConversationManager(state);
      const status = selectStatus(state);
      const haveTranscripts = selectHaveTranscripts(state);
      const features = selectFeatures(state);
      const pageToShowAfterEndingConversation = selectPageToShowAfterEndingConversation(state);

      const isSaveTranscript = features.saveTranscript;
      const isForbidConversationSaving = features['forbid-conversation-saving'];

      if (isSaveTranscript && !isForbidConversationSaving) {
        if (haveTranscripts) {
          if (pageToShowAfterEndingConversation === 'transcript') {
            dispatch(setSelectedTranscriptId(status.id));
          }
          const newTranscript = Conversation.prepareTranscriptAfterConversation(state);
          //@ts-ignore
          dispatch(addNewTranscript(newTranscript));
        }
      }

      Conversation.trackHadConversationFromState(state);

      branchSdk.track('pageview');

      dispatch(setConversationStatus(status));
      if (haveTranscripts) {
        dispatch(setRateConversationOpen(true));
      }

      if (state.conversation.conversationWakeLock) {
        await state.conversation.conversationWakeLock.release();
      }
      dispatch(setWakeLockSentinel(undefined));

      dispatch(stopRecording());
      dispatch(scribeDashboardClear());
      dispatch(resetConversationEndingState());

      recallAIManager?.reset(isHost);
      joinConversationManager?.reset();
      dispatch(setConnectToMeetingsOpen(false));
      if (selectConfigEnableSolodia(state)) {
        localStorage.removeItem('soloDiaNotificationShowed');
      }
    }

    if (state.quickSetup.urlToOpenAfterStartingConvo && isInConversation) {
      const url = state.quickSetup.urlToOpenAfterStartingConvo;
      setTimeout(() => {
        if (window.desktopIntegration) {
          window.desktopIntegration.openExternalURL(url);
        }
      }, 500);
      dispatch(setUrlToOpenAfterStartingConvo(undefined));
    }
  }
);

export const createJoinConversationManager = createAsyncThunk(
  'conversation/createJoinConversationManager',
  (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppDispatch;
    const state = thunkAPI.getState() as RootState;
    const { v1Socket } = state.v1Session;
    const avaName = state.userProfile.parse?.avaName || '';
    const firebaseUser = state.auth.firebaseUser?.uid || '';
    const avaId = state.userProfile.parse?.avaId || '';
    if (!v1Socket) return;
    return new JoinConversationManager(v1Socket, dispatch, avaName, firebaseUser, avaId);
  }
);

export const joinConversationAnonymously = createAsyncThunk(
  'conversation/joinConversationAnonymously',
  async (avaName: string, { dispatch, getState }) => {
    if (avaName.length < 3) return;
    const state = getState() as RootState;
    dispatch(
      setLoading({
        isLoading: true,
        loadingReason: 'Checking if need to create Anonymous authentication',
      })
    );
    const avaId = selectAvaId(state);
    const joinConversationManager = selectJoinConversationManager(state);
    if (!avaId) {
      localStorage.setItem('ava_request', avaName);
      getAuthInstance().signInAnonymously();
      segment.track('Joined Conversation', {
        HostAvaName: avaName,
        'Join Method': 'Room Name',
      });
      // ava_request will be picked up during v1 Socket creation.
    } else {
      // user is already logged in, so the v1 Socket already exists
      joinConversationManager?.handleJoinAvaRoom(avaName);
    }
  }
);
