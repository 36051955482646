export const STORAGE_KEYS = {
    AVA_ID: 'avaId',
    AVA_REQUEST: 'ava_request',
    CACHE: 'avaCachedUserRequested',
    SELECTED_MIC_ID: 'selectedMicId',
    INCLUDE_EXTERNAL_AUDIO: 'includeExternalAudio',
};
const LocalStorage = {
    get: (key, defaultValue) => {
        const value = localStorage.getItem(key);
        if (typeof value !== 'string') {
            return defaultValue;
        }
        try {
            return JSON.parse(value);
        }
        catch (err) {
            return value;
        }
    },
    set: (key, value) => localStorage.setItem(key, typeof value !== 'string' ? JSON.stringify(value) : value),
};
export default LocalStorage;
