import { clearScribeConversationState } from '../actions/conversation';
import { logout } from '../store/slices/auth';
import { toggleSideBar } from '../store/slices/uiState';
import { deleteLocalAndSessionStorageAndCookies } from './cache';
import * as segment from './segment';

export enum HEARING_PROFILES {
  DEAF = 'deaf',
  HARD_OF_HEARING = 'hard-of-hearing',
  HEARING = 'hearing',
}

export enum VOICING_PROFILES {
  NOT_VOICING = 'not voicing',
  VOICING = 'voicing',
}

export const HEARING_PROFILES_OPTIONS = {
  // $FlowFixMe
  0: 'hearingProfile.deaf',
  // $FlowFixMe
  1: 'hearingProfile.hardOfHearing',
  // $FlowFixMe
  2: 'hearingProfile.hearing',
};

export const isUserNameValid = (userName) => {
  return userName.trim().length >= 2 && userName.trim().length < 50;
};

export const getUserColor = (username) => {
  if (!username) {
    return 'grey';
  }
  let hash = 0;
  username.split('').forEach((c) => {
    // eslint-disable-next-line
    hash = c.charCodeAt(0) + ((hash << 5) - hash);
  });
  const h = hash % 360;
  // eslint-disable-next-line
  return 'hsl(' + h + ', ' + 50 + '%, ' + 50 + '%)';
};

export const isDevMode = (features, backends) => {
  return !!features?.['dev-mode'] || localStorage.getItem('avaBackendUrl') === backends.production.url;
};

const unregisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
};

export const logoutAndClean = async () => {
  segment.track('Signed Out');
  segment.reset();
  deleteLocalAndSessionStorageAndCookies();
  unregisterServiceWorker();

  window.store.dispatch(clearScribeConversationState());
  window.store.dispatch(toggleSideBar(false));
  await logout(window.store.dispatch);
  //@ts-ignore
  window.location = '/';

  if (window.electronIPC) {
    window.electronIPC.sendSetIsLoggedIn(false);
    window.electronIPC.sendCCMode('expand');
  }
};

if (window.electronIPC) {
  window.electronIPC.onSignOutAndShowSignIn(async () => {
    await logoutAndClean();
    //@ts-ignore
    if (window.navigate) {
      //@ts-ignore
      window.navigate('/web/login');
    } else {
      window.history.pushState({}, document.title, '/web/login');
      window.location.reload();
    }
  });
}

export const buildUrl = (baseUrl: string, params: Record<string, any>) => {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key];
    if (value !== '') {
      searchParams.append(key, value);
    }
  }

  return `${baseUrl}?${searchParams.toString()}`;
};
