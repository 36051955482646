import { getSearchValue } from '../http';
const ORGANIZATIONS = {
    '': '',
    edu: 'edu',
    event: 'hr',
    hr: 'hr',
    ind: 'individual',
    individual: 'individual',
    loc: 'hr',
    wor: 'worship',
    worship: 'worship',
};
class AccountManager {
    constructor() {
        this.orgType = getSearchValue(window, 'orgType');
        this.mkt = getSearchValue(window, 'mkt');
        this.role = getSearchValue(window, 'role');
        this.orgName = getSearchValue(window, 'orgName');
        this.trial = getSearchValue(window, 't');
        this.d = getSearchValue(window, 'd');
    }
    setAccountDetailsInLocalStorage() {
        if (this.orgType || this.mkt) {
            this.setOrganizationType();
        }
        window.localStorage.setItem('account_orgName', this.orgName || '');
        window.localStorage.setItem('account_role', this.role || '');
        window.localStorage.setItem('account_trial', this.trial || '15');
        window.localStorage.setItem('account_d', this.d || 'y');
    }
    setOrganizationType() {
        let organizationType;
        if (this.role === 'user') {
            organizationType = 'individual';
        }
        else {
            organizationType = ORGANIZATIONS[this.orgType || this.mkt || ''];
        }
        window.localStorage.setItem('account_orgType', organizationType);
    }
}
export default AccountManager;
