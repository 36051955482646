import { selectV1Websocket, selectV1WebsocketStatus } from '../selectors/v1Session';
import type { WebsocketStatus } from '../store/slices/v1Session';
import { useAppSelector } from '../store/store';

export const useV1Socket: () => [WebSocket | undefined, WebsocketStatus] = () => {
  const v1Socket = useAppSelector(selectV1Websocket);
  const v1SocketStatus = useAppSelector(selectV1WebsocketStatus);

  return [v1Socket, v1SocketStatus];
};
