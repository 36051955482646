import i18next from 'i18next';
import { AVA_URL } from '../constants';
import { fetchTtsVoices } from '../store/slices/textToSpeech';
export const langs = ['fr', 'en'];
export const langsDisplay = {
    fr: 'Français',
    en: 'English',
};
export const getLanguageCode = () => {
    let langCode = i18next.language.split('-')[0];
    // we only support fr and en for now
    // so we default to en if the language is not supported
    if (!langs.includes(langCode)) {
        langCode = 'en';
    }
    return langCode;
};
export const getAvaLanguageUrl = () => {
    const languageCode = getLanguageCode();
    if (languageCode !== 'en') {
        return `${AVA_URL}/${languageCode}`;
    }
    return AVA_URL;
};
export const getCurrentLanguage = () => {
    return langsDisplay[getLanguageCode()];
};
export const getPhraseOrEmptyString = (key) => {
    return i18next.exists(key) ? i18next.t(key) : '';
};
export const getPhraseBoldData = (phrase, separator) => {
    const phrases = phrase.split(separator);
    return phrases.map((phrase, index) => ({ text: phrase, isBold: index % 2 === 1 })).filter((p) => p.text);
};
export const changeLanguage = (lang, i18n, t, dispatch) => {
    if (lang === i18n.language)
        return;
    i18n.changeLanguage(lang, () => {
        window.document.title = t('title');
    });
    dispatch(fetchTtsVoices(lang));
};
