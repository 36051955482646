import produce from 'immer';
import type { ActionType } from 'typesafe-actions';
import { getType } from 'typesafe-actions';

import * as actions from '../../actions/account';

export interface ISessionState {
  token: string;
  features: string[];
  isLoggedin: boolean;
  loading: boolean;
}

export const initSessionState: ISessionState = {
  features: [],
  isLoggedin: false,
  loading: false,
  token: '',
};

export type SessionAction = ActionType<typeof actions>;

export default (state = initSessionState, action: SessionAction) =>
  // $FlowFixMe
  produce(state, (draftArg) => {
    const draft = draftArg;
    switch (action.type) {
      case getType(actions.session.login): {
        draft.token = action.payload.token;
        draft.loading = true;
        draft.isLoggedin = true;
        return draft;
      }
      case getType(actions.session.logout): {
        draft.token = initSessionState.token;
        draft.isLoggedin = false;
        return draft;
      }
      case getType(actions.user.fetchUser.failure): {
        draft.loading = false;
        return draft;
      }
      case getType(actions.user.fetchUser.success): {
        draft.loading = false;
        return draft;
      }
      case getType(actions.session.unvalidateLogin): {
        draft.isLoggedin = false;
        draft.loading = false;
        return draft;
      }
      default:
        return draft;
    }
  });
