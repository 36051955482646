var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSearchValue } from '../../utils/http';
import { updateDownAudio } from '../../utils/ws-v1';
const initialState = {
    speakerListened: 'host',
    scribeInURL: !!getSearchValue(window, 'scribe'),
};
export const scribeSlice = createSlice({
    name: 'scribe',
    initialState,
    reducers: {
        // UPDATE_SPEAKER_LISTENED
        setSpeakerListened(state, { payload }) {
            state.speakerListened = payload;
        },
    },
});
export const scribeReducer = scribeSlice.reducer;
const { setSpeakerListened } = scribeSlice.actions;
export const updateSpeakerListened = createAsyncThunk('scribe/updateSpeakerListened', (speakerListened, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const state = (yield thunkAPI.getState());
    const dispatch = thunkAPI.dispatch;
    const ws = state.v1Session.v1Socket;
    if (!ws)
        return;
    yield updateDownAudio(ws, speakerListened);
    const speakerIdentified = speakerListened.trackId || speakerListened.avaId;
    dispatch(setSpeakerListened(speakerIdentified || 'host'));
}));
