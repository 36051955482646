var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import 'firebase/analytics';
import 'firebase/remote-config';
// In testing environments, this import will be undefined because we're going to mock it
import firebase from 'firebase/app';
export { getAuthInstance, setAuthInstance } from './base';
let analyticsInstance = firebase === null || firebase === void 0 ? void 0 : firebase.analytics();
export const getAnalyticsInstance = () => analyticsInstance;
export const setAnalyticsInstance = (value) => {
    analyticsInstance = value;
};
let performanceInstance = firebase === null || firebase === void 0 ? void 0 : firebase.performance();
export const getPerformanceInstance = () => performanceInstance;
export const setPerformanceInstance = (value) => {
    performanceInstance = value;
};
let remoteConfigInstance = firebase === null || firebase === void 0 ? void 0 : firebase.remoteConfig();
export const getRemoteConfigInstance = () => remoteConfigInstance;
export const setRemoteConfigInstance = (value) => {
    remoteConfigInstance = value;
};
if (remoteConfigInstance) {
    remoteConfigInstance.settings.minimumFetchIntervalMillis = 60 * 60 * 1000;
}
export const googleProvider = firebase && new firebase.auth.GoogleAuthProvider();
export const facebookProvider = firebase && new firebase.auth.FacebookAuthProvider();
export const appleProvider = firebase && new firebase.auth.OAuthProvider('apple.com');
let firestore = undefined;
export const getFirestore = () => __awaiter(void 0, void 0, void 0, function* () {
    yield import(/* webpackChunkName: "firebase" */ 'firebase/firestore');
    if (!firestore)
        firestore = firebase.firestore();
    return firestore;
});
export const setFirestore = (instance) => {
    firestore = instance;
};
export default firebase;
export const restName = 'firebase-mobile';
