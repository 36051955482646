import { makeStyles } from '@mui/styles';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
}));

function OnboardingV2({ children }: Props) {
  const classes = useStyles();
  return <main className={classes.root}>{children}</main>;
}

export default OnboardingV2;
