export const isPremiumCaptions = (status, avaId) => {
  return (
    !!status &&
    !!(
      (status.audioStreams &&
        status.audioStreams.findIndex((elem) => elem.usePaidASR && elem.avaId === avaId) !== -1) ||
      (status.asr && status.asr.googleCloud)
    )
  );
};

export const isUnlimitedPaidASR = (subscription, features: { 'google-cloud-asr'?: boolean }) => {
  const ongoingSubscription = subscription?.ongoingSubscription;
  const fromFlag = features['google-cloud-asr'];
  const fromSubscription =
    !!ongoingSubscription &&
    ongoingSubscription !== 'Free' &&
    ongoingSubscription !== 'Freedom' &&
    subscription?.ongoingSubscriptionSubtype !== 'Lite';
  return fromFlag || fromSubscription;
};

export const isHost = (status, avaId) => !!status && !!status.host && status.host.avaId === avaId;

export const getPlanType = (subscription) => {
  const { ongoingSubscription, ongoingSubscriptionSubtype } = subscription;
  const subscriptionLabel = `${getValueOrEmptyString(ongoingSubscription)} ${getValueOrEmptyString(
    ongoingSubscriptionSubtype
  )}`;

  if (subscriptionLabel.includes('Freedom')) {
    return subscriptionLabel.replace('Freedom', 'Community').trim();
  } else if (subscriptionLabel.endsWith('Lite')) {
    return 'Free';
  }
  return subscriptionLabel.trim();
};

export const isBoostEnabled = (subscription) => {
  if (!subscription) {
    return false;
  }

  const { ongoingSubscription, ongoingSubscriptionSubtype } = subscription;

  return ongoingSubscription && ongoingSubscription !== 'Free' && ongoingSubscriptionSubtype !== 'Lite';
};

const getValueOrEmptyString = (value) => value || '';
