import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import config from '../../../electron/package.json';
import { createNewConversation, startConversationReadClient } from '../../actions/conversation';
import { getAnalyticsInstance } from '../../firebase';
import { selectConfigEnableCCV2, selectConfigEnableConvoV2, selectIsTextToSpeechV2 } from '../../selectors/auth';
import { selectUserRoleInConversation, UserRoleInConversation } from '../../selectors/combined';
import { selectJoinConversationManager } from '../../selectors/conversation';
import { selectScribeInURL } from '../../selectors/scribe';
import { selectFeatures } from '../../selectors/userProfile';
import { conversations, users as avaUsers } from '../../services/api/ava';
import { tauriEventEmit, tauriEventListen, tauriInvoke } from '../../services/desktopIntegration';
import { getBrowserName, getBrowserVersion, getOS, getOSVersion } from '../../utils';
import { clearSearchValue, getSearchValue, setSearchValue } from '../../utils/http';
import * as segment from '../../utils/segment';
import { startStopwatch, stopAndTrack } from '../../utils/stopwatch';
import { getSingletonWebRTCManager, recreateSingletonWebRTCManager } from '../../utils/webrtc';
import {
  getDefaultRoomId,
  isDefaultRoomId,
  respondPingMessage,
  sendPing,
  sendWebRTCTrackMetadataMessage,
  sendWsMessage,
} from '../../utils/ws-v1';
import { type AppDispatch, type RootState } from '../store';
import { maybeRestartRecording, stopRecording } from './audioV2';
import { createAvaTranslateManager } from './avaTranslate';
import { createBoostManager } from './boost';
import { createJoinConversationManager, setCreateConversationWhenReady } from './conversation';
import { createRecallAIManager } from './recallAI';
import { createTextToSpeechManager, v1FetchVoices } from './textToSpeech';
import { setLoading } from './uiState';
import { fetchUserProfile } from './userProfile';

const WS_URL_TTL_MS = 25000;

export type WebsocketStatus =
  | 'uninitialized'
  | 'pending'
  | 'online'
  | 'reconnecting'
  | 'disconnected'
  | 'closed'
  | 'offline';

export type BackendDict = {
  [key: string]: { name: string; url: string; color?: string };
};

const PING_INTERVAL = 5000;
const PING_TIMEOUT = 3000;
const SOCKET_STATUS_INTERVAL = 1000;
const RECONNECTOR_CHECK_INTERVAL = 1000;
const RECONNECTOR_COOLDOWN_MS = 5000;

const LATEST_SOCKET_MESSAGES: any[] = [];
const LATEST_SOCKET_MESSAGES_SIZE = 50;

export type State = {
  // This might be equivalent to inTwilioCalls
  conferenceCallRequested: boolean;

  // wsURL is only valid for 30 seconds. If we want to use it after more than 25 seconds
  // pass we will proactively attempt to refresh it first.
  v1WebSocketURL?: string;
  v1URLTimestamp: number;
  v1Socket?: WebSocket;
  v1WebsocketStatus: WebsocketStatus;
  v1ReconnectionURL?: string;
  v1Token?: string;

  backends: BackendDict;
  displayDev: boolean;
};

const INITIAL_STATE: State = {
  conferenceCallRequested: false,
  v1Socket: undefined,
  v1WebsocketStatus: 'uninitialized',
  v1URLTimestamp: 0,
  v1WebSocketURL: undefined,
  v1ReconnectionURL: undefined,
  v1Token: getSearchValue(window, 'token', undefined),

  backends: {
    production: {
      name: 'production',
      url: 'https://api-production.ava.me',
      color: 'seagreen',
    },
    stage: {
      name: 'stage',
      url: 'https://api-stage.ava.me',
      color: 'steelblue',
    },
    local: {
      name: 'local',
      url: 'http://localhost:3000',
      color: 'saddlebrown',
    },
  },

  displayDev: window.location.hostname === 'localhost',
};

const v1SessionSlice = createSlice({
  name: 'v1SessionSlice',
  initialState: INITIAL_STATE,
  reducers: {
    closeV1Connection(state) {
      // Maybe set websocket status to 'closed'?
      Object.assign(state, INITIAL_STATE);
    },
    setConferenceCallRequested(state, { payload }) {
      state.conferenceCallRequested = payload;
    },
    setV1WebsocketStatus(state, { payload }: { payload: WebsocketStatus }) {
      state.v1WebsocketStatus = payload;
    },
    setV1WebsocketURL(state, { payload }) {
      state.v1WebSocketURL = payload;
      state.v1URLTimestamp = Date.now();
    },
    setV1ReconnectionURL(state, { payload }) {
      state.v1ReconnectionURL = payload;
    },
    setV1Socket(state, { payload }) {
      state.v1Socket = payload;
    },
    setV1Token(state, { payload }) {
      if (payload) {
        setSearchValue(window, 'token', payload);
      } else {
        clearSearchValue(window, 'token');
      }
      state.v1Token = payload;
    },
    enableDevMode(state) {
      state.displayDev = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncBackends.fulfilled, (state, action) => {
        Object.assign(
          state.backends,
          Object.fromEntries(
            action.payload.map((backend) => [
              backend.name,
              {
                ...backend,
                color: `#${backend.color}`,
              },
            ])
          )
        );
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.displayDev = state.displayDev || action.payload?.user?.features['dev-mode'];
      });
  },
});

export const {
  setV1ReconnectionURL,
  closeV1Connection,
  setConferenceCallRequested,
  setV1WebsocketStatus,
  setV1WebsocketURL,
  enableDevMode,
  setV1Token,
} = v1SessionSlice.actions;
const { setV1Socket } = v1SessionSlice.actions;

export const v1SessionReducer = v1SessionSlice.reducer;

const createNewSocket = (url): Promise<WebSocket> =>
  new Promise((resolve, reject) => {
    const ws = new WebSocket(url);
    ws.addEventListener(
      'open',
      () => {
        resolve(ws);
      },
      { once: true }
    );
    ws.addEventListener(
      'error',
      (error) => {
        reject(new Error('Websocket error', { cause: error }));
      },
      { once: true }
    );
    ws.addEventListener(
      'close',
      () => {
        reject(new Error('Websocket error', { cause: 'socket closed' }));
      },
      { once: true }
    );
  });

export const fetchAsyncBackends = createAsyncThunk('v1Session/fetchAsyncBackends', async () => {
  const { endpoints } = await (
    await fetch('https://5ebpkaja6a.execute-api.us-west-2.amazonaws.com/default/get-endpoints')
  ).json();
  return endpoints;
});

export const createV1Socket = createAsyncThunk('v1Session/createV1Socket', async (_, { getState, dispatch }) => {
  /*
  This is only used for performance investigations. Uncommenting this
  gives you the ability to very quickly generate large transcripts.
  window.generateTranscripts = (n: number) => {
    for (const i of Array(n).keys()) {
      scribeCreateTranscript(undefined, 'dummy', avaId)(window.store.dispatch, window.store.getState);
    }
  };
   */
  startStopwatch('awaitConnectionSucceeded');
  prepareReconnector(getState as () => RootState, dispatch as AppDispatch);
  const state = getState() as RootState;
  const uid = state.auth.firebaseUser?.uid;
  const avaId = state.userProfile.parse?.avaId;
  const v1Token = state.v1Session.v1Token;
  const v1WebsocketStatus = state.v1Session.v1WebsocketStatus;
  const oldWs = state.v1Session.v1Socket;
  const userRoleIsScribe = selectUserRoleInConversation(state) === UserRoleInConversation.SCRIBE;
  const roomIdInStorage = sessionStorage.getItem(v1Token || '');
  const scribeInURL = selectScribeInURL(state);
  const scribeInSessionStorage = sessionStorage.getItem('lastScribedConversation');
  const features = selectFeatures(state);
  const desktopV2InFirebase = selectConfigEnableCCV2(state);
  const desktopV2 = features['desktop-v2'] !== false && desktopV2InFirebase;
  const convoV2InFirebase = Boolean(selectConfigEnableConvoV2(state));
  const convoV2 = features['convo-v2'] !== false && convoV2InFirebase;

  const beginAsScribe = Boolean(
    userRoleIsScribe ||
      scribeInURL ||
      (scribeInSessionStorage && roomIdInStorage && scribeInSessionStorage === roomIdInStorage)
  );

  const isTextToSpeechV2 = selectIsTextToSpeechV2(state);

  if (v1WebsocketStatus === 'pending' || v1WebsocketStatus === 'reconnecting') {
    // NOTE: It's very important for there to be no awaits between begining of
    // this function and setting the status to 'pending' or 'reconnecting'.
    // Otherwise we can have a few sockets open at the same time.
    return;
  }

  if (oldWs && oldWs.readyState === WebSocket.OPEN) {
    console.log('Closing websocket because a new one is being created');
    oldWs.close();
  }

  const status = state.scribeConversation.status;

  // Migrated code used this, but now that we have types, we know this is never defined?
  // const branch = state.scribeSession.branch;

  if (!uid || !avaId) {
    throw new Error('User not logged in');
  }
  const userName = state.userProfile.parse?.userName;
  let url = state.v1Session.v1ReconnectionURL;

  let ws;
  try {
    if (url) {
      dispatch(setV1WebsocketStatus('reconnecting'));
    } else {
      dispatch(setV1WebsocketStatus('pending'));
      if (Date.now() - state.v1Session.v1URLTimestamp < WS_URL_TTL_MS) {
        url = state.v1Session.v1WebSocketURL;
      }
    }
    if (!url) {
      ({ wsUrl: url } = (await dispatch(fetchUserProfile()).unwrap()) || {});
    }
    if (!url) {
      throw new Error('Failed to get wsUrl');
    }
    // Consuming the Websocket URL after it has been used. They are one-time
    // use only.
    dispatch(setV1WebsocketURL(''));
    dispatch(setV1ReconnectionURL(''));

    ws = await createNewSocket(url);
    dispatch(setV1Socket(ws));
    await prepareV1Socket(ws, avaId, uid, beginAsScribe, dispatch as AppDispatch, isTextToSpeechV2);
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        category: 'v1Socket',
      },
    });
    dispatch(setV1WebsocketStatus('disconnected'));
    return;
  }
  startConversationReadClient(ws, dispatch as AppDispatch, getState as () => RootState);

  ws.addEventListener('message', (message) => {
    const data = JSON.parse(message.data);
    LATEST_SOCKET_MESSAGES.push({
      time: Date.now(),
      ...data,
    });
    if (LATEST_SOCKET_MESSAGES.length > LATEST_SOCKET_MESSAGES_SIZE) {
      LATEST_SOCKET_MESSAGES.shift();
    }
  });

  const roomId = await getRoomIdFromTokenOrStatus(v1Token, status, userRoleIsScribe || scribeInURL);

  await sendConnectionParams(ws, avaId, roomId || getDefaultRoomId(avaId), beginAsScribe, userName || '', {
    desktopV2,
    convoV2,
  });

  // Apparently the V1 socket (at the time of writing this comment) expects
  // <- connection-succeeded
  // -> connection-params
  // <- room-status
  // -> <other messages from the client>
  // If you send any other message before room-status (including responding to
  // ping) the whole socket will die.

  startStopwatch('awaitRoomStatus');
  await roomStatusReceived(ws);
  stopAndTrack('awaitRoomStatus');

  preparePing(ws);

  const newState = getState() as RootState;
  const joinConversationManager = selectJoinConversationManager(newState);
  if (joinConversationManager?.shouldJoinOnInit()) {
    console.log('joining conversation on init');
    startStopwatch('joinConversation');
    joinConversationManager?.joinConversationOnInit();
  } else if (newState.conversation.createConversationWhenReady) {
    console.log('creating new conversation');
    startStopwatch('startConversation');
    await createNewConversation()(dispatch, getState);
  } else if (!roomId) {
    dispatch(
      setLoading({
        isLoading: false,
      })
    );
  }
  if (ws.readyState === WebSocket.OPEN) {
    // Sometimes the WebSocket might die somewhere between being created and us
    // getting all the way here. That's why it's important to check its state.
    dispatch(setV1WebsocketStatus('online'));
  }
  dispatch(maybeRestartRecording());
  await dispatch(setCreateConversationWhenReady(false));
  return;
});

const roomStatusReceived = (ws: WebSocket) => {
  return new Promise((resolve) => {
    const listener = (message) => {
      const data = JSON.parse(message.data);
      if (data.type === 'room-status') {
        console.log('room-status received, resolving');
        resolve(true);
        ws.removeEventListener('message', listener);
      } else {
        console.log('invalid V1 second message, expected room-status', data);
        segment.track('Web - Login Error - Regular Profile', { reason: 'not room-status: ' + data.type });
        // Sentry.captureMessage('invalid V1 second message, expected room-status', {
        //   extra: {
        //     message: data,
        //   },
        //   tags: {
        //     category: 'v1Socket',
        //   },
        // });
      }
    };
    ws.addEventListener('message', listener);
  });
};

let lastReconnectAttempt = 0;
const prepareReconnector = (getState: () => RootState, dispatch: AppDispatch) => {
  const reconnectInterval = setInterval(() => {
    const state = getState();
    const status = state.v1Session.v1WebsocketStatus;
    if (status === 'disconnected' || status === 'closed' || status === 'offline') {
      if (lastReconnectAttempt === 0 || Date.now() - lastReconnectAttempt > RECONNECTOR_COOLDOWN_MS) {
        lastReconnectAttempt = Date.now();
        clearInterval(reconnectInterval);
        dispatch(createV1Socket());
      } else {
        console.log('Reconnect attempt too soon');
      }
    }
  }, RECONNECTOR_CHECK_INTERVAL);
};

// Socket returned from here will have received `connection-succeeded` message.
const prepareV1Socket = async (
  ws: WebSocket,
  avaId: string,
  uid: string,
  scribe: boolean,
  dispatch: AppDispatch,
  isTextToSpeechV2: boolean
) => {
  ws.addEventListener('error', () => {
    // Sentry.captureMessage('Websocket error', {
    //   tags: {
    //     category: 'v1Socket',
    //   },
    // });
  });
  ws.addEventListener('close', () => {
    // Sentry.captureMessage('Websocket close', {
    //   tags: {
    //     category: 'v1Socket',
    //   },
    // });
  });
  const wsCloseInterval = setInterval(() => {
    // Listening to 'close' event is not enough, because it is not fired
    // until the closing handshake comes back from the other side, which will
    // not happen if the connection is lost.
    if (ws.readyState === ws.CLOSED || ws.readyState === ws.CLOSING) {
      dispatch(setV1WebsocketStatus('disconnected'));
      dispatch(setV1Token(undefined));
      clearInterval(wsCloseInterval);
    }
  }, SOCKET_STATUS_INTERVAL);

  window.onunload = () => {
    ws.send(
      JSON.stringify({
        type: 'connection-params-update',
        roomId: getDefaultRoomId(avaId),
      })
    );
  };

  // Awaiting connection-succeeded message
  await new Promise((resolve, reject) => {
    ws.addEventListener(
      'message',
      (message) => {
        const data = JSON.parse(message.data);
        if (data.type !== 'connection-succeeded') {
          // Sentry.captureMessage('invalid V1 first message, expected connection-succeeeded', {
          //   extra: {
          //     message: data,
          //   },
          //   tags: {
          //     category: 'v1Socket',
          //   },
          // });
          segment.track('Web - Login Error - Regular Profile', { reason: 'invalid first message: ' + data.type });
          reject(new Error('invalid V1 first message, expected connection-succeeeded'));
        }
        dispatch(setV1ReconnectionURL(data.reconnectionWsUrl));
        dispatch({
          type: 'CONNECTION_SUCCEEDED',
          status: data.hash,
        });
        if (window.electronIPC) {
          window.electronIPC.sendDoneLoading();
        }
        resolve(true);
      },
      { once: true }
    );
  });
  stopAndTrack('awaitConnectionSucceeded');

  window.__TAURI__ ? await prepareWebRTCForTauri(ws, dispatch) : prepareWebRTC(ws, dispatch);
  dispatch(createRecallAIManager({ ws, dispatch }));
  dispatch(createJoinConversationManager());
  dispatch(createTextToSpeechManager());
  dispatch(createBoostManager());
  dispatch(createAvaTranslateManager());
  if (scribe) prepareScribe(ws);

  if (!isTextToSpeechV2) dispatch(v1FetchVoices());

  if (window.electronIPC) {
    window.electronIPC.sendSetIsLoggedIn(!!uid);
  }
};

const prepareScribe = (ws: WebSocket) => {
  ws.addEventListener('message', (message) => {
    const data = JSON.parse(message.data);
    if (data.type === 'room-status' && data.id && !isDefaultRoomId(data.id)) {
      sendWsMessage(ws, {
        type: 'connection-params-update',
        roomId: data.id,
        downAudio: {
          speakerId: data.audioStreams[0]?.avaId,
          trackId: data.audioStreams[0]?.trackId,
        },
        role: 'scribe',
      });
    }
  });
};

const prepareWebRTCForTauri = async (ws: WebSocket, dispatch: AppDispatch) => {
  ws.addEventListener('message', (message) => {
    const data = JSON.parse(message.data);
    if (data.type === 'webrtc') {
      tauriEventEmit('webrtc-w2t', data);
    }
  });

  dispatch(stopRecording());
  await tauriInvoke('close_webrtc_connection');
  const unlistens = [
    await tauriEventListen('webrtc-t2w', (event: any) => {
      sendWsMessage(ws, {
        type: 'webrtc',
        offer: event.payload?.offer || undefined,
        answer: event.payload?.answer || undefined,
        iceCandidate: event.payload?.iceCandidate || undefined,
      });
    }),
    await tauriEventListen('webrtc-metadata', (event: any) => {
      sendWebRTCTrackMetadataMessage(ws, {
        streamId: event.payload.streamId,
        name: event.payload.name,
        isInternal: event.payload.isInternal,
      });
    }),
    await tauriEventListen('webrtc-connection-state-change', (event) => {
      // @ts-ignore
      const state = event.payload.toLowerCase();
      if (state === 'closed') {
        console.log('closing websocket because webrtc peer-connection is closed');
        ws.close();
      }
    }),
  ];

  ws.addEventListener('close', () => {
    unlistens.forEach((x) => x());
  });
};

const prepareWebRTC = (ws: WebSocket, dispatch: AppDispatch) => {
  // Lifetime of the RTC connection is tied to the WS lifetime. It will be freed
  // when the socket reconnects, or when the whole app closes. We do not close
  // the webRTC connection when the socket closes, so that the audio has chance to
  // flow even when the socket is unstable.
  recreateSingletonWebRTCManager(dispatch);

  ws.addEventListener('message', (message) => {
    const data = JSON.parse(message.data);
    if (data.type === 'webrtc') {
      // WebRTCManager can handle messages even before it is initialized.
      getSingletonWebRTCManager()?.handleV1Message(data);
    }
  });

  getSingletonWebRTCManager()?.initialize((message) => {
    sendWsMessage(ws, {
      type: 'webrtc',
      ...message,
    });
  });
};

const preparePing = (ws: WebSocket) => {
  const ping = setInterval(() => {
    sendPing(ws);

    // Closing the WebSocket after 3 seconds of no response.
    const wsCloseTimeout = setTimeout(() => {
      Sentry.captureMessage('Websocket ping timeout', {
        tags: {
          category: 'v1Socket',
        },
      });
      console.log('closing websocket because of ping timeout');
      ws.close();
      clearPing();
    }, PING_TIMEOUT);

    ws.addEventListener('message', (message) => {
      const data = JSON.parse(message.data);
      if (data.type === 'pong') {
        clearTimeout(wsCloseTimeout);
      }
    });
  }, PING_INTERVAL);

  ws.addEventListener('message', (message) => {
    const data = JSON.parse(message.data);
    if (data.type === 'PING') {
      // This seems benign, yet if we send this before we send connection-params,
      // the backend will die.
      respondPingMessage(ws, data);
    }
  });

  const clearPing = () => {
    clearInterval(ping);
  };

  window.onunload = clearPing;
  ws.addEventListener('close', clearPing);
};

const getRoomIdFromTokenOrStatus = async (token: any, status: any, isScribe: boolean) => {
  let roomId = status ? status.id : undefined;
  if (token && !roomId) {
    const roomIdInSession = sessionStorage.getItem(token);
    if (roomIdInSession) {
      roomId = roomIdInSession;
    } else {
      try {
        const res = await conversations.getRoomId({ token });
        roomId = res.data.roomId;
        sessionStorage.setItem(token, roomId);
        if (isScribe) sessionStorage.setItem('lastScribedConversation', roomId);
      } catch (err: any) {
        Sentry.captureException(err);
      }
    }
  }
  return roomId;
};

const sendConnectionParams = (
  ws: WebSocket,
  avaId: string,
  roomId: string,
  scribe: boolean,
  userName: string,
  clients: { desktopV2: boolean; convoV2: boolean }
) => {
  let type: string;
  if (window.__TAURI__ || window.isElectron) {
    if (clients.desktopV2) {
      type = 'desktop-v2';
    } else {
      type = 'desktop';
    }
  } else {
    if (clients.convoV2) {
      type = 'web-v2';
    } else {
      type = 'web';
    }
  }
  const appVersion = window.__TAURI__ ? 'tauri-' + window.isElectron.version : config.version;
  const system = getOS();
  const operationSystem = getOSVersion() || 'Unknown';
  const browserName = getBrowserName();
  const browserVersion = getBrowserVersion() || 'Unknown';

  const client = {
    type,
    appVersion,
    system,
    os: operationSystem,
    device: 'Unknown',
    browserName,
    browserVersion,
  };

  return ws.send(
    JSON.stringify({
      type: 'connection-params',
      speakerId: avaId,
      roomId: roomId,
      username: userName,
      client,
      downAudio: scribe
        ? {
            host: true,
          }
        : undefined,
      conversationMode: 'public',
      role: scribe ? 'scribe' : 'participant',
    })
  );
};

const getRoomIdFromBranch = async (branch: any, avaId: string, uid: string) => {
  // TODO: I think this is never triggered, but maybe it is?
  if (branch && (branch.roomId || branch.convoChannel)) {
    // This is an invite, let's track it
    getAnalyticsInstance().logEvent('app_downloaded', {
      referrer_ava_id: branch.avaName,
      invite_channel: branch['~channel'],
      invite_campaign: branch['~campaign'],
    });
    segment.track('Invites Triggered', {
      'Invite Link': branch['~referring_link'],
      'Inviter Ava Name': branch.avaName,
    });
    segment.identify({
      'Invite Channel': branch['~channel'],
      'Invite Campaign': branch['~campaign'],
    });
    const metricsToUpdate = {
      'Total Invites Triggered Count': 1,
    } as const;

    await avaUsers.updateInviteMetrics({
      avaId,
      firebaseAuthUID: uid,
      inviteMetrics: metricsToUpdate,
    });
    return branch.roomId || branch.convoChannel;
  }
  return undefined;
};

export function logLatestSocketMessagesToSentry() {
  for (const message of LATEST_SOCKET_MESSAGES) {
    if (message?.mutation?.data?.text) {
      delete message.mutation.data.text;
    }

    Sentry.addBreadcrumb({
      category: 'v1Socket',
      data: message,
    });
  }
}
