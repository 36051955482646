var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { clearScribeConversationState } from '../actions/conversation';
import { logout } from '../store/slices/auth';
import { toggleSideBar } from '../store/slices/uiState';
import { deleteLocalAndSessionStorageAndCookies } from './cache';
import * as segment from './segment';
export var HEARING_PROFILES;
(function (HEARING_PROFILES) {
    HEARING_PROFILES["DEAF"] = "deaf";
    HEARING_PROFILES["HARD_OF_HEARING"] = "hard-of-hearing";
    HEARING_PROFILES["HEARING"] = "hearing";
})(HEARING_PROFILES || (HEARING_PROFILES = {}));
export var VOICING_PROFILES;
(function (VOICING_PROFILES) {
    VOICING_PROFILES["NOT_VOICING"] = "not voicing";
    VOICING_PROFILES["VOICING"] = "voicing";
})(VOICING_PROFILES || (VOICING_PROFILES = {}));
export const HEARING_PROFILES_OPTIONS = {
    // $FlowFixMe
    0: 'hearingProfile.deaf',
    // $FlowFixMe
    1: 'hearingProfile.hardOfHearing',
    // $FlowFixMe
    2: 'hearingProfile.hearing',
};
export const isUserNameValid = (userName) => {
    return userName.trim().length >= 2 && userName.trim().length < 50;
};
export const getUserColor = (username) => {
    if (!username) {
        return 'grey';
    }
    let hash = 0;
    username.split('').forEach((c) => {
        // eslint-disable-next-line
        hash = c.charCodeAt(0) + ((hash << 5) - hash);
    });
    const h = hash % 360;
    // eslint-disable-next-line
    return 'hsl(' + h + ', ' + 50 + '%, ' + 50 + '%)';
};
export const isDevMode = (features, backends) => {
    return !!(features === null || features === void 0 ? void 0 : features['dev-mode']) || localStorage.getItem('avaBackendUrl') === backends.production.url;
};
const unregisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (const registration of registrations) {
                registration.unregister();
            }
        });
    }
};
export const logoutAndClean = () => __awaiter(void 0, void 0, void 0, function* () {
    segment.track('Signed Out');
    segment.reset();
    deleteLocalAndSessionStorageAndCookies();
    unregisterServiceWorker();
    window.store.dispatch(clearScribeConversationState());
    window.store.dispatch(toggleSideBar(false));
    yield logout(window.store.dispatch);
    //@ts-ignore
    window.location = '/';
    if (window.electronIPC) {
        window.electronIPC.sendSetIsLoggedIn(false);
        window.electronIPC.sendCCMode('expand');
    }
});
if (window.electronIPC) {
    window.electronIPC.onSignOutAndShowSignIn(() => __awaiter(void 0, void 0, void 0, function* () {
        yield logoutAndClean();
        //@ts-ignore
        if (window.navigate) {
            //@ts-ignore
            window.navigate('/web/login');
        }
        else {
            window.history.pushState({}, document.title, '/web/login');
            window.location.reload();
        }
    }));
}
