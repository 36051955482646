import type { Selector } from 'reselect';
import { createSelector } from 'reselect';

import type { State } from '../reducers/legacyConversationReducer';
import type { RootState } from '../store/store';
import type {
  ConversationMode,
  Participant,
  Scribe,
  ScribeStatus,
  ScribeStatusMessage,
  Speaker,
  Transcript,
} from '../types';
import { binarySearchArray, compareIntegerStrings } from '../utils/binarySearch';
import { isDefaultRoomId } from '../utils/ws-v1';
import type { TranscriptSelector } from './transcriptSelectors';
import { selectAvaId, selectFeatures } from './userProfile';

const selectScribeConversation = (state: RootState) => state.scribeConversation;

// DO NOT USE. Gets updated 10 times a second sometimes. Use sub-selectors.
export const selectStatus = createSelector(
  [selectScribeConversation],
  (scribeConversation: State) => scribeConversation.status
);

export const selectHostAvaId = createSelector([selectStatus], (status) => status?.host?.avaId);

export const selectHasHostEnableSpeakerId = createSelector([selectStatus], (status) => {
  const hostFlags = status?.host?.flags;
  return hostFlags?.['mono-segmentation'] && hostFlags?.['nats'];
});

const selectStatusPermissions = createSelector([selectStatus], (status) => status?.permissions);
export const selectPermissionsTranslation = createSelector([selectStatusPermissions], (permissions) =>
  Boolean(permissions?.translation?.value)
);

export const selectStatusScribe: Selector<RootState, ScribeStatusMessage | undefined> = createSelector(
  [selectStatus],
  (status) => status?.scribe
);

// Since it's an array of objects we shouldn't rely on it being memoized properly,
// use when necessary but it can lead to performance issues
export const selectScribes: Selector<RootState, Scribe[] | undefined> = createSelector(
  [selectStatusScribe],
  (status) => status?.scribes
);
export const selectStatusOfScribes: Selector<RootState, ScribeStatus | undefined> = createSelector(
  [selectStatusScribe],
  (scribeStatus) => scribeStatus?.status
);
export const selectScribesHashes = createSelector([selectScribes], (scribes) => scribes?.map((scribe) => scribe.hash));
export const selectHasScribe = createSelector([selectStatusOfScribes], (scribeStatus) => scribeStatus === 'ongoing');
export const selectPendingScribe = createSelector(
  [selectStatusOfScribes],
  (scribeStatus) => scribeStatus === 'pending'
);
export const selectScribeLeftOrLeaving = createSelector(
  [selectStatusOfScribes],
  (scribeStatus) => scribeStatus === 'ended' || scribeStatus === 'leaving'
);
export const selectScribeRequestor = createSelector([selectStatusScribe], (statusScribe) => statusScribe?.requestor);

//TODO type this better with types from schema
export const selectStatusAudioStreams: Selector<RootState, Array<any>> = createSelector(
  [selectStatus],
  (status) => status?.audioStreams
);
export const selectStatusAsr: Selector<RootState, any> = createSelector([selectStatus], (status) => status?.asr);
export const selectStatusRoomId: Selector<RootState, string | undefined> = createSelector(
  [selectStatus],
  (status) => status?.id
);
export const selectStatusHostAvaId = createSelector([selectStatus], (status) => status?.host?.avaId);
export const selectStatusHostAvaName = createSelector([selectStatus], (status) => status?.host?.avaName);
export const selectIsDefaultRoomId = createSelector(
  [selectStatusRoomId],
  (roomId) => roomId && isDefaultRoomId(roomId)
);
export const selectStatusConversationMode = createSelector(
  [selectStatus],
  (status) => status?.conversationMode as ConversationMode
);
export const selectStatusTitle = createSelector([selectStatus], (status) => status?.title);
export const selectStatusLinksRo = createSelector([selectStatus], (status) => status?.links?.ro);
export const selectStatusPast = createSelector([selectStatus], (status) => status?.past);
export const selectStatusReason = createSelector([selectStatus], (status) => status?.reason);
export const selectStatusStateValue = createSelector([selectStatus], (status) => status?.state?.value);
export const selectStatusRestrictionsConvoWarningCountdownTimerMs = createSelector(
  [selectStatus],
  (status) => status?.restrictions?.convoWarningCountdownTimerMs
);
export const selectTwilioCalls = createSelector([selectStatus], (status) => status?.twilioCalls);
export const selectStatusBoost = createSelector([selectStatus], (status) => status?.boost);

// This is similar to status.participants, but:
// * It is a map, not an array
// * It can contain virtual participants (e.g. 'recall-ai')
// * It is enhanced with solo-dia information in the reducer
export const selectSpeakersMap = createSelector(
  [selectScribeConversation],
  (scribeConversation: State) => scribeConversation.speakers
);

export const selectSpeakersMapLength = createSelector([selectSpeakersMap], (speakers) => Object.keys(speakers).length);

/**
 * Basically, speakers and transcriptOwners refer the same map, this map is Map<AvaId, api.Speaker> (transcriptOwnerMap)
 * the map is being used internally in the backend codebase, and we don’t expose it directly in room-status.
 * speakers is an array and the value is the values of the map above.
 * transcriptOwners is a list of string and the value is the keys of the map above.
 */
export const selectStatusTranscriptOwners = createSelector([selectStatus], (status) => status?.transcriptOwners);

// solo-dia speakers follow this pattern 'speaker_1', 'speaker_2', etc.
export const selectSoloDiaSpeakers = createSelector([selectSpeakersMap], (speakers) => {
  if (!speakers) return [];

  return Object.values(speakers);
});

// This is similar to speakers, but:
// * It is an array
// * It contains only real participants (no virtual participants)
// * It is exactly as returned by the backend (duplicate participants?)
export const selectParticipants = createSelector(
  [selectScribeConversation],
  (scribeConversation: State) => scribeConversation.status?.participants || []
);

export const selectParticipantsLength = createSelector([selectParticipants], (participants) => participants.length);
export const selectTranscriptOwnersLength = createSelector(
  [selectStatus],
  (status) => status?.transcriptOwners?.length || 0
);

export const selectParticipantsPresent = createSelector([selectParticipants], (participants) => !!participants);

//TODO Remove filtering for scribe once backend removes backwards compatability
// This is the best of both worlds for selectParticipants and selectSpeakers:
// * It is an array
// * It contains only real participants (no virtual participants)
// * It is enhanced with solo-dia information in the reducer
// * It does not contain duplicates
export const selectUniqueParticipants = createSelector(
  [selectParticipants, selectSpeakersMap],
  (participants, speakers) => {
    const unique = [...new Set<string>(participants.map((p: Speaker) => p.avaId))].map(
      (avaId: string) => speakers[avaId]
    );

    const filtered = unique.filter((participants) => participants !== undefined);
    return filtered;
  }
);

export const selectUnionSpeakersAndParticipants = createSelector(
  [selectParticipants, selectSpeakersMap],
  (participants, speakersMap) => {
    const uniqueAvaIds = new Set(participants.map((p) => p.avaId));

    Object.values(speakersMap).forEach((speaker) => {
      uniqueAvaIds.add(speaker.avaId);
    });

    const unionParticipantsAndSpeakers: Array<Speaker | Participant> = Array.from(uniqueAvaIds).map((avaId) => {
      const participant = participants.find((p) => p.avaId === avaId);
      if (participant) return participant;

      return Array.from(Object.values(speakersMap)).find((s) => s.avaId === avaId);
    });

    return unionParticipantsAndSpeakers;
  }
);

export const selectTranscripts = createSelector(
  [selectScribeConversation],
  (scribeConversation) => scribeConversation.transcripts
);

export const selectTranscriptsCurrent = createSelector(
  [selectScribeConversation],
  (scribeConversation) => scribeConversation.transcriptsCurrent
);

export const selectTranscriptsFinal = createSelector(
  [selectScribeConversation],
  (scribeConversation) => scribeConversation.transcriptsFinal
);

export const selectTotalTranscriptCount = createSelector(
  [selectTranscriptsCurrent, selectTranscriptsFinal],
  (transcriptsCurrent, transcriptsFinal) => transcriptsCurrent.length + transcriptsFinal.length
);

export const selectHaveTranscripts = createSelector(
  [selectTotalTranscriptCount],
  (totalTranscriptCount) => totalTranscriptCount > 0
);

export const selectLang = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.lang);

export const selectSpeechLang = createSelector(
  [selectScribeConversation],
  (scribeConversation) => scribeConversation.speechLang
);

export const selectAllTranscriptIds = createSelector(
  [selectTranscriptsCurrent, selectTranscriptsFinal],
  (current, final) => final.concat(current)
);

export const selectAllTranscripts = createSelector(
  [selectTranscripts, selectAllTranscriptIds],
  (transcripts, allTranscriptsIds) => allTranscriptsIds.map((transcriptId: string) => transcripts[transcriptId])
);
export const selectTranscript = createSelector<
  [typeof selectTranscripts, Selector<RootState, string, [transcriptId: string]>],
  Transcript
>(
  [selectTranscripts, (state: RootState, transcriptId: string) => transcriptId],
  (transcripts, transcriptId) => transcripts[transcriptId]
);

export const selectLastTranscriptId = createSelector(
  [selectAllTranscriptIds],
  (transcripts) => transcripts[transcripts.length - 1]
);

// This selector is O(log N) in the worst case, doing a binary search over all transcripts
export const selectNeighborTranscriptAuthors = createSelector<
  [
    typeof selectTranscripts,
    typeof selectTranscriptsCurrent,
    typeof selectTranscriptsFinal,
    Selector<RootState, string, [transcriptId: string]>
  ],
  { previousTranscriptAuthor: string | undefined; nextTranscriptAuthor: string | undefined }
>(
  [
    selectTranscripts,
    selectTranscriptsCurrent,
    selectTranscriptsFinal,
    (state: RootState, transcriptId: string) => transcriptId,
  ],
  (transcripts, transcriptsCurrent, transcriptsFinal, transcriptId) => {
    const allTranscriptIds = [...transcriptsFinal, ...transcriptsCurrent];
    const transcriptIndex = binarySearchArray(allTranscriptIds, transcriptId, compareIntegerStrings);
    if (transcriptIndex === -1) {
      return { previousTranscriptAuthor: undefined, nextTranscriptAuthor: undefined };
    }
    const previousTranscriptId = allTranscriptIds[transcriptIndex - 1];
    const nextTranscriptId = allTranscriptIds[transcriptIndex + 1];
    return {
      previousTranscriptAuthor: previousTranscriptId && transcripts[previousTranscriptId]?.author,
      nextTranscriptAuthor: nextTranscriptId && transcripts[nextTranscriptId]?.author,
    };
  }
);

export const selectHighLightedById = createSelector(
  [selectTranscripts, (state: RootState, transcriptId: string) => transcriptId],
  (transcripts, transcriptId) => Boolean(transcripts?.[transcriptId]?.highlighted)
);

export const selectAsrFinalById: TranscriptSelector<boolean> = createSelector(
  [selectTranscripts, (state: RootState, transcriptId: string) => transcriptId],
  (transcripts, transcriptId) => Boolean(transcripts?.[transcriptId]?.['asr.final'])
);

export const selectTranscriptAuthor: TranscriptSelector<string> = createSelector(
  [selectTranscript],
  (transcript) => transcript.author
);

export const selectFurthestObservedCursors = createSelector(
  [selectScribeConversation],
  (scribeConversation) => scribeConversation.furthestObservedCursors
);

export const selectIsHost = createSelector([selectStatus, selectAvaId], (status, avaId) => {
  // we check status.host.avaName for DevExp purposes
  // since when changing to other servers (eg PENTEST)
  // the first status.host.avaId is a default host value
  // that's unrelated to the actual host or any participants
  if (status && status.host && status.host.avaId && status.host.avaName && avaId) {
    return avaId === status.host.avaId;
  } else return false;
});

export const selectSoloDiaSpeakersWhoSpoke = createSelector(
  [selectScribeConversation, selectSoloDiaSpeakers],
  (scribeConversation, soloDiaSpeakers) =>
    soloDiaSpeakers.filter((speaker) => scribeConversation.speakersWhoSpoke.includes(speaker.avaId))
);

export const selectSoloDiaSpeakerMeExists = createSelector([selectSoloDiaSpeakers], (soloDiaSpeakers) =>
  soloDiaSpeakers.find((speaker) => speaker.myself)
);

export const selectSpeaker = createSelector(
  [selectSpeakersMap, selectTranscriptAuthor, selectAvaId, selectFeatures],
  (speakers, author, avaId, features) => {
    if (!avaId) return undefined;
    const currentUser = speakers[avaId];
    // Who the transcript is related
    const currentSpeaker = speakers[author];

    let speaker = currentUser;

    // When a scribe/user splits a transcript the transcript.author is briefly 'Callee'
    // before it is corrected. We need to handle this brief moment so it doesn't crash the app.
    if (currentSpeaker && (!currentSpeaker.isSoloDiaSpeakerCurrentUser || features['mono-segmentation'])) {
      // If is Solo-Dia and is marked as myself = true then use currentUser speaker object.
      speaker = currentSpeaker;
    }
    return speaker;
  }
);
