import type { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect } from 'react';

import { selectJoinConversationManager } from '../../selectors/conversation';
import { logout } from '../../store/slices/auth';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
import * as segment from '../../utils/segment';
import animationData from './loader.json';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    zIndex: Z_INDICES.loading,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    background: theme.palette.ava.transparent.black[theme.palette.mode],
  },
  loader: {
    width: 150,
    height: 150,
  },
}));

interface Props {
  className?: string;
  children?: any;
}

export default function Loading({ className, children }: Props) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const joinConversationManager = useAppSelector(selectJoinConversationManager);
  const loadingReason = useAppSelector((state) => state.ui.loadingReason);

  useEffect(() => {
    const container = document.getElementById('lottie-animation');
    // react-lottie is a thing, but it weighs a lot, and we already load lottie in index.html
    // why we use animationData https://github.com/airbnb/lottie-web/issues/1925#issuecomment-1190650241
    //@ts-ignore
    if (container && window.lottie) {
      //@ts-ignore
      const animation = window.lottie.loadAnimation({
        container,
        renderer: 'svg',
        animationData,
        autoplay: true,
        loop: true,
      });
      return () => {
        animation.destroy();
      };
    }
  }, []);

  useEffect(() => {
    // on login users are supposed to be redirected and this loader renders while that happens
    // this is a failsafe to prevent an infinite loader in the case that the redirect fails or never issues for whatever reason
    const logoutTimeout = setTimeout(() => {
      const failedReason = loadingReason ? `${loadingReason} timeout` : 'unknown reason timeout';

      segment.track('Loading Screen Timed Out', { loadingTimeoutReason: failedReason });

      /**
       * Since "join_by_qrcode_token" and "ava_request" will be parsed in index.html as the very beginning of the app,
       * and they will be reset when "joinConversationManager" is initialized.
       * Therefore, this way can promise that we can submit "AppLess Handled" event successfully,
       * regardless of where the app is stuck.
       */
      const isJoinByQRCode =
        joinConversationManager?.isJoinedByQRCode ?? sessionStorage.getItem('join_by_qrcode_token') ?? false;
      const qrCodeAvaName = joinConversationManager?.qrCodeAvaName ?? localStorage.getItem('ava_request') ?? undefined;

      if (isJoinByQRCode) {
        segment.track('AppLess Handled', {
          isSuccessful: false,
          qrCodeAvaName,
          failedReason,
        });
      }

      logout(dispatch);
    }, 60000);

    return () => {
      clearTimeout(logoutTimeout);
    };
  }, [loadingReason, joinConversationManager]);

  return (
    <div className={classnames(classes.root, className)}>
      {children}
      <div className={classes.wrapper}>
        <div className={classes.loader} id="lottie-animation"></div>
      </div>
    </div>
  );
}
