import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import type { CustomContentProps } from 'notistack';
import { SnackbarContent } from 'notistack';
import React, { useCallback } from 'react';

import { ConvoV2CloseIcon } from '../convoV2/ConvoV2CloseIcon';
import { ConvoV2NotificationContent } from '../convoV2/ConvoV2NotificationContent';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    padding: 0,
  },
}));

export type ConvoV2NotificationProps = {
  icon?: React.ReactNode;
  isDesktop: boolean | undefined;
};
type Props = CustomContentProps & ConvoV2NotificationProps;

const ConvoV2Notification = React.forwardRef<HTMLDivElement, Props>(
  ({ icon, message, closeSnackbar, id, isDesktop }, ref) => {
    const classes = useStyles();

    const handleClick = useCallback(() => {
      if (closeSnackbar && id) {
        return closeSnackbar(id as string);
      }
    }, [closeSnackbar, id]);

    return (
      <SnackbarContent className={classes.wrapper} ref={ref}>
        <ConvoV2NotificationContent desktop={!!isDesktop}>
          {icon}
          {message}
          <IconButton className={classes.button} onClick={handleClick}>
            <ConvoV2CloseIcon desktop={true} />
          </IconButton>
        </ConvoV2NotificationContent>
      </SnackbarContent>
    );
  }
);

export default ConvoV2Notification;
