import type { Selector } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';

import type { State } from '../store/slices/boost';
import type { RootState } from '../store/store';
import type { BoostManager } from '../utils/boost';

const selectBoost = (state: RootState): State => state.boost;

export const selectIsBoosting: Selector<RootState, boolean> = createSelector(
  [selectBoost],
  (boost) => boost.isBoosting
);

export const selectBoostWords: Selector<RootState, string[]> = createSelector(
  [selectBoost],
  (boost) => boost.boostWords
);

export const selectAddBoostWordsStatus = createSelector([selectBoost], (boost) =>
  boost.addBoostWordsLoading
    ? 'pending'
    : boost.boostModifyError
    ? 'rejected'
    : boost.addBoostWordsSuccess
    ? 'fulfilled'
    : ''
);

export const selectBoostManager: Selector<RootState, BoostManager | undefined> = createSelector(
  [selectBoost],
  (boost) => boost.boostManager
);
