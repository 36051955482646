/* eslint-disable @typescript-eslint/no-empty-interface */
import type { PaletteMode } from '@mui/material';
import type { Theme } from '@mui/material';

import type { IOrganization } from './modules/account/models/organization';

declare global {
  interface Window {
    __TAURI__: any;
    isElectron: any;
    electronIPC: any;
    electronApp: any;
    electron: any;
    electronCurrentWindow: any;
    store: any;
    desktopIntegration: any;
    utm_parameters: {
      [key: string]: string;
    };
    analytics: {
      track: (...args: any) => void;
      identify: (...args: any) => void;
      reset: (...args: any) => void;
    };
  }
  interface Navigator {
    userLanguage: string | undefined;
  }
}

/**
declare module '@mui/material/Tooltip' {
  export interface TooltipProps {
    title: NonNullable<React.ReactNode> | string;
  }
}

declare module '@mui/material/styles/createPalette' {
  export interface TypeBackground {
    dark: string;
    light: string;
    default: string;
  }
  export type PaletteType = string;
  export interface Palette {
    contrast: {
      dark: string;
      light: string;
    };
    border: {
      dark: string;
      light: string;
    };
    // @ts-ignore
    divider: {
      dark: string;
      light: string;
    };
    // @ts-ignore
    text: {
      dark: {
        primary: string;
        secondary: string;
      };
      light: {
        primary: string;
        secondary: string;
      };
    };
    red: {
      dark: string;
      light: string;
    };
    ava: {
      accent: string;
      blue: string;
      blue1: string;
      blue2: string;
      blue4: string;
      blue5: string;
      dark1: string;
      dark2: string;
      dark4: string;
      dark5: string;
      darkBlue: string;
      darkBg1: string;
      darkBg2: string;
      deepBlue: string;
      gradientBlue: string;
      grey: string;
      grey4: string;
      grey5: string;
      grey6: string;
      green: string;
      green2: string;
      navy: string;
      navy2: string;
      navy5: string;
      offBlue1: string;
      red2: string;
      red3: string;
      red4: string;
      red5: string;
      scribe1: string;
      scribe2: string;
      selfColorBlue: string;
      selfColorBlueDark: string;
      white: string;
      whiteGradient: string;
      transparent: {
        green: {
          light: string;
          dark: string;
        };
      };
      icon: {
        dark1: string;
        darkBg: string;
      };
      text: {
        dark1: string;
        dark2: string;
        grey: string;
      };
      text1: string;
      states: {
        online: string;
      };
      navyGroup: {
        convoText: string;
        convoTextDark: string;
      };
    };
    ccMode: {
      conversationButtonsBg: string;
      backgroundGradient: string;
    };
  }
}
 */

export type ScribeTranscriptDeleteMutation = {
  type: 'delete';
  parent: string;
  id: string;
  data: {
    index: number;
    length: number;
    lang: string;
    /* Deprecated? */
    mutatorHash?: string;
  };
};

export type ScribeTranscriptUpdateFieldMutation = {
  type: 'updateField';
  parent: string;
  id: string;
  data: {
    field: string;
    value: string | boolean | number;
    /* Deprecated? */
    mutatorHash?: string;
  };
};

export type ScribeTranscriptInsertMutation = {
  type: 'insert';
  parent: string;
  id: string;
  data: {
    index: number;
    text: string;
    lang: string;
    /* Deprecated? */
    mutatorHash?: string;
  };
};

export type ScribeTranscriptChangeSpeakerMutation = {
  type: 'changeSpeaker';
  parent: string;
  id: string;
  data: {
    speakerId: string;
    /* Deprecated? */
    mutatorHash?: string;
  };
};

export type ScribeTranscriptCursorMutation = {
  type: 'cursor';
  parent: string;
  id: string;
  data: {
    index: number;
    mutatorHash?: string;
  };
};

/**
 * We won't refer this type in the codebase, but it's here for reference.
 */
export interface Mutation {
  type: 'delete' | 'insert' | 'changeSpeaker' | 'cursor' | 'updateField';
  data: {
    /**
     * index of the first character concerned by the mutation.
     */
    index?: number;
    /**
     * length of the mutation (only for delete mutation).
     */
    length?: number;
    /**
     * text of the mutation (only for insert mutation).
     */
    text?: string;
    /**
     * deprecated - use updateField mutations. Speaker id for the current text.
     */
    speakerId?: string;
    /**
     * deprecated - use updateField mutations. Hash from the mutator.
     */
    mutatorHash?: string;
    /**
     * The field to update.
     */
    field?: string;
    /**
     * The value of the field to update
     */
    value?: string | boolean | number;
    /**
     * For insert / delete mutation, the lang of the text to update. Default (deprecated) is the target language of the transcript. 2 letter code as in https://cloud.google.com/translate/docs/languages.
     */
    lang?: string;
  };
  /**
   * Id of the mutation.
   */
  id: string;
  /**
   * Id of the previous mutation.
   */
  parent: string;
}

export interface TranscriptEdit {
  type: 'transcript-edit';
  data: {
    /**
     * Id of the transcript.
     */
    transcriptId: string;
    /**
     * A mutation object
     */
    mutation: Mutation;
    /**
     * The origin of the mutation.
     */
    origin?: 'asr' | 'human';
    /**
     * For debug or final transcripts only - value of the transcript when the diff was created
     * Will be deprecated, TBD.
     */
    text?: string;
    /**
     * Will be deprecated since the Backend doesn't use it.
     */
    isFinal?: boolean;
    fromScribe?: boolean;
  };
}

export type ConversationMode = 'public' | 'public-muted';

export type Cursor = {
  mutatorHash: string;
  index: number;
};

export type ScribeTranscriptMutation =
  | ScribeTranscriptDeleteMutation
  | ScribeTranscriptInsertMutation
  | ScribeTranscriptChangeSpeakerMutation
  | ScribeTranscriptCursorMutation
  | ScribeTranscriptUpdateFieldMutation;

export type SpeakerTheme = {
  speaker_color: string;
  text_color: string;
  highlight_color: string;
  background_color: string;
};

export type Speaker = {
  // This is provided, but should not be used for anything
  color: string;
  // This is provided, but should not be used for anything
  colorBackground?: string;
  avaId: string;
  avaName: string;
  userName: string;
  userAvatar?: string;
  // Some parts of the code imply this exists, but I have never seen it.
  photoUrl?: string;
  //TODO need to remove id from speaker since it's no longer being given from the server
  id: string;
  theme?: {
    // This should be used to show the color of the speaker
    dark_theme?: SpeakerTheme;
    // This should be used to show the color of the speaker
    light_theme?: SpeakerTheme;
    // scale is also here, but I do not know what it is used for.
    // scale?: any;
  };
  // this decides whether it's You or not
  myself: boolean;
  isSoloDiaSpeaker: boolean;
  isSoloDiaSpeakerCurrentUser?: boolean;
};

export type ClientApplicationType = 'android' | 'web' | 'ios' | 'desktop' | 'recall-ai';

export type PlanType = 'free' | 'premium' | 'starter' | 'welcome' | 'pro' | 'welcome_pro' | 'event' | 'enterprise';

export type Participant = {
  avaId?: string;
  avaName?: string;
  userName?: string;
  photoUrl?: string;
  hash?: string;
  channel: ClientApplicationType;
  theme?: {
    // This should be used to show the color of the speaker
    dark_theme?: SpeakerTheme;
    // This should be used to show the color of the speaker
    light_theme?: SpeakerTheme;
    // scale is also here, but I do not know what it is used for.
    // scale?: any;
  };
  flags?: {
    [name: string]: boolean;
  };
  planType?: PlanType;
  isTyping?: boolean;
};

export type ScribeCaptioner = {
  id: string;
  username: string;
  name: string;
  email?: string;
  userPermissions?: Array<string>;
  avaId?: string;
  firebaseAuthUID: string;
  profile?: object;
  organization?: IOrganization | null;
};

export type Transcript = {
  texts: { [key: string]: string };
  mutationsQueuedByHumanMutation?: Array<ScribeTranscriptMutation>;
  mutations?: Array<ScribeTranscriptMutation>;
  indexes?: object;
  origin?: string;
  isFinal?: boolean;
  author: string;
  authorShow?: boolean;
  id: string;
  t0: number;
  cursors: Array<Cursor>;
  speechLang: string;
  timestampMs: number;
  trackName: string;
  highlighted: boolean;
  'asr.final': boolean;
  usePaidASR: boolean;
};

export type TranscriptMap = {
  [id: string]: Transcript;
} & {
  lang?: string;
};

export type FeatureMap = {
  [featureName: string]: boolean;
};

export type Subscription = {
  consumedCredit: number;
  extraCredit: number;
  id: string;
  lastPaymentDate: string;
  ongoingSubscription: string;
  ongoingSubscriptionEndDate: string;
  ongoingSubscriptionSubtype: string;
  remainingCredit: number;
  subscriptionFrom: string;
  subscriptionId?: string;
};

interface Colors {
  primary: {
    main: string;
    dark: string;
    light: string;
  };
  secondary: {
    main: string;
    dark: string;
    light: string;
  };
  v2: {
    mainBackground: { light: string; dark: string };
    controlBarBackground: { light: string; dark: string };
    modalBackground: { light: string; dark: string };
    toastBackground: { light: string; dark: string };
  };
  contrast: {
    dark: string;
    light: string;
  };
  red: {
    dark: string;
    light: string;
  };
  background: {
    dark: string;
    default: string;
    light: string;
  };
  border: {
    dark: string;
    light: string;
  };
  text: {
    dark: {
      primary: string;
      secondary: string;
    };
    light: {
      primary: string;
      secondary: string;
    };
  };
}

interface Ava {
  accent: string;
  blue: string;
  blue1: string;
  blue2: string;
  blue4: string;
  blue5: string;
  dark1: string;
  dark2: string;
  dark4: string;
  dark5: string;
  darkBlue: string;
  darkBg1: string;
  darkBg2: string;
  deepBlue: string;
  gradientBlue: string;
  asrGrey: string;
  asrGrey2: string;
  grey: string;
  grey2: string;
  grey3: string;
  grey4: string;
  grey5: string;
  grey6: string;
  grey7: string;
  grey8: string;
  green: string;
  green2: string;
  navy: string;
  navy2: string;
  navy5: string;
  offBlue1: string;
  orange6: string;
  red2: string;
  red3: string;
  red4: string;
  red5: string;
  scribe1: string;
  scribe2: string;
  scribe6: string;
  selfColorBlue: string;
  selfColorBlueDark: string;
  white: string;
  whiteGradient: string;
  quality: {
    free: string;
    basic: string;
    premium: string;
    scribe: string;
  };
  transparent: {
    green: {
      light: string;
      dark: string;
    };
    black: {
      light: string;
    };
    blue: {
      light: string;
      dark: string;
    };
    blueHover: {
      light: string;
      dark: string;
    };
  };
  icon: {
    dark1: string;
    darkBg: string;
  };
  text: {
    dark1: string;
    dark2: string;
    grey: string;
  };
  text1: string;
  states: {
    online: string;
  };
  navyGroup: {
    convoText: string;
    convoTextDark: string;
  };
}

type ThemeType = 'dark' | 'light';

interface TextColors {
  primary: string;
  secondary: string;
}

export interface ThemeObject {
  palette: {
    mode: PaletteMode;
    primary: Colors['primary'];
    secondary: Colors['secondary'];
    background: Colors['background'];
    contrast: Colors['contrast'];
    border: Colors['border'][ThemeType];
    divider: Colors['border'][ThemeType];
    v2: Colors['v2'];
    text: {
      dark: TextColors;
      light: TextColors;
    };
    red: Colors['red'][ThemeType];
    bold: string;
    ava: Ava;
    ccMode: {
      conversationButtonsBg: string;
      backgroundGradient: string;
    };
  };
  typography: {
    useNextVariants: boolean;
    fontFamily: string;
  };
  breakpoints: Theme['breakpoints'];
  components: Theme['components'];
}

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      error: any;
      success: any;
      grey: any;
      mode: PaletteMode;
      primary: Colors['primary'];
      secondary: Colors['secondary'];
      background: Colors['background'];
      contrast: Colors['contrast'];
      v2: Colors['v2'];
      border: Colors['border'][ThemeType];
      divider: Colors['border'][ThemeType];
      text: TextColors;
      red: Colors['red'][ThemeType];
      bold: string;
      ava: Ava;
      ccMode: {
        conversationButtonsBg: string;
        backgroundGradient: string;
      };
    };
    typography: {
      useNextVariants: boolean;
      fontFamily: string;
    };
    breakpoints: {
      values: {
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
      };
      down: any;
      up: any;
      between: any;
    };
    //@ts-ignore
    components: Theme['components'];
  }
  interface ThemeOptions extends ThemeObject {}
}

declare module 'notistack' {
  interface VariantOverrides {
    warning: true;
    success: true;
    soloDia: true;
    convoV2: true;
    error: true;
    default: true;
    info: true;
  }
  interface OptionsObject {
    closeSnackbar?: (key: string) => void;
    color?: string;
  }
}

export type ScribeStatus = 'pending' | 'ongoing' | 'leaving' | 'ended';
export type Scribe = {
  avaId?: string;
  avaName?: string;
  userName?: string;
  // connection hash
  hash: string;
};
export type ScribeStatusMessage = {
  status: ScribeStatus;
  requestor?: string;
  // true if scribe.status has already been ongoing
  hasBeenScribed?: boolean;
  scribes?: Scribe[];
};

export enum ScribeCorrectionMode {
  Unset = '',
  Fast = 'fast',
  Accurate = 'accurate',
}
