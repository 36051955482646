var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { users } from './api/ava';
export const getConversationCloseReason = ({ currentUserAvaId, endedByCurrentUser, endedDueToConvoRestrictedSession, host, isClosingApp, }) => {
    let reason = '';
    if (isClosingApp) {
        reason = 'app_terminated';
    }
    else if (endedDueToConvoRestrictedSession) {
        reason = 'max_convo_time_reached';
    }
    else if (endedByCurrentUser && (host === null || host === void 0 ? void 0 : host.avaId) !== currentUserAvaId) {
        reason = 'closed';
    }
    else if (!endedByCurrentUser && (host === null || host === void 0 ? void 0 : host.avaId) === currentUserAvaId) {
        reason = 'last_guest_left';
    }
    else {
        reason = 'host_left';
    }
    return reason;
};
export const getConversationTimeInSeconds = (startTimestampMs) => {
    return Math.floor((Date.now() - startTimestampMs) / 1000);
};
const getConversationTotalWords = ({ wordsCapturedGroup, wordsTypedGroup, }) => {
    return wordsTypedGroup + wordsCapturedGroup;
};
export const getMetricsToUpdate = ({ conversationTimeInSeconds, isSoloConvo, transcriptSaved, transcriptStats, }) => {
    const isGroupConvo = !isSoloConvo;
    const conversationTotalWords = getConversationTotalWords(transcriptStats);
    const metricsToUpdate = {
        'Total Convo Time': conversationTimeInSeconds,
        'Total Saved Transcripts': transcriptSaved ? 1 : 0,
        'Total Solo Time': isSoloConvo ? conversationTimeInSeconds : 0,
        'Total Group Time': isGroupConvo ? conversationTimeInSeconds : 0,
        'Total Conversations': 1,
        'Total Convo Words': conversationTotalWords,
        'Total Solo Words': isSoloConvo ? conversationTotalWords : 0,
        'Total Group Words': isGroupConvo ? conversationTotalWords : 0,
        'Total Solo Conversations Web': isSoloConvo ? 1 : 0,
        'Total Group Conversations Web': isGroupConvo ? 1 : 0,
    };
    if (isSoloConvo) {
        metricsToUpdate.NB_SOLO_CONVO = 1;
    }
    else {
        metricsToUpdate.NB_GROUP_CONVO = 1;
    }
    return metricsToUpdate;
};
export const isFirstConvo = (currentMetrics) => {
    return !currentMetrics || (!currentMetrics.NB_SOLO_CONVO && !currentMetrics.NB_GROUP_CONVO);
};
export const isHost = ({ host, avaId }) => {
    return host && host.avaId === avaId;
};
export const isScribeRequester = ({ scribe, avaId }) => {
    return scribe && scribe.requestor === avaId;
};
export const updateConversationMetrics = ({ metricsToUpdate, avaId, firebaseAuthUID, }) => __awaiter(void 0, void 0, void 0, function* () {
    yield users.updateMetrics({
        avaId: avaId || '',
        firebaseAuthUID: firebaseAuthUID,
        convoMetrics: metricsToUpdate,
    });
});
export const getRatingMetricsToUpdate = ({ rate }) => {
    const metricsToUpdate = {};
    metricsToUpdate[`Rated_${rate || 0}`] = 1;
    return metricsToUpdate;
};
