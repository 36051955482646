import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store/store';

const selectQuickSetup = (state: RootState) => state.quickSetup;

export const selectShowQuickSetup = createSelector([selectQuickSetup], (quickSetup) => quickSetup.showQuickSetup);
export const selectUrlToOpenAfterConvoStarted = createSelector(
  [selectQuickSetup],
  (quickSetup) => quickSetup.urlToOpenAfterStartingConvo
);
