import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { sleep } from '../../utils';
import type { AppDispatch, RootState } from '../store';

export type TtsErrors = 'unableToHear' | '' | 'unsupportedLanguage';
import availableVoices from '../../../assets/ttsVoices.json';
import { tts } from '../../services/api/ava';
import * as segment from '../../utils/segment';
import type { Participant } from '../../utils/textToSpeech';
import { TextToSpeechManager } from '../../utils/textToSpeech';

export type TtsGender = 'female' | 'male' | 'neutral';

export type TtsVoice = {
  id: string;
  name: string;
  voiceProviderId: string;
  urlAudio?: string;
  gender: TtsGender;
  provider?: 'openai' | 'google' | 'elevenlabs';
  tones: string[];
  selected?: boolean;
  availableInUserLanguage?: boolean;
};

export type TtSVoiceEntry = Map<string, TtsVoice>;

export type TextToSpeechState = {
  ttsVoices: TtSVoiceEntry;
  ttsError: TtsErrors;
  ttsGender?: TtsGender;
  isUsingTts: boolean;
  textToSpeechManager?: TextToSpeechManager;
  userTyping?: Participant;
  displayedItemsCount: number;
  currentSelectedVoice?: TtsVoice;
  isTtsLoading: boolean;
  v1Voices: Array<SpeechSynthesisVoice>;
  v1TtsBestVoice?: SpeechSynthesisVoice;
};

const getInitialState = (): TextToSpeechState => {
  return {
    ttsVoices: new Map<string, TtsVoice>(),
    ttsError: '',
    ttsGender: undefined,
    isUsingTts: true,
    displayedItemsCount: 2,
    isTtsLoading: false,
    v1Voices: [],
  };
};

export const textToSpeechSlice = createSlice({
  name: 'textToSpeech',
  initialState: getInitialState,
  reducers: {
    setV1Voices(state, { payload }: PayloadAction<Array<SpeechSynthesisVoice>>) {
      state.v1Voices = payload;
    },
    setTtsVoices(state, { payload }: PayloadAction<TtSVoiceEntry>) {
      state.ttsVoices = payload;
    },
    setTtsError(state, { payload }: PayloadAction<TtsErrors>) {
      state.ttsError = payload;
    },
    setIsUsingTts(state, { payload }: PayloadAction<boolean>) {
      state.isUsingTts = payload;
    },
    setUserTyping(state, { payload }: PayloadAction<Participant | undefined>) {
      state.userTyping = payload;
    },
    setDisplayedItemsCount(state, { payload }: PayloadAction<number>) {
      state.displayedItemsCount = payload;
    },
    setTtsVoice(state, { payload }: PayloadAction<TtsVoice>) {
      state.currentSelectedVoice = payload;
      state.ttsGender = payload.gender;
    },
    setTtsVoicesFromJson(state, { payload }: PayloadAction<TtSVoiceEntry>) {
      state.ttsVoices = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(v1FindBestTTSVoice.fulfilled, (state, { payload }: PayloadAction<SpeechSynthesisVoice>) => {
      state.v1TtsBestVoice = payload;
    });
    builder.addCase(
      createTextToSpeechManager.fulfilled,
      (state, { payload }: PayloadAction<TextToSpeechManager | undefined>) => {
        if (payload) state.textToSpeechManager = payload;
      }
    );
    builder.addCase(fetchTtsVoices.pending, (state) => {
      state.isTtsLoading = true;
    });
    builder.addCase(fetchTtsVoices.rejected, (state) => {
      state.isTtsLoading = false;
    });
    builder.addCase(fetchTtsVoices.fulfilled, (state, { payload }: PayloadAction<TtSVoiceEntry>) => {
      state.ttsVoices = payload;
      state.isTtsLoading = false;
    });
    builder.addCase(fetchGenderPreference.fulfilled, (state, { payload }: PayloadAction<TtsGender | undefined>) => {
      state.ttsGender = payload;
    });
    builder.addCase(setTtsGenderThunk.fulfilled, (state, { payload }: PayloadAction<TtsGender | undefined>) => {
      state.ttsGender = payload;
    });
    builder.addCase(setTtsGenderThunk.rejected, (state) => {
      state.ttsGender = undefined;
    });
  },
});

export const textToSpeechReducer = textToSpeechSlice.reducer;
export const {
  setTtsError,
  setTtsVoices,
  setIsUsingTts,
  setUserTyping,
  setDisplayedItemsCount,
  setTtsVoice,
  setTtsVoicesFromJson,
  setV1Voices,
} = textToSpeechSlice.actions;

export const v1FetchVoices = () => (dispatch: AppDispatch) => {
  if (window.speechSynthesis) {
    const populateVoiceList = () => {
      const newVoices = window.speechSynthesis.getVoices();
      if (newVoices) {
        dispatch(setV1Voices(newVoices));
      }
    };

    populateVoiceList();

    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = populateVoiceList;
    }
  }
};

export const v1FindBestTTSVoice = createAsyncThunk('textToSpeech/v1FindBestTTS', (_, { getState }) => {
  const state = getState() as RootState;
  const { speechLang } = state.scribeConversation;
  const { v1Voices } = state.textToSpeech;
  let voice;
  if (window.speechSynthesis && v1Voices.length) {
    let candidateVoices = v1Voices.filter((v) => v.lang === speechLang);
    if (!candidateVoices.length) {
      const pattern = /-|_/;
      const shortLang = speechLang.split(pattern)[0];
      candidateVoices = v1Voices.filter((v) => v.lang.split(pattern)[0] === shortLang);
    }
    if (candidateVoices.length === 1) [voice] = candidateVoices;
    if (candidateVoices.length > 1) {
      [voice] = candidateVoices;
    }
  }
  return voice;
});

export const setErrorAndReset = createAsyncThunk(
  'textToSpeech/setErrorAndReset',
  async (error: TtsErrors, thunkAPI) => {
    thunkAPI.dispatch(setTtsError(error));
    await sleep(2000);
    thunkAPI.dispatch(setTtsError(''));
  }
);

export const fetchTtsVoices = createAsyncThunk('textToSpeech/fetchTtsVoices', async (language: string, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;

    const firebaseAuthUID = state.auth.firebaseUser?.uid ?? '';
    const lang = language.split('-')[0] ?? 'en';
    const { data: voices } = await tts.getTtsVoices({ firebaseAuthUID, lang });
    const ttsVoices = new Map<string, TtsVoice>();
    voices.forEach((value) => {
      const voice: TtsVoice = {
        name: value.name,
        gender: value.gender,
        provider: value.provider,
        tones: value.tones,
        id: `${value.provider}-${value.voiceProviderId}`,
        voiceProviderId: value.voiceProviderId,
        urlAudio: value.urlAudio,
        availableInUserLanguage: value.availableInUserLanguage,
        selected: value.selected,
      };
      ttsVoices.set(voice.voiceProviderId, voice);
      // if user selected a voice last session let them have it load up
      if (value.selected) {
        thunkAPI.dispatch(setTtsVoice(value));
      }
    });
    return ttsVoices;
  } catch (error) {
    // we want to fallback on the json file if the backend fails
    console.error('Failed to fetch TTS voices from backend, using local JSON', error);
    const ttsVoices = new Map<string, TtsVoice>();

    Object.entries(availableVoices).forEach(([key, value]) => {
      const voice: TtsVoice = {
        name: value.name,
        gender: value.gender as TtsGender,
        provider: value.provider as 'openai' | 'google' | 'elevenlabs' | undefined,
        tones: value.tones,
        id: `${value.provider}-${value.voiceProviderId}`,
        voiceProviderId: value.voiceProviderId,
      };

      ttsVoices.set(key, voice);
    });
    thunkAPI.dispatch(setTtsVoicesFromJson(ttsVoices));
    return thunkAPI.rejectWithValue(error);
  }
});

export const setTtsVoiceThunk = createAsyncThunk('texToSpeech/setTtsVoiceThunk', async (voice: TtsVoice, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const dispatch = thunkAPI.dispatch as AppDispatch;
    const firebaseAuthUID = state.auth.firebaseUser?.uid ?? '';

    segment.track('Updated Voice', {
      name: voice.voiceProviderId,
      gender: voice.gender,
      app_platform: window.isElectron ? 'desktop' : 'web',
    });

    dispatch(setTtsVoice(voice));
    dispatch(setDisplayedItemsCount(2));
    await tts.postTtsVoicePreference({ firebaseAuthUID, voiceId: voice.id });
  } catch (error) {
    console.error('failed to update selected voice on server: ', error);
  }
});

export const setTtsGenderThunk = createAsyncThunk(
  'textToSpeech/setTtsGenderThunk',
  async (gender: TtsGender, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const firebaseAuthUID = state.auth.firebaseUser?.uid ?? '';

      segment.track('Gender Selected', {
        gender,
        app_platform: window.isElectron ? 'desktop' : 'web',
      });

      await tts.postTtsGenderPreference({ firebaseAuthUID, gender });
      return gender;
    } catch (error) {
      console.error('failed to update selected gender on server: ', error);
    }
  }
);

export const fetchGenderPreference = createAsyncThunk('textToSpeech/fetchGenderPreference', async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const firebaseAuthUID = state.auth.firebaseUser?.uid ?? '';
    const genderPreference = await tts.getTtsGenderPreference({ firebaseAuthUID });
    return genderPreference.data.gender || undefined;
  } catch (error) {
    console.error('Failed to fetch TTS Gender Preference from backend, defaulting to none', error);
  }
});

export const createTextToSpeechManager = createAsyncThunk('textToSpeech/createTextToSpeechManager', (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppDispatch;
  const state = thunkAPI.getState() as RootState;

  const { v1Socket } = state.v1Session;
  if (!v1Socket) return;

  return new TextToSpeechManager(v1Socket, dispatch);
});
