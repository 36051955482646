import type { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { useMemo, useState } from 'react';

import { makeTheme } from '../utils/themeCreator';

type Props = {
  children: any;
  isUserLoggedIn?: boolean;
};

export type SiteThemeContextProps = {
  theme: 'light' | 'dark';
  bold: boolean | string;
  handleThemeChange(value?: SiteThemeContextProps['theme']): void;
  handleBoldThemeChange(): void;
};

export const SiteThemeContext = React.createContext<SiteThemeContextProps>({
  theme: 'light',
  bold: 'false',
  handleThemeChange: () => {},
  handleBoldThemeChange: () => {},
});

export const SiteThemeProvider = ({ children, isUserLoggedIn }: Props) => {
  const [theme, setTheme] = useState<PaletteMode>(
    (window.localStorage.getItem('scribe_theme') === 'dark' ? 'dark' : 'light') as PaletteMode
  );

  const [bold, setBold] = useState(window.localStorage.getItem('scribe_theme_bold') || 'false');

  const handleThemeChange: SiteThemeContextProps['handleThemeChange'] = (value) => {
    const newTheme = value || (theme === 'dark' ? 'light' : 'dark');
    window.localStorage.setItem('scribe_theme', newTheme);
    setTheme(newTheme);
  };

  const handleBoldThemeChange: SiteThemeContextProps['handleBoldThemeChange'] = () => {
    window.localStorage.setItem('scribe_theme_bold', bold === 'true' ? 'false' : 'true');
    setBold(bold === 'true' ? 'false' : 'true');
  };

  const themeMaterial = useMemo(() => {
    if (isUserLoggedIn) {
      return makeTheme(theme, bold);
    }

    // fallback theme if the user is not logged in
    return makeTheme('light', 'false');
  }, [bold, isUserLoggedIn, theme]);

  return (
    <SiteThemeContext.Provider
      value={{
        theme,
        bold,
        handleThemeChange: handleThemeChange,
        handleBoldThemeChange: handleBoldThemeChange,
      }}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeMaterial}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </SiteThemeContext.Provider>
  );
};
