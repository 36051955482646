export enum AudioErrorType {
  FAILED_TO_INIT_AUDIO_CONTEXT = 'FAILED_TO_INIT_AUDIO_CONTEXT',
  NO_MICROPHONE_SELECTED = 'NO_MICROPHONE_SELECTED',
  NO_AUDIO_TRACKS = 'NO_AUDIO_TRACKS',
  NO_WEBRTC_MANAGER = 'NO_WEBRTC_MANAGER',
  NO_AUDIO_CONTEXT = 'NO_AUDIO_CONTEXT',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  PERMISSION_DISMISSED = 'PERMISSION_DISMISSED',
  NO_AUDIO_INPUTS = 'NO_AUDIO_INPUTS',

  GENERIC = 'GENERIC',
}

export interface AudioError {
  audioErrorType: AudioErrorType;
  message?: string;
}

const isPermissionDenied = (err) => {
  const chromePermissionDenied = err.message.includes('Permission denied');
  const firefoxSafariPermissionDenied = err.message.includes('not allowed by the user agent');
  return chromePermissionDenied || firefoxSafariPermissionDenied;
};

const isPermissionDismissed = (err) => {
  return err.message.includes('Permission dismissed');
};
export const errorToAudioError = (err: any) => {
  if (isPermissionDenied(err)) {
    return {
      audioErrorType: AudioErrorType.PERMISSION_DENIED,
    };
  }
  if (isPermissionDismissed(err)) {
    return {
      audioErrorType: AudioErrorType.PERMISSION_DISMISSED,
    };
  }
  return {
    audioErrorType: AudioErrorType.GENERIC,
    message: err.message,
  };
};
