import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getSearchValue } from '../../utils/http';
import { updateDownAudio } from '../../utils/ws-v1';
import type { RootState } from '../store';

export type State = {
  speakerListened: string;
  // we need this to check the correct value for search string before it gets updated
  // this only happens when a scribe clicks on "transcribe" and it opens a new window
  scribeInURL: boolean;
};

export type V1AudioStream = {
  trackId?: string;
  avaId?: string;
};

const initialState: State = {
  speakerListened: 'host',
  scribeInURL: !!getSearchValue(window, 'scribe'),
};

export const scribeSlice = createSlice({
  name: 'scribe',
  initialState,
  reducers: {
    // UPDATE_SPEAKER_LISTENED
    setSpeakerListened(state, { payload }: PayloadAction<string>) {
      state.speakerListened = payload;
    },
  },
});

export const scribeReducer = scribeSlice.reducer;
const { setSpeakerListened } = scribeSlice.actions;

export const updateSpeakerListened = createAsyncThunk(
  'scribe/updateSpeakerListened',
  async (speakerListened: V1AudioStream, thunkAPI) => {
    const state = (await thunkAPI.getState()) as RootState;
    const dispatch = thunkAPI.dispatch;
    const ws = state.v1Session.v1Socket;
    if (!ws) return;
    await updateDownAudio(ws, speakerListened);
    const speakerIdentified = speakerListened.trackId || speakerListened.avaId;
    dispatch(setSpeakerListened(speakerIdentified || 'host'));
  }
);
