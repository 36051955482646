import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type QuickSetupState = {
  showQuickSetup: boolean;
  urlToOpenAfterStartingConvo?: string;
};

const INITIAL_STATE: QuickSetupState = {
  showQuickSetup: false,
};

const quickSetupSlice = createSlice({
  name: 'quickSetup',
  initialState: INITIAL_STATE,
  reducers: {
    setShowQuickSetup: (state, { payload }: PayloadAction<boolean>) => {
      state.showQuickSetup = payload;
    },
    setUrlToOpenAfterStartingConvo: (state, { payload }: PayloadAction<string | undefined>) => {
      state.urlToOpenAfterStartingConvo = payload;
    },
  },
});

export const quickSetupReducer = quickSetupSlice.reducer;
export const { setShowQuickSetup, setUrlToOpenAfterStartingConvo } = quickSetupSlice.actions;
