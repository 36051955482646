/* eslint-disable react-hooks/rules-of-hooks */

import deepEqual from 'deep-equal';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { createNewConversation } from '../../actions';
import { useNotifications } from '../../hooks/useNotifications';
import {
  selectAudioV2Status,
  selectOverrideAudioRestart,
  selectSelectedMicrophone,
  selectWebRTCConnectionStatus,
} from '../../selectors/audioV2';
import { selectLoggedOut } from '../../selectors/combined';
import { selectIsInConversation } from '../../selectors/conversation';
import { tauriInvoke } from '../../services/desktopIntegration';
import {
  RecordingStatus,
  setStatus,
  setVolume,
  setWebRTCConnectionStatus,
  stopRecording,
} from '../../store/slices/audioV2';
import { setCCMode } from '../../store/slices/ccMode';
import { initiateConversationEndSequence } from '../../store/slices/conversationEnd';
import type { RootState } from '../../store/store';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { isMac } from '../../utils';
import { logoutAndClean } from '../../utils/user';

export const TauriIntegration = () => {
  if (!window.__TAURI__) return null;

  const { notify } = useNotifications();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cancelRecordingTimeoutRef = useRef<undefined | ReturnType<typeof setTimeout>>(undefined);
  const isInConversation = useAppSelector(selectIsInConversation);
  const previousStateToSendRef = useRef<any>(undefined);
  const isLoggedOut = useAppSelector(selectLoggedOut);

  useEffect(() => {
    const unsubscribe = window.store.subscribe(() => {
      // Store can be as much as a few megabytes! (If it includes all transcripts)
      // Sending this back and forth, and serializing it to JSON, is not ideal.
      // We will only be sending the part that is reflect in redux_state.rs, and
      // ideally this should also be debounced;
      const state = window.store.getState();
      const stateToSend = {
        conversation: { isInConversation: state.conversation.isInConversation },
        userProfile: state.userProfile,
      };
      if (!previousStateToSendRef.current || !deepEqual(previousStateToSendRef.current, stateToSend)) {
        previousStateToSendRef.current = stateToSend;
        tauriInvoke('plugin:redux_state|update', {
          state: stateToSend,
        });
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const inner = async () => {
      if (isLoggedOut) {
        try {
          // The tauri side needs to know the user is logged out to bring them to the
          // logged-out screen and away from the main webapp.
          await tauriInvoke('logged_out');
        } catch {}
      }
    };
    inner();
  }, [isLoggedOut]);

  const useEffectWithUnsubscribe = (asyncUnsubscribe, deps) => {
    useEffect(() => {
      const promise = asyncUnsubscribe();
      return () => promise.then((unsubscribe) => unsubscribe());
    }, deps);
  };

  useEffectWithUnsubscribe(
    () =>
      window.electronIPC.onAudioAllStopped(() => {
        console.log('UseEffectWithUbsubscribe set the status to not recording.');
        dispatch(setStatus(RecordingStatus.NOT_RECORDING));
      }),
    []
  );

  useEffectWithUnsubscribe(
    () =>
      window.electronIPC.onSignOut(() => {
        logoutAndClean();
      }),
    []
  );

  useEffectWithUnsubscribe(
    () =>
      window.electronIPC.onStartConversation(() => {
        if (isInConversation) return;
        console.log('starting new conversation');
        createNewConversation()(dispatch, window.store.getState);
        dispatch(setCCMode('conversation'));
      }),
    [isInConversation, dispatch]
  );

  useEffectWithUnsubscribe(
    () =>
      window.electronIPC.onEndConversation(() => {
        if (!isInConversation) return;
        window.store.dispatch(initiateConversationEndSequence());
      }),
    [isInConversation, dispatch]
  );

  useEffectWithUnsubscribe(
    () =>
      window.electronIPC.onTauriWebRTCConnectionStateChange((payload) => {
        // @ts-ignore
        const state = payload.toLowerCase();
        dispatch(setWebRTCConnectionStatus(state));
        if (state === 'closed' || state === 'disconnected' || state === 'failed') {
          console.log('UseEffectWithUbsubscribe set the status to not recording.');
          dispatch(setStatus(RecordingStatus.NOT_RECORDING));
        }
      }),
    []
  );

  useEffectWithUnsubscribe(() => window.electronIPC.onLogEventReceived(() => {}), []);

  useEffectWithUnsubscribe(
    () =>
      window.electronIPC.onVolumeReceived((payload: number) => {
        dispatch(setVolume(payload));
        const state = window.store.getState() as RootState;
        const webRTCConnectionStatus = selectWebRTCConnectionStatus(state);
        const currentAudioStatus = selectAudioV2Status(state);
        const shouldOverrideAudioRestart = selectOverrideAudioRestart(state);
        if (
          webRTCConnectionStatus === 'connected' ||
          webRTCConnectionStatus === 'connecting' ||
          webRTCConnectionStatus === 'new'
        ) {
          // As long as we are in a healthy RTC state - we keep recording
          // or waiting for connection. 5 seconds after no audio, or
          // bad webRTC state - we stop recording.
          if (cancelRecordingTimeoutRef.current) {
            clearTimeout(cancelRecordingTimeoutRef.current);
          }
          cancelRecordingTimeoutRef.current = setTimeout(() => {
            // After 5 seconds of no audio input, we stop the recording.
            dispatch(stopRecording());
          }, 1000 * 5);
        }

        if (webRTCConnectionStatus === 'connected' && currentAudioStatus !== RecordingStatus.RECORDING) {
          if (!shouldOverrideAudioRestart) {
            dispatch(setStatus(RecordingStatus.RECORDING));
            console.log('received volume on connected webRTC - setting state to recording');
          }
        } else if (
          (webRTCConnectionStatus === 'connecting' || webRTCConnectionStatus === 'new') &&
          currentAudioStatus !== RecordingStatus.PENDING
        ) {
          console.log('received volume on disconnected webRTC - setting state to pending');
          dispatch(setStatus(RecordingStatus.PENDING));
        }
      }),
    []
  );

  useEffectWithUnsubscribe(
    () =>
      window.electronIPC.onAudioOutputChanged((payload: string) => {
        const newOutputDevice = payload;
        const state = window.store.getState() as RootState;
        if (
          selectAudioV2Status(state) === RecordingStatus.RECORDING &&
          selectSelectedMicrophone(state)?.isInternal &&
          isMac &&
          newOutputDevice !== 'Ava Speakers'
        ) {
          dispatch(stopRecording());
          notify(t('tauri.speakerChangedDuringRecording'), {
            variant: 'warning',
          });
        }
      }),
    [notify, t]
  );

  useEffectWithUnsubscribe(
    () =>
      window.electronIPC.onScreenCaptureError(() => {
        setTimeout(() => {
          dispatch(stopRecording());
        }, 250);
        notify(
          t('tauri.screenCapturePermissionMissing'),
          {
            variant: 'error',
            autoHideDuration: 10000,
          },
          {
            text: t('tauri.openSettings'),
            action: () => {
              tauriInvoke('plugin:os_specific|open_sc_settings');
            },
          }
        );
      }),
    [notify, t]
  );

  return null;
};
