import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
    showQuickSetup: false,
};
const quickSetupSlice = createSlice({
    name: 'quickSetup',
    initialState: INITIAL_STATE,
    reducers: {
        setShowQuickSetup: (state, { payload }) => {
            state.showQuickSetup = payload;
        },
        setUrlToOpenAfterStartingConvo: (state, { payload }) => {
            state.urlToOpenAfterStartingConvo = payload;
        },
    },
});
export const quickSetupReducer = quickSetupSlice.reducer;
export const { setShowQuickSetup, setUrlToOpenAfterStartingConvo } = quickSetupSlice.actions;
