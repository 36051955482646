import { createAction } from 'typesafe-actions';

import type { IUser } from '../../modules/account/models/user';
import { LOGIN, LOGOUT, UNVALIDATELOGIN, UPDATE_LANG } from './constants';

export const login = createAction(LOGIN, (action) => {
  return (user: IUser, token: string) => action({ user, token });
});
export const unvalidateLogin = createAction(UNVALIDATELOGIN);
export const rehydrate = createAction('persist/REHYDRATE');
export const logout = createAction(LOGOUT);
export const updateLang = createAction(UPDATE_LANG);
