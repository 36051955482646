import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { selectJoinConversationManager } from '../../selectors/conversation';
import { logout } from '../../store/slices/auth';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
import * as segment from '../../utils/segment';
import animationData from './loader.json';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        display: 'flex',
        flexDirection: 'column',
        zIndex: Z_INDICES.loading,
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        background: theme.palette.ava.transparent.black[theme.palette.mode],
    },
    loader: {
        width: 150,
        height: 150,
    },
}));
export default function Loading({ className, children }) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const joinConversationManager = useAppSelector(selectJoinConversationManager);
    const loadingReason = useAppSelector((state) => state.ui.loadingReason);
    useEffect(() => {
        const container = document.getElementById('lottie-animation');
        // react-lottie is a thing, but it weighs a lot, and we already load lottie in index.html
        // why we use animationData https://github.com/airbnb/lottie-web/issues/1925#issuecomment-1190650241
        //@ts-ignore
        if (container && window.lottie) {
            //@ts-ignore
            const animation = window.lottie.loadAnimation({
                container,
                renderer: 'svg',
                animationData,
                autoplay: true,
                loop: true,
            });
            return () => {
                animation.destroy();
            };
        }
    }, []);
    useEffect(() => {
        // on login users are supposed to be redirected and this loader renders while that happens
        // this is a failsafe to prevent an infinite loader in the case that the redirect fails or never issues for whatever reason
        const logoutTimeout = setTimeout(() => {
            var _a, _b, _c, _d;
            const failedReason = loadingReason ? `${loadingReason} timeout` : 'unknown reason timeout';
            segment.track('Loading Screen Timed Out', { loadingTimeoutReason: failedReason });
            /**
             * Since "join_by_qrcode_token" and "ava_request" will be parsed in index.html as the very beginning of the app,
             * and they will be reset when "joinConversationManager" is initialized.
             * Therefore, this way can promise that we can submit "AppLess Handled" event successfully,
             * regardless of where the app is stuck.
             */
            const isJoinByQRCode = (_b = (_a = joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.isJoinedByQRCode) !== null && _a !== void 0 ? _a : sessionStorage.getItem('join_by_qrcode_token')) !== null && _b !== void 0 ? _b : false;
            const qrCodeAvaName = (_d = (_c = joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.qrCodeAvaName) !== null && _c !== void 0 ? _c : localStorage.getItem('ava_request')) !== null && _d !== void 0 ? _d : undefined;
            if (isJoinByQRCode) {
                segment.track('AppLess Handled', {
                    isSuccessful: false,
                    qrCodeAvaName,
                    failedReason,
                });
            }
            logout(dispatch);
        }, 60000);
        return () => {
            clearTimeout(logoutTimeout);
        };
    }, [loadingReason, joinConversationManager]);
    return (React.createElement("div", { className: classnames(classes.root, className) },
        children,
        React.createElement("div", { className: classes.wrapper },
            React.createElement("div", { className: classes.loader, id: "lottie-animation" }))));
}
