import { createSelector } from 'reselect';
import { selectHasScribe } from './legacy-conversation';
const selectConversation = (state) => state.conversation;
export const selectEditedWords = createSelector([selectConversation], (conversation) => conversation.editedWords);
export const selectCurseFilter = createSelector([selectConversation], (conversation) => conversation.curseFilter);
export const selectJoiningStatus = createSelector([selectConversation], (conversation) => conversation.joiningStatus);
export const selectIsInConversation = createSelector([selectConversation], (conversation) => conversation.isInConversation);
export const selectConversationEnded = createSelector([selectConversation], (conversation) => conversation.conversationEnded);
export const selectInTwilioCalls = createSelector([selectConversation], (conversation) => conversation.inTwilioCalls);
export const selectSelectedCaptions = createSelector([selectConversation], (conversation) => conversation.selectedCaptions);
export const selectJoinConversationManager = createSelector([selectConversation], (conversation) => conversation.joinConversationManager);
export const selectJoinConversationError = createSelector([selectConversation], (conversation) => conversation.joinConversationError);
export const selectCaptionQualityFromBackend = createSelector([selectConversation], (conversation) => conversation.backendSelectedCaptions);
export const selectCaptionQualityFromScribe = createSelector([selectCaptionQualityFromBackend, selectSelectedCaptions, selectHasScribe], (backend, frontend, hasScribe) => backend === 'scribe' || frontend === 'scribe' ? 'scribe' : hasScribe ? 'scribe' : undefined);
export const selectCaptionQuality = createSelector([selectCaptionQualityFromBackend, selectSelectedCaptions, selectCaptionQualityFromScribe], (backend, frontend, scribe) => {
    if (scribe)
        return scribe;
    else if (backend)
        return backend;
    else if (frontend)
        return frontend;
    else
        return 'free';
});
