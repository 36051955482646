var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import uuidv4 from 'uuid/v4';
const minDelay = 0.1;
const maxDelay = 179;
class TrackPlayer {
    // This class is used to play webRTC tracks and handle delays and speed changes.
    // It starts playing the track when setTrack is called, and then can seamlessly
    // switch tracks on subsequent setTrack calls. It always plays the last track
    // passed by setTrack.
    constructor() {
        this.initialized = false;
        this.state = {};
        this.stateChangeListeners = {};
        this.currentDelay = 0.5;
        this.delayStep = 5;
        this.paused = false;
        this.oldGain = 1;
        this.chromeBugHacked = false;
        this.changeDelay = (delayIncrement) => this.setDelay(this.currentDelay + delayIncrement);
        this.decrementDelay = (decrement = this.delayStep) => this.changeDelay(-decrement);
        this.decrementTinyDelay = () => this.decrementDelay(this.delayStep / 5);
        this.incrementDelay = (increment = this.delayStep) => this.changeDelay(increment);
        this.incrementTinyDelay = () => this.incrementDelay(this.delayStep / 5);
        this.setGain = (newGain) => {
            if (!this.isReady())
                return;
            if (!this.gainNode)
                return;
            this.gainNode.gain.value = newGain;
            this.stateChanged({ gain: this.gainNode.gain.value });
        };
        this.changeGain = (gainMultiplier) => {
            if (!this.isReady())
                return;
            if (!this.gainNode)
                return;
            this.setGain(this.gainNode.gain.value * gainMultiplier);
        };
        this.decreaseGain = () => this.changeGain(0.9);
        this.increaseGain = () => this.changeGain(1.1);
        if (window.__TAURI__) {
            // We currently do not support audio on Tauri, and just creating AudioContext
            // in WebKit makes the app use additional +20% CPU.
            return;
        }
        this.state = {};
        this.stateChangeListeners = {};
        // If AudioContext isn't present, we can't play audio at all.
        if (!window.AudioContext) {
            return;
        }
        this.audioCtx = new window.AudioContext();
        this.stateChanged({ state: this.audioCtx.state });
        this.audioCtx.addEventListener('statechange', () => {
            var _a;
            this.stateChanged({ state: (_a = this.audioCtx) === null || _a === void 0 ? void 0 : _a.state });
        });
        // 3 WebAudio nodes are used:
        // MediaStreamSourceNode -> DelayNode -> GainNode -> audioCtx.destination
        // Both DelayNode and GainNode can be controlled through methods on this
        // class. Play/pause is handled by changing gain to 0 and progressively
        // pushing the delay (there is no built-in way to buffer incoming real-time
        // streams, so it needs to be buffered within DelayNode).
        this.delayNode = new DelayNode(this.audioCtx, {
            delayTime: 0.5,
            // In case delays higher than 180 seconds need to be added, the solution
            // will be to chain a few DelayNodes together.
            maxDelayTime: 179,
        });
        this.gainNode = this.audioCtx.createGain();
        this.delayNode.connect(this.gainNode);
        this.gainNode.connect(this.audioCtx.destination);
        this.currentDelay = 0.5;
        this.delayStep = 5;
        this.paused = false;
        // AudioCtx timestamp of when the delay was last updated during pause.
        // Needed mostly because setInterval triggers after "at least" its argument
        // and audioCtx time follows different clock than setInterval and Date().
        this.lastDelayUpdateAt = undefined;
        this.delayPushingInterval = undefined;
        this.oldGain = 1;
        this.chromeBugHacked = false;
        this.chromeBugAudioElement = undefined;
        this.initialized = true;
    }
    isReady() {
        if (this.initialized) {
            return true;
        }
        console.log('Cannot use TrackPlayer, AudioContext is not available.');
        return false;
    }
    hackAroundGoogleChromeBug() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.track)
                return;
            if (!this.isReady())
                return;
            // Google chrome does not 'start' a WebRTC track until it's connected to HTML5 Audio element.
            // But that element does not handle delays. The WebRTC track is fully usable within
            // WebAudio, but only after it's connected to a (potentially muted) HTML5 Audio element.
            // https://stackoverflow.com/questions/55703316
            // https://bugs.chromium.org/p/chromium/issues/detail?id=687574
            if (!this.chromeBugAudioElement) {
                this.chromeBugAudioElement = new Audio();
            }
            this.chromeBugAudioElement.srcObject = new MediaStream([this.track]);
            this.chromeBugAudioElement.muted = true;
            try {
                // According to Chrome specs and docs, autoplaying a muted track should
                // always succeed. However empirically it sometimes does not. In those cases
                // the play() method throws an error. In that case the whole routine
                // needs to be started again, but only as a response to user action (e.g. click).
                yield this.chromeBugAudioElement.play();
                this.chromeBugHacked = true;
            }
            catch (e) {
                // Failed to autoplay, we will need to play this again, on some user action.
                // No special handling is neccesary.
            }
        });
    }
    setTrack(track) {
        var _a, _b;
        if (!this.isReady())
            return;
        if (this.streamSource && this.delayNode) {
            this.streamSource.disconnect(this.delayNode);
        }
        if (this.track) {
            this.track.stop();
        }
        this.track = track;
        this.hackAroundGoogleChromeBug();
        this.streamSource = (_a = this.audioCtx) === null || _a === void 0 ? void 0 : _a.createMediaStreamSource(new MediaStream([track]));
        if (this.delayNode)
            (_b = this.streamSource) === null || _b === void 0 ? void 0 : _b.connect(this.delayNode);
        this.stateChanged({ paused: false, currentDelay: this.currentDelay });
        this.play();
    }
    setDelay(newDelay) {
        if (!this.isReady())
            return;
        if (!this.delayNode)
            return;
        let delayToSet = newDelay;
        if (delayToSet < minDelay)
            delayToSet = minDelay;
        if (delayToSet > maxDelay)
            delayToSet = maxDelay;
        this.currentDelay = delayToSet;
        this.delayNode.delayTime.value = delayToSet;
        this.stateChanged({ currentDelay: this.currentDelay });
    }
    playPause() {
        var _a;
        if (this.paused || ((_a = this.audioCtx) === null || _a === void 0 ? void 0 : _a.state) === 'suspended')
            this.play();
        else
            this.pause();
    }
    play() {
        var _a;
        if (!this.isReady())
            return;
        // AudioContext sometimes begins suspended.
        (_a = this.audioCtx) === null || _a === void 0 ? void 0 : _a.resume();
        // Chrome bug should already be hacked while setting the track, however
        // sometimes it can only be solved as a response to a user action. And play()
        // is always called as a response to a keyPress or a click.
        if (!this.chromeBugHacked)
            this.hackAroundGoogleChromeBug();
        // play-pause can't be easily implemented using live streams, so we simulate
        // it by muting the track on pause, counting the time in the 'paused' state
        // and then increasing the delay. This effectively functions as
        // play-pause, but is capped at 3 minutes.
        if (this.paused && this.oldGain) {
            this.setGain(this.oldGain);
        }
        if (this.delayPushingInterval) {
            clearInterval(this.delayPushingInterval);
            this.delayPushingInterval = undefined;
        }
        this.paused = false;
        this.stateChanged({ paused: false });
    }
    pause() {
        var _a;
        if (!this.isReady())
            return;
        this.paused = true;
        if (this.gainNode)
            this.oldGain = this.gainNode.gain.value;
        this.setGain(0);
        this.lastDelayUpdateAt = (_a = this.audioCtx) === null || _a === void 0 ? void 0 : _a.currentTime;
        this.delayPushingInterval = setInterval(() => this.pushDelayDuringPause(), 100);
        this.stateChanged({ paused: true });
    }
    pushDelayDuringPause() {
        var _a;
        if (!this.isReady())
            return;
        if (!this.audioCtx)
            return;
        if (!this.paused || !this.lastDelayUpdateAt)
            return;
        this.incrementDelay(this.audioCtx.currentTime - this.lastDelayUpdateAt);
        this.lastDelayUpdateAt = (_a = this.audioCtx) === null || _a === void 0 ? void 0 : _a.currentTime;
    }
    updateSpeed(speed) {
        // TODO: This is currently never called, so it's left unimplemented.
        this.stateChanged({ speed });
    }
    addStateChangeListener(f) {
        const uuid = uuidv4();
        this.stateChangeListeners[uuid] = f;
        f(this.state);
        return () => {
            delete this.stateChangeListeners[uuid];
        };
    }
    stateChanged(params) {
        this.state = Object.assign(Object.assign({}, this.state), params);
        for (const f of Object.values(this.stateChangeListeners)) {
            f(this.state);
        }
    }
}
// TrackPlayer can handle many subsequent tracks, and currently only one
// is needed in the app. No cleanup or recreation is necessary, that's why it's
// safe to keep it a singleton initialized during app startup.
// It also does not inherently hog any resources (apart from the fairly cheap
// audioContext, and a few nodes), so it's okay to always instantiate it.
export const singletonTrackPlayer = new TrackPlayer();
