import { createSelector } from '@reduxjs/toolkit';
const selectBoost = (state) => state.boost;
export const selectIsBoosting = createSelector([selectBoost], (boost) => boost.isBoosting);
export const selectBoostWords = createSelector([selectBoost], (boost) => boost.boostWords);
export const selectAddBoostWordsStatus = createSelector([selectBoost], (boost) => boost.addBoostWordsLoading
    ? 'pending'
    : boost.boostModifyError
        ? 'rejected'
        : boost.addBoostWordsSuccess
            ? 'fulfilled'
            : '');
export const selectBoostManager = createSelector([selectBoost], (boost) => boost.boostManager);
