var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getSaasBackendEndpoint, httpRequestWithToken } from '../../utils/http';
export const triggerOrgTrial = (avaId, firebaseAuthUID) => __awaiter(void 0, void 0, void 0, function* () {
    const channel = window.isElectron ? 'desktop' : 'web';
    return httpRequestWithToken({
        url: `${getSaasBackendEndpoint()}/api/v1/subscription/activate-organization-trial?avaId=${avaId}&firebaseAuthUID=${firebaseAuthUID}&channel=${channel}`,
        method: 'POST',
        payload: { planType: 'Pro' },
    });
});
