var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectCharWidthLookup } from '../../selectors/ccMode';
import { getCharWidthLookup } from '../../utils/splitLines';
export var CCV2ConversationViewMode;
(function (CCV2ConversationViewMode) {
    CCV2ConversationViewMode["FloatingCaptions"] = "floatingCaptions";
    CCV2ConversationViewMode["CaptionsBox"] = "captionsBox";
})(CCV2ConversationViewMode || (CCV2ConversationViewMode = {}));
const getInitialState = () => {
    return {
        ccMode: 'conversation',
        notifications: [],
        showBubbleAboveCCMode: false,
        ccV2ConversationViewMode: CCV2ConversationViewMode.CaptionsBox,
    };
};
export const ccModeSlice = createSlice({
    name: 'ccMode',
    initialState: getInitialState(),
    reducers: {
        addNotification(state, { payload }) {
            state.notifications.push(payload);
        },
        clearNotifications(state) {
            state.notifications = [];
        },
        deleteNotification(state, { payload }) {
            state.notifications.splice(payload, 1);
        },
        setCCMode(state, { payload }) {
            state.ccMode = payload;
        },
        setShowBubbleAboveCCMode(state, { payload }) {
            state.showBubbleAboveCCMode = payload;
        },
        setCCV2ConversationViewMode(state, { payload }) {
            state.ccV2ConversationViewMode = payload;
        },
        setCharWidthLookup(state, { payload }) {
            state.charWidthLookup = payload;
        },
        setRestartCCConversation(state, { payload }) {
            state.restartCCConversation = payload;
        },
    },
});
let hideBubbleTimeout;
export const setShowBubbleAboveCCModeDebounced = createAsyncThunk('ccMode/showBubbleAboveCCModeDebounced', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const dispatchImmediate = () => {
        if (hideBubbleTimeout) {
            clearTimeout(hideBubbleTimeout);
            hideBubbleTimeout = undefined;
        }
        dispatch(ccModeSlice.actions.setShowBubbleAboveCCMode(payload));
    };
    if (payload) {
        // Showing the bubbles immediately, and cancelling all pending hiding
        dispatchImmediate();
    }
    else {
        // Hiding the bubbles after 3 seconds. In certain situations on Windows
        // the bubbles hide before the user has a chance to click on them. This
        // will give the user a chance to always click them.
        // Not doing anything if we are already about to hide the cc bubbles
        if (hideBubbleTimeout)
            return;
        hideBubbleTimeout = setTimeout(dispatchImmediate, 3000);
    }
}));
export const intializeCharWidthLookupIfNeeded = createAsyncThunk('ccMode/intializeCharWidthLookupIfNeeded', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    if (selectCharWidthLookup(state))
        return;
    dispatch(setCharWidthLookup(getCharWidthLookup()));
}));
export const ccModeReducer = ccModeSlice.reducer;
export const { addNotification, clearNotifications, deleteNotification, setCCMode, setCCV2ConversationViewMode, setCharWidthLookup, setRestartCCConversation, } = ccModeSlice.actions;
