import produce from 'immer';
import { getType } from 'typesafe-actions';
import * as actions from '../../actions/account';
export const initUserState = {
    errorOrganizationAdminAlreadyExists: false,
    errorOrganizationNameAlreadyUse: false,
    errorUserLoading: false,
    errorUserLoadingMessage: '',
    loading: false,
    organizationNewLoading: false,
    organizationSearchResults: [],
    searchingForOrganizations: false,
    user: { id: '', email: '', phoneNumber: '', name: '', isIndividual: true },
};
export default (state = initUserState, action) => 
// $FlowFixMe
produce(state, (draftArg) => {
    var _a, _b, _c;
    const draft = draftArg;
    switch (action.type) {
        case getType(actions.session.login): {
            draft.loading = true;
            return draft;
        }
        case getType(actions.user.fetchUser.request): {
            draft.loading = true;
            return draft;
        }
        case getType(actions.user.fetchUser.success): {
            draft.loading = false;
            if (action && action.payload && action.payload.user) {
                draft.user.id = action.payload.user.uid;
                draft.user.email = action.payload.user.email;
                draft.user.features = action.payload.user.features;
                if (action.payload.user.parse) {
                    draft.user.parse = action.payload.user.parse;
                    draft.user.name = action.payload.user.parse.userName;
                }
            }
            return draft;
        }
        case getType(actions.user.fetchUser.failure): {
            draft.loading = false;
            draft.errorUserLoading = true;
            draft.errorUserLoadingMessage = action.payload ? action.payload.statusText : '';
            return draft;
        }
        case getType(actions.user.fetchUserInfos.success): {
            if (action.payload.organization) {
                draft.user.organization = action.payload.organization;
                if (action.payload.organization.type !== 'individual') {
                    draft.user.isIndividual = false;
                }
                if (action.payload.user.parse) {
                    draft.user.name = action.payload.user.parse.userName;
                }
            }
            return draft;
        }
        case getType(actions.user.newOrganization.request): {
            draft.organizationNewLoading = true;
            draft.errorOrganizationNameAlreadyUse = false;
            return draft;
        }
        case getType(actions.user.newOrganization.failure): {
            const error = action.payload.data && action.payload.data.error && action.payload.data.error.error
                ? action.payload.data.error.error
                : null;
            if (error && error.content && error.content.errorCode === 'nameAlreadyUse') {
                draft.errorOrganizationNameAlreadyUse = true;
            }
            else if (error && error.content && error.content.errorCode === 'adminAlreadyExists') {
                draft.errorOrganizationAdminAlreadyExists = true;
            }
            draft.organizationNewLoading = false;
            return draft;
        }
        case getType(actions.user.newOrganization.success): {
            draft.user.organization = action.payload.organization;
            if (action.payload.organization && action.payload.organization.type !== 'individual') {
                draft.user.isIndividual = false;
            }
            draft.organizationNewLoading = false;
            return draft;
        }
        case getType(actions.user.fetchOrganizations.request): {
            draft.searchingForOrganizations = true;
            return draft;
        }
        case getType(actions.user.fetchOrganizations.success): {
            // We keep 3 results, parse first
            const organizations = (((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.resultFromParse) &&
                action.payload.result.resultFromParse.slice(0, 3).map((parseResult) => ({
                    id: parseResult.objectId,
                    name: parseResult.name,
                }))) ||
                [];
            if (organizations.length < 3) {
                const googleResults = ((_c = (_b = action.payload.result) === null || _b === void 0 ? void 0 : _b.resultFromGoogle) === null || _c === void 0 ? void 0 : _c.results) &&
                    action.payload.result.resultFromGoogle.results.slice(0, 3 - organizations.length).map((googleResult) => ({
                        address: googleResult.formatted_address,
                        id: googleResult.place_id,
                        name: googleResult.name,
                    }));
                if (googleResults === null || googleResults === void 0 ? void 0 : googleResults.length) {
                    organizations.push(...googleResults);
                }
            }
            draft.organizationSearchResults = organizations;
            draft.searchingForOrganizations = false;
            return draft;
        }
        case getType(actions.user.fetchOrganizations.failure): {
            draft.searchingForOrganizations = false;
            return draft;
        }
        case getType(actions.user.clearOrganizationErrors): {
            draft.errorOrganizationAdminAlreadyExists = false;
            draft.errorOrganizationNameAlreadyUse = false;
            return draft;
        }
        case getType(actions.session.logout): {
            draft.user = initUserState.user;
            return draft;
        }
        case getType(actions.session.rehydrate): {
            draft.loading = false;
            return draft;
        }
        default:
            return draft;
    }
});
