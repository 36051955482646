import type { ParsedWord } from './transcripts';

// When clicking the buttons to increase or decrease the font size,
// the current font size has this added or subtracted from it.
export const FONT_SIZE_STEP = 1;
export const MIN_FONT_SIZE = 13;
export const MAX_FONT_SIZE = 60;

const TEXT_FONT = 'Source Sans Pro';

export const getCharWidthLookup = (): CharWidthLookup => {
  const stringified = window.localStorage.getItem('charWidth');
  if (!stringified) {
    return measureCharWidthsForAllSizes();
  } else {
    return JSON.parse(stringified);
  }
};

export type CharWidthLookup = { [fontSize: string]: { [char: string]: number } };

const measureCharWidthsForAllSizes = (font = TEXT_FONT): CharWidthLookup => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:\'",.<>/?`~é ';
  const charWidthsBySize: CharWidthLookup = {};
  const fontSizes = new Array(MAX_FONT_SIZE - MIN_FONT_SIZE + 1).fill(0).map((_, i) => (13 + i) * FONT_SIZE_STEP);

  const span = document.createElement('span');
  span.style.position = 'absolute';
  span.style.top = '-9999px';
  span.style.left = '-9999px';
  span.style.visibility = 'hidden';
  span.style.whiteSpace = 'nowrap';
  document.body.appendChild(span);

  fontSizes.forEach((size) => {
    const fontSize = `${size}px`;
    span.style.font = `${fontSize} ${font}`;
    charWidthsBySize[fontSize] = {};

    for (const char of chars) {
      span.textContent = char;
      charWidthsBySize[fontSize][char] = span.offsetWidth;
    }

    // Measure space width using difference in widths
    span.textContent = 'a a';
    const widthWithSpace = span.offsetWidth;

    span.textContent = 'aa';
    const widthWithoutSpace = span.offsetWidth;

    const spaceWidth = widthWithSpace - widthWithoutSpace;
    charWidthsBySize[fontSize][' '] = spaceWidth;
  });

  document.body.removeChild(span);

  window.localStorage.setItem('charWidth', JSON.stringify(charWidthsBySize));
  return charWidthsBySize;
};

// This function is approximating the char width, we're not shooting for 100% accuracy
// rather we look for an estimation to get a smooth UX for floating captions in Desktop
export const getWordWidth = (
  word: ParsedWord,
  charWidthMap: { [char: string]: number },
  speakerLabel?: string
): number => {
  // longest width char as fallback
  const fallbackCharWidth = charWidthMap['W'];
  const boldOffset = 1.1;
  // we're repurposing this function to calculate the width of the speaker label such that
  // we have enough padding between speaker label and text
  if (speakerLabel) {
    return Array.from(speakerLabel).reduce((width, char) => {
      const charWidth = charWidthMap[char] || fallbackCharWidth;
      return width + charWidth * boldOffset;
    }, 5);
  }
  return Array.from(word.text).reduce((width, char) => {
    const charWidth = charWidthMap[char] || fallbackCharWidth;
    return width + (word.bold ? charWidth * boldOffset : charWidth);
  }, 0);
};

export const calculateLineBreaks = (
  words: ParsedWord[],
  charWidths: CharWidthLookup,
  fontSize: string,
  maxWidth: number,
  extraPadding: number
): ParsedWord[][] => {
  const lines: ParsedWord[][] = [];
  let currentLine: ParsedWord[] = [];
  let currentWidth = 0;

  const charWidthMap = charWidths[fontSize + 'px'];
  const spaceWidth = charWidthMap[' '];

  for (const word of words) {
    const wordWidth = getWordWidth(word, charWidthMap);

    const isFirstLine = lines.length === 0;
    const extraWordOfSpace = charWidthMap['A'] * 3;
    const scrollbarWidth = 30;
    const widthMinusSubtractions = maxWidth - scrollbarWidth - extraWordOfSpace;
    const adjustedMaxWidth = isFirstLine ? widthMinusSubtractions - extraPadding : widthMinusSubtractions;

    // If adding the word exceeds the maxWidth, push the current line and start a new one
    if (currentWidth + wordWidth + (currentLine.length > 0 ? spaceWidth : 0) > adjustedMaxWidth) {
      if (currentLine.length > 0) {
        lines.push(currentLine);
        currentLine = [];
        currentWidth = 0;
      }
    }

    // Add the word to the current line
    currentLine.push(word);
    currentWidth += wordWidth + (currentLine.length > 1 ? spaceWidth : 0);
  }

  // Add the last line if it has words
  if (currentLine.length > 0) {
    lines.push(currentLine);
  }

  return lines;
};
