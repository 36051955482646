import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { selectCharWidthLookup } from '../../selectors/ccMode';
import type { CCMode, Notification } from '../../types/cc-mode';
import { type CharWidthLookup, getCharWidthLookup } from '../../utils/splitLines';
import type { RootState } from '../store';

export enum CCV2ConversationViewMode {
  FloatingCaptions = 'floatingCaptions',
  CaptionsBox = 'captionsBox',
}

export interface State {
  ccMode: CCMode | typeof undefined;
  notifications: Notification[];
  showBubbleAboveCCMode: boolean;
  charWidthLookup?: Readonly<CharWidthLookup>;
  ccV2ConversationViewMode: CCV2ConversationViewMode;
  restartCCConversation?: boolean;
}

const getInitialState = (): State => {
  return {
    ccMode: 'conversation',
    notifications: [],
    showBubbleAboveCCMode: false,
    ccV2ConversationViewMode: CCV2ConversationViewMode.CaptionsBox,
  };
};

export const ccModeSlice = createSlice({
  name: 'ccMode',
  initialState: getInitialState(),
  reducers: {
    addNotification(state, { payload }: PayloadAction<Notification>) {
      state.notifications.push(payload);
    },
    clearNotifications(state) {
      state.notifications = [];
    },
    deleteNotification(state, { payload }: PayloadAction<number>) {
      state.notifications.splice(payload, 1);
    },
    setCCMode(state, { payload }: PayloadAction<CCMode>) {
      state.ccMode = payload;
    },
    setShowBubbleAboveCCMode(state, { payload }: PayloadAction<boolean>) {
      state.showBubbleAboveCCMode = payload;
    },
    setCCV2ConversationViewMode(state, { payload }: PayloadAction<CCV2ConversationViewMode>) {
      state.ccV2ConversationViewMode = payload;
    },
    setCharWidthLookup(state, { payload }: PayloadAction<CharWidthLookup>) {
      state.charWidthLookup = payload;
    },
    setRestartCCConversation(state, { payload }: PayloadAction<boolean>) {
      state.restartCCConversation = payload;
    },
  },
});

let hideBubbleTimeout: undefined | ReturnType<typeof setTimeout>;
export const setShowBubbleAboveCCModeDebounced = createAsyncThunk(
  'ccMode/showBubbleAboveCCModeDebounced',
  async (payload: boolean, { dispatch }) => {
    const dispatchImmediate = () => {
      if (hideBubbleTimeout) {
        clearTimeout(hideBubbleTimeout);
        hideBubbleTimeout = undefined;
      }
      dispatch(ccModeSlice.actions.setShowBubbleAboveCCMode(payload));
    };
    if (payload) {
      // Showing the bubbles immediately, and cancelling all pending hiding
      dispatchImmediate();
    } else {
      // Hiding the bubbles after 3 seconds. In certain situations on Windows
      // the bubbles hide before the user has a chance to click on them. This
      // will give the user a chance to always click them.

      // Not doing anything if we are already about to hide the cc bubbles
      if (hideBubbleTimeout) return;
      hideBubbleTimeout = setTimeout(dispatchImmediate, 3000);
    }
  }
);

export const intializeCharWidthLookupIfNeeded = createAsyncThunk(
  'ccMode/intializeCharWidthLookupIfNeeded',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    if (selectCharWidthLookup(state)) return;
    dispatch(setCharWidthLookup(getCharWidthLookup()));
  }
);

export const ccModeReducer = ccModeSlice.reducer;
export const {
  addNotification,
  clearNotifications,
  deleteNotification,
  setCCMode,
  setCCV2ConversationViewMode,
  setCharWidthLookup,
  setRestartCCConversation,
} = ccModeSlice.actions;
