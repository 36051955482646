import { createAction, createAsyncAction } from 'typesafe-actions';
import * as actions from './constants';
export const fetchUser = createAsyncAction(actions.USER_FETCH_REQUEST, actions.USER_FETCH_SUCCESS, actions.USER_FETCH_FAILURE)();
export const updateUser = createAsyncAction(actions.USER_UPDATE_REQUEST, actions.USER_UPDATE_SUCCESS, actions.USER_UPDATE_FAILURE)();
export const fetchUserInfos = createAsyncAction(actions.USER_FETCH_INFO_REQUEST, actions.USER_FETCH_INFO_SUCCESS, actions.USER_FETCH_INFO_FAILURE)();
export const newOrganization = createAsyncAction(actions.NEW_ORGANIZATION_REQUEST, actions.NEW_ORGANIZATION_SUCCESS, actions.NEW_ORGANIZATION_FAILURE)();
export const fetchOrganizations = createAsyncAction(actions.SEARCH_ORGANIZATIONS_REQUEST, actions.SEARCH_ORGANIZATIONS_SUCCESS, actions.SEARCH_ORGANIZATIONS_FAILURE)();
export const clearOrganizationErrors = createAction(actions.CLEAR_ORGANIZATION_ERRORS);
export const dashboardReady = createAction(actions.DASHBOARD_READY);
export const dashboardLoading = createAction(actions.DASHBOARD_LOADING);
