import { users } from './api/ava';

export const getConversationCloseReason = ({
  currentUserAvaId,
  endedByCurrentUser,
  endedDueToConvoRestrictedSession,
  host,
  isClosingApp,
}: {
  currentUserAvaId: string;
  endedByCurrentUser: boolean;
  endedDueToConvoRestrictedSession: boolean;
  host: any;
  isClosingApp?: boolean;
}): string => {
  let reason = '';

  if (isClosingApp) {
    reason = 'app_terminated';
  } else if (endedDueToConvoRestrictedSession) {
    reason = 'max_convo_time_reached';
  } else if (endedByCurrentUser && host?.avaId !== currentUserAvaId) {
    reason = 'closed';
  } else if (!endedByCurrentUser && host?.avaId === currentUserAvaId) {
    reason = 'last_guest_left';
  } else {
    reason = 'host_left';
  }

  return reason;
};

export const getConversationTimeInSeconds = (startTimestampMs: number) => {
  return Math.floor((Date.now() - startTimestampMs) / 1000);
};

const getConversationTotalWords = ({
  wordsCapturedGroup,
  wordsTypedGroup,
}: {
  wordsCapturedGroup: number;
  wordsTypedGroup: number;
}) => {
  return wordsTypedGroup + wordsCapturedGroup;
};

export const getMetricsToUpdate = ({
  conversationTimeInSeconds,
  isSoloConvo,
  transcriptSaved,
  transcriptStats,
}: {
  conversationTimeInSeconds: number;
  isSoloConvo: boolean;
  transcriptSaved: boolean;
  transcriptStats: any;
}): any => {
  const isGroupConvo = !isSoloConvo;
  const conversationTotalWords = getConversationTotalWords(transcriptStats);

  const metricsToUpdate: any = {
    'Total Convo Time': conversationTimeInSeconds,
    'Total Saved Transcripts': transcriptSaved ? 1 : 0,
    'Total Solo Time': isSoloConvo ? conversationTimeInSeconds : 0,
    'Total Group Time': isGroupConvo ? conversationTimeInSeconds : 0,
    'Total Conversations': 1,
    'Total Convo Words': conversationTotalWords,
    'Total Solo Words': isSoloConvo ? conversationTotalWords : 0,
    'Total Group Words': isGroupConvo ? conversationTotalWords : 0,
    'Total Solo Conversations Web': isSoloConvo ? 1 : 0,
    'Total Group Conversations Web': isGroupConvo ? 1 : 0,
  };

  if (isSoloConvo) {
    metricsToUpdate.NB_SOLO_CONVO = 1;
  } else {
    metricsToUpdate.NB_GROUP_CONVO = 1;
  }

  return metricsToUpdate;
};

export const isFirstConvo = (currentMetrics: any) => {
  return !currentMetrics || (!currentMetrics.NB_SOLO_CONVO && !currentMetrics.NB_GROUP_CONVO);
};

export const isHost = ({ host, avaId }: { host: any; avaId: string }) => {
  return host && host.avaId === avaId;
};

export const isScribeRequester = ({ scribe, avaId }: { scribe: any; avaId: string }) => {
  return scribe && scribe.requestor === avaId;
};

export const updateConversationMetrics = async ({
  metricsToUpdate,
  avaId,
  firebaseAuthUID,
}: {
  metricsToUpdate: any;
  avaId: string;
  firebaseAuthUID: string;
}) => {
  await users.updateMetrics({
    avaId: avaId || '',
    firebaseAuthUID: firebaseAuthUID,
    convoMetrics: metricsToUpdate,
  });
};

export const getRatingMetricsToUpdate = ({ rate }: { rate?: number }) => {
  const metricsToUpdate: any = {};
  metricsToUpdate[`Rated_${rate || 0}`] = 1;

  return metricsToUpdate;
};
