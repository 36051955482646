import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { call, put } from 'redux-saga/effects';

import { unvalidateLogin } from '../../actions/account/session';
import { dashboardReady, fetchUser, fetchUserInfos, newOrganization, updateUser } from '../../actions/account/user';
import { getAuthInstance } from '../../firebase';
import type { IOrganization } from '../../modules/account/models/organization';
import * as avaApi from '../../services/api/ava';
import { users } from '../../services/api/saas';

export function* login({ payload }: any) {
  yield put(fetchUser.request(payload.user));
}

export function* unvalidateLoginRequest() {
  yield getAuthInstance().signOut();
}

export function* fetchUserRequest({ payload }: any) {
  try {
    const orgType = window.localStorage.getItem('account_orgType');

    const res = yield call(users.getOrCreateUser, {
      name: payload ? payload.name : '',
      type: orgType,
    });

    if (res.data.user && res.data.user.parse) {
      yield put(fetchUser.success(res.data));
      yield put(fetchUserInfos.request());
      //@ts-ignore
      window.navigate('/account/dashboard');
    } else {
      yield put(unvalidateLogin());
      if (orgType === 'individual') {
        //@ts-ignore
        window.navigate('web/login');
      } else if (!orgType) {
        //@ts-ignore
        window.navigate('/account/start');
      }
    }
  } catch (err: any) {
    yield put(fetchUser.failure(err.response));
  }
}

export function* updateUserRequest({ payload }: any) {
  try {
    const { role } = payload;
    //@ts-ignore
    const { data } = yield call(users.getOrCreateUser);
    if (data.user && data.user.parse) {
      const { user } = data;
      yield call(avaApi.users.updateRole, {
        avaId: user.parse.avaId,
        firebaseAuthUID: user.parse.firebaseAuthUID,
        role,
      });
      yield put(updateUser.success(data));
    }
    if (payload.reset) {
      yield window.open('/', '_self');
    }
  } catch (err: any) {
    Sentry.captureException(err);
    yield put(updateUser.failure(err.response));
  }
}

export function* fetchUserInfoRequest() {
  try {
    const { language } = i18n;

    const res = yield call(users.getUser, language);

    yield put(fetchUserInfos.success(res.data));
  } catch (err: any) {
    const { data } = err.response;

    if (
      data.ok === false &&
      data.error &&
      data.error.error &&
      data.error.error.content.missing &&
      data.error.error.content.missing.organization
    ) {
      const isIndividual = window.localStorage.getItem('account_orgType') === 'individual';
      if (isIndividual) {
        const organization: IOrganization = {
          name: '',
          type: 'individual',
        };
        yield put(newOrganization.request(organization));
      } else {
        //@ts-ignore
        window.navigate('/account/organization/create');
      }
    } else if (err && err.response && err.response.data.ok === false) {
      yield put(unvalidateLogin());
      //@ts-ignore
      window.navigate('web/login');
    }
    yield put(fetchUserInfos.failure(err.response));
  }
}

export function* handleFetchInfoUser({ payload }: any) {
  if (!payload.selfLicence.licence && !payload.organization) {
    //@ts-ignore
    window.navigate('/account/organization/create');
  }
  yield put(dashboardReady());
}

export function* logout() {
  yield getAuthInstance().signOut();
}

export function* updateLang() {
  if (window?.location?.pathname?.includes('/buy')) {
    yield put(fetchUserInfos.request());
  }
}
