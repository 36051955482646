import type { Selector } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';

import type { DesktopThemes, KaraokeState, UiState } from '../store/slices/uiState';
import type { RootState } from '../store/store';
import type { ScribeCorrectionMode } from '../types';

const selectUi = (state: RootState): UiState => state.ui;
export const selectCorrectionMode: Selector<RootState, ScribeCorrectionMode> = createSelector(
  [selectUi],
  (ui) => ui.correctionMode
);

export const selectElectronCaptionMode: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.electronCaptionMode
);

export const selectFullScreen: Selector<RootState, boolean> = createSelector([selectUi], (ui) => ui.fullScreen);

export const selectDisplayShortcuts: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.displayShortcuts || ui.insertSpeakerNameMode
);

export const selectInsertSpeakerNameMode: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.insertSpeakerNameMode
);

export const selectIsInviteCardOpen: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.isInviteCardOpen
);

export const selectIsSideBarOpen: Selector<RootState, boolean> = createSelector([selectUi], (ui) => ui.sidebar);

export const selectLoading: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.loading !== undefined && ui.loading
);

export const selectFontSize: Selector<RootState, number> = createSelector([selectUi], (ui) => ui.fontSize);

export const selectIsIntercomOpen: Selector<RootState, boolean> = createSelector([selectUi], (ui) => ui.intercomOpen);

export const selectElectronAppLoaded: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.electronAppLoaded !== undefined && ui.electronAppLoaded
);

export const selectIsWordBeingEdited: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.wordBeingEdited
);

export const selectKeyboardInputShown: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.keyboardInputShown
);

export const selectIsConnectToMeetingOpen: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.isConnectToMeetingsOpen
);

export const selectKaraokeState: Selector<RootState, KaraokeState> = createSelector(
  [selectUi],
  (ui) => ui.karaokeState
);

export const selectShouldHighlightConnectToMeetings: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.shouldHighlightConnectToMeetings
);

export const selectOpenTauriAttempted: Selector<RootState, boolean> = createSelector(
  [selectUi],
  (ui) => ui.openTauriAttempted
);

export const selectWasMutedBeforeKaraoke = createSelector([selectUi], (ui) => ui.wasMutedBeforeKaraoke);

export const selectIsOneClickScribeModalOpen = createSelector([selectUi], (ui) => ui.isOneClickScribeModalOpen);

export const selectDesktopTheme: Selector<RootState, DesktopThemes> = createSelector(
  [selectUi],
  (ui) => ui.desktopTheme
);

export const selectShowControlBar = createSelector([selectUi], (ui) => ui.showControlBar);
