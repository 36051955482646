import { createSelector } from 'reselect';
import { RecordingStatus } from '../store/slices/audioV2';
import { isFirefox, isLinux, isMac, isWindows } from '../utils';
import { isConversationEnded, isDefaultRoomId } from '../utils/ws-v1';
import { selectAudioV2Status, selectMicrophoneAccess, selectNeedInternalAudioAccess } from './audioV2';
import { selectConfigEnableSolodia, selectFirebaseAuthInitiated, selectFirebaseAuthTriggered, selectFirebaseUser, } from './auth';
import { selectAutoTranslateLanguage } from './avaTranslate';
import { selectCaptionQuality, selectInTwilioCalls, selectIsInConversation } from './conversation';
import { selectFurthestObservedCursors, selectHasScribe, selectIsDefaultRoomId, selectIsHost, selectLang, selectParticipantsLength, selectPendingScribe, selectScribeRequestor, selectScribes, selectScribesHashes, selectSoloDiaSpeakersWhoSpoke, selectStatus, selectStatusAudioStreams, selectStatusConversationMode, selectStatusOfScribes, selectStatusRoomId, selectTranscripts, selectUniqueParticipants, } from './legacy-conversation';
import { selectShowQuickSetup } from './quickSetup';
import { selectRecallAIStatus } from './recallAI';
import { selectScribeTrainingRequested } from './scribe-dashboard';
import { selectElectronCaptionMode, selectFullScreen } from './ui';
import { selectAvaId, selectFeature, selectFeaturesScribe, selectIsWebGuest, selectSubscription, selectUserProfileFetchFinished, selectUserProfileFetchInitiated, } from './userProfile';
import { selectConferenceCallRequested } from './v1Session';
export var UserRoleInConversation;
(function (UserRoleInConversation) {
    // participant is default user role
    UserRoleInConversation["PARTICIPANT"] = "participant";
    UserRoleInConversation["SCRIBE"] = "scribe";
})(UserRoleInConversation || (UserRoleInConversation = {}));
/**
 * selectUserRoleInConversation is supposed to be the single source of truth
 * for the webapp deciding which UI/UX to serve to the user IN CONVERSATION
 * We only want to show scribe UI/UX if the user has scribe feature flag and is joining as a scribe
 * otherwise show participant (or end user's) UI/UX
 */
export const selectUserRoleInConversation = createSelector([
    selectFeaturesScribe,
    selectScribes,
    selectAvaId,
    selectStatusRoomId,
    selectScribeTrainingRequested,
    selectParticipantsLength,
    selectIsInConversation,
], (canScribe, scribeArray, avaId, roomId, trainingRequested, participantsLength, isInConversation) => {
    const defaultRoom = isDefaultRoomId(roomId);
    const currentUserIsScribe = Boolean(canScribe && (scribeArray === null || scribeArray === void 0 ? void 0 : scribeArray.some((scribe) => scribe.avaId === avaId)));
    // if the host ends the conversation and there are no participants, the scribe should be able to scribe
    // we include check for isInConversation to let scribe be able to start a normal conversation, as postConvoScribe depends on scribe being in a conversation already
    // for more information on to why ask @skinner and look at ticket:
    // https://linear.app/weareava/issue/WEB-1949/web-client-scribe-session-when-scribing-mobile-convo-switched-to-a
    const postConvoScribe = canScribe && !participantsLength && isInConversation;
    if (defaultRoom)
        return undefined;
    else if (postConvoScribe)
        return UserRoleInConversation.SCRIBE;
    else if (trainingRequested)
        return UserRoleInConversation.SCRIBE;
    else
        return currentUserIsScribe ? UserRoleInConversation.SCRIBE : UserRoleInConversation.PARTICIPANT;
});
export const selectConversationMuted = createSelector([selectStatus, selectAvaId, selectUserRoleInConversation], (status, currentAvaId, role) => {
    return (status.conversationMode === 'public-muted' &&
        role !== UserRoleInConversation.SCRIBE &&
        (!currentAvaId || (status.host && status.host.avaId !== currentAvaId)));
});
export const selectFirebaseLoginInProgress = createSelector([selectFirebaseAuthInitiated, selectUserProfileFetchInitiated], (x, y) => {
    return x || y;
});
export const selectLoggedIn = createSelector([selectFirebaseUser, selectAvaId], (firebaseUser, avaId) => {
    return !!avaId && !!firebaseUser && !firebaseUser.isAnonymous;
});
export const selectLoggedInAnonymously = createSelector([selectFirebaseUser], (firebaseUser) => {
    return firebaseUser && firebaseUser.isAnonymous;
});
export const selectLoggedOut = createSelector([
    selectFirebaseAuthTriggered,
    selectFirebaseAuthInitiated,
    selectFirebaseUser,
    selectAvaId,
    selectUserProfileFetchFinished,
], (firebaseAuthTriggered, firebaseAuthInitiated, firebaseUser, avaId, userProfileFetchFinished) => {
    if (!firebaseAuthTriggered) {
        // Firebase Auth has not been triggered yet - we know nothing about our
        // authentication state. We cannot say whether we are logged in or logged
        // out.
        return false;
    }
    if (firebaseAuthInitiated) {
        // Login has been initiated, but not completed
        return false;
    }
    if (firebaseUser && !userProfileFetchFinished) {
        // We have a firebase user, but we have not yet finished the call to
        // get user profile.
        return false;
    }
    return !firebaseUser || !avaId;
});
export const selectFurthestObservedByScribe = createSelector([selectStatus, selectFurthestObservedCursors, selectScribesHashes, selectStatusOfScribes], (status, furthestObservedCursors, scribesHashes, scribeStatus) => {
    let furthestObservedByScribe;
    if (scribeStatus === 'ongoing') {
        // Finding the furthest word any scribe has clicked on
        const scribeCursors = scribesHashes === null || scribesHashes === void 0 ? void 0 : scribesHashes.map((hash) => furthestObservedCursors[hash]);
        if (scribeCursors) {
            for (const cursor of scribeCursors) {
                if (!cursor)
                    continue;
                if (!furthestObservedByScribe ||
                    furthestObservedByScribe.tid < cursor.tid ||
                    (furthestObservedByScribe.tid === cursor.tid && furthestObservedByScribe.index < cursor.index)) {
                    furthestObservedByScribe = cursor;
                }
            }
        }
    }
    return furthestObservedByScribe;
});
// The language for transcripts to show the user. This will either be the `lang`
// kept in scribeConversation, or a language decided by the auto translation
// feature. This can be xx, xx-XX, or one of the weird google language codes.
// It's difficult to keep up with various language codes, so be careful.
export const selectLangForTranscripts = createSelector([selectLang, selectAutoTranslateLanguage], (lang, autoTranslateLang) => {
    return autoTranslateLang || lang;
});
export const selectShowKeyboardInput = createSelector([
    selectConversationMuted,
    selectRecallAIStatus,
    selectUserRoleInConversation,
    selectFullScreen,
    selectElectronCaptionMode,
    selectConferenceCallRequested,
    selectInTwilioCalls,
    selectIsInConversation,
    selectIsHost,
], (conversationMuted, recallAIStatus, role, fullScreen, electronCaptionMode, conferenceCallRequested, inTwilioCalls, isInConversation, isHost) => (!conversationMuted || (recallAIStatus === 'CAPTIONING' && isHost)) &&
    (!fullScreen || electronCaptionMode) &&
    !conferenceCallRequested &&
    !inTwilioCalls &&
    isInConversation);
export const selectQuickSetupShown = createSelector([selectNeedInternalAudioAccess, selectMicrophoneAccess, selectShowQuickSetup], (needInternalAudioAccess, microphoneAccess, showQuickSetup) => needInternalAudioAccess || microphoneAccess !== 'granted' || showQuickSetup);
export const selectCanRecord = createSelector([
    selectRecallAIStatus,
    selectConversationMuted,
    selectIsInConversation,
    selectUserRoleInConversation,
    selectScribeTrainingRequested,
], (recallAIStatus, conversationMuted, isInConversation, role, scribeTrainingRequested) => {
    return (!conversationMuted &&
        isInConversation &&
        !(recallAIStatus === 'CAPTIONING' || recallAIStatus === 'DISCONNECT') &&
        (role !== UserRoleInConversation.SCRIBE || scribeTrainingRequested));
});
export const selectCanBeginRecording = createSelector([selectAudioV2Status, selectCanRecord], (audioStatus, canRecord) => {
    return canRecord && audioStatus === RecordingStatus.NOT_RECORDING;
});
export const selectIsAvaMicAvailable = createSelector([selectUniqueParticipants, selectConversationMuted], (participants, conversationMuted) => {
    if (participants.length > 1 && !conversationMuted) {
        return false;
    }
    else if (isMac) {
        // window.isElectron sometimes returns nontruthy value when should be truthy because javascript
        return window.isElectron !== undefined;
    }
    else if (isWindows) {
        // TODO: Verify that maybe Firefox on Windows now works?
        // Capturing system-wide audio is currently only available on Windows, but not in
        // Firefox (see this issue: https://bugzilla.mozilla.org/show_bug.cgi?id=1541425).
        // On Windows on Tauri AvaMic is always available
        return !!window.__TAURI__ || (!isFirefox && !!navigator.mediaDevices.getDisplayMedia);
    }
    else if (isLinux) {
        return false;
    }
    else
        return true;
});
export const selectIsConversationEnded = createSelector([selectStatus, selectAvaId], (status, avaId) => isConversationEnded(status, avaId));
export const selectIsReadOnlyWebGuest = createSelector([selectIsWebGuest, selectStatusConversationMode], (isWebGuest, conversationMode) => isWebGuest && conversationMode === 'public-muted');
export const selectShouldShowSoloDiaNotification = createSelector([
    selectConfigEnableSolodia,
    selectStatusAudioStreams,
    selectIsInConversation,
    selectSoloDiaSpeakersWhoSpoke,
    selectIsReadOnlyWebGuest,
    selectRecallAIStatus,
], (isSoloDiaV2, audioStreams, isInConversation, soloDiaSpeakersWhoSpoke, isReadOnlyWebGuest, recallAIStatus) => {
    if (!isSoloDiaV2 || isReadOnlyWebGuest || recallAIStatus !== 'NOT_CONNECTED') {
        return false;
    }
    if ((audioStreams && audioStreams.length !== 1) || !isInConversation) {
        return false;
    }
    // we really only want to show if there's 3 solo-dia speakers or more
    if (soloDiaSpeakersWhoSpoke.length > 2) {
        return true;
    }
    return false;
});
export const selectScribeOnWord = createSelector([
    selectScribes,
    selectTranscripts,
    selectFeaturesScribe,
    (state, transcriptId, wordIndex) => transcriptId,
    (state, transcriptId, wordIndex) => wordIndex,
], (scribes, transcripts, featuresScribe, transcriptId, wordIndex) => {
    if (!scribes || scribes.length === 0)
        return undefined;
    // Only scribes can see current cursors.
    if (!featuresScribe)
        return undefined;
    const transcript = transcripts[transcriptId];
    if (!transcript)
        return undefined;
    const cursors = transcript.cursors;
    if (!cursors || cursors.length === 0)
        return undefined;
    const currentWordCursor = cursors.find((cursor) => cursor.index === wordIndex);
    if (!currentWordCursor)
        return undefined;
    return scribes.find((scribe) => scribe.hash === (currentWordCursor === null || currentWordCursor === void 0 ? void 0 : currentWordCursor.mutatorHash));
});
// This can be a gradient or anything that goes into the `background` CSS property
export const selectCurrentCaptionsBackground = createSelector([selectAudioV2Status, selectHasScribe, selectCaptionQuality], (recordingStatus, hasScribe, captionQuality) => {
    if (recordingStatus === RecordingStatus.RECORDING) {
        if (hasScribe) {
            return (theme) => theme.palette.ava.green;
        }
        else if (captionQuality === undefined || captionQuality === 'premium') {
            return (theme) => theme.palette.ava.gradientBlue;
        }
        else {
            return (theme) => theme.palette.ava.quality.basic;
        }
    }
    else {
        return (theme) => theme.palette.ava.grey;
    }
});
// This will always be a simple color.
export const selectCurrentCaptionsBackgroundColor = createSelector([selectAudioV2Status, selectHasScribe, selectCaptionQuality], (recordingStatus, hasScribe, captionQuality) => {
    if (recordingStatus === RecordingStatus.RECORDING) {
        if (hasScribe) {
            return (theme) => theme.palette.ava.green;
        }
        else if (captionQuality === undefined || captionQuality === 'premium') {
            return (theme) => theme.palette.ava.darkBlue;
        }
        else {
            return (theme) => theme.palette.ava.blue;
        }
    }
    else {
        return (theme) => theme.palette.ava.grey;
    }
});
export const selectCanSwitchConversationMode = createSelector([selectSubscription, selectIsHost, selectRecallAIStatus], (subscription, isHost, recallAIStatus) => {
    const sufficientSubscription = subscription &&
        subscription.ongoingSubscription &&
        subscription.ongoingSubscription !== 'Free' &&
        subscription.ongoingSubscription !== 'Freedom' &&
        subscription.ongoingSubscriptionSubtype !== 'Lite';
    return recallAIStatus === 'NOT_CONNECTED' && isHost && sufficientSubscription;
});
export const selectHasRequestedScribe = createSelector([selectHasScribe, selectPendingScribe, selectScribeRequestor, selectAvaId], (hasScribe, pendingScribe, ScribeRequestor, avaId) => (hasScribe || pendingScribe) && ScribeRequestor === avaId);
export const selectAvaConnectAvailable = createSelector([selectIsInConversation, selectFeature('recall-ai')], (isInConversation, featureRecallAi) => isInConversation && featureRecallAi);
export const selectIsHostInConversation = createSelector([selectIsHost, selectIsInConversation, selectStatusRoomId, selectIsDefaultRoomId], (isHost, isInConversation, roomId, defaultRoomId) => Boolean(roomId && !defaultRoomId && isHost && isInConversation));
