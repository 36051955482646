import ISO6391 from 'iso-639-1';

import {
  setActive,
  setAutoTranslateActive,
  setGuestLanguage,
  setHostLanguage,
  setToggleInProgress,
} from '../store/slices/avaTranslate';
import type { AppDispatch, RootState } from '../store/store';
import { sendWsMessage } from './ws-v1';

export type Active = 'host' | 'guest';
export type AutoTranslateStatus = {
  hostLanguage: string;
  guestLanguage: string;
  active: Active;
};

export type RoomStatusUpdate = {
  type: 'room-status-update';
  autoTranslation?: AutoTranslateStatus;
};

export class AvaTranslateManager {
  ws: WebSocket;
  dispatch: AppDispatch;

  constructor(ws: WebSocket, dispatch: AppDispatch) {
    this.ws = ws;
    this.dispatch = dispatch;
    this.ws.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      this.handleMessage(message);
    });
    this.handleMessage = this.handleMessage.bind(this);

    /** Uncomment to force ava translate on in the UI
    setTimeout(() => {
      this.dispatch(setToggleInProgress(false));
      this.dispatch(setHostLanguage('pl-PL'));
      this.dispatch(setGuestLanguage('pt-BR'));
      this.dispatch(setActive('guest'));
      this.dispatch(setAutoTranslateActive(true));
    }, 2000);
     */
  }
  handleMessage(message: RoomStatusUpdate) {
    switch (message.type) {
      case 'room-status-update': {
        const { autoTranslation } = message;
        const state = window.store.getState() as RootState;
        if (!autoTranslation) {
          if (
            state.avaTranslate.toggleInProgress ||
            state.avaTranslate.hostLanguage ||
            state.avaTranslate.guestLanguage ||
            state.avaTranslate.active ||
            state.avaTranslate.autoTranslateActive
          ) {
            this.dispatch(setToggleInProgress(false));
            this.dispatch(setHostLanguage(undefined));
            this.dispatch(setGuestLanguage(undefined));
            this.dispatch(setActive(undefined));
            this.dispatch(setAutoTranslateActive(false));
          }
          return;
        }
        const {
          active: oldActive,
          guestLanguage: oldGuestLanguage,
          hostLanguage: oldHostLanguage,
        } = state.avaTranslate;
        const { hostLanguage, guestLanguage, active } = autoTranslation;
        if (oldActive !== active || oldGuestLanguage !== guestLanguage || oldHostLanguage !== hostLanguage) {
          this.forceUpdateTranscripts();
        }
        this.dispatch(setToggleInProgress(false));
        this.dispatch(setHostLanguage(hostLanguage));
        this.dispatch(setGuestLanguage(guestLanguage));
        this.dispatch(setActive(active));
        this.dispatch(setAutoTranslateActive(true));
        break;
      }
    }
  }

  forceUpdateTranscripts() {
    // AutoTranslate feature has a bug in the backend, where the old
    // transcripts do not get translated. To work around this, we
    // send a connection-params-update message to the backend, which
    // will cause the backend to re-send the transcripts.
    sendWsMessage(this.ws, {
      type: 'connection-params-update',
    });
  }

  handleSendAutoTranslateToggle() {
    const state = window.store.getState() as RootState;
    const { active, guestLanguage, hostLanguage } = state.avaTranslate;
    sendWsMessage(this.ws, {
      type: 'auto-translate-toggle',
      active: active === 'host' ? 'guest' : 'host',
    });

    this.forceUpdateTranscripts();

    this.dispatch(setToggleInProgress(true));
    setTimeout(() => {
      // TODO cancel it if it is set to false elsewhere
      this.dispatch(setToggleInProgress(false));
    }, 5000);
  }

  handleSendSetGuestLanguage(lang: string) {
    sendWsMessage(this.ws, {
      type: 'auto-translate-set-guest-language',
      guestLanguage: lang,
    });
    this.dispatch(setToggleInProgress(true));
    setTimeout(() => {
      // TODO cancel it if it is set to false elsewhere
      this.dispatch(setToggleInProgress(false));
    }, 5000);
  }
}

export const getLanguageNativeName = (languageCode) => {
  // Google API generally allows ISO-639-1 codes, but it also allows
  // two BCP-47 language codes - for two variants of ZH language. Native names
  // for those languages are hard coded here.
  if (languageCode === 'zh-TW') {
    return '國語(台灣)';
  }
  if (languageCode === 'zh-CN') {
    return '普通话(中国大陆)';
  }
  if (languageCode === 'zh-HK') {
    return '廣東話';
  }
  return ISO6391.getNativeName(languageCode);
};
