import { type PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { selectWebRTCTracks } from '../../selectors/audioV2';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectCurrentConversationEndingStage } from '../../selectors/conversationEnd';
import { selectSpeakersMap, selectStatus, selectStatusRoomId } from '../../selectors/legacy-conversation';
import { selectScribeTrainingRequested } from '../../selectors/scribe-dashboard';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { selectAvaId, selectSubscription } from '../../selectors/userProfile';
import { selectV1Websocket } from '../../selectors/v1Session';
import { calls } from '../../services/api/ava';
import { checkEditedWords } from '../../utils/boost';
import { isBoostEnabled } from '../../utils/status';
import {
  getDefaultRoomId,
  sendMbackendMessage,
  sendResetRoomIdMessage,
  sendRoomStateUpdateMessage,
} from '../../utils/ws-v1';
import type { RootState } from '../store';
import { setHostName } from './rateConversation';

export enum ConversationEndingStage {
  NOT_ENDING = 0,
  BOOST = 1,
  SAVE_TRANSCRIPTS = 2,
  END_ON_BACKEND = 3,
}
const getNextStage = (stage: ConversationEndingStage): ConversationEndingStage => {
  const nextState = stage + 1;
  if (nextState > ConversationEndingStage.END_ON_BACKEND) {
    console.error(
      'getNextStage: Invalid state. Make sure that shouldActivateStage(END_ON_BACKEND) returns true and no further steps are requested.'
    );
    throw new Error('getNextStage: Invalid state');
  }
  return nextState;
};

export type State = {
  conversationEndInitiatedByCurrentUser: boolean;

  currentConversationEndingStage: ConversationEndingStage;
  conversationEndInitiatedTimestamp?: number;

  // For analytics purposes
  endedDueToConvoRestrictedSession: boolean;

  pageToShowAfterEndingConversation: 'conversations' | 'transcript';
};

const initialState: State = {
  conversationEndInitiatedByCurrentUser: false,
  currentConversationEndingStage: ConversationEndingStage.NOT_ENDING,
  endedDueToConvoRestrictedSession: false,
  pageToShowAfterEndingConversation: 'transcript',
};

export const conversationEndSlice = createSlice({
  name: 'conversationEnd',
  initialState,
  reducers: {
    resetConversationEndingState(state) {
      state.conversationEndInitiatedByCurrentUser = false;
      state.currentConversationEndingStage = ConversationEndingStage.NOT_ENDING;
      state.conversationEndInitiatedTimestamp = undefined;
      state.endedDueToConvoRestrictedSession = false;
    },
    setCurrentConversationEndingStage(state, { payload }: PayloadAction<ConversationEndingStage>) {
      state.currentConversationEndingStage = payload;
    },
    setConversationEndInitiatedTimestampToNow(state) {
      state.conversationEndInitiatedTimestamp = Date.now();
    },
    setConversationEndInitiatedByCurrentUser(state, { payload }: PayloadAction<boolean>) {
      state.conversationEndInitiatedByCurrentUser = payload;
    },
    setEndedDueToConvoRestrictedSession(state, { payload }: PayloadAction<boolean>) {
      state.endedDueToConvoRestrictedSession = payload;
    },
    setPageToShowAfterEndingConversation(
      state,
      { payload }: PayloadAction<State['pageToShowAfterEndingConversation']>
    ) {
      state.pageToShowAfterEndingConversation = payload;
    },
  },
});

export const conversationEndReducer = conversationEndSlice.reducer;

export const {
  resetConversationEndingState,
  setConversationEndInitiatedByCurrentUser,
  setEndedDueToConvoRestrictedSession,
  setPageToShowAfterEndingConversation,
} = conversationEndSlice.actions;

const { setCurrentConversationEndingStage, setConversationEndInitiatedTimestampToNow } = conversationEndSlice.actions;

// This function initiates the conversation end sequence, which depending on situation
// might mean showing conversation end modals, or directly stopping the conversation on
// the backend.
// See: https://www.notion.so/ava/When-the-convo-ends-the-user-should-land-on-the-1ae289dd7eb64909a8bd34c637315cd8
export const initiateConversationEndSequence = createAsyncThunk(
  'conversationEnd/initiateConversationEndSequence',
  async (
    params: { pageToShowAfterEndingConversation: State['pageToShowAfterEndingConversation'] } | undefined,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const dispatch = thunkAPI.dispatch;
    const statusRoomId = selectStatusRoomId(state);
    const speakers = selectSpeakersMap(state);
    const ws = selectV1Websocket(state);
    const webRTCTracks = selectWebRTCTracks(state);

    dispatch(setPageToShowAfterEndingConversation(params?.pageToShowAfterEndingConversation ?? 'transcript'));

    const currentStage = selectCurrentConversationEndingStage(state);
    if (currentStage !== ConversationEndingStage.NOT_ENDING) {
      // Generally we shouldn't do anything, because this means that conversation
      // closing has been initiated twice, which we can ignore.
      // Potential problem with this approach is if something goes wrong and one of the
      // stages does not go well due to bugs. That's why if more than 30 seconds passed
      // we reset the conversation ending state.
      if (
        state.conversationEnd.conversationEndInitiatedTimestamp &&
        state.conversationEnd.conversationEndInitiatedTimestamp - Date.now() > 30000
      ) {
        dispatch(setCurrentConversationEndingStage(ConversationEndingStage.NOT_ENDING));
      } else {
        return;
      }
    }

    let hostName = '';
    if (statusRoomId && speakers) {
      const hostId = statusRoomId.split('_')[0];
      const host = speakers[hostId] || {};
      hostName = host.userName || hostId;
    }

    dispatch(setHostName(hostName));
    dispatch(setConversationEndInitiatedTimestampToNow());
    dispatch(activateNextEndConversationStage());
    if (ws) {
      webRTCTracks.forEach((track) => {
        sendMbackendMessage(ws, { type: 'webrtc-track-destroy', streamId: track.id });
      });
    }
  }
);

const shouldActivateStage = (stage: ConversationEndingStage, state: RootState) => {
  const scribeTrainingRequested = selectScribeTrainingRequested(state);
  const electronCaptionMode = selectElectronCaptionMode(state);
  const subscription = selectSubscription(state);
  const editedWords = checkEditedWords(state);
  switch (stage) {
    case ConversationEndingStage.NOT_ENDING:
      return true;
    case ConversationEndingStage.BOOST:
      return !(scribeTrainingRequested || electronCaptionMode || !isBoostEnabled(subscription) || !editedWords);
    case ConversationEndingStage.SAVE_TRANSCRIPTS:
      return !(scribeTrainingRequested || electronCaptionMode);
    case ConversationEndingStage.END_ON_BACKEND:
      return true;
    default:
      return false;
  }
};

export const activateNextEndConversationStage = createAsyncThunk(
  'conversationEnd/activateNextEndConversationStage',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const dispatch = thunkAPI.dispatch;
    const currentStage = state.conversationEnd.currentConversationEndingStage;
    if (currentStage === ConversationEndingStage.END_ON_BACKEND) {
      // We are already at the last stage, so we don't need to do anything.
      return;
    }
    let nextStageToActivate = getNextStage(currentStage);
    // This loop depends on the fact that the last stage will always be activatable. Otherwise,
    // it would loop forever or throw an error.
    while (!shouldActivateStage(nextStageToActivate, state)) nextStageToActivate = getNextStage(nextStageToActivate);
    dispatch(setCurrentConversationEndingStage(nextStageToActivate));
    // If anything needs to happen to activate the stage, it should go here.
    // Currently, the modals are triggered by the field itself, and the only special thing
    // that needs to happen is to stop the conversation on the backend.
    if (nextStageToActivate === ConversationEndingStage.END_ON_BACKEND) {
      dispatch(stopConversationOnTheBackend());
    }
  }
);

// This performs all the necessary things to close the conversation on the backend.
export const stopConversationOnTheBackend = createAsyncThunk(
  'conversationEnd/stopConversationOnTheBackend',
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const status = selectStatus(state);
    const avaId = selectAvaId(state);
    const firebaseUser = selectFirebaseUser(state);
    const ws = selectV1Websocket(state);

    if (status.host && status.host.avaId === avaId) {
      Object.keys(status.twilioCalls || {}).forEach((sid) => {
        calls.terminateCall({ avaId, uid: firebaseUser?.uid || '', sid });
      });
      if (ws) sendRoomStateUpdateMessage(ws, { value: 'ended' });
    }
    if (ws) sendResetRoomIdMessage(ws, getDefaultRoomId(avaId));
  }
);

export const endConversation = createAsyncThunk(
  'conversationEnd/endConversation',
  async (params: { pageToShowAfterEndingConversation: State['pageToShowAfterEndingConversation'] }, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    dispatch(setConversationEndInitiatedByCurrentUser(true));
    dispatch(initiateConversationEndSequence(params));
  }
);
