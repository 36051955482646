import React, { lazy } from 'react';

import Loading from '../../components/Loading';
const DesktopCCLayout = lazy(() => import(/* webpackChunkName: "desktopCCLayout" */ '../../Layouts/DesktopCCLayout'));
import { selectConfigEnableCCV2 } from '../../selectors/auth';
import { selectFeatures } from '../../selectors/userProfile';
import { checkIfEligibleForCCV2 } from '../../services/desktopIntegration';
import { useAppSelector } from '../../store/store';

const CCV2ConversationPage = lazy(
  () => import(/* webpackChunkName: "ccV2" */ '../../modules/ccV2/CCV2ConversationPage')
);

const CCPage = lazy(() => import(/* webpackChunkName: "ccV1" */ '../../views/cc-mode/CCPage/CCPage'));

export const CCV2ConversationPageSwitch = React.memo(() => {
  const hasFlagInFirebase = useAppSelector(selectConfigEnableCCV2);
  const features = useAppSelector(selectFeatures);
  const hasFlagInUserProfile = features['desktop-v2'];
  const isEligible = checkIfEligibleForCCV2();

  if (isEligible && (hasFlagInUserProfile || (hasFlagInFirebase && hasFlagInUserProfile !== false))) {
    return (
      <DesktopCCLayout>
        <CCV2ConversationPage />
      </DesktopCCLayout>
    );
  }
  if (hasFlagInFirebase === undefined) {
    return <Loading />;
  }

  return (
    <DesktopCCLayout>
      <CCPage />
    </DesktopCCLayout>
  );
});
