var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const getAppVersion = () => __awaiter(void 0, void 0, void 0, function* () {
    if (window.isElectron) {
        let result = window.electronApp.getVersion();
        if (!result && window.electronApp.getVersionAsync) {
            result = yield window.electronApp.getVersionAsync();
        }
        return result;
    }
    return null;
});
export const getIsMac = () => {
    if (window.isElectron) {
        return window.isElectron.electronOS === 'macOS';
    }
    return false;
};
// This is main-world event-target that replicates a few events from the
// ipcRenderer. Its main purpose is the ability to track listeners, and be able to
// deregister them (which is not possible through the contextBridge). Electron is hard.
// https://github.com/electron/electron/issues/33328
// tl;dr: If you need to call removeEventListener on any of the electron IPCs, you need to
// add this particular event not only into the contextBridge, but also here. Look in the code
// for usages of ipcRendererWithDeregistering.
class IPCEvent extends Event {
    constructor(type, sender, ...args) {
        super(type);
        this.sender = sender;
        this.args = args;
    }
}
export const ipcRendererWithDeregistering = new EventTarget();
if (window.electronIPC) {
    window.electronIPC.onMultiOutputChanged((sender, ...args) => ipcRendererWithDeregistering.dispatchEvent(new IPCEvent('multiOutputChanged', sender, ...args)));
    window.electronIPC.onWindowActivated((sender, ...args) => ipcRendererWithDeregistering.dispatchEvent(new IPCEvent('window-activated', sender, ...args)));
}
