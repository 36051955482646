import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AvaTranslateManager } from '../../utils/AvaTranslate';
import type { AppDispatch, RootState } from '../store';

export type AvaTranslateState = {
  toggleInProgress: boolean;
  guestLanguageSelectorOpen: boolean;

  autoTranslateActive: boolean;
  hostLanguage?: string;
  guestLanguage?: string;
  active?: 'guest' | 'host';

  avaTranslateManager?: AvaTranslateManager;

  sncfConsentGiven: boolean;
  sncfConsentDialogShown: boolean;
};

const initialState: AvaTranslateState = {
  toggleInProgress: false,
  guestLanguageSelectorOpen: false,
  autoTranslateActive: false,

  // Consent is implied to start with.
  sncfConsentGiven: true,
  sncfConsentDialogShown: false,
};

export const AvaTranslateSlice = createSlice({
  name: 'avaTranslate',
  initialState,
  reducers: {
    setToggleInProgress(state, { payload }: PayloadAction<boolean>) {
      state.toggleInProgress = payload;
    },
    setAutoTranslateActive(state, { payload }: PayloadAction<boolean>) {
      state.autoTranslateActive = payload;
    },
    setHostLanguage(state, { payload }: PayloadAction<string | undefined>) {
      state.hostLanguage = payload;
    },
    setGuestLanguage(state, { payload }: PayloadAction<string | undefined>) {
      state.guestLanguage = payload;
    },
    setActive(state, { payload }: PayloadAction<'guest' | 'host' | undefined>) {
      state.active = payload;
    },
    setGuestLanguageSelectorOpen(state, { payload }: PayloadAction<boolean>) {
      state.guestLanguageSelectorOpen = payload;
    },
    setSncfConsentGiven(state, { payload }: PayloadAction<boolean>) {
      state.sncfConsentGiven = payload;
    },
    setSncfConsentDialogShown(state, { payload }: PayloadAction<boolean>) {
      state.sncfConsentDialogShown = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      createAvaTranslateManager.fulfilled,
      (state, { payload }: PayloadAction<AvaTranslateManager | undefined>) => {
        if (payload) state.avaTranslateManager = payload;
      }
    );
  },
});

export const AvaTranslateReducer = AvaTranslateSlice.reducer;
export const {
  setToggleInProgress,
  setAutoTranslateActive,
  setActive,
  setHostLanguage,
  setGuestLanguage,
  setGuestLanguageSelectorOpen,
  setSncfConsentGiven,
  setSncfConsentDialogShown,
} = AvaTranslateSlice.actions;

export const createAvaTranslateManager = createAsyncThunk('avaTranslate/createAvaTranslateManager', (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppDispatch;
  const state = thunkAPI.getState() as RootState;
  const { v1Socket } = state.v1Session;
  if (!v1Socket) return;
  return new AvaTranslateManager(v1Socket, dispatch);
});
