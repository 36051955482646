/* eslint-disable unused-imports/no-unused-imports */
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import { AppWrapper } from '../App';
import Loading from '../components/Loading';
import OnboardingV2 from '../Layouts/OnboardingV2';
import Muter from '../modules/AudioV2/Muter';
import { CCV2ConversationPageSwitch } from '../modules/ccV2/CCV2ConversationPageSwitch';
import { CONVOV2_APP_LAUNCH_ROUTE, CONVOV2_HOME_PAGE_ROUTE } from '../modules/convoV2/constants';
import { ConvoV2ConversationPageSwitch } from '../modules/convoV2/ConvoV2ConversationPageSwitch';
import { LoginListener } from '../modules/login/LoginListener';
import GlobalHotkeyHandler from '../modules/scribe/GlobalHotkeyHandler';
import { selectAccountUser } from '../selectors/account';
import { selectIsTextToSpeechV2 } from '../selectors/auth';
import { selectSpeechLang } from '../selectors/legacy-conversation';
import { selectV1TtsVoices } from '../selectors/textToSpeech';
import { selectAvaId } from '../selectors/userProfile';
import { v1FindBestTTSVoice } from '../store/slices/textToSpeech';
import { useAppDispatch, useAppSelector } from '../store/store';
import { Z_INDICES } from '../styles';
import { logoutAndClean } from '../utils/user';
import PrivateRouteWrapper from './PrivateRouteWrapper';

// The Default component contains the header, which is not always loaded, and if it is
// it contains a lot of components related to Ava-Connect and others.
const Default = lazy(() => import(/* webpackChunkName: "default" */ '../Layouts/Default'));

// Components relating to starting the web app through various means. They are very often
// loaded without any other component.
const BranchRedirectScreen = lazy(() => import(/* webpackChunkName: "branch" */ '../components/BranchRedirectScreen'));
const AppDownloadScreen = lazy(() => import(/* webpackChunkName: "appDownload" */ '../components/AppDownloadScreen'));
const DesktopLoginSuccess = lazy(
  () => import(/* webpackChunkName: "desktopLogin" */ '../components/DesktopLoginSuccess')
);
const ElectronLandingPage = lazy(
  () => import(/* webpackChunkName: "electron_landing_page" */ '../modules/onboarding/ElectronLandingPage')
);

// Scribing components
const ScribeDashboardContainer = lazy(
  () => import(/* webpackChunkName: "scribe" */ '../modules/scribe/ScribeDashboard')
);

// Conversations component shared between a few different routes.
const ScribeConversationsContainer = lazy(
  () => import(/* webpackChunkName: "transcripts" */ '../modules/scribe/ScribeConversationsContainer')
);

// Account management pages
const Dashboard = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/dashboard'));
const LicenceBuy = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/license/buy'));
const Payment = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/license/payment'));
const PaymentSucceed = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/payment-succeed'));
const OrganizationCreate = lazy(
  () => import(/* webpackChunkName: "account" */ '../modules/account/pages/organization/create')
);
const AccountStart = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/start'));

// Currently unused Workspace V2 component
const Workspace = lazy(() => import(/* webpackChunkName: "workspace" */ '../components/Workspace'));

// Onboarding components (used only during onboarding)
const ScribeLoginProfiling = lazy(
  () => import(/* webpackChunkName: "loginProfiling" */ '../modules/scribe/ScribeLoginProfilingContainer')
);
const Onboarding = lazy(() => import(/* webpackChunkName: "onboarding" */ '../modules/onboarding/Onboarding'));

// Account settings components
const Boost = lazy(() => import(/* webpackChunkName: "account-settings" */ '../modules/account-settings/boost/Boost'));
const General = lazy(() => import(/* webpackChunkName: "account-settings" */ '../modules/account-settings/General'));
const MyProfile = lazy(
  () => import(/* webpackChunkName: "account-settings" */ '../modules/account-settings/MyProfile')
);
const Navigation = lazy(
  () => import(/* webpackChunkName: "account-settings" */ '../modules/account-settings/Navigation')
);

// ConvoV1 components
const ConversationPage = lazy(
  () => import(/* webpackChunkName: "transcript" */ '../views/conversation/ConversationPage/ConversationPage')
);

// ConvoV2 components
const ConvoV2AppLaunchScreen = lazy(
  () => import(/* webpackChunkName: "convoV2" */ '../modules/convoV2/ConvoV2AppLaunchScreen')
);
const ConvoV2ConversationPage = lazy(
  () => import(/* webpackChunkName: "convoV2" */ '../modules/convoV2/ConvoV2ConversationPage')
);
const ConvoV2HomePage = lazy(() => import(/* webpackChunkName: "transcript" */ '../modules/convoV2/ConvoV2HomePage'));

const browserHistory = createBrowserHistory();

Sentry.init({
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
    }),
  ],
  tracesSampleRate: 1.0,
  ignoreErrors: ['connection appears to be offline'],
});

const LogoutComponent = () => {
  logoutAndClean();

  return <Navigate to="/web/login" replace />;
};

const AppRoutes = () => {
  const avaId = useAppSelector(selectAvaId);
  const accountUser = useAppSelector(selectAccountUser);

  const webAppAuthenticate = () => Boolean(avaId);
  const accountAuthenticate = () => Boolean(accountUser?.id);

  return (
    <div id="gesture">
      <BrowserRouter>
        <Routes>
          <Route element={<RouterWrapper />}>
            <Route element={<OnboardingWrapper />}>
              <Route path="web/onboarding/profile/*" element={<ScribeLoginProfiling />} />
              <Route path="web/signup" element={<Onboarding />} />
              <Route path="web/login" element={<Onboarding changeModeToLogin={true} />} />
            </Route>

            <Route element={<WebAppWrapper />}>
              <Route path="/" element={<Navigate to="/web" replace />} />
              <Route path="branch/:branchUrlId" element={<BranchRedirectScreen />} />
              <Route path="download" element={<AppDownloadScreen />} />
              <Route path="workspace/*" element={<Workspace />} />
              <Route path="web/login/desktop" element={<ElectronLandingPage />} />
              <Route path="account/*" element={<AccountRoutes accountAuthenticate={accountAuthenticate} />} />
              <Route path="web/*" element={<WebRoutes webAppAuthenticate={webAppAuthenticate} />} />
              <Route path="*" element={<Navigate to="/web/signup" replace />} />
            </Route>

            <Route path="/cc">
              <Route
                path="conversation"
                element={
                  <PrivateRouteWrapper authenticationMethod={webAppAuthenticate} redirectPath="/web">
                    <CCV2ConversationPageSwitch />
                  </PrivateRouteWrapper>
                }
              />
            </Route>

            <Route
              path={CONVOV2_HOME_PAGE_ROUTE}
              element={
                <PrivateRouteWrapper authenticationMethod={webAppAuthenticate} redirectPath="/web">
                  <ConvoV2HomePage />
                </PrivateRouteWrapper>
              }
            />
            <Route path={CONVOV2_APP_LAUNCH_ROUTE} element={<ConvoV2AppLaunchScreen />} />

            <Route
              path="/web/transcript"
              element={
                <PrivateRouteWrapper authenticationMethod={webAppAuthenticate} redirectPath="/web">
                  <ConvoV2ConversationPageSwitch />
                </PrivateRouteWrapper>
              }
            />
            {/* These routes are here for development when we need to work on Convo V1 and V2 simultaneously */}
            <Route
              path="/convo-v1/transcript"
              element={
                <PrivateRouteWrapper authenticationMethod={webAppAuthenticate} redirectPath="/web">
                  <Default>
                    <ConversationPage />
                  </Default>
                </PrivateRouteWrapper>
              }
            />
            <Route
              path={'/convo-v2/transcript'}
              element={
                <PrivateRouteWrapper authenticationMethod={webAppAuthenticate} redirectPath="/web">
                  <ConvoV2ConversationPage />
                </PrivateRouteWrapper>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    paddingLeft: 40,
    paddingRight: 40,
    opacity: 0.98,
  },
  content: {
    paddingTop: 40,
    '& > :not(:last-child)': {
      position: 'relative',
      zIndex: Z_INDICES.accountSettingsWrapper,
      marginBottom: 16,
    },
  },
}));

const AccountSettingsWrapper = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Navigation />
      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  );
};

const OnboardingWrapper = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.isElectron) {
      // navigating to this route lets us have the electron layout for landing page
      // since this file is using onboarding wrapper
      navigate('web/login/desktop');
    }
  }, []);

  return (
    <>
      <OnboardingV2>
        <Outlet />
      </OnboardingV2>
    </>
  );
};

const WebAppWrapper = () => {
  return (
    <>
      <Default>
        <Outlet />
      </Default>
    </>
  );
};

const RouterWrapper = () => {
  const navigate = useNavigate();
  //@ts-ignore
  window.navigate = navigate;

  const dispatch = useAppDispatch();

  const v1Voices = useAppSelector(selectV1TtsVoices);
  const speechLang = useAppSelector(selectSpeechLang);
  const isTextToSpeechV2 = useAppSelector(selectIsTextToSpeechV2);

  //TODO remove this once TTS 2.1 is released
  useEffect(() => {
    if (!isTextToSpeechV2) {
      dispatch(v1FindBestTTSVoice());
    }
  }, [v1Voices, speechLang, isTextToSpeechV2]);

  return (
    <>
      <LoginListener />
      <Muter />
      <GlobalHotkeyHandler />
      <AppWrapper>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </AppWrapper>
    </>
  );
};

const AccountRoutes = ({ accountAuthenticate }: { accountAuthenticate: () => boolean }) => {
  return (
    <Routes>
      <Route index element={<Navigate to="/account/dashboard" replace />} />
      <Route path="start" element={<AccountStart />} />
      <Route path="login/profile" element={<ScribeLoginProfiling />} />
      <Route
        path="payment-succeed"
        element={
          <PrivateRouteWrapper authenticationMethod={accountAuthenticate} redirectPath="/account/dashboard">
            <PaymentSucceed />
          </PrivateRouteWrapper>
        }
      />
      <Route
        path="organization/create"
        element={
          <PrivateRouteWrapper authenticationMethod={accountAuthenticate} redirectPath="/account/dashboard">
            <OrganizationCreate />
          </PrivateRouteWrapper>
        }
      />
      <Route
        path="dashboard"
        //@ts-ignore
        element={<Dashboard />}
      />
      <Route
        path="license/buy"
        element={
          <PrivateRouteWrapper authenticationMethod={accountAuthenticate} redirectPath="/account/dashboard">
            <LicenceBuy />
          </PrivateRouteWrapper>
        }
      />
      <Route
        path="license/payment"
        element={
          <PrivateRouteWrapper authenticationMethod={accountAuthenticate} redirectPath="/account/dashboard">
            <Payment />
          </PrivateRouteWrapper>
        }
      />
      <Route path="*" element={<Navigate to="/account/dashboard" replace />} />
    </Routes>
  );
};
const WebRoutes = ({ webAppAuthenticate }: { webAppAuthenticate: () => boolean }) => {
  return (
    <Routes>
      <Route index element={<Navigate to="/web/signup" />} />
      <Route path="login/desktop/success" element={<DesktopLoginSuccess />} />
      <Route path="logout" element={<LogoutComponent />} />
      <Route
        path="scribe-dashboard"
        element={
          <PrivateRouteWrapper authenticationMethod={webAppAuthenticate} redirectPath="/web">
            <ScribeDashboardContainer />
          </PrivateRouteWrapper>
        }
      />
      <Route
        path="conversations"
        element={
          <PrivateRouteWrapper authenticationMethod={webAppAuthenticate} redirectPath="/web">
            <ScribeConversationsContainer />
          </PrivateRouteWrapper>
        }
      />
      <Route path="account-settings/*" element={<AccountSettingsRoutes webAppAuthenticate={webAppAuthenticate} />} />
      <Route path="*" element={<Navigate to="/web/signup" replace />} />
    </Routes>
  );
};

const AccountSettingsRoutes = ({ webAppAuthenticate }: { webAppAuthenticate: () => boolean }) => {
  return (
    <Routes>
      <Route element={<AccountSettingsWrapper />}>
        <Route index element={<Navigate to="my-profile" />} />
        <Route
          path="my-profile"
          element={
            <PrivateRouteWrapper authenticationMethod={webAppAuthenticate} redirectPath="/web">
              <MyProfile />
            </PrivateRouteWrapper>
          }
        />
        <Route
          path="general"
          element={
            <PrivateRouteWrapper authenticationMethod={webAppAuthenticate} redirectPath="/web">
              <General />
            </PrivateRouteWrapper>
          }
        />
        <Route
          path="boost"
          element={
            <PrivateRouteWrapper authenticationMethod={webAppAuthenticate} redirectPath="/web">
              <Boost />
            </PrivateRouteWrapper>
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
