const PROD_SEGMENT_WRITE_KEY = 'F7uCu50ONjACS2uuLo6gYiWzSdPXuA36';
const STAGE_SEGMENT_WRITE_KEY = 'j9l3dG9yR5X6gChUpYOUICygHDqpGdGb';

const BACKENDS = {
  prod: {
    segmentKey: PROD_SEGMENT_WRITE_KEY,
    captionerUrl: 'https://api-production.ava.me',
    saasUrl: 'https://api.saas.ava.me',
    chabarakUrl: 'https://chabarak-production.ava.me',
    scribeUrl: 'https://scribe.ava.me',
    subscriptionUrl: 'https://subscriptions.ava.me/api',
    firebaseFunctionsEndpoint: 'https://us-central1-ava-product.cloudfunctions.net',
    firebaseConfig: {
      apiKey: 'AIzaSyAW1__2426QnTMpHhp7ALFJBF3KNu6mVfs',
      authDomain: 'auth.ava.me',
      databaseURL: 'https://ava-product.firebaseio.com',
      projectId: 'ava-product',
      storageBucket: 'ava-product.appspot.com',
      messagingSenderId: '47114113593',
      appId: '1:47114113593:web:8b5e34ea305036acb333e8',
      measurementId: 'G-XNKCPW2V41',
    },
  },
  stage: {
    segmentKey: STAGE_SEGMENT_WRITE_KEY,
    captionerUrl: 'https://api-stage.ava.me',
    saasUrl: 'https://api-stage.saas.ava.me',
    chabarakUrl: 'https://chabarak-stage.ava.me',
    scribeUrl: 'https://scribe-playground.ava.me',
    subscriptionUrl: 'https://subscriptions-dev.ava.me/api',
    firebaseFunctionsEndpoint: 'https://us-central1-ava-app-dev-92f7f.cloudfunctions.net',
    firebaseConfig: {
      apiKey: 'AIzaSyAGbQMVzNWxMpMjZXcOck9IrBiJs0vkdnU',
      authDomain: 'auth-dev.ava.me',
      databaseURL: 'https://ava-app-dev-92f7f.firebaseio.com',
      projectId: 'ava-app-dev-92f7f',
      storageBucket: 'ava-app-dev-92f7f.appspot.com',
      messagingSenderId: '316081949771',
      appId: '1:316081949771:web:786112e9ba07d72b12d1ce',
    },
  },
  // Only used in development mode when BACKEND_ENV is set to 'local', which
  // is triggered on `yarn dev_loval`
  local: {
    segmentKey: STAGE_SEGMENT_WRITE_KEY,
    captionerUrl: 'http://localhost:3000',
    saasUrl: 'http://localhost:6001',
    chabarakUrl: '',
    scribeUrl: 'http://localhost:3000',
    subscriptionUrl: '',
    firebaseFunctionsEndpoint: 'http://localhost:5001/ava-app-dev-92f7f/us-central1',
    firebaseConfig: undefined,
  },
} as const;

export const getBackendConfig = (configKey: string) => {
  return BACKENDS[process.env.BACKEND_ENV || 'stage'][configKey] || BACKENDS['stage'][configKey];
};
