import { createSelector } from 'reselect';

import type { RootState } from '../store/store';

const selectConversationEnd = (state: RootState) => state.conversationEnd;

export const selectConversationEndInitiatedByCurrentUser = createSelector(
  [selectConversationEnd],
  (conversationEnd) => conversationEnd.conversationEndInitiatedByCurrentUser
);

export const selectEndedDueToConvoRestrictedSession = createSelector(
  [selectConversationEnd],
  (conversationEnd) => conversationEnd.endedDueToConvoRestrictedSession
);

export const selectCurrentConversationEndingStage = createSelector(
  [selectConversationEnd],
  (conversationEnd) => conversationEnd.currentConversationEndingStage
);

export const selectPageToShowAfterEndingConversation = createSelector(
  [selectConversationEnd],
  (conversationEnd) => conversationEnd.pageToShowAfterEndingConversation
);
