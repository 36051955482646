import { createSelector } from '@reduxjs/toolkit';

import type { State } from '../store/slices/ccMode';
import type { RootState } from '../store/store';

const selectCcMode = (state: RootState): State => state.ccMode;

export const selectNotifications = createSelector([selectCcMode], (ccMode) => ccMode.notifications);

export const selectCCMode = createSelector([selectCcMode], (ccMode) => ccMode.ccMode);

export const selectShowBubbleAboveCCMode = createSelector([selectCcMode], (ccMode) => ccMode.showBubbleAboveCCMode);

export const selectCharWidthLookup = createSelector([selectCcMode], (ccMode) => ccMode.charWidthLookup);

export const selectCCV2ConversationViewMode = createSelector(
  [selectCcMode],
  (ccMode) => ccMode.ccV2ConversationViewMode
);

export const selectRestartCCConversation = createSelector([selectCcMode], (ccMode) => ccMode.restartCCConversation);
