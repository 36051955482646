import type { TtsErrors, TtsGender, TtsVoice, TtSVoiceEntry } from '../store/slices/textToSpeech';
import { setTtsError, setUserTyping } from '../store/slices/textToSpeech';
import type { AppDispatch } from '../store/store';
import { sendWsMessage } from './ws-v1';

export interface SignalIAmTyping {
  type: 'signal-i-am-typing';
  fromUser: UserTyping;
}

export interface ResponseTextToSpeech {
  type: 'response-text-to-speech';
  isDone: boolean;
  error: string;
}

export interface RoomStatusUpdate {
  type: 'room-status-update';
  reason: string;
  participants: Participant[];
}

// this isn't everything in participant, but all we care for now.
export interface Participant {
  avaId: string;
  avaName: string;
  userName: string;
  scribe: boolean;
  isTyping: boolean;
}

export interface Message {
  text: string;
  voiceGender?: TtsGender;
  ttsProvider?: 'google' | 'elevenlabs';
  voiceId?: string;
}

export interface UserTyping {
  avaId: string;
  userName: string;
}

//TODO Remove these 2 consts when we remove TTS 1.0
export const TTS_PLACEHOLDER_VOICE_ID = 'openai-echo';
export const TTS_PLACEHOLDER_GENDER = 'male';

export const sortVoiceByGender = (ttsVoices: TtSVoiceEntry, gender: TtsGender) => {
  const femaleVoices = new Map();
  const neutralVoices = new Map();
  const maleVoices = new Map();

  ttsVoices.forEach((voice, key) => {
    if (voice.gender === 'neutral') {
      neutralVoices.set(key, voice);
    } else if (voice.gender === 'female') {
      femaleVoices.set(key, voice);
    } else {
      maleVoices.set(key, voice);
    }
  });

  let sortedVoices: TtSVoiceEntry = new Map();

  if (gender === 'neutral') {
    sortedVoices = new Map([...neutralVoices, ...femaleVoices, ...maleVoices]);
  } else if (gender === 'female') {
    sortedVoices = new Map([...femaleVoices, ...neutralVoices, ...maleVoices]);
  } else {
    sortedVoices = new Map([...maleVoices, ...neutralVoices, ...femaleVoices]);
  }

  return sortedVoices;
};

export const isAudioPlaying = (audioElement: HTMLAudioElement) => {
  return !audioElement.paused && !audioElement.ended && audioElement.currentTime > 0;
};

export const stopAudio = (audioElement: HTMLAudioElement) => {
  audioElement.pause();
  audioElement.currentTime = 0;
};

export const getImageNameForVoice = (voice?: TtsVoice) => {
  if (!voice) return;
  const voiceImageMapping = [
    ['nova', 'alloy', 'echo'],
    ['shimmer', 'fable', 'onyx'],
  ];
  const index = voiceImageMapping.findIndex((row) => row.includes(voice.voiceProviderId));

  return `${voice.gender}${index + 1}`;
};

export class TextToSpeechManager {
  ws: WebSocket;
  dispatch: AppDispatch;

  constructor(ws: WebSocket, dispatch: AppDispatch) {
    this.ws = ws;
    this.dispatch = dispatch;
    this.ws.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      this.handleMessage(message);
    });
    this.handleMessage = this.handleMessage.bind(this);
  }

  handleMessage(response: Partial<RoomStatusUpdate | ResponseTextToSpeech>): void {
    if (response.type === 'room-status-update') {
      if (response.reason && response.reason === 'someone-is-typing') {
        const user = response.participants?.find((participant) => participant.isTyping === true);
        this.dispatch(setUserTyping(user));
      } else {
        if (response.participants) {
          const noLongerTyping = !response.participants.some((participant) => participant.isTyping === true);
          if (noLongerTyping) {
            this.dispatch(setUserTyping(undefined));
          }
        }
      }
    } else if (response.type === 'response-text-to-speech') {
      if (response.error) {
        setTtsError(response.error as TtsErrors);
      }
    }
  }
  handleTextToSpeech(message: Message) {
    sendWsMessage(this.ws, { type: 'request-text-to-speech', ...message });
  }
  handleSendIAmTyping(userTyping: UserTyping) {
    sendWsMessage(this.ws, { type: 'signal-i-am-typing', fromUser: userTyping });
  }
}

export const attemptSpeakWithVoice = ({
  message,
  recording,
  startRecord,
  stopRecord,
  voice,
}: {
  message: string;
  recording: boolean;
  startRecord: () => void;
  stopRecord: () => void;
  // $FlowFixMe
  voice: SpeechSynthesisVoice;
}) => {
  if (!window.speechSynthesis || !voice) return;

  const utterThis = new SpeechSynthesisUtterance(message);
  // 1 is the default, ranges from 0.1 to 10. 1 sounds a bit too fast.
  utterThis.rate = 0.7;
  utterThis.voice = voice;

  if (recording) {
    stopRecord();
    utterThis.onend = startRecord;
    utterThis.onerror = startRecord;
  }

  window.speechSynthesis.speak(utterThis);
};
