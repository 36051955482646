import useTheme from '@mui/material/styles/useTheme';
import React from 'react';

type Props = {
  desktop?: boolean;
};

export const ConvoV2CloseIcon = React.memo(({ desktop }: Props) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const fillColor = desktop || darkMode ? 'white' : 'black';
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0673 15.1827C16.1254 15.2407 16.1714 15.3097 16.2028 15.3855C16.2343 15.4614 16.2505 15.5427 16.2505 15.6249C16.2505 15.707 16.2343 15.7883 16.2028 15.8642C16.1714 15.94 16.1254 16.009 16.0673 16.067C16.0092 16.1251 15.9403 16.1712 15.8644 16.2026C15.7885 16.234 15.7072 16.2502 15.6251 16.2502C15.543 16.2502 15.4617 16.234 15.3858 16.2026C15.3099 16.1712 15.241 16.1251 15.1829 16.067L10.0001 10.8835L4.81729 16.067C4.70002 16.1843 4.54096 16.2502 4.3751 16.2502C4.20925 16.2502 4.05019 16.1843 3.93292 16.067C3.81564 15.9498 3.74976 15.7907 3.74976 15.6249C3.74976 15.459 3.81564 15.2999 3.93292 15.1827L9.11651 9.99986L3.93292 4.81705C3.81564 4.69977 3.74976 4.54071 3.74976 4.37486C3.74976 4.20901 3.81564 4.04995 3.93292 3.93267C4.05019 3.8154 4.20925 3.74951 4.3751 3.74951C4.54096 3.74951 4.70002 3.8154 4.81729 3.93267L10.0001 9.11627L15.1829 3.93267C15.3002 3.8154 15.4593 3.74951 15.6251 3.74951C15.791 3.74951 15.95 3.8154 16.0673 3.93267C16.1846 4.04995 16.2505 4.20901 16.2505 4.37486C16.2505 4.54071 16.1846 4.69977 16.0673 4.81705L10.8837 9.99986L16.0673 15.1827Z"
        fill={fillColor}
        fill-opacity="0.32"
      />
    </svg>
  );
});
