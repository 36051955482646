import { combineReducers } from 'redux';

import accountReducer from '../reducers/account';
import scribeConversation from '../reducers/legacyConversationReducer';
import { audioV2Reducer } from './slices/audioV2';
import { authReducer } from './slices/auth';
import { AvaTranslateReducer } from './slices/avaTranslate';
import { boostReducer } from './slices/boost';
import { ccModeReducer } from './slices/ccMode';
import { conversationReducer } from './slices/conversation';
import { conversationEndReducer } from './slices/conversationEnd';
import { quickSetupReducer } from './slices/quickSetup';
import { rateConversationReducer } from './slices/rateConversation';
import { recallAIReducer } from './slices/recallAI';
import { savedTranscriptReducer } from './slices/savedTranscript';
import { scribeReducer } from './slices/scribe';
import { scribeDashboardReducer } from './slices/scribeDashboard';
import { scribeNotificationReducer } from './slices/scribeNotification';
import { textToSpeechReducer } from './slices/textToSpeech';
import { uiStateReducer } from './slices/uiState';
import { userProfileReducer } from './slices/userProfile';
import { v1SessionReducer } from './slices/v1Session';

const rootReducer = combineReducers({
  scribeConversation,
  ui: uiStateReducer,
  boost: boostReducer,
  scribeNotifications: scribeNotificationReducer,
  scribeDashboard: scribeDashboardReducer,
  scribe: scribeReducer,
  auth: authReducer,
  account: accountReducer,
  savedTranscript: savedTranscriptReducer,
  rateConversation: rateConversationReducer,
  audioV2: audioV2Reducer,
  ccMode: ccModeReducer,
  userProfile: userProfileReducer,
  v1Session: v1SessionReducer,
  quickSetup: quickSetupReducer,
  conversation: conversationReducer,
  conversationEnd: conversationEndReducer,
  recallAI: recallAIReducer,
  avaTranslate: AvaTranslateReducer,
  textToSpeech: textToSpeechReducer,
});

export default rootReducer;
