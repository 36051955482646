// Like the Array.sort compare function, this should
// if left === right: return 0
// if left < right: return negative value
// if left > right: return positive value
export type BinarySearchCompare<T> = (left: T, right: T) => number;

export const compareIntegerStrings: BinarySearchCompare<string> = (left, right) => {
  const leftInt = parseInt(left);
  const rightInt = parseInt(right);
  return leftInt - rightInt;
};

// Returns target index if found or -1 if not found
export function binarySearchArray<T>(arr: T[], target: T, compare: BinarySearchCompare<T>): number {
  let leftIndex = 0;
  let rightIndex = arr.length - 1;

  while (leftIndex <= rightIndex) {
    const midIndex = Math.floor((leftIndex + rightIndex) / 2);
    const midValue = arr[midIndex];

    const compared = compare(midValue, target);
    if (compared === 0) {
      return midIndex;
    } else if (compared < 0) {
      leftIndex = midIndex + 1; // Search right half
    } else {
      rightIndex = midIndex - 1; // Search left half
    }
  }

  return -1;
}
