import type { AxiosResponse } from 'axios';
import axios from 'axios';

import { getAuthInstance, restName } from '../firebase';
import { getBackendConfig } from './backends';

export const httpRequestWithToken = async <T = any>({
  url,
  method,
  payload,
  options,
}: {
  url: string;
  method: string;
  payload?: any;
  options?: any;
}) => {
  const token = await getAuthInstance().currentUser?.getIdToken();
  const opts = {
    ...options,
    auth: { username: restName, password: token },
  } as const;

  return httpRequest<T>({ url, method, payload, options: opts });
};

export const httpRequest = async <T>({
  url,
  method,
  payload,
  options,
}: {
  url: string;
  method: string;
  payload?: any;
  options?: any;
}): Promise<AxiosResponse<T>> => {
  try {
    options = options || {};
    const res = await axios({
      url,
      method,
      data: payload,
      ...options,
    });

    // monkey-patch response object for easier logging
    res.request.url = url;
    res.request.payload = payload;
    res.request.method = method;

    return res;
  } catch (err: any) {
    // Exception may be raised at different points of request lifecycle
    // and we want to monkey-patch the object the same as the
    // regular response above.

    if (err.response) {
      // Http request was made, but server replied with a non-2xx status code
      // (by default, axios raises an exception in such case).
      err.request = { url, method, payload };

      // Backend may send also specific error message embedded in the response json.
      // This can be formatted differently, depending on particular api implementation.
      const { data } = err.response;
      err.message = data.error?.error?.content?.message || data.msg || data.name || err.message;
    } else if (err.request) {
      // Http request was made, but no response was received
      err.request.url = url;
      err.request.payload = payload;
      err.request.method = method;
    } else {
      // Error happened during request setup or serialization
      err.request = { url, method, payload };
    }

    throw err;
  }
};

export const getAvaBackendEndpoint = (): string =>
  localStorage.getItem('avaBackendUrl') || getBackendConfig('captionerUrl');

export const getScribeBackendEndpoint = (): string =>
  localStorage.getItem('scribeBackendUrl') || getBackendConfig('scribeUrl');

export const firebaseFunctionsEndpoint = getBackendConfig('firebaseFunctionsEndpoint');

export const cloudTranscriptsEndpoint = `${firebaseFunctionsEndpoint}/widgets/cloudTranscripts`;

export const smartRecapEndpoint = `${firebaseFunctionsEndpoint}/generateSmartRecap`;

export const getSubscriptionBackendEndpoint = (): string => getBackendConfig('subscriptionUrl');

export const getSaasBackendEndpoint = (): string =>
  localStorage.getItem('saasBackendUrl') || getBackendConfig('saasUrl');

export const getSearchValueFromString = (searchString: string, key: string, _default: any) => {
  if (!searchString) {
    return _default;
  }
  const params = new URLSearchParams(searchString);
  return params.get(key) || _default;
};

export const getSearchValue = (window: any, key: string, _default: any = undefined) => {
  // I don't know why, but window.location can sometimes be undefined. This looks
  // like browser bug, but even when window.location is undefined,
  // document.location is set.
  const location = window.location || document.location;
  const searchString = location.search;
  return getSearchValueFromString(searchString, key, _default);
};

export const setSearchValue = (window: any, key: string, value: string) => {
  const location = window.location || document.location;
  const url = new URL(location.href);
  const oldUrlString = url.toString();
  const params = new URLSearchParams(url.search);

  params.set(key, value);
  url.search = params.toString();
  const newUrlString = url.toString();
  if (oldUrlString !== newUrlString) {
    window.history.replaceState(null, '', newUrlString);
  }
};

export const clearSearchValue = (window: any, key: string) => {
  const location = window.location || document.location;
  const url = new URL(location.href);
  const oldUrlString = url.toString();
  const params = new URLSearchParams(url.search);

  params.delete(key);
  url.search = params.toString();
  const newUrlString = url.toString();
  if (oldUrlString !== newUrlString) {
    window.history.replaceState(null, '', newUrlString);
  }
};

const initBackend = getSearchValue(window, 'endpoint', undefined);
if (initBackend && initBackend.endsWith('ava.me')) {
  const url = new URL(initBackend);
  if (url.hostname.endsWith('ava.me')) {
    localStorage.setItem('avaBackendUrl', initBackend);
  }
}

export const updateBackendAva = (backendId: string, backendsAva: any) => {
  localStorage.setItem('avaBackendUrl', backendsAva[backendId].url);
  window.location.replace('/');
};

export const fetchWithTimeout = async (url: string, options: RequestInit & { timeout?: number } = {}) => {
  const { timeout = 5000, ...fetchOptions } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(url, {
    ...fetchOptions,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
};
